import React from "react";
import { useEffect } from "react";
import useAxiosInstance from "../../../../../Utilities/Hooks/useAxiosInstance";
import { useState } from "react";
import { DEMAND_PRODUCTS_API } from "../../../../../Utilities/APIs/APIs";
import Image from "../../../../Partials/Elements/Image/Image";

function ViewDemandProducts({ targetID }) {
  const axiosInstance = useAxiosInstance();

  const [demandProductInfo, setDemandProductInfo] = useState(null);

  useEffect(() => {
    async function fetchAndSetSingleMaterial() {
      const { data } = await axiosInstance.get(
        DEMAND_PRODUCTS_API + "getSingleDemandProduct/" + targetID
      );
      setDemandProductInfo(data);
    }
    fetchAndSetSingleMaterial();
  }, [targetID, axiosInstance]);

  return (
    <div className="crud_view_content">
      {demandProductInfo && (
        <>
          <h1>Customer Name</h1>
          <p>{demandProductInfo?.customer?.name}</p>

          <h1> Phone</h1>
          <p>{demandProductInfo?.customer?.mobile}</p>
          <h1>Email</h1>
          <p>{demandProductInfo?.email}</p>

          <h1>Product Name</h1>
          <p>{demandProductInfo?.productSize?.product?.name}</p>
          <h1>Date</h1>
          <p>{new Date(demandProductInfo?.createdAt).toDateString()}</p>
          <Image
                imgLink={demandProductInfo?.productSize?.product?.cardFrontImage}
                imgAlt={"Card Front Image"}
           />
        </>
      )}
    </div>
  );
}

export default ViewDemandProducts;

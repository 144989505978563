
import { RiAdminLine, RiUser2Line, RiUserSharedLine } from "react-icons/ri";
import NavCard from "../../../../../Partials/Layouts/NavCardList/NavCard/NavCard";
import NavCardList from "../../../../../Partials/Layouts/NavCardList/NavCardList";

export const ordersFromStatusRoute = "/main/ordersFromStatus/";

function EmployeesScreen() {


  return (
    <NavCardList numOfCards="four">
      <NavCard cardName={`Employees`} navCardLink={"/main/employee"}>
        <RiUser2Line />
      </NavCard>
      <NavCard
        cardName={`Invite Employees`}
        navCardLink={"/main/inviteEmployees"}
      >
        <RiUserSharedLine />
      </NavCard>
      {/* <NavCard cardName={`Admin`}
      navCardLink={"/main/admins"}
      >
        
        <RiAdminLine />
      </NavCard> */}
      {/* <NavCard cardName={`Departments`} navCardLink={"/main/departments"}>
        <RiGiftFill />
      </NavCard>
      <NavCard cardName={`Roles`} navCardLink={"/main/roles"}>
        <RiGiftFill />
      </NavCard>
      <NavCard cardName={`Employee Roles`} navCardLink={"/main/employeeRoles"}>
        <RiGiftFill />
      </NavCard>
      <NavCard
        cardName={`Employee Designations`}
        navCardLink={"/main/employeeDesignations"}
      >
        <RiGiftFill />
      </NavCard>
      <NavCard
        cardName={`Designations`}
        navCardLink={"/main/designations"}
      >
        <RiGiftFill />
      </NavCard> */}
    </NavCardList>
  );
}
export default EmployeesScreen;

import { useEffect, useState } from "react";
import useAxiosInstance from "../../../../../Utilities/Hooks/useAxiosInstance";
import Form from "../../../../Partials/Layouts/Forms/Form";
import ShortTextInput from "../../../../Partials/Layouts/Forms/FormInputs/ShortTextInput/ShortTextInput";
import FormSubmitButton from "../../../../Partials/Layouts/Forms/FormSubmitButton/FormSubmitButton";
import { FAQ_API } from "../../../../../Utilities/APIs/APIs";
import SwitchInput from "../../../../Partials/Layouts/Forms/FormInputs/SwitchInput/SwitchInput";
import CustomEditor from "../../../../Partials/Elements/CustomEditor/CustomEditor";
import NumberInput from "../../../../Partials/Layouts/Forms/FormInputs/NumberInput/NumberInput";

function UpdateFAQ({ type, targetID, triggerFetch, setShowUpdateForm }) {
  const axiosInstance = useAxiosInstance();

  const [title, setTitle] = useState("");
  const [description, setDescription] = useState("");
  const [isActive, setisActive] = useState(false);
  const [precedence, setPrecedence] = useState(0)

  useEffect(() => {
    async function fetchAndSetSingleFAQ() {
      const { data } = await axiosInstance.get(
        FAQ_API + "getSingleFAQ/" + targetID
      );
      setTitle(data.title);
      setDescription(data.description);
      setisActive(data.isActive);
    }
    fetchAndSetSingleFAQ();
  }, [targetID, axiosInstance]);

  async function handleSubmit(e) {
    e.preventDefault();
    const itemData = { title, type, description, isActive , precedence};

    const response = await axiosInstance.patch(FAQ_API + targetID, itemData);
    if (response) {
      triggerFetch();
      setShowUpdateForm(false);
    }
  }

  return (
    <div className="crate_form">
      <Form onSubmit={handleSubmit}>
        <div>
          <ShortTextInput
            label={`Title`}
            value={title}
            placeholder={`Enter Title`}
            setState={setTitle}
          />
          <CustomEditor setState={setDescription} data={description} />
          <SwitchInput
            label={`isActive`}
            toggleSwitch={() => setisActive((prevState) => !prevState)}
            checked={isActive}
          />
          <NumberInput
            label={`Precedence`}
            value={precedence}
            placeholder={`Enter Precedence`}
            setState={setPrecedence}
          />
        </div>
        <FormSubmitButton text="Submit" />
      </Form>
    </div>
  );
}

export default UpdateFAQ;

import { useEffect, useState } from 'react'
import useAxiosInstance from '../../../../../Utilities/Hooks/useAxiosInstance'
import { BRANDS_API } from '../../../../../Utilities/APIs/APIs'

function ViewBrand({targetID}) {

    const axiosInstance = useAxiosInstance()
    
    const [brandInfo, setbrandInfo] = useState(null)

    useEffect(() => {
        async function fetchAndSetSingleBrand() {
          const { data } = await axiosInstance.get(BRANDS_API + 'getSingleBrand/' + targetID)
          setbrandInfo(data)
        }
        fetchAndSetSingleBrand()

      }, [targetID, axiosInstance])

    return (
        <div className="crud_view_content">
            {
                brandInfo &&
                <>
                    <h1>Brand</h1>
                    <p>{brandInfo.name}</p>
                    <h1>Slug</h1>
                    <p>{brandInfo.slug}</p>
                    <h1>Description</h1>
                    <p>{brandInfo.description}</p>
                    <h1>Precedence</h1>
                    <p>{brandInfo.precedence}</p>
                    <h1>Brand Category</h1>
                    <p>{brandInfo.brandCategory}</p>
                    <h1>Status</h1>
                    <p>{brandInfo.isActive === true ? 'True' : 'False'}</p>
                </>
            }
            
        </div>
    )
}

export default ViewBrand
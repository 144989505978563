import React, { useEffect, useRef } from 'react';
import './CustomEditor.css';

function CustomEditor({ setState, data }) {
    const editorRef = useRef(null);
    const editorInstance = useRef(null);

    useEffect(() => {
        const loadEditor = async () => {
            if (!window.CKEDITOR) {
                const script = document.createElement('script');
                script.src = 'https://cdn.ckeditor.com/ckeditor5/41.4.2/super-build/ckeditor.js';
                script.async = true;
                script.onload = () => {
                    initializeEditor();
                };
                document.body.appendChild(script);
            } else {
                initializeEditor();
            }
        };

        const initializeEditor = () => {
            if (window.CKEDITOR && editorRef.current) {
                window.CKEDITOR.ClassicEditor.create(editorRef.current, {
                    toolbar: {
                        items: [
                            'exportPDF', 'exportWord', '|',
                            'findAndReplace', 'selectAll', '|',
                            'heading', '|',
                            'bold', 'italic', 'strikethrough', 'underline', 'code', 'subscript', 'superscript', 'removeFormat', '|',
                            'bulletedList', 'numberedList', 'todoList', '|',
                            'outdent', 'indent', '|',
                            'undo', 'redo', '-',
                            'fontSize', 'fontFamily', 'fontColor', 'fontBackgroundColor', 'highlight', '|',
                            'alignment', '|',
                            'link', 'uploadImage', 'blockQuote', 'insertTable', 'mediaEmbed', 'codeBlock', 'htmlEmbed', '|',
                            'specialCharacters', 'horizontalLine', 'pageBreak', '|',
                            'textPartLanguage', '|',
                            'sourceEditing'
                        ],
                        shouldNotGroupWhenFull: true
                    },
                    placeholder: '',
                    fontFamily: {
                        options: [
                            'default',
                            'Arial, Helvetica, sans-serif',
                            'Courier New, Courier, monospace',
                            'Georgia, serif',
                            'Lucida Sans Unicode, Lucida Grande, sans-serif',
                            'Tahoma, Geneva, sans-serif',
                            'Times New Roman, Times, serif',
                            'Trebuchet MS, Helvetica, sans-serif',
                            'Verdana, Geneva, sans-serif'
                        ],
                        supportAllValues: true
                    },
                    fontSize: {
                        options: [10, 12, 14, 'default', 18, 20, 22],
                        supportAllValues: true
                    },
                    removePlugins: [
                        'AIAssistant', 'CKBox', 'CKFinder', 'EasyImage',
                        'MultiLevelList', 'RealTimeCollaborativeComments',
                        'RealTimeCollaborativeTrackChanges', 'RealTimeCollaborativeRevisionHistory',
                        'PresenceList', 'Comments', 'TrackChanges', 'TrackChangesData', 'RevisionHistory',
                        'Pagination', 'WProofreader', 'MathType', 'SlashCommand', 'Template', 'DocumentOutline',
                        'FormatPainter', 'TableOfContents', 'PasteFromOfficeEnhanced', 'CaseChange'
                    ]
                }).then(editor => {
                    editorInstance.current = editor;
                    if (data) {
                        editor.setData(data);
                    }
                    editor.model.document.on('change:data', () => {
                      
                        if (typeof setState === 'function') {
                            setState(editor.getData());
                        }
                    });
                }).catch(error => {
                    console.error('There was a problem initializing the editor.', error);
                });
            } else {
                console.error('CKEditor is not loaded or editorRef is not defined');
            }
        };

        loadEditor();

        return () => {
            if (editorInstance.current) {
                editorInstance.current.destroy();
                editorInstance.current = null;
            }
        };
    }, [setState, data]);

    return (
        <div className="custom_editor">
            <div ref={editorRef}></div>
        </div>
    );
}

export default CustomEditor;

import React, { useEffect, useState } from "react";
import {
  PRODUCTS_API,
  PRODUCT_COLLECTIONS_API,
} from "../../../../../../Utilities/APIs/APIs";
import useAxiosInstance from "../../../../../../Utilities/Hooks/useAxiosInstance";
import Form from "../../../../../Partials/Layouts/Forms/Form";
import SelectInput from "../../../../../Partials/Layouts/Forms/FormInputs/SelectInput/SelectInput";
import SelectOption from "../../../../../Partials/Layouts/Forms/FormInputs/SelectInput/SelectOption/SelectOption";
import FormSubmitButton from "../../../../../Partials/Layouts/Forms/FormSubmitButton/FormSubmitButton";
import SearchSelectField from "../../../../../Partials/Layouts/Forms/FormInputs/searchSelectField/SearchSelectField";

function CreateProductCollectionProducts({
  id,
  triggerFetch,
  setShowCreateForm,
}) {
  const axiosInstance = useAxiosInstance();
  const [product, setProduct] = useState("");
  const [products, setProducts] = useState(null);
  const [options, setOptions] = useState(null);
  const [searchTerm, setSearchTerm] = useState("");
  
  const [selectValue, setSelectValue] = useState(null);
  console.log("selectValue", selectValue);

  useEffect(() => {
    async function fetchAndSetPairedProducts() {
      const { data } = await axiosInstance.get(
        PRODUCTS_API + "/getAllProductsForCollection"
      );

      console.log(data);
      setProducts(data);
      const optionsData = data.map((product) => {
        return {
          value: product?._id,
          label: product?.name,
        };
      });
      setOptions(optionsData);
    }
    fetchAndSetPairedProducts();
  }, [axiosInstance]);

  async function handleSubmit(e) {
    e.preventDefault();
    const itemData = new FormData();
    itemData.append("productCollection", id);
    itemData.append("product", selectValue.value);
    const response = await axiosInstance.post(
      PRODUCT_COLLECTIONS_API + "createProductCollectionProduct",
      itemData
    );
    if (response) {
      triggerFetch();
      setShowCreateForm(false);
    }
  }

  return (
    <div className="crate_form">
      <Form onSubmit={handleSubmit}>
        <SearchSelectField
          options={options}
          value={selectValue}
          setState={setSelectValue}
        />
        {/* <SelectInput label={"Product"} value={product} setState={setProduct}>
          <SelectOption optionValue="" optionText="Select Product" />
          {filteredProducts?.map((product) => (
            <SelectOption
              key={product._id}
              optionValue={product._id}
              optionText={product.name}
            />
          ))}
        </SelectInput> */}
        <FormSubmitButton text="Add Product" />
      </Form>
    </div>
  );
}

export default CreateProductCollectionProducts;

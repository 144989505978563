import { RiCloseLine } from 'react-icons/ri'

import './ModalHeader.css'

function ModalHeader({ modalHeading, setShowModalContent }) {
  return (
    <div className='modal_header'>
      <h1 className="modal_heading">{modalHeading}</h1>
      <button type='button' className='modal_close' onClick={() => setShowModalContent(false)}>
        <RiCloseLine />
      </button>
    </div>
  )
}

export default ModalHeader
import { useEffect, useState } from "react"
import useAxiosInstance from "../../../../../Utilities/Hooks/useAxiosInstance"
import Form from "../../../../Partials/Layouts/Forms/Form"
import ShortTextInput from "../../../../Partials/Layouts/Forms/FormInputs/ShortTextInput/ShortTextInput"
import FormSubmitButton from "../../../../Partials/Layouts/Forms/FormSubmitButton/FormSubmitButton"
import { DEPARTMENTS_API } from "../../../../../Utilities/APIs/APIs"

import slugify from "react-slugify"

function UpdateDepartment({targetID, triggerFetch, setShowUpdateForm}) {

    const axiosInstance = useAxiosInstance()

    const [name, setName] = useState('')  
    const [description, setDescription] = useState('')
    
    useEffect(() => {
        async function fetchAndSetSingleCatagory() {
          const { data } = await axiosInstance.get(DEPARTMENTS_API + 'getSingleDepartment/' + targetID)
          setName(data.name)
          setDescription(data.description)
        }
        fetchAndSetSingleCatagory()

      }, [targetID, axiosInstance])

    async function handleSubmit(e) {

        e.preventDefault()
        const itemData = new FormData()

        itemData.append('name', name)
        itemData.append('description', description)
        itemData.append('slug', slugify(name))
    
        const response = await axiosInstance.patch(DEPARTMENTS_API + targetID, itemData)
        if (response) {
            triggerFetch()
            setShowUpdateForm(false)
        }
    }

    return (
        <div className='crate_form'>
            <Form onSubmit={handleSubmit}>
                <div>
                   <ShortTextInput
                        label={`Department Name`}
                        value={name}
                        placeholder={`Department Name`}
                        setState={setName}
                    />
                    <ShortTextInput
                        label={`Description`}
                        value={description}
                        placeholder={`Description`}
                        setState={setDescription}
                    />
                    <ShortTextInput
                        label={`Slug`}
                        value={slugify(name)}
                        placeholder={`Slug`}
                        disabled
                    />

                </div>
                <FormSubmitButton text="Submit" />
            </Form>
        </div>
    )
}

export default UpdateDepartment
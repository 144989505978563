import {useState } from "react";
import useAxiosInstance from "../../../../../../Utilities/Hooks/useAxiosInstance";
import NavCardList from "../../../../../Partials/Layouts/NavCardList/NavCardList";
import NavCard from "../../../../../Partials/Layouts/NavCardList/NavCard/NavCard";
import {
  RiGiftFill,
} from "react-icons/ri";

export const ordersFromStatusRoute = "/main/ordersFromStatus/";

function ProductGrouping() {
  return (
    <NavCardList numOfCards="four">
      <NavCard
        cardName={`Catergories`}
        navCardLink={'/main/catagoriesScreen'}
      >
        <RiGiftFill />
      </NavCard>

      <NavCard
        cardName={`Collections`}
        navCardLink={"/main/CollectionScreen/"}
      >
        <RiGiftFill />
      </NavCard>
    </NavCardList>
  );
}

export default ProductGrouping;

import React, { useState } from "react";
import useAxiosInstance from "../../../../../Utilities/Hooks/useAxiosInstance";
import { PRODUCT_COST } from "../../../../../Utilities/APIs/APIs";
import { Form } from "react-router-dom";
import ShortTextInput from "../../../../Partials/Layouts/Forms/FormInputs/ShortTextInput/ShortTextInput";
import FormSubmitButton from "../../../../Partials/Layouts/Forms/FormSubmitButton/FormSubmitButton";

function CreateProductCost({ productID, triggerFetch, setShowCreateForm }) {
  const axiosInstance = useAxiosInstance();

  const [unitCost, setUnitCost] = useState("");

  async function handleSubmit(e) {
    e.preventDefault();
    const itemData = new FormData();

    itemData.append("product", productID);
    itemData.append("unitCost", unitCost);

    const response = await axiosInstance.post(PRODUCT_COST, itemData);
    if (response) {
      triggerFetch();
      setShowCreateForm(false);
    }
  }

  return (
    <div className="crate_form">
      <Form onSubmit={handleSubmit}>
        <div>
          <ShortTextInput
            label={`unitCost`}
            value={unitCost}
            placeholder={`Unit Cost`}
            setState={setUnitCost}
          />
        </div>
        <FormSubmitButton text="Create Product Image" />
      </Form>
    </div>
  );
}

export default CreateProductCost;

import { useEffect, useState } from 'react'
import useAxiosInstance from '../../../../../Utilities/Hooks/useAxiosInstance'
import { CARE_API } from '../../../../../Utilities/APIs/APIs'
import Image from '../../../../Partials/Elements/Image/Image'

function ViewCare({targetID}) {

    const axiosInstance = useAxiosInstance()
    
    const [careInfo, setcareInfo] = useState(null)

    useEffect(() => {
        async function fetchAndSetSingleCare() {
          const { data } = await axiosInstance.get(CARE_API + 'getSingleCare/' + targetID)
          setcareInfo(data)
        }
        fetchAndSetSingleCare()

      }, [targetID, axiosInstance])

    return (
        <div className="crud_view_content">
            {
                careInfo &&
                <>
                    <h1>Care</h1>
                    <p>{careInfo.name}</p>
                    <h1>Care Image</h1>
                    <Image
                        imgLink={careInfo.image}
                        imgAlt={'Care Image'}
                    />
                    <h1>Status</h1>
                    <p>{careInfo.isActive === true ? "True" : "False"}</p>
                </>
            }
            
        </div>
    )
}

export default ViewCare
import { useEffect, useState } from "react"
import { COLORS_API, PRODUCTCOLOR_API } from "../../../../../Utilities/APIs/APIs"
import useAxiosInstance from "../../../../../Utilities/Hooks/useAxiosInstance"
import Form from "../../../../Partials/Layouts/Forms/Form"
import FormSubmitButton from "../../../../Partials/Layouts/Forms/FormSubmitButton/FormSubmitButton"
import SelectInput from "../../../../Partials/Layouts/Forms/FormInputs/SelectInput/SelectInput"
import SelectOption from "../../../../Partials/Layouts/Forms/FormInputs/SelectInput/SelectOption/SelectOption"

function CreateProductColor({productID, triggerFetch, setShowCreateForm }) {

    const axiosInstance = useAxiosInstance()

    const [color, setColor] = useState('')

    const [colors, setColors] = useState(null)

    useEffect(() => {

        async function fetchAndSetColors() {
            const { data } = await axiosInstance.get(COLORS_API)
            setColors(data)
        }
        fetchAndSetColors()

    }, [axiosInstance])

    async function handleSubmit(e) {

        e.preventDefault()
        const itemData = new FormData()

        itemData.append('product', productID)
        itemData.append('color', color)

        const response = await axiosInstance.post(PRODUCTCOLOR_API, itemData)
        if (response) {
            triggerFetch()
            setShowCreateForm(false)
        }
    }

    return (
        <div className='crate_form'>
            <Form onSubmit={handleSubmit}>
                <div>

                    <SelectInput
                        label={'Color'}
                        value={color}
                        setState={setColor}
                    >
                        <SelectOption optionValue='' optionText="Select Color" />
                        {colors?.map((color => (
                            <SelectOption optionValue={color._id} key={color._id}
                                optionText={color?.name} />
                        )))}
                    </SelectInput>
                  
                </div>
                <FormSubmitButton text="Add Product Color" />
            </Form>
        </div>
    )
}

export default CreateProductColor
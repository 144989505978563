import { useEffect, useState } from 'react'
import useAxiosInstance from '../../../../../Utilities/Hooks/useAxiosInstance'
import { ROLES_API } from '../../../../../Utilities/APIs/APIs'

function ViewRole({targetID}) {

    const axiosInstance = useAxiosInstance()
    
    const [roleInfo, setRoleInfo] = useState(null)

    useEffect(() => {
        async function fetchAndSetSingleRole() {
          const { data } = await axiosInstance.get(ROLES_API + 'getSingleRole/' + targetID)
          setRoleInfo(data)
        }
        fetchAndSetSingleRole()

      }, [targetID, axiosInstance])

    return (
        <div className="crud_view_content">
            {
                roleInfo &&
                <>
                    <h1>Role</h1>
                    <p>{roleInfo.name}</p>
                    <h1>Description</h1>
                    <p>{roleInfo.description}</p>
                    <h1>Slug</h1>
                    <p>{roleInfo.slug}</p>
    
                </>
            }
            
        </div>
    )
}

export default ViewRole
import React, { useContext, useState } from 'react'
import { Link } from 'react-router-dom'
import PasswordInput from '../../../Partials/Layouts/Forms/FormInputs/PasswordInput/PasswordInput'
import EmailInput from '../../../Partials/Layouts/Forms/FormInputs/EmailInput/EmailInput'
import FormSubmitButton from '../../../Partials/Layouts/Forms/FormSubmitButton/FormSubmitButton'
import Form from '../../../Partials/Layouts/Forms/Form'
import AppContext from '../../../../AppContext/AppContext'
import './Login.css'

function Login() {

    const { login } = useContext(AppContext)
    const [email, setEmail] = useState('')
    const [password, setPassword] = useState('')
    
    
    function handleSubmit(e) {

        e.preventDefault();

        const employeeData = {
            email,
            password
        }

        login(employeeData)

    }

    return (
        <div className="form_wrapper">
            <h3>Login</h3>
            <Form
                onSubmit={handleSubmit}
                hasImage
            >

                <EmailInput
                    label='Email'
                    value={email}
                    placeholder='Please Enter your Email'
                    setState={setEmail}
                />
                <PasswordInput
                    label='Password'
                    value={password}
                    placeholder='Please Enter your Password'
                    setState={setPassword}
                />

                <FormSubmitButton text='Login' />

            </Form>
            <div className='forgot_password'>
                <Link to='/auth/forgotPassword'>
                    <p>Forgot Password?</p>
                </Link>
            </div>
            <div className="have_account">
                <p>Don't have an account?</p>
                <Link to='/auth/register'>
                    <button>Register</button>
                </Link>
            </div>
        </div>
    )
}

export default Login
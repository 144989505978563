import { useEffect, useState } from 'react'
import useAxiosInstance from '../../../../../Utilities/Hooks/useAxiosInstance'
import { SIZE_API } from '../../../../../Utilities/APIs/APIs'

function ViewSize({targetID}) {

    const axiosInstance = useAxiosInstance()
    
    const [sizeInfo, setsizeInfo] = useState(null)

    useEffect(() => {
        async function fetchAndSetSingleSize() {
          const { data } = await axiosInstance.get(SIZE_API + 'getSingleSize/' + targetID)
          setsizeInfo(data)
        }
        fetchAndSetSingleSize()

      }, [targetID, axiosInstance])

    return (
        <div className="crud_view_content">
            {
                sizeInfo &&
                <>
                    <h1>Size</h1>
                    <p>{sizeInfo.name}</p>
                    <h1>Size Type</h1>
                    <p>{sizeInfo.sizeType}</p>
                    <h1>Barcode No</h1>
                    <p>{sizeInfo.barcodeNo}</p>
                    <h1>Precedence</h1>
                    <p>{sizeInfo.precedence}</p>
                    <h1>Status</h1>
                    <p>{sizeInfo.isActive === true ? "True" : "False"}</p>
                </>
            }
            
        </div>
    )
}

export default ViewSize
import { useEffect, useRef, useState } from 'react'
import CreateButton from '../../../../Partials/Layouts/CRUDs/CRUDHeader/CreateButton/CreateButton';
import CRUDTable from '../../../../Partials/Layouts/CRUDs/CRUDTable/CRUDTable';
import CRUDTableHeader from '../../../../Partials/Layouts/CRUDs/CRUDTable/CRUDTableHeader/CRUDTableHeader';
import CRUDth from '../../../../Partials/Layouts/CRUDs/CRUDTable/CRUDTableHeader/CRUDth/CRUDth';
import ViewButton from '../../../../Partials/Layouts/CRUDs/CRUDButtons/ViewButton/ViewButton';
import EditButton from '../../../../Partials/Layouts/CRUDs/CRUDButtons/EditButton/EditButton';
import DeleteButton from '../../../../Partials/Layouts/CRUDs/CRUDButtons/DeleteButton/DeleteButton';
import ShortTextCell from '../../../../Partials/Layouts/CRUDs/CRUDTable/CRUDTableCells/ShortTextCell/ShortTextCell';
import CRUDTableRow from '../../../../Partials/Layouts/CRUDs/CRUDTable/CRUDTableRow/CRUDTableRow';
import Modal from '../../../../Partials/Elements/Modal/Modal';
import CreateCare from './CreateCare';
import ViewCare from './ViewCare';
import useAxiosInstance from '../../../../../Utilities/Hooks/useAxiosInstance';
import DeleteItem from '../../../../Partials/Layouts/DeleteItem/DeleteItem';
import UpdateCare from './UpdateCare';
import { CARE_API } from '../../../../../Utilities/APIs/APIs';
import ImageCell from '../../../../Partials/Layouts/CRUDs/CRUDTable/CRUDTableCells/ImageCell/ImageCell';


function Cares() {

  const [cares, setCares] = useState(null)
  const screenHeaerRef = useRef()
  const [screenHeaderHeight, setScreenHeaderHeight] = useState(0);

  useEffect(() => {
    let screenHeaderHeight = screenHeaerRef.current.clientHeight;
    setScreenHeaderHeight(screenHeaderHeight)
  }, [screenHeaerRef]);

  const [showCreateForm, setShowCreateForm] = useState(false);
  const [showViewSection, setShowViewSection] = useState(false);
  const [showUpdateForm, setShowUpdateForm] = useState(false);
  const [showDeleteSection, setShowDeleteSection] = useState(false)
  const [toggleFetch, setToggleFetch] = useState(false)

  const [targetID, setTargetID] = useState(null)
  const axiosInstance = useAxiosInstance()

  useEffect(() => {

    async function fetchAndSetCares() {
      const { data } = await axiosInstance.get(CARE_API)
      setCares(data)
    }
    fetchAndSetCares()

  }, [toggleFetch, axiosInstance])

  function triggerFetch() {
    setToggleFetch(prevState => !prevState)
  }
  return (
    <>
      <div className='screen_header' ref={screenHeaerRef}>
        <h1 className='screen_heading'>Cares ({cares?.length}  in total)</h1>
        <CreateButton
          screenTopicSingular={'Care'}
          setShowCreateForm={setShowCreateForm}
        />
      </div>
      <CRUDTable screenHeaderHeight={screenHeaderHeight}>
        <CRUDTableHeader>
          <CRUDth th='Care' />
          <CRUDth th='Image' />
          <CRUDth th='Actions' />
        </CRUDTableHeader>
        <tbody>
          {
            cares?.map(care =>
              <CRUDTableRow key={care._id}>
                <ShortTextCell text={care.name} />
                <ImageCell imgSrc={care.image} />
                <td>
                  <div className="action_buttons_wrapper">
                    <div className="action_buttons">
                      <ViewButton
                        setShowViewSection={setShowViewSection}
                        targetID={care._id}
                        setTargetID={setTargetID}
                        tooltipName={"View"} 
                        tooltipContent={"View"}
                      />

                      <EditButton
                        setShowUpdateForm={setShowUpdateForm}
                        targetID={care._id}
                        setTargetID={setTargetID}
                        tooltipName={"Edit"} 
                        tooltipContent={"Edit"}
                      />

                      <DeleteButton
                        setShowDeleteSection={setShowDeleteSection}
                        targetID={care._id}
                        setTargetID={setTargetID}
                        tooltipName={"Delete"} 
                        tooltipContent={"Delete"}
                      />
                    </div>
                  </div>
                </td>
              </CRUDTableRow>

            )
          }
        </tbody>
      </CRUDTable>
      {
        showCreateForm &&
        <Modal
          extraClass={'small'}
          modalHeading={`Add Care`}
          setShowModalContent={setShowCreateForm}
        >
          <CreateCare
            triggerFetch={triggerFetch}
            setShowCreateForm={setShowCreateForm}
          />
        </Modal>
      }
      {
        showUpdateForm &&
        <Modal
          extraClass={'small'}
          modalHeading={`Update Care`}
          setShowModalContent={setShowUpdateForm}
        >
          <UpdateCare
            triggerFetch={triggerFetch}
            targetID={targetID}
            setShowUpdateForm={setShowUpdateForm}
          />
        </Modal>
      }
      {
        showViewSection &&
        <Modal
          extraClass={'small'}
          modalHeading={`View Care`}
          setShowModalContent={setShowViewSection}
        >
          <ViewCare
            targetID={targetID}
          />
        </Modal>
      }
      {
        showDeleteSection &&
        <Modal
          extraClass={'small'}
          modalHeading={`Delete Care`}
          setShowModalContent={setShowDeleteSection}
        >
          <DeleteItem
            api={CARE_API}
            triggerFetch={triggerFetch}
            targetID={targetID}
            setShowDeleteItem={setShowDeleteSection}
          />
        </Modal>
      }
    </>
  )
}

export default Cares
import {  useState } from "react"
import { PRICEHISTORY_API } from "../../../../../Utilities/APIs/APIs"
import useAxiosInstance from "../../../../../Utilities/Hooks/useAxiosInstance"
import Form from "../../../../Partials/Layouts/Forms/Form"
import FormSubmitButton from "../../../../Partials/Layouts/Forms/FormSubmitButton/FormSubmitButton"
import ShortTextInput from "../../../../Partials/Layouts/Forms/FormInputs/ShortTextInput/ShortTextInput"
import DateInput from "../../../../Partials/Layouts/Forms/FormInputs/DateInput/DateInput"

function CreatePriceHistories({ productID, triggerFetch, setShowCreateForm }) {

    const axiosInstance = useAxiosInstance()

    const [price, setPrice] = useState('')
    const [date, setDate] = useState('')


    async function handleSubmit(e) {

        e.preventDefault()
        const itemData = new FormData()

        itemData.append('product', productID)
        itemData.append('price', price)
        itemData.append('date', date)

        const response = await axiosInstance.post(PRICEHISTORY_API, itemData)
        if (response) {
            triggerFetch()
            setShowCreateForm(false)
        }
    }

    return (
        <div className='crate_form'>
            <Form onSubmit={handleSubmit}>
                <div>

                    <ShortTextInput
                        label={'Price'}
                        value={price}
                        setState={setPrice}
                    />

                    <DateInput
                        label={'Date'}
                        value={date}
                        setState={setDate}
                    />
                  
                </div>
                <FormSubmitButton text="Add Price" />
            </Form>
        </div>
    )
}

export default CreatePriceHistories
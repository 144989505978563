import { useEffect, useRef, useState } from 'react'
import CreateButton from '../../../../../Partials/Layouts/CRUDs/CRUDHeader/CreateButton/CreateButton';
import CRUDTable from '../../../../../Partials/Layouts/CRUDs/CRUDTable/CRUDTable';
import CRUDTableHeader from '../../../../../Partials/Layouts/CRUDs/CRUDTable/CRUDTableHeader/CRUDTableHeader';
import CRUDth from '../../../../../Partials/Layouts/CRUDs/CRUDTable/CRUDTableHeader/CRUDth/CRUDth';
import ViewButton from '../../../../../Partials/Layouts/CRUDs/CRUDButtons/ViewButton/ViewButton';
import EditButton from '../../../../../Partials/Layouts/CRUDs/CRUDButtons/EditButton/EditButton';
import DeleteButton from '../../../../../Partials/Layouts/CRUDs/CRUDButtons/DeleteButton/DeleteButton';
import ShortTextCell from '../../../../../Partials/Layouts/CRUDs/CRUDTable/CRUDTableCells/ShortTextCell/ShortTextCell';
import CRUDTableRow from '../../../../../Partials/Layouts/CRUDs/CRUDTable/CRUDTableRow/CRUDTableRow';
import Modal from '../../../../../Partials/Elements/Modal/Modal';
import useAxiosInstance from '../../../../../../Utilities/Hooks/useAxiosInstance';
import DeleteItem from '../../../../../Partials/Layouts/DeleteItem/DeleteItem';
import { EMPLOYEE_DESIGNATIONS_API } from '../../../../../../Utilities/APIs/APIs';
import ViewEmployeeDesignations from './ViewEmployeeDesignations';
import CreateEmployeeDesignations from './CreateEmployeeDesignations';
import UpdateEmployeeDesignations from './UpdateEmployeeDesignations';


function EmployeeDesignations() {

  const [employeeDesignations, setEmployeeDesignations] = useState(null)
  const screenHeaerRef = useRef()
  const [screenHeaderHeight, setScreenHeaderHeight] = useState(0);

  useEffect(() => {
    let screenHeaderHeight = screenHeaerRef.current.clientHeight;
    setScreenHeaderHeight(screenHeaderHeight)
  }, [screenHeaerRef]);

  const [showCreateForm, setShowCreateForm] = useState(false);
  const [showViewSection, setShowViewSection] = useState(false);
  const [showUpdateForm, setShowUpdateForm] = useState(false);
  const [showDeleteSection, setShowDeleteSection] = useState(false)
  const [toggleFetch, setToggleFetch] = useState(false)

  const [targetID, setTargetID] = useState(null)
  const axiosInstance = useAxiosInstance()

  useEffect(() => {

    async function fetchAndSetEmployeeDesignations() {
      const { data } = await axiosInstance.get(EMPLOYEE_DESIGNATIONS_API)
      setEmployeeDesignations(data)
    }
    fetchAndSetEmployeeDesignations()

  }, [toggleFetch, axiosInstance])

  function triggerFetch() {
    setToggleFetch(prevState => !prevState)
  }
  return (
    <>
      <div className='screen_header' ref={screenHeaerRef}>
        <h1 className='screen_heading'>Employee Designation ({employeeDesignations?.length}  in total)</h1>
        <CreateButton
          screenTopicSingular={'Employee Designation'}
          setShowCreateForm={setShowCreateForm}
        />
      </div>
      <CRUDTable screenHeaderHeight={screenHeaderHeight}>
        <CRUDTableHeader>
          <CRUDth th='Employee' />
          <CRUDth th='Designations' />
          <CRUDth th='Action' />

        </CRUDTableHeader>
        <tbody>
          {
            employeeDesignations?.map(employeeDesignation =>
              <CRUDTableRow key={employeeDesignation._id}>
                <ShortTextCell text={employeeDesignation?.employee?.name} />
                <ShortTextCell text={employeeDesignation?.designation?.name} />
                <td>
                  <div className="action_buttons_wrapper">
                    <div className="action_buttons">
                      <ViewButton
                        setShowViewSection={setShowViewSection}
                        targetID={employeeDesignation._id}
                        setTargetID={setTargetID}
                      />

                      <EditButton
                        setShowUpdateForm={setShowUpdateForm}
                        targetID={employeeDesignation._id}
                        setTargetID={setTargetID}
                      />

                      <DeleteButton
                        setShowDeleteSection={setShowDeleteSection}
                        targetID={employeeDesignation._id}
                        setTargetID={setTargetID}
                      />
                    </div>
                  </div>
                </td>
              </CRUDTableRow>

            )
          }
        </tbody>
      </CRUDTable>
      {
        showCreateForm &&
        <Modal
          extraClass={'small'}
          modalHeading={`Add Employee Designation`}
          setShowModalContent={setShowCreateForm}
        >
          <CreateEmployeeDesignations
            triggerFetch={triggerFetch}
            setShowCreateForm={setShowCreateForm}
          />
        </Modal>
      }
      {
        showUpdateForm &&
        <Modal
          extraClass={'small'}
          modalHeading={`Update Employee Designation`}
          setShowModalContent={setShowUpdateForm}
        >
          <UpdateEmployeeDesignations
            triggerFetch={triggerFetch}
            targetID={targetID}
            setShowUpdateForm={setShowUpdateForm}
          />
        </Modal>
      }
      {
        showViewSection &&
        <Modal
          extraClass={'small'}
          modalHeading={`View Employee Designation`}
          setShowModalContent={setShowViewSection}
        >
          <ViewEmployeeDesignations
            targetID={targetID}
          />
        </Modal>
      }
      {
        showDeleteSection &&
        <Modal
          extraClass={'small'}
          modalHeading={`Delete Employee Designation`}
          setShowModalContent={setShowDeleteSection}
        >
          <DeleteItem
            api={EMPLOYEE_DESIGNATIONS_API}
            triggerFetch={triggerFetch}
            targetID={targetID}
            setShowDeleteItem={setShowDeleteSection}
          />
        </Modal>
      }
    </>
  )
}

export default EmployeeDesignations
import "./DeleteItem.css";
import useAxiosInstance from "../../../../Utilities/Hooks/useAxiosInstance";


function DeleteItem({ api, targetID, setShowDeleteItem, triggerFetch }) {
  const axiosInstance = useAxiosInstance();

  async function handleDelete(id) {
    const res = await axiosInstance.delete(
      `${api}/${id}`
    );

    console.log(res);

    setShowDeleteItem(false);
    triggerFetch();
  }

  return (
    <div className="delete_item">
      <div className="delete_content">
        <h1>Are you sure?</h1>
        <p>Please confirm you want to delete this item.</p>
        <div className="delete_btn">
          <button
            onClick={() => {
              handleDelete(targetID);
            }}
            className="delete"
          >
            Delete
          </button>
          <button
            className="cancel"
            onClick={() => {
              setShowDeleteItem(false);
            }}
          >
            Cancel
          </button>
        </div>
      </div>
    </div>
  );
}

export default DeleteItem;

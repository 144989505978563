import { RiArrowDownSLine } from "react-icons/ri";
import SidebarItem from "../SidebarItem/SidebarItem";
import './DropDownItems.css'
// import { useNavigate } from "react-router-dom";
// import { useEffect } from "react";

function DropDownItems({
  type,
  icon,
  dropDownTitle,
  selectedDropdownItem,
  setSelectedDropdownItem,
  sidebarItems
}) {

  // const navigate = useNavigate()

  // useEffect(() => {
  //   if (selectedDropdownItem === 'Dashboard') {
  //     navigate('/main/dashboard')
  //   }

  // }, [navigate, selectedDropdownItem])


  return (
    <>
      {/* {dropDownTitle === 'Dashboard' && <SidebarItem title={dropDownTitle} children={icon} />} */}
      <div className="dropDownItems">
        <div
          onClick={() => setSelectedDropdownItem(dropDownTitle)}
          className={`${selectedDropdownItem === dropDownTitle ? 'active_title' : ''}`}
        >
          <div className='item_title'>
            {icon}
            <p>{dropDownTitle}</p>
          </div>
          {
            type === 'dropdown' &&
            <div className="dropdownIcon">
              {!(selectedDropdownItem === dropDownTitle) && <RiArrowDownSLine />}
            </div>
          }
        </div>
      </div>

      {(selectedDropdownItem === dropDownTitle) && sidebarItems?.map(sidebarItem => (
        <div className="d_items" k={sidebarItem.title}>
          <SidebarItem title={sidebarItem.title} children={sidebarItem.children} />
        </div>
      )
      )}
    </>
  );
}

export default DropDownItems;

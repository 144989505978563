import { useEffect, useState } from 'react'
import useAxiosInstance from '../../../../../../Utilities/Hooks/useAxiosInstance'
import { EMPLOYEE_ROLES_API } from '../../../../../../Utilities/APIs/APIs'

function ViewEmployeeRole({ targetID }) {

    const axiosInstance = useAxiosInstance()

    const [employeeRoleInfo, setEmployeeRoleInfo] = useState(null)

    useEffect(() => {
        async function fetchAndSetSingleEmployeeRole() {
            const { data } = await axiosInstance.get(EMPLOYEE_ROLES_API + 'getSingleEmployeeRole/' + targetID)
            setEmployeeRoleInfo(data)
        }
        fetchAndSetSingleEmployeeRole()

    }, [targetID, axiosInstance])

    return (
        <div className="crud_view_content">
            {
                employeeRoleInfo &&
                <>
                    <h1>Employee</h1>
                    <p>{employeeRoleInfo.employee?.name}</p>

                    <h1>Roles</h1>
                    <p>{employeeRoleInfo.role?.name}</p>
                </>
            }

        </div>
    )
}

export default ViewEmployeeRole

import { useRef } from "react";
import { useEffect } from "react";
import { useState } from "react";
import useAxiosInstance from "../../../../../../../Utilities/Hooks/useAxiosInstance";
import { ADDRESS_API } from "../../../../../../../Utilities/APIs/APIs";
import CRUDTable from "../../../../../../Partials/Layouts/CRUDs/CRUDTable/CRUDTable";
import CRUDTableHeader from "../../../../../../Partials/Layouts/CRUDs/CRUDTable/CRUDTableHeader/CRUDTableHeader";
import CRUDth from "../../../../../../Partials/Layouts/CRUDs/CRUDTable/CRUDTableHeader/CRUDth/CRUDth";
import CRUDTableRow from "../../../../../../Partials/Layouts/CRUDs/CRUDTable/CRUDTableRow/CRUDTableRow";
import ShortTextCell from "../../../../../../Partials/Layouts/CRUDs/CRUDTable/CRUDTableCells/ShortTextCell/ShortTextCell";
import ViewButton from "../../../../../../Partials/Layouts/CRUDs/CRUDButtons/ViewButton/ViewButton";
import { useParams } from "react-router-dom";
import Modal from "../../../../../../Partials/Elements/Modal/Modal";
import ViewAddress from "./ViewAddress";

function AddressesFromCustomer() {
  const [addresses, setAddresses] = useState(null);
  const screenHeaerRef = useRef();
  const [screenHeaderHeight, setScreenHeaderHeight] = useState(0);

  useEffect(() => {
    let screenHeaderHeight = screenHeaerRef.current.clientHeight;
    setScreenHeaderHeight(screenHeaderHeight);
  }, [screenHeaerRef]);

  const [showCreateForm, setShowCreateForm] = useState(false);
  const [showViewSection, setShowViewSection] = useState(false);
  const [showUpdateForm, setShowUpdateForm] = useState(false);
  const [showDeleteSection, setShowDeleteSection] = useState(false);
  const [toggleFetch, setToggleFetch] = useState(false);

  const [targetID, setTargetID] = useState(null);
  const axiosInstance = useAxiosInstance();
  const {customerID} = useParams()

  useEffect(() => {
    async function fetchAndSetAddresses() {
      const { data } = await axiosInstance.get(ADDRESS_API);
      const customerAddresses = data.filter(add => add.customer === customerID)
      setAddresses(customerAddresses)
    }
    fetchAndSetAddresses();
  }, [toggleFetch, axiosInstance, customerID]);

  function triggerFetch() {
    setToggleFetch((prevState) => !prevState);
  }
  return (
    <>
      <div className="screen_header" ref={screenHeaerRef}>
        <h1 className="screen_heading">
          Address ({addresses?.length} in total)
        </h1>
        {/* <CreateButton
          screenTopicSingular={'Employee Designation'}
          setShowCreateForm={setShowCreateForm}
        /> */}
      </div>
      <CRUDTable screenHeaderHeight={screenHeaderHeight}>
        <CRUDTableHeader>
          <CRUDth th="Name" />
          <CRUDth th="Area" />
          <CRUDth th="Action" />
        </CRUDTableHeader>
        <tbody>
          {addresses?.map((address) => (
            <CRUDTableRow key={address._id}>
              <ShortTextCell text={address?.name} />
              <ShortTextCell text={address?.area} />
              <td>
                <div className="action_buttons_wrapper">
                  <div className="action_buttons">
                    <ViewButton
                      setShowViewSection={setShowViewSection}
                      targetID={address._id}
                      setTargetID={setTargetID}
                    />
                    {/* 
                      <EditButton
                        setShowUpdateForm={setShowUpdateForm}
                        targetID={customer._id}
                        setTargetID={setTargetID}
                      /> */}

                    {/* <DeleteButton
                        setShowDeleteSection={setShowDeleteSection}
                        targetID={customer._id}
                        setTargetID={setTargetID}
                      /> */}

                  </div>
                </div>
              </td>
            </CRUDTableRow>
          ))}
        </tbody>
      </CRUDTable>

      {showViewSection && (
        <Modal
          extraClass={"small"}
          modalHeading={`View Address`}
          setShowModalContent={setShowViewSection}
        >
          <ViewAddress targetID={targetID} />
        </Modal>
      )}
      {/* {
        showDeleteSection &&
        <Modal
          extraClass={'small'}
          modalHeading={`Delete Employee Designation`}
          setShowModalContent={setShowDeleteSection}
        >
          <DeleteItem
            api={EMPLOYEE_DESIGNATIONS_API}
            triggerFetch={triggerFetch}
            targetID={targetID}
            setShowDeleteItem={setShowDeleteSection}
          />
        </Modal>
      } */}
    </>
  );
}

export default AddressesFromCustomer;

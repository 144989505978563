import { useRef, useState, useEffect, useContext } from "react";
import AppContext from "../../../../../../AppContext/AppContext";
import useAxiosInstance from "../../../../../../Utilities/Hooks/useAxiosInstance";
import { EMPLOYEE_INVITES_API } from "../../../../../../Utilities/APIs/AuthAPIs/AuthAPIs";
import CreateButton from "../../../../../Partials/Layouts/CRUDs/CRUDHeader/CreateButton/CreateButton";
import CRUDTable from "../../../../../Partials/Layouts/CRUDs/CRUDTable/CRUDTable";
import CRUDTableHeader from "../../../../../Partials/Layouts/CRUDs/CRUDTable/CRUDTableHeader/CRUDTableHeader";
import CRUDth from "../../../../../Partials/Layouts/CRUDs/CRUDTable/CRUDTableHeader/CRUDth/CRUDth";
import CRUDTableRow from "../../../../../Partials/Layouts/CRUDs/CRUDTable/CRUDTableRow/CRUDTableRow";
import ShortTextCell from "../../../../../Partials/Layouts/CRUDs/CRUDTable/CRUDTableCells/ShortTextCell/ShortTextCell";
import DeleteButton from "../../../../../Partials/Layouts/CRUDs/CRUDButtons/DeleteButton/DeleteButton";
import Modal from "../../../../../Partials/Elements/Modal/Modal";
import CreateInviteEmployee from "./CreateInviteEmployee";
import DeleteItem from "../../../../../Partials/Layouts/DeleteItem/DeleteItem";




function InviteEmployees() {

    const [inviteEmployees, setInviteEmployees] = useState(null)
    const screenHeaerRef = useRef()
    const [screenHeaderHeight, setScreenHeaderHeight] = useState(0);

    useEffect(() => {
        let screenHeaderHeight = screenHeaerRef.current.clientHeight;
        setScreenHeaderHeight(screenHeaderHeight)
    }, [screenHeaerRef]);

    const [showCreateForm, setShowCreateForm] = useState(false);
    const [showViewSection, setShowViewSection] = useState(false);
    const [showUpdateForm, setShowUpdateForm] = useState(false);
    const [showDeleteSection, setShowDeleteSection] = useState(false)
    const [toggleFetch, setToggleFetch] = useState(false)
    const [targetID, setTargetID] = useState(null)

    const { employee } = useContext(AppContext)
    const axiosInstance = useAxiosInstance()

    useEffect(() => {

        async function fetchAndSetEmployeeInvites() {
            const { data } = await axiosInstance.get(EMPLOYEE_INVITES_API + 'getAllInvites')
            setInviteEmployees(data)
        }
        fetchAndSetEmployeeInvites()


    }, [toggleFetch, axiosInstance])


    function triggerFetch() {
        setToggleFetch(prevState => !prevState)
    }
    return (
        <>
            <div className='screen_header' ref={screenHeaerRef}>
                <h1 className='screen_heading'>Invite Employees ({inviteEmployees?.length} in total)</h1>
                <CreateButton
                    screenTopicSingular={'Invite Employee'}
                    setShowCreateForm={setShowCreateForm}
                />
            </div>
            <CRUDTable screenHeaderHeight={screenHeaderHeight}>
                <CRUDTableHeader>
                    <CRUDth th='Email' />
                    <CRUDth th='Actions' />
                </CRUDTableHeader>
                <tbody>
                    {inviteEmployees?.map(inviteEmployee => (
                        <CRUDTableRow key={inviteEmployee._id}>
                            <ShortTextCell text={inviteEmployee.email} />
                            <td>
                                <div className="action_buttons_wrapper">
                                    <div className="action_buttons">
                                        {/* <ViewButton
                                            setShowViewSection={setShowViewSection}
                                            targetID={inviteEmployee._id}
                                            setTargetID={setTargetID}
                                        /> */}

                                        {/* <EditButton
                                            setShowUpdateForm={setShowUpdateForm}
                                            targetID={inviteEmployee._id}
                                            setTargetID={setTargetID}
                                        /> */}

                                        <DeleteButton
                                            setShowDeleteSection={setShowDeleteSection}
                                            targetID={inviteEmployee._id}
                                            setTargetID={setTargetID}
                                        />

                                    </div>

                                </div>
                            </td>
                        </CRUDTableRow>
                    ))}
                </tbody>

            </CRUDTable>

            {
                showCreateForm &&
                <Modal
                    extraClass={'small'}
                    modalHeading={`Create Invite`}
                    setShowModalContent={setShowCreateForm}
                >
                    <CreateInviteEmployee
                        employee={employee}
                        triggerFetch={triggerFetch}
                        setShowCreateForm={setShowCreateForm}

                    />
                </Modal>
            }
            {
                showViewSection &&
                <Modal
                    extraClass={'small'}
                    modalHeading={`View Department`}
                    setShowModalContent={setShowViewSection}
                >
                    {/* <ViewDepartment
                        employee={employee}
                        triggerFetch={triggerFetch}
                        targetID={targetID}

                    /> */}
                </Modal>
            }
            {
                showUpdateForm &&
                <Modal
                    extraClass={'small'}
                    modalHeading={`Update Department`}
                    setShowModalContent={setShowUpdateForm}
                >
                    {/* <UpdateDepartment
                        employee={employee}
                        triggerFetch={triggerFetch}
                        targetID={targetID}
                        setShowUpdateForm={setShowUpdateForm}

                    /> */}
                </Modal>
            }
            {
                showDeleteSection &&
                <Modal
                    extraClass={'small'}
                    modalHeading={`Delete Invite Employee`}
                    setShowModalContent={setShowDeleteSection}
                >
                    <DeleteItem
                        api={EMPLOYEE_INVITES_API + 'deleteInvite'}
                        employee={employee}
                        triggerFetch={triggerFetch}
                        targetID={targetID}
                        setShowDeleteItem={setShowDeleteSection}
                    />
                </Modal>
            }
        </>
    )
}

export default InviteEmployees
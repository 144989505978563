import React, { useState } from 'react'
import FormSubmitButton from '../../../Partials/Layouts/Forms/FormSubmitButton/FormSubmitButton'
import Form from '../../../Partials/Layouts/Forms/Form'
import './EmailVerification.css'
import ShortTextInput from '../../../Partials/Layouts/Forms/FormInputs/ShortTextInput/ShortTextInput'
function EmailVerification() {
    const [verificationCode, setVerificationCode] = useState('')
    return (
        <div className="form_wrapper forgot_pass verification">
            <h3>Email Verification</h3>
            <p>We will send a one time SMS code to your Email</p>
            <Form
                // onSubmit={handleSubmit}
                hasImage
            >

                <ShortTextInput
                    label='Verification Code'
                    value={verificationCode}
                    placeholder='Please Enter Verification Code'
                    setState={setVerificationCode}
                />

                <div className="have_account">
                    <p style={{paddingBottom: '0px' }}>Didn’t receive a link?</p>
                    <button>resend Code</button>

                </div>
                <FormSubmitButton text='Verify' />
            </Form>

        </div>
    )
}

export default EmailVerification
import { useEffect, useState } from 'react'
import useAxiosInstance from '../../../../../Utilities/Hooks/useAxiosInstance'
import { FAQ_API } from '../../../../../Utilities/APIs/APIs'

function ViewFAQ({targetID}) {

    const axiosInstance = useAxiosInstance()
    
    const [faqInfo, setfaqInfo] = useState(null)

    useEffect(() => {
        async function fetchAndSetSingleFAQ() {
          const { data } = await axiosInstance.get(FAQ_API + 'getSingleFAQ/' + targetID)
          setfaqInfo(data)
        }
        fetchAndSetSingleFAQ()

      }, [targetID, axiosInstance])

    return (
        <div className="crud_view_content">
            {
                faqInfo &&
                <>
                    <h1>Title</h1>
                    <p>{faqInfo.title}</p>
                  
                    <h1>Type</h1>
                    <p>{faqInfo.type}</p>

                    <h1>Description</h1>
                    <div dangerouslySetInnerHTML={{ __html: faqInfo?.description }} />

                    <h1>Is Active</h1>
                    <p>{faqInfo.isActive === true ? "True" : 'False'}</p>                   
                </>
            }
            
        </div>
    )
}

export default ViewFAQ
import React from 'react'
import NavCardList from '../../../../../Partials/Layouts/NavCardList/NavCardList';
import NavCard from '../../../../../Partials/Layouts/NavCardList/NavCard/NavCard';
import { RiGiftFill } from 'react-icons/ri';

function CollectionScreen() {
  
    return (
      <NavCardList numOfCards="four">
        <NavCard
          cardName={`Product Collections`}
          navCardLink={'/main/productCollections'}
        >
          <RiGiftFill />
        </NavCard>
  
        <NavCard
          cardName={`Featured Collections`}
          navCardLink={"/main/featurdCollection/"}
        >
          <RiGiftFill />
        </NavCard>
      </NavCardList>
    );
  }

export default CollectionScreen
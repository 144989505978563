import { useEffect, useState } from "react"
import useAxiosInstance from "../../../../../../Utilities/Hooks/useAxiosInstance"
import Form from "../../../../../Partials/Layouts/Forms/Form"
import FormSubmitButton from "../../../../../Partials/Layouts/Forms/FormSubmitButton/FormSubmitButton"
import { EMPLOYEE_API, DESIGNATIONS_API, EMPLOYEE_DESIGNATIONS_API } from "../../../../../../Utilities/APIs/APIs"
import SelectInput from "../../../../../Partials/Layouts/Forms/FormInputs/SelectInput/SelectInput"
import SelectOption from "../../../../../Partials/Layouts/Forms/FormInputs/SelectInput/SelectOption/SelectOption"

function UpdateEmployeeDesignation({ targetID, triggerFetch, setShowUpdateForm }) {

    const axiosInstance = useAxiosInstance()

    const [employee, setEmployee] = useState('')
    const [designation, setDesignation] = useState('')

    const [employees, setEmployees] = useState(null)
    const [designations, setDesignations] = useState(null)

    useEffect(() => {
        async function fetchAndSetSingleEmployeeDesignation() {
            const { data } = await axiosInstance.get(EMPLOYEE_DESIGNATIONS_API + 'getSingleEmployeeDesignation/' + targetID)
            setEmployee(data.employee._id)
            setDesignation(data.designation._id)
        }
        fetchAndSetSingleEmployeeDesignation()

    }, [targetID, axiosInstance])

    useEffect(() => {

        async function fetchAndSetEmployees() {
            const { data } = await axiosInstance.get(EMPLOYEE_API)
            setEmployees(data)
        }
        fetchAndSetEmployees()

        async function fetchAndSetDesignations() {
            const { data } = await axiosInstance.get(DESIGNATIONS_API)
            setDesignations(data)
        }
        fetchAndSetDesignations()

    }, [axiosInstance])

    async function handleSubmit(e) {

        e.preventDefault()
        const itemData = new FormData()

        itemData.append('employee', employee)
        itemData.append('designation', designation)

        const response = await axiosInstance.patch(EMPLOYEE_DESIGNATIONS_API + targetID, itemData)
        if (response) {
            triggerFetch()
            setShowUpdateForm(false)
        }
    }

    return (
        <div className='crate_form'>
            <Form onSubmit={handleSubmit}>
                <div>
                <SelectInput
                        label={'Employee'}
                        value={employee}
                        setState={setEmployee}
                    >
                        <SelectOption optionValue='' optionText="Select Employee" />
                        {employees?.map((employee => (
                            <SelectOption optionValue={employee._id} key={employee._id}
                                optionText={employee?.name} />
                        )))}
                    </SelectInput>

                    <SelectInput
                        label={'Designation'}
                        value={designation}
                        setState={setDesignation}
                    >
                        <SelectOption optionValue='' optionText="Select Designation" />
                        {designations?.map((designation => (
                            <SelectOption optionValue={designation._id} key={designation._id}
                                optionText={designation?.name} />
                        )))}
                    </SelectInput>

                </div>
                <FormSubmitButton text="Update Employee Designation" />
            </Form>
        </div>
    )
}

export default UpdateEmployeeDesignation
import { RiEyeLine } from "react-icons/ri"
import CRUDButton from "../CRUDButton"


function ViewButton({ setShowViewSection, setTargetID, targetID, tooltip, tooltipContent, tooltipName }) {
    return (
        <CRUDButton
            handleClick={() => {
                setShowViewSection(true)
                setTargetID(targetID)
            }}
            tooltip={tooltip}
            tooltipName={tooltipName} 
            tooltipContent={tooltipContent}
        >
           <RiEyeLine />
        </CRUDButton>
    )
}

export default ViewButton
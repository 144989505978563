import { useEffect, useState } from 'react'
import useAxiosInstance from '../../../../../Utilities/Hooks/useAxiosInstance';
import { COLORS_API } from '../../../../../Utilities/APIs/APIs'

function ViewColor({targetID}) {
    

    const axiosInstance = useAxiosInstance()
    const [colorInfo, setcolorInfo] = useState(null)

    useEffect(() => {
        async function fetchAndSetSingleColor() {
          const { data } = await axiosInstance.get(COLORS_API + 'getSingleColor/' + targetID)
          setcolorInfo(data)
        }
        fetchAndSetSingleColor()

      }, [targetID, axiosInstance])

    return (
        <div className="crud_view_content">
            {
                colorInfo &&
                <>
                    <h1>Title</h1>
                    <p>{colorInfo.name}</p>

                    <h1>Hex Value</h1>
                    <p>{colorInfo.hexValue}</p>

                    <h1>Status</h1>
                    <p>{colorInfo.isActive === true ? 'True' : 'False'}</p>
                </>
            }
            
        </div>
    )
}

export default ViewColor
import React from 'react'

function RefundedFromDelivered({
    setShowRefundedOrder,
    targetID,
    handleStatusChange,
  }) {
    return (
      <div className="delete_item">
        <div className="delete_content">
          <h1>Are you sure?</h1>
          <p>Please confirm you want to refund this order?</p>
          <div className="delete_btn">
            <button
              className="delete"
              onClick={() => handleStatusChange(targetID)}
            >
              Accept
            </button>
            <button
              className="cancel"
              onClick={() => {
                setShowRefundedOrder(false);
              }}
            >
              Cancel
            </button>
          </div>
        </div>
      </div>
    );
  }

export default RefundedFromDelivered
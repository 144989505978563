import { useEffect, useState } from 'react'
import useAxiosInstance from '../../../../../Utilities/Hooks/useAxiosInstance'
import { PRODUCTS_API } from '../../../../../Utilities/APIs/APIs'
import Image from '../../../../Partials/Elements/Image/Image'

function ViewProduct({ targetID }) {

    const axiosInstance = useAxiosInstance()

    const [productInfo, setProductInfo] = useState(null)

    useEffect(() => {
        async function fetchAndSetSingleProduct() {
            const { data } = await axiosInstance.get(PRODUCTS_API + 'getSingleProduct/' + targetID)
            // console.log(data,'dddddddddddview')
            setProductInfo(data)
        }
        fetchAndSetSingleProduct()

    }, [targetID, axiosInstance])

    return (
        <div className="crud_view_content">
            {
                productInfo &&
                <>
                    <h1>Sku Id</h1>
                    <p>{productInfo?.skuId}</p>

                    <h1>Category</h1>
                    <p>{productInfo?.category?.categoryName}</p>

                    <h1>Brand</h1>
                    <p>{productInfo?.brand?.name}</p>

                    <h1>Name</h1>
                    <p>{productInfo?.name}</p>

                    <h1>Gender</h1>
                    <p>{productInfo?.gender}</p>

                    <h1>Slug</h1>
                    <p>{productInfo?.slug}</p>

                    <h1>Price</h1>
                    <p>{productInfo?.price}</p>

                    <h1>Current Price</h1>
                    <p>{productInfo?.currentPrice}</p>
                    <h1>Quantity</h1>
                    <p>{productInfo?.quantity}</p>

                    <h1>Partner</h1>
                    <p>{productInfo?.partner?.name}</p>

                    <h1>Description</h1>
                    <p>{console.log(productInfo?.description)}</p>
                    <p>{productInfo?.description}</p>

                    <h1>Discount</h1>
                    <p>{productInfo?.discount?.percentage}</p>

                    <h1>Is Visible</h1>
                    <p>{productInfo?.isVisible === true ? 'True' : 'False'}</p>

                    <h1>Card Front Image</h1>
                    <Image
                        imgLink={productInfo?.cardFrontImage}
                        imgAlt={'Card Front Image'}
                    />

                    <h1>Card Hover Image</h1>
                    <Image
                        imgLink={productInfo?.cardHoverImage}
                        imgAlt={'cardHoverImage'}
                    />

                    <h1>Meta Title</h1>
                    <p>{productInfo?.metaTitle}</p>

                    <h1>Meta Description</h1>
                    <p>{productInfo?.metaDescription}</p>
                </>
            }

        </div>
    )
}

export default ViewProduct
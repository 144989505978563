import React, { useEffect, useState } from 'react'
import useAxiosInstance from '../../../../../../Utilities/Hooks/useAxiosInstance'
import { PRODUCT_COLLECTIONS_API } from '../../../../../../Utilities/APIs/APIs'

function ViewFeaturdCollection({targetID}) {
    const axiosInstance = useAxiosInstance()
    
    const [featurdCollectionInfo, setFeaturdCollectionInfo] = useState(null)
    useEffect(() => {
        async function fetchAndSetSingleProductCollections() {
          const { data } = await axiosInstance.get(PRODUCT_COLLECTIONS_API + 'getSingleFeaturedCollection/' + targetID)
          setFeaturdCollectionInfo(data)
        }
        fetchAndSetSingleProductCollections()

      }, [targetID, axiosInstance])

    return (
        <div className="crud_view_content">
            {
                featurdCollectionInfo &&
                <>
                    <h1>Product Collection</h1>
                    <p>{featurdCollectionInfo.productCollection?.name}</p>
                    <h1>precedence</h1>
                    <p>{featurdCollectionInfo?.precedence}</p>
                    <h1>Type</h1>
                    <p>{featurdCollectionInfo?.type}</p>
                </>
            }
            
        </div>
    )
}

export default ViewFeaturdCollection
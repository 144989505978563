import { useEffect, useState } from "react"
import useAxiosInstance from "../../../../../Utilities/Hooks/useAxiosInstance"
import Form from "../../../../Partials/Layouts/Forms/Form"
import ShortTextInput from "../../../../Partials/Layouts/Forms/FormInputs/ShortTextInput/ShortTextInput"
import SelectInput from "../../../../Partials/Layouts/Forms/FormInputs/SelectInput/SelectInput"
import SelectOption from "../../../../Partials/Layouts/Forms/FormInputs/SelectInput/SelectOption/SelectOption"
import FormSubmitButton from "../../../../Partials/Layouts/Forms/FormSubmitButton/FormSubmitButton"
import { DESIGNATIONS_API, DEPARTMENTS_API } from "../../../../../Utilities/APIs/APIs"

import slugify from "react-slugify"

function CreateDesignation({triggerFetch, setShowCreateForm}) {

    const axiosInstance = useAxiosInstance()
    
    const [department, setDepartment] = useState('')
    const [name, setName] = useState('')  
    const [description, setDescription] = useState('')

    const [departments, setDepartments] = useState(null)

    useEffect(() => {
        async function fetchAndsetDepartments() {
            const { data } = await axiosInstance.get(DEPARTMENTS_API)
            setDepartments(data)
        }
        fetchAndsetDepartments()

    }, [axiosInstance])

    async function handleSubmit(e) {

        e.preventDefault()
        const itemData = new FormData()

        itemData.append('department', department)
        itemData.append('name', name)
        itemData.append('slug', slugify(name))
        itemData.append('description', description)

        const response = await axiosInstance.post(DESIGNATIONS_API, itemData)
        if (response) {
            triggerFetch()
            setShowCreateForm(false)
        }
    }

    return (
        <div className='crate_form'>
            <Form onSubmit={handleSubmit}>
                <div>

                    <SelectInput
                        label={'Department'}
                        value={department}
                        setState={setDepartment}
                     >
                        <SelectOption optionValue='' optionText="Select Deparment" />
                        {departments?.map((department => (
                            <SelectOption optionValue={department._id} key={department._id}
                                optionText={department?.name} />
                        )))}
                    </SelectInput>

                    <ShortTextInput
                        label={`Designation Name`}
                        value={name}
                        placeholder={`Designation Name`}
                        setState={setName}
                    />
                    <ShortTextInput
                        label={`Description`}
                        value={description}
                        placeholder={`Description`}
                        setState={setDescription}
                    />
                    <ShortTextInput
                        label={`Slug`}
                        value={slugify(name)}
                        placeholder={`Slug`}
                        disabled
                    />

                </div>
                <FormSubmitButton text="Submit" />
            </Form>
        </div>
    )
}

export default CreateDesignation
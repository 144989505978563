import { useState } from "react"

import {   PRODUCT_IMAGES_API } from "../../../../../Utilities/APIs/APIs"
import useAxiosInstance from "../../../../../Utilities/Hooks/useAxiosInstance"
import Form from "../../../../Partials/Layouts/Forms/Form"
import ShortTextInput from "../../../../Partials/Layouts/Forms/FormInputs/ShortTextInput/ShortTextInput"
import FormSubmitButton from "../../../../Partials/Layouts/Forms/FormSubmitButton/FormSubmitButton"
import SwitchInput from "../../../../Partials/Layouts/Forms/FormInputs/SwitchInput/SwitchInput"
import { useEffect } from "react"
import ImageInput from "../../../../Partials/Layouts/Forms/FormInputs/ImageInput/ImageInput"


function UpdateProductImage({targetID, productID, triggerFetch, setShowUpdateForm }) {

    const axiosInstance = useAxiosInstance()

    const [image, setImage] = useState('')
    const [precedence, setPrecedence] = useState('')
    const [isActive, setIsActive] = useState(false)

    useEffect(() => {
        async function fetchAndSetSingleProductImage() {
            const { data } = await axiosInstance.get(PRODUCT_IMAGES_API + 'getSingleProductImage/' + targetID)
            setImage(data.image)
            setPrecedence(data.precedence)
            setIsActive(data.isActive)
        }
        fetchAndSetSingleProductImage()

    }, [targetID, axiosInstance])


    async function handleSubmit(e) {

        e.preventDefault()

        const itemData = new FormData()

        itemData.append('image', image)
        itemData.append('precedence', precedence)
        itemData.append('isActive', isActive)


        const response = await axiosInstance.patch(PRODUCT_IMAGES_API + targetID, itemData)
        if (response) {
            triggerFetch()
            setShowUpdateForm(false)
        }
    }

    return (
        <div className='crate_form'>
            <Form onSubmit={handleSubmit}>
                <div>
                <ImageInput
                        fieldId="1"
                        state={image}
                        setState={setImage}
                        allowUpdateImage
                    >
                        Upload Card Image
                    </ImageInput>
                    <ShortTextInput
                        label={`Precedence`}
                        value={precedence}
                        placeholder={`Precedence`}
                        setState={setPrecedence}
                    />
                    <SwitchInput
                        label={`Is Active`}
                        toggleSwitch={() => setIsActive(prevState => !prevState)}
                        checked={isActive}
                    />


                </div>
                <FormSubmitButton text="Create Product Image" />
            </Form>
        </div>
    )
}

export default UpdateProductImage
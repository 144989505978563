import React, { useEffect, useState } from 'react'
import useAxiosInstance from '../../../../../../Utilities/Hooks/useAxiosInstance'
import { SIZEGUIRDES } from '../../../../../../Utilities/APIs/APIs'
import { Form } from 'react-router-dom'
import ShortTextInput from '../../../../../Partials/Layouts/Forms/FormInputs/ShortTextInput/ShortTextInput'
import SwitchInput from '../../../../../Partials/Layouts/Forms/FormInputs/SwitchInput/SwitchInput'
import FormSubmitButton from '../../../../../Partials/Layouts/Forms/FormSubmitButton/FormSubmitButton'
import CustomEditor from '../../../../../Partials/Elements/CustomEditor/CustomEditor'

function UpdateSizeGuides({targetID, triggerFetch, setShowUpdateForm}) {

  const axiosInstance = useAxiosInstance()

  const [sizeGuidesInfo, setSizeGuidesInfo] = useState(null)  
  const [description, setDescription] = useState('')
  const [isActive, setIsActive] = useState('')   
  
  useEffect(() => {
      async function fetchAndSetSingleColor() {
        const { data } = await axiosInstance.get(SIZEGUIRDES + 'getSingleSizeGuide/' + targetID)
        setSizeGuidesInfo(data.name)
        setDescription(data.description)
        setIsActive(data.isActive)
      }
      fetchAndSetSingleColor()

    }, [targetID, axiosInstance])

  async function handleSubmit(e) {

      e.preventDefault()
      const itemData = new FormData()

      itemData.append('sizeGuidesInfo', sizeGuidesInfo)
      itemData.append('description', description)
      itemData.append('isActive', isActive)

      const response = await axiosInstance.patch(SIZEGUIRDES + targetID, itemData)
      if (response) {
          triggerFetch()
          setShowUpdateForm(false)
      }
  }

  return (
      <div className='crate_form'>
          <Form onSubmit={handleSubmit}>
              <div>
                  <ShortTextInput
                      label={`Name`}
                      value={sizeGuidesInfo}
                      placeholder={`Enter Name`}
                      setState={setSizeGuidesInfo}
                  />
                  {/* <ShortTextInput
                      label={`Hex Value`}
                      value={description}
                      placeholder={`Enter Hex Value`}
                      setState={setDescription}
                  /> */}
                <CustomEditor setState={setDescription} data={description} />
                   <SwitchInput
                      label={`Status`}
                      toggleSwitch={() => setIsActive(prevState => !prevState)}
                      checked={isActive}
                  />
              </div>
              <FormSubmitButton text="Submit" />
          </Form>
      </div>
  )
}
export default UpdateSizeGuides
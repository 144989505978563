import SidebarTop from "./SidebarTop/SidebarTop";
import "./Sidebar.css";
import SidebarItems from "./SidebarItems/SidebarItems";

function Sidebar() {
  return (
    <div className="sidebar">
      <div className="sidebar_inner">
        <div className="logo_menu_wrapper">
          <SidebarTop />
        </div>
        <SidebarItems />
      </div>
    </div>
  );
}

export default Sidebar;

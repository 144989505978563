import { useEffect, useState } from 'react'
import useAxiosInstance from '../../../../../Utilities/Hooks/useAxiosInstance'
import { DEPARTMENTS_API } from '../../../../../Utilities/APIs/APIs'

function ViewDepartment({targetID}) {

    const axiosInstance = useAxiosInstance()
    
    const [departmentInfo, setDepartmentInfo] = useState(null)

    useEffect(() => {
        async function fetchAndSetSingleDepartment() {
          const { data } = await axiosInstance.get(DEPARTMENTS_API + 'getSingleDepartment/' + targetID)
          setDepartmentInfo(data)
        }
        fetchAndSetSingleDepartment()

      }, [targetID, axiosInstance])

    return (
        <div className="crud_view_content">
            {
                departmentInfo &&
                <>
                    <h1>Department</h1>
                    <p>{departmentInfo.name}</p>
                    <h1>Description</h1>
                    <p>{departmentInfo.description}</p>
                    <h1>Slug</h1>
                    <p>{departmentInfo.slug}</p>
    
                </>
            }
            
        </div>
    )
}

export default ViewDepartment
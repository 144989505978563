import { useState } from "react"
import useAxiosInstance from "../../../../../Utilities/Hooks/useAxiosInstance"
import Form from "../../../../Partials/Layouts/Forms/Form"
import ShortTextInput from "../../../../Partials/Layouts/Forms/FormInputs/ShortTextInput/ShortTextInput"
import FormSubmitButton from "../../../../Partials/Layouts/Forms/FormSubmitButton/FormSubmitButton"
import { SIZE_API } from "../../../../../Utilities/APIs/APIs"
import SwitchInput from "../../../../Partials/Layouts/Forms/FormInputs/SwitchInput/SwitchInput"

function CreateSize({triggerFetch, setShowCreateForm}) {

    const axiosInstance = useAxiosInstance()

    const [name, setName] = useState('')  
    const [sizeType, setSizeType] = useState('')
    const [precedence, setPrecedence] = useState('')
    const [barcodeNo, setBarcodeNo] = useState('')
    const [isActive, setIsActive] = useState('') 

    async function handleSubmit(e) {

        e.preventDefault()
        const itemData = new FormData()

        itemData.append('name', name)
        itemData.append('sizeType', sizeType)
        itemData.append('barcodeNo', barcodeNo)
        itemData.append('precedence', precedence)
        itemData.append('isActive', isActive)

        const response = await axiosInstance.post(SIZE_API, itemData)
        if (response) {
            triggerFetch()
            setShowCreateForm(false)
        }
    }

    return (
        <div className='crate_form'>
            <Form onSubmit={handleSubmit}>
                <div>

                    <ShortTextInput
                        label={`Size Name`}
                        value={name}
                        placeholder={`Size Name`}
                        setState={setName}
                    />

                    <ShortTextInput
                        label={`Size Type`}
                        value={sizeType}
                        placeholder={`Size Type`}
                        setState={setSizeType}
                    />
                    <ShortTextInput
                        label={`Barcode No`}
                        value={barcodeNo}
                        placeholder={`Category Code`}
                        setState={setBarcodeNo}
                    />
                    <ShortTextInput
                        label={`Precedence`}
                        value={precedence}
                        placeholder={`Precedence`}
                        setState={setPrecedence}
                    />
                     <SwitchInput
                        label={`Status`}
                        toggleSwitch={() => setIsActive(prevState => !prevState)}
                        checked={isActive}
                    />

                </div>
                <FormSubmitButton text="Submit" />
            </Form>
        </div>
    )
}

export default CreateSize
import { useEffect, useState } from "react"
import useAxiosInstance from "../../../../../Utilities/Hooks/useAxiosInstance"
import Form from "../../../../Partials/Layouts/Forms/Form"
import FormSubmitButton from "../../../../Partials/Layouts/Forms/FormSubmitButton/FormSubmitButton"
import {COLORS_API, PRODUCTCOLOR_API } from "../../../../../Utilities/APIs/APIs"
import SelectInput from "../../../../Partials/Layouts/Forms/FormInputs/SelectInput/SelectInput"
import SelectOption from "../../../../Partials/Layouts/Forms/FormInputs/SelectInput/SelectOption/SelectOption"

function UpdateProductColor({  productID,  targetID, triggerFetch, setShowUpdateForm }) {

    const axiosInstance = useAxiosInstance()

    const [color, setColor] = useState('')

    const [colors, setColors] = useState(null)

    useEffect(() => {
        async function fetchAndSetSingleProductColor() {
            const { data } = await axiosInstance.get(PRODUCTCOLOR_API + 'getSingleProductColor/' + targetID)
            setColor(data.color.name)
        }
        fetchAndSetSingleProductColor()

    }, [targetID, axiosInstance])

    useEffect(() => {


        async function fetchAndSetBrands() {
            const { data } = await axiosInstance.get(COLORS_API)
            setColors(data)
        }
        fetchAndSetBrands()

    }, [axiosInstance])

    async function handleSubmit(e) {

        e.preventDefault()
        const itemData = new FormData()

        itemData.append('product', productID)
        itemData.append('color', color)

        const response = await axiosInstance.patch(PRODUCTCOLOR_API + targetID, itemData)
        if (response) {
            triggerFetch()
            setShowUpdateForm(false)
        }
    }

    return (
        <div className='crate_form'>
            <Form onSubmit={handleSubmit}>
                <div>

                    <SelectInput
                        label={'Color'}
                        value={color}
                        setState={setColor}
                    >
                        <SelectOption optionValue='' optionText="Select Color" />
                        {colors?.map((color => (
                            <SelectOption optionValue={color._id} key={color._id}
                                optionText={color?.name} />
                        )))}
                    </SelectInput>
                </div>
                <FormSubmitButton text="Update ProductColor" />
            </Form>
        </div>
    )
}

export default UpdateProductColor
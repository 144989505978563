import React, { useState } from 'react'
import useAxiosInstance from '../../../../../../Utilities/Hooks/useAxiosInstance'
import { SIZEGUIRDES } from '../../../../../../Utilities/APIs/APIs'
import { Form } from 'react-router-dom'
import ShortTextInput from '../../../../../Partials/Layouts/Forms/FormInputs/ShortTextInput/ShortTextInput'
import SwitchInput from '../../../../../Partials/Layouts/Forms/FormInputs/SwitchInput/SwitchInput'
import FormSubmitButton from '../../../../../Partials/Layouts/Forms/FormSubmitButton/FormSubmitButton'
import CustomEditor from '../../../../../Partials/Elements/CustomEditor/CustomEditor'

function CreateSizeGuides({triggerFetch, setShowCreateForm}) {

    const axiosInstance = useAxiosInstance()

    const [name, setName] = useState('')  
    const [description, setDescription] = useState('')
    const [isActive, setIsActive] = useState('')   

    async function handleSubmit(e) {

        e.preventDefault()
        const itemData = new FormData()

        itemData.append('name', name)
        itemData.append('description', description)
        itemData.append('isActive', isActive)

        const response = await axiosInstance.post(SIZEGUIRDES, itemData)
        if (response) {
            triggerFetch()
            setShowCreateForm(false)
        }
    }

    return (
        <div className='crate_form'>
            <Form onSubmit={handleSubmit}>
                <div>
                    <ShortTextInput
                        label={`Name`}
                        value={name}
                        placeholder={`Enter Name`}
                        setState={setName}
                    />
                    {/* <ShortTextInput
                        label={`Description`}
                        value={description}
                        placeholder={`Enter Hex Description`}
                        setState={setDescription}
                    /> */}
                     <CustomEditor setState={setDescription}  />

                    <SwitchInput
                        label={`Status`}
                        toggleSwitch={() => setIsActive(prevState => !prevState)}
                        checked={isActive}
                    />

                </div>
                <FormSubmitButton text="Submit" />
            </Form>
        </div>
    )
}

export default CreateSizeGuides
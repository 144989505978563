import { useEffect, useState } from 'react'
import useAxiosInstance from '../../../../../Utilities/Hooks/useAxiosInstance'
import { PRODUCTMATERIAL_API } from '../../../../../Utilities/APIs/APIs'

function ViewProductMaterial({ targetID }) {

    const axiosInstance = useAxiosInstance()

    const [productmaterialInfo, setProductMaterialInfo] = useState(null)

    useEffect(() => {
        async function fetchAndSetSingleProductMaterial() {
            const { data } = await axiosInstance.get(PRODUCTMATERIAL_API + 'getSingleProductMaterial/' + targetID)
            setProductMaterialInfo(data)
        }
        fetchAndSetSingleProductMaterial()

    }, [targetID, axiosInstance])

    return (
        <div className="crud_view_content">
            {
                productmaterialInfo &&
                <>
                    <h1>Product</h1>
                    <p>{productmaterialInfo.product?.name}</p>

                    <h1>Material</h1>
                    <p>{productmaterialInfo.material?.name}</p>
                </>
            }

        </div>
    )
}

export default ViewProductMaterial
import React, { useEffect, useState } from 'react'
import { Form } from 'react-router-dom'
import ShortTextInput from '../../../../Partials/Layouts/Forms/FormInputs/ShortTextInput/ShortTextInput'
import FormSubmitButton from '../../../../Partials/Layouts/Forms/FormSubmitButton/FormSubmitButton'
import useAxiosInstance from '../../../../../Utilities/Hooks/useAxiosInstance';
import { PRODUCT_COST } from '../../../../../Utilities/APIs/APIs';

function UpdateProductCost({targetID, productID, triggerFetch, setShowUpdateForm }) {
    const axiosInstance = useAxiosInstance();
    const [unitCost, setUnitCost] = useState("");
  
    useEffect(() => {
        async function fetchAndSetSingleProductCost() {
            const { data } = await axiosInstance.get(PRODUCT_COST + 'getManageCostByProduct/' + targetID)
            setUnitCost(data.unitCost)
        }
        fetchAndSetSingleProductCost()
    }, [targetID, axiosInstance])

    async function handleSubmit(e) {
        e.preventDefault();
        const itemData = new FormData();
        itemData.append("product", productID);
        itemData.append("unitCost", unitCost);
        const response = await axiosInstance.patch(PRODUCT_COST + targetID, itemData)
        if (response) {
            triggerFetch()
            setShowUpdateForm(false)
        }
    }
  return (
    <div className="crate_form">
    <Form onSubmit={handleSubmit}>
      <div>
        <ShortTextInput
          label={`unitCost`}
          value={unitCost}
          placeholder={`Unit Cost`}
          setState={setUnitCost}
        />
      </div>
      <FormSubmitButton text="Create Product Image" />
    </Form>
  </div>
  )
}

export default UpdateProductCost
import { useEffect, useState } from "react";
import { RiCloseCircleFill, RiGiftFill, RiRepeatFill } from "react-icons/ri";
import NavCardList from "../../../Partials/Layouts/NavCardList/NavCardList";
import NavCard from "../../../Partials/Layouts/NavCardList/NavCard/NavCard";
import useAxiosInstance from "../../../../Utilities/Hooks/useAxiosInstance";
import { ORDERS_API } from "../../../../Utilities/APIs/APIs";

export const ordersFromStatusRoute = "/main/ordersFromStatus/";

function OrderScreens() {
  const axiosInstance = useAxiosInstance();

  const [orders, setOrders] = useState(null);


  useEffect(() => {
    async function fetchAndSetItems() {
      const { data } = await axiosInstance.get(
        ORDERS_API + "getOrderCountsByStatus"
      );
      console.log("hello442165465", data);
      setOrders(data);
    }
    fetchAndSetItems();
  }, [axiosInstance]);
  console.log("🚀 ~ file: OrderScreens.js:15 ~ OrderScreens ~ orders:", orders);
  return (
    <NavCardList numOfCards="eight">
      <NavCard
        cardName={`Pending(${orders?.find((i) => i.status === "pending")?.count
          })`}
        navCardLink={ordersFromStatusRoute + "pending"}
      >
        <RiRepeatFill />
      </NavCard>
      <NavCard
        cardName={`Dispatched In House(${orders?.find((i) => i.status === "dispatchedInHouse")?.count
          })`}
        navCardLink={ordersFromStatusRoute + "dispatchedInHouse"}
      >
        <RiGiftFill />
      </NavCard>
      <NavCard
        cardName={`Dispatched Courier(${orders?.find((i) => i.status === "dispatchedCourier")?.count
          })`}
        navCardLink={ordersFromStatusRoute + "dispatchedCourier"}
      >
        <RiGiftFill />
      </NavCard>
      <NavCard
        cardName={`Delivered(${orders?.find((i) => i.status === "delivered")?.count
          })`}
        navCardLink={ordersFromStatusRoute + "delivered"}
      >
        <RiGiftFill />
      </NavCard>

      <NavCard
        cardName={`Delivered Unpaid(${orders?.find((i) => i.status === "deliveredUnpaid")?.count
          })`}
        navCardLink={ordersFromStatusRoute + "deliveredUnpaid"}
      >
        <RiGiftFill />
      </NavCard>
      <NavCard
        cardName={`Refunded(${orders?.find((i) => i.status === "refunded")?.count
          })`}
        navCardLink={ordersFromStatusRoute + "refunded"}
      >
        <RiGiftFill />
      </NavCard>
      <NavCard
        cardName={`Exchanged(${orders?.find((i) => i.status === "exchanged")?.count
          })`}
        navCardLink={ordersFromStatusRoute + "exchanged"}
      >
        <RiGiftFill />
      </NavCard>

      <NavCard
        cardName={`Cancelled(${orders?.find((i) => i.status === "canceled")?.count
          })`}
        navCardLink={ordersFromStatusRoute + "canceled"}
      >
        <RiCloseCircleFill />
      </NavCard>
    </NavCardList>
  );
}

export default OrderScreens;

import { useEffect, useRef, useState } from 'react'
import CreateButton from '../../../../Partials/Layouts/CRUDs/CRUDHeader/CreateButton/CreateButton';
import CRUDTable from '../../../../Partials/Layouts/CRUDs/CRUDTable/CRUDTable';
import CRUDTableHeader from '../../../../Partials/Layouts/CRUDs/CRUDTable/CRUDTableHeader/CRUDTableHeader';
import CRUDth from '../../../../Partials/Layouts/CRUDs/CRUDTable/CRUDTableHeader/CRUDth/CRUDth';
import ViewButton from '../../../../Partials/Layouts/CRUDs/CRUDButtons/ViewButton/ViewButton';
import EditButton from '../../../../Partials/Layouts/CRUDs/CRUDButtons/EditButton/EditButton';
import DeleteButton from '../../../../Partials/Layouts/CRUDs/CRUDButtons/DeleteButton/DeleteButton';
import ShortTextCell from '../../../../Partials/Layouts/CRUDs/CRUDTable/CRUDTableCells/ShortTextCell/ShortTextCell';
import CRUDTableRow from '../../../../Partials/Layouts/CRUDs/CRUDTable/CRUDTableRow/CRUDTableRow';
import Modal from '../../../../Partials/Elements/Modal/Modal';
import CreateCategory from './CreateCategory';
import ViewCategory from './ViewCategory';
import useAxiosInstance from '../../../../../Utilities/Hooks/useAxiosInstance';
import DeleteItem from '../../../../Partials/Layouts/DeleteItem/DeleteItem';
import UpdateCategory from './UpdateCategory';
import { CATAGORY_API } from '../../../../../Utilities/APIs/APIs';
import ImageCell from '../../../../Partials/Layouts/CRUDs/CRUDTable/CRUDTableCells/ImageCell/ImageCell';
import {  useParams } from 'react-router-dom';

function Catagories() {

  const [catagories, setCatagories] = useState(null)
  const screenHeaerRef = useRef()
  const [screenHeaderHeight, setScreenHeaderHeight] = useState(0);

  useEffect(() => {
    let screenHeaderHeight = screenHeaerRef.current.clientHeight;
    setScreenHeaderHeight(screenHeaderHeight)
  }, [screenHeaerRef]);

  const [showCreateForm, setShowCreateForm] = useState(false);
  const [showViewSection, setShowViewSection] = useState(false);
  const [showUpdateForm, setShowUpdateForm] = useState(false);
  const [showDeleteSection, setShowDeleteSection] = useState(false)
  const [toggleFetch, setToggleFetch] = useState(false)

  const [targetID, setTargetID] = useState(null)
  const axiosInstance = useAxiosInstance()
 const {gender}= useParams()

  useEffect(() => {
    if (gender === 'all') {
      async function fetchAndSetCatagories() {
        const { data } = await axiosInstance.get(CATAGORY_API)
        setCatagories(data)
      }
      fetchAndSetCatagories()
    }else{

      async function fetchAndSetCatagories() {
        const { data } = await axiosInstance.get(CATAGORY_API + 'getCategoryByGender/' + gender)
        setCatagories(data)
      }
      fetchAndSetCatagories()
    }


  }, [toggleFetch, axiosInstance, gender])

  function triggerFetch() {
    setToggleFetch(prevState => !prevState)
  }
  return (
    <>
      <div className='screen_header' ref={screenHeaerRef}>
        <h1 className='screen_heading'>Catagories ({catagories?.length}  in total)</h1>
        <CreateButton
          screenTopicSingular={'Catagory'}
          setShowCreateForm={setShowCreateForm}
        />
      </div>
      <CRUDTable screenHeaderHeight={screenHeaderHeight}>
        <CRUDTableHeader>
          <CRUDth th='Category' />
          <CRUDth th='Meta Title' />
          <CRUDth th='Category Slug' />
          <CRUDth th='Image' />
          <CRUDth th='Actions' />
        </CRUDTableHeader>
        <tbody>
          {
            catagories?.map(catagory =>
              <CRUDTableRow key={catagory._id}>
                <ShortTextCell text={catagory.categoryName} />
                <ShortTextCell text={catagory.metaTitle} />
                <ShortTextCell text={catagory.categorySlug} />
                <ImageCell imgSrc={catagory.categoryImage} />
                <td>
                  <div className="action_buttons_wrapper">
                    <div className="action_buttons">
                      <ViewButton
                        setShowViewSection={setShowViewSection}
                        targetID={catagory._id}
                        setTargetID={setTargetID}
                        tooltipContent={"View"} 
                        tooltipName={"view"}
                        
                      />

                      <EditButton
                        setShowUpdateForm={setShowUpdateForm}
                        targetID={catagory._id}
                        setTargetID={setTargetID}
                        tooltipContent={"Edit"} 
                        tooltipName={"edit"}
                      />

                      <DeleteButton
                        setShowDeleteSection={setShowDeleteSection}
                        targetID={catagory._id}
                        setTargetID={setTargetID}
                        tooltipContent={"Delete"} 
                        tooltipName={"delete"}
                      />
                    </div>
                  </div>
                </td>
              </CRUDTableRow>

            )
          }
        </tbody>
      </CRUDTable>
      {
        showCreateForm &&
        <Modal
          extraClass={'small'}
          modalHeading={`Add Catagory`}
          setShowModalContent={setShowCreateForm}
        >
          <CreateCategory
            triggerFetch={triggerFetch}
            setShowCreateForm={setShowCreateForm}
          />
        </Modal>
      }
      {
        showUpdateForm &&
        <Modal
          extraClass={'small'}
          modalHeading={`Update Catagory`}
          setShowModalContent={setShowUpdateForm}
        >
          <UpdateCategory
            triggerFetch={triggerFetch}
            targetID={targetID}
            setShowUpdateForm={setShowUpdateForm}
          />
        </Modal>
      }
      {
        showViewSection &&
        <Modal
          extraClass={'small'}
          modalHeading={`View Catagory`}
          setShowModalContent={setShowViewSection}
        >
          <ViewCategory
            targetID={targetID}
          />
        </Modal>
      }
      {
        showDeleteSection &&
        <Modal
          extraClass={'small'}
          modalHeading={`Delete Brand`}
          setShowModalContent={setShowDeleteSection}
        >
          <DeleteItem
            api={CATAGORY_API}
            triggerFetch={triggerFetch}
            targetID={targetID}
            setShowDeleteItem={setShowDeleteSection}
          />
        </Modal>
      }
    </>
  )
}

export default Catagories
import { useState } from "react"
import useAxiosInstance from '../../../../../Utilities/Hooks/useAxiosInstance';
import { COLORS_API } from "../../../../../Utilities/APIs/APIs"
import ShortTextInput from "../../../../Partials/Layouts/Forms/FormInputs/ShortTextInput/ShortTextInput"
import SwitchInput from "../../../../Partials/Layouts/Forms/FormInputs/SwitchInput/SwitchInput"
import FormSubmitButton from "../../../../Partials/Layouts/Forms/FormSubmitButton/FormSubmitButton"
import Form from "../../../../Partials/Layouts/Forms/Form"

function CreateColor({triggerFetch, setShowCreateForm}) {

    const axiosInstance = useAxiosInstance()

    const [name, setName] = useState('')  
    const [hexValue, setHexValue] = useState('')
    const [isActive, setIsActive] = useState('')   

    async function handleSubmit(e) {

        e.preventDefault()
        const itemData = new FormData()

        itemData.append('name', name)
        itemData.append('hexValue', hexValue)
        itemData.append('isActive', isActive)

        const response = await axiosInstance.post(COLORS_API, itemData)
        if (response) {
            triggerFetch()
            setShowCreateForm(false)
        }
    }

    return (
        <div className='crate_form'>
            <Form onSubmit={handleSubmit}>
                <div>
                    <ShortTextInput
                        label={`Title`}
                        value={name}
                        placeholder={`Enter Title`}
                        setState={setName}
                    />
                    <ShortTextInput
                        label={`Hex Value`}
                        value={hexValue}
                        placeholder={`Enter Hex Value`}
                        setState={setHexValue}
                    />
                    <SwitchInput
                        label={`Status`}
                        toggleSwitch={() => setIsActive(prevState => !prevState)}
                        checked={isActive}
                    />

                </div>
                <FormSubmitButton text="Submit" />
            </Form>
        </div>
    )
}

export default CreateColor
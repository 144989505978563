import { useEffect, useState } from 'react'
import { SLIDERS_API } from '../../../../../Utilities/APIs/APIs'
import Image from '../../../../Partials/Elements/Image/Image'
import './ViewSlider.css'
import useAxiosInstance from '../../../../../Utilities/Hooks/useAxiosInstance'

function ViewSlider({targetID}) {

    const axiosInstance = useAxiosInstance()
    
    const [sliderInfo, setSliderInfo] = useState(null)

    useEffect(() => {
        async function fetchAndSetSingleSlider() {
          const { data } = await axiosInstance.get(SLIDERS_API + 'getSingleSlider/' + targetID)
          setSliderInfo(data)
        }
        fetchAndSetSingleSlider()

      }, [targetID, axiosInstance])

    return (
        <div className="crud_view_content">
            {
                sliderInfo &&
                <>
                    <h1>Title</h1>
                    <p>{sliderInfo.title}</p>

                    <h1>SubTitle</h1>
                    <p>{sliderInfo.subTitle}</p>

                    <h1>Image</h1>
                    <Image
                        imgLink={sliderInfo.image}
                        imgAlt={'slider'}
                        
                    />

                    <h1>Product Link</h1>
                    <p>{sliderInfo.linkProduct}</p>
                </>

            }
            
            
        </div>
    )
}

export default ViewSlider
import { useState } from "react"
import useAxiosInstance from '../../../../../Utilities/Hooks/useAxiosInstance';
import Form from "../../../../Partials/Layouts/Forms/Form"
import ShortTextInput from "../../../../Partials/Layouts/Forms/FormInputs/ShortTextInput/ShortTextInput"
import FormSubmitButton from "../../../../Partials/Layouts/Forms/FormSubmitButton/FormSubmitButton"
import { SOCIALLINKS_API } from "../../../../../Utilities/APIs/APIs"
import SwitchInput from "../../../../Partials/Layouts/Forms/FormInputs/SwitchInput/SwitchInput"

function CreateSocialLink({triggerFetch, setShowCreateForm}) {

    const axiosInstance = useAxiosInstance()

    const [platform, setPlatform] = useState('')  
    const [visibility, setVisibility] = useState(false)
    const [link, setLink] = useState('')

    
    async function handleSubmit(e) {

        e.preventDefault()
        const itemData = new FormData()

        itemData.append('platform', platform)
        itemData.append('visibility', visibility)
        itemData.append('link', link)

        const response = await axiosInstance.post(SOCIALLINKS_API, itemData)
        if (response) {
            triggerFetch()
            setShowCreateForm(false)
        }
    }

    return (
        <div className='crate_form'>
            <Form onSubmit={handleSubmit}>
                <div>
                    <ShortTextInput
                        label={`Title`}
                        value={platform}
                        placeholder={`Enter Title`}
                        setState={setPlatform}
                    />
                    <SwitchInput
                        label={`Visibility`}
                        toggleSwitch={() => setVisibility(prevState => !prevState)}
                        checked={visibility}
                    />
                    <ShortTextInput
                        label={`Link`}
                        value={link}
                        placeholder={`Enter Link`}
                        setState={setLink}
                    />
                </div>
                <FormSubmitButton text="Submit" />
            </Form>
        </div>
    )
}

export default CreateSocialLink
import { useEffect, useRef, useState } from 'react'
import CreateButton from '../../../../Partials/Layouts/CRUDs/CRUDHeader/CreateButton/CreateButton';
import CRUDTable from '../../../../Partials/Layouts/CRUDs/CRUDTable/CRUDTable';
import CRUDTableHeader from '../../../../Partials/Layouts/CRUDs/CRUDTable/CRUDTableHeader/CRUDTableHeader';
import CRUDth from '../../../../Partials/Layouts/CRUDs/CRUDTable/CRUDTableHeader/CRUDth/CRUDth';
import ViewButton from '../../../../Partials/Layouts/CRUDs/CRUDButtons/ViewButton/ViewButton';
import EditButton from '../../../../Partials/Layouts/CRUDs/CRUDButtons/EditButton/EditButton';
import ShortTextCell from '../../../../Partials/Layouts/CRUDs/CRUDTable/CRUDTableCells/ShortTextCell/ShortTextCell';
import CRUDTableRow from '../../../../Partials/Layouts/CRUDs/CRUDTable/CRUDTableRow/CRUDTableRow';
import Modal from '../../../../Partials/Elements/Modal/Modal';
import CreateSlider from './CreateSlider';
import ViewSlider from './ViewSlider';
import UpdateSlider from './UpdateSlider';
import { SLIDERS_API } from '../../../../../Utilities/APIs/APIs';
import ImageCell from '../../../../Partials/Layouts/CRUDs/CRUDTable/CRUDTableCells/ImageCell/ImageCell';

import './Sliders.css'
import useAxiosInstance from '../../../../../Utilities/Hooks/useAxiosInstance';

function Sliders() {

  const [sliders, setSliders] = useState(null)
  const screenHeaerRef = useRef()
  const [screenHeaderHeight, setScreenHeaderHeight] = useState(0);

  useEffect(() => {
    let screenHeaderHeight = screenHeaerRef.current.clientHeight;
    setScreenHeaderHeight(screenHeaderHeight)
  }, [screenHeaerRef]);

  const [showCreateForm, setShowCreateForm] = useState(false);
  const [showViewSection, setShowViewSection] = useState(false);
  const [showUpdateForm, setShowUpdateForm] = useState(false);
  // const [showDeleteSection, setShowDeleteSection] = useState(false)
  const [toggleFetch, setToggleFetch] = useState(false)

  const [targetID, setTargetID] = useState(null)
  const axiosInstance = useAxiosInstance()

  useEffect(() => {

  async function fetchAndSetSliders() {
      const { data } = await axiosInstance.get(SLIDERS_API)
      setSliders(data)
    }
    fetchAndSetSliders()

  }, [toggleFetch, axiosInstance])

  function triggerFetch() {
    setToggleFetch(prevState => !prevState)
  }
  return (
    <>
      <div className='screen_header' ref={screenHeaerRef}>
        <h1 className='screen_heading'>Sliders ({sliders?.length}  in total)</h1>
        <CreateButton
          screenTopicSingular={'Slider'}
          setShowCreateForm={setShowCreateForm}
        />
      </div>
      <CRUDTable screenHeaderHeight={screenHeaderHeight}>
        <CRUDTableHeader>
          <CRUDth th='Title' />
          <CRUDth th='SubTitle' />
          <CRUDth th='Image' />
          <CRUDth th='Actions' />
        </CRUDTableHeader>
        <tbody>
          {
            sliders?.map(slider =>
              <CRUDTableRow key={slider._id}>
                <ShortTextCell text={slider.title} />
                <ShortTextCell text={slider.subTitle} />
                <ImageCell imgSrc={slider.image} />
                <td>
                  <div className="action_buttons_wrapper">
                    <div className="action_buttons">
                      <ViewButton
                        setShowViewSection={setShowViewSection}
                        targetID={slider._id}
                        setTargetID={setTargetID}
                        tooltipName={"View"} 
                        tooltipContent={"View"}
                      />

                      <EditButton
                        setShowUpdateForm={setShowUpdateForm}
                        targetID={slider._id}
                        setTargetID={setTargetID}
                        tooltipName={"Edit"} 
                        tooltipContent={"Edit"}
                      />

                      {/* <DeleteButton
                        setShowDeleteSection={setShowDeleteSection}
                        targetID={slider._id}
                        setTargetID={setTargetID}
                      /> */}
                    </div>
                  </div>
                </td>
              </CRUDTableRow>

            )
          }
        </tbody>
      </CRUDTable>
      {
        showCreateForm &&
        <Modal
          extraClass={'small'}
          modalHeading={`Create Slider`}
          setShowModalContent={setShowCreateForm}
        >
          <CreateSlider
            triggerFetch={triggerFetch}
            setShowCreateForm={setShowCreateForm}
          />
        </Modal>
      }
      {
        showUpdateForm &&
        <Modal
          extraClass={'small'}
          modalHeading={`Update Slider`}
          setShowModalContent={setShowUpdateForm}
        >
          <UpdateSlider
            triggerFetch={triggerFetch}
            targetID={targetID}
            setShowUpdateForm={setShowUpdateForm}
          />
        </Modal>
      }
      {
        showViewSection &&
        <Modal
          extraClass={'small'}
          modalHeading={`View Slider`}
          setShowModalContent={setShowViewSection}
        >
          <ViewSlider
            targetID={targetID}
          />
        </Modal>
      }
      {/* {
        showDeleteSection &&
        <Modal
          extraClass={'small'}
          modalHeading={`Delete Slider`}
          setShowModalContent={setShowDeleteSection}
        >
          <DeleteItem
            api={SLIDERS_API}
            triggerFetch={triggerFetch}
            targetID={targetID}
            setShowDeleteItem={setShowDeleteSection}
          />
        </Modal>
      } */}
    </>
  )
}

export default Sliders
import { useEffect, useState } from 'react'
import useAxiosInstance from '../../../../../../Utilities/Hooks/useAxiosInstance'
import { EMPLOYEE_DESIGNATIONS_API } from '../../../../../../Utilities/APIs/APIs'

function ViewEmployeeDesignation({ targetID }) {

    const axiosInstance = useAxiosInstance()

    const [employeeDesignationInfo, setEmployeeDesignationInfo] = useState(null)

    useEffect(() => {
        async function fetchAndSetSingleEmployeeDesignation() {
            const { data } = await axiosInstance.get(EMPLOYEE_DESIGNATIONS_API + 'getSingleEmployeeDesignation/' + targetID)
            setEmployeeDesignationInfo(data)
        }
        fetchAndSetSingleEmployeeDesignation()

    }, [targetID, axiosInstance])

    return (
        <div className="crud_view_content">
            {
                employeeDesignationInfo &&
                <>
                    <h1>Employee</h1>
                    <p>{employeeDesignationInfo.employee?.name}</p>

                    <h1>Designations</h1>
                    <p>{employeeDesignationInfo.designation?.name}</p>
                </>
            }

        </div>
    )
}

export default ViewEmployeeDesignation
import React, { useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import { RiListSettingsLine } from "react-icons/ri";
import useAxiosInstance from "../../../../Utilities/Hooks/useAxiosInstance";
import { ORDERS_API } from "../../../../Utilities/APIs/APIs";
import CRUDTable from "../../../Partials/Layouts/CRUDs/CRUDTable/CRUDTable";
import CRUDTableHeader from "../../../Partials/Layouts/CRUDs/CRUDTable/CRUDTableHeader/CRUDTableHeader";
import CRUDth from "../../../Partials/Layouts/CRUDs/CRUDTable/CRUDTableHeader/CRUDth/CRUDth";
import CRUDTableRow from "../../../Partials/Layouts/CRUDs/CRUDTable/CRUDTableRow/CRUDTableRow";
import ShortTextCell from "../../../Partials/Layouts/CRUDs/CRUDTable/CRUDTableCells/ShortTextCell/ShortTextCell";
import ViewButton from "../../../Partials/Layouts/CRUDs/CRUDButtons/ViewButton/ViewButton";
import CRUDButton from "../../../Partials/Layouts/CRUDs/CRUDButtons/CRUDButton";
import Pagination from "../../../Partials/Elements/pagination/Pagination";
import Modal from "../../../Partials/Elements/Modal/Modal";
import DateInput from "../../../Partials/Layouts/Forms/FormInputs/DateInput/DateInput";
import ViewOrder from "../OrderScreens/ViewOrder";
import OrderInvoice from "../../../Partials/Elements/OrderInvoice/OrderInvoice";
import './Reports.css';

function ReportProcess() {
  const [products, setProducts] = useState([]);
  const screenHeaerRef = useRef();
  const [screenHeaderHeight, setScreenHeaderHeight] = useState(0);
  const [targetID, setTargetID] = useState(null);

  useEffect(() => {
    let screenHeaderHeight = screenHeaerRef.current.clientHeight;
    setScreenHeaderHeight(screenHeaderHeight);
  }, [screenHeaerRef]);

  const [showViewSection, setShowViewSection] = useState(false);
  const [limit] = useState(40);
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const [totalOrderPrice, setTotalOrderPrice] = useState(0);
  const [oneday, setOneday] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const [totalItems, setTotalItems] = useState(0);
  const [showInvoice, setShowInvoice] = useState(false);

  const [selectedOrders, setSelectedOrders] = useState([]);
  const navigate = useNavigate();

  const handleSelectOrder = (orderId) => {
    setSelectedOrders((prevSelectedOrders) =>
      prevSelectedOrders.includes(orderId)
        ? prevSelectedOrders.filter((id) => id !== orderId)
        : [...prevSelectedOrders, orderId]
    );
  };

  const handleSelectAll = () => {
    if (selectedOrders.length === products.length) {
      setSelectedOrders([]);
    } else {
      setSelectedOrders(products.map((order) => order._id));
    }
  };

    const handleSubmit = async () => {
        try {
            const res = await axiosInstance.post('/orders/submitarchOrders', {
                orderIds: selectedOrders,
            });
    
            if (res.status === 200) {
                alert('Orders successfully submitted');
                setSelectedOrders([]); // Clear selected orders after submission
            } else {
                alert('Failed to submit orders');
            }
        } catch (error) {
            console.error('Error submitting orders:', error);
            alert('An error occurred while submitting orders');
        }
    };

  const axiosInstance = useAxiosInstance();

  useEffect(() => {
    setStartDate(
      `${new Date().getFullYear()}-${
        new Date().getMonth() + 1
      }-${new Date().getDate() < 10 ? "0" + new Date().getDate() : new Date().getDate()}`
    );
    setEndDate(
      `${new Date().getFullYear()}-${
        new Date().getMonth() + 1
      }-${new Date().getDate() < 10 ? "0" + new Date().getDate() : new Date().getDate()}`
    );
  }, []);

  useEffect(() => {
    async function fetchAndSetProducts() {
      const res = await axiosInstance.get(
        `${ORDERS_API}getOrderSaleReports?startDate=${startDate}&endDate=${endDate}&oneday=${oneday}&page=${currentPage}&limit=${limit}`
      );

      const fetchedProducts = res?.data?.orders || [];
      setProducts(fetchedProducts);
      setTotalItems(res?.data?.totalItems || 0);
      const formatNumber = new Intl.NumberFormat("en-IN").format(
        parseInt(res?.data?.totalOrderAmount || 0)
      );

      setTotalOrderPrice(formatNumber);
    }
    fetchAndSetProducts();
  }, [startDate, endDate, oneday, currentPage, limit, axiosInstance]);

  return (
    <>
      <div className="screen_header" ref={screenHeaerRef}>
        <h1 className="screen_heading">Orders ({totalItems} in total)</h1>
        <DateInput
          label={"Start Date"}
          value={startDate}
          setState={setStartDate}
        />
        <DateInput label={"End Date"} value={endDate} setState={setEndDate} />
        <DateInput label={"One Day"} value={oneday} setState={setOneday} />
        <h1 className="screen_heading">
          Total Order Price {totalOrderPrice} TK
        </h1>
      </div>
      <CRUDTable screenHeaderHeight={screenHeaderHeight}>
        <CRUDTableHeader>
          <CRUDth>
            <input
              type="checkbox"
              className="checkbox_large"
              onChange={handleSelectAll}
              checked={products.length > 0 && selectedOrders.length === products.length}
            />
          </CRUDth>
          <CRUDth th="Invoice No" />
          <CRUDth th="Date" />
          <CRUDth th="Name" />
          <CRUDth th="Order Total" />
          <CRUDth th="Delivery Media" />
          <CRUDth th="Actions" />
        </CRUDTableHeader>
        <tbody>
          {products?.length > 0 &&
            products?.map((order) => (
              <CRUDTableRow key={order._id}>
                <td>
                  <input
                    type="checkbox"
                    className="checkbox_large"
                    onChange={() => handleSelectOrder(order._id)}
                    checked={selectedOrders.includes(order._id)}
                  />
                </td>
                <ShortTextCell text={order?.invoiceNo} />
                <ShortTextCell
                  text={new Date(order?.createdAt).toDateString()}
                />
                <ShortTextCell text={order?.shippingName} />
                <ShortTextCell text={order?.orderTotal} />
                <ShortTextCell text={order?.shippingMediaType} />
                <td>
                  <div className="action_buttons_wrapper">
                    <div className="action_buttons">
                      <ViewButton
                        setShowViewSection={setShowViewSection}
                        targetID={order?._id}
                        setTargetID={setTargetID}
                        tooltipContent={"View Product Details"}
                        tooltipName={"view"}
                      />
                      <CRUDButton
                        handleClick={() =>
                          navigate("/main/order_items_from_order/" + order._id)
                        }
                        tooltip={"Order Items From Order"}
                      >
                        <RiListSettingsLine />
                      </CRUDButton>
                    </div>
                  </div>
                </td>
              </CRUDTableRow>
            ))}

          <button className="submit_button" onClick={handleSubmit}>
            Submit
          </button>
        </tbody>

        <Pagination
          items={products}
          totalItems={totalItems}
          limit={limit}
          currentPage={currentPage}
          setCurrentPage={setCurrentPage}
        />
      </CRUDTable>

      {showViewSection && (
        <Modal
          extraClass={"small"}
          modalHeading={`View Order Details`}
          setShowModalContent={setShowViewSection}
        >
          <ViewOrder targetID={targetID} />
        </Modal>
      )}
      {showInvoice && (
        <Modal
          modalHeading={`View Invoice`}
          setShowModalContent={setShowInvoice}
        >
          <OrderInvoice targetID={targetID} />
        </Modal>
      )}
    </>
  );
}

export default ReportProcess;

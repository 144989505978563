import { useEffect, useState } from 'react'
import useAxiosInstance from '../../../../../Utilities/Hooks/useAxiosInstance';
import { DIVISION_API } from '../../../../../Utilities/APIs/APIs'

function ViewDivision({targetID}) {

    const axiosInstance = useAxiosInstance()
    
    const [divisionInfo, setDivisionInfo] = useState(null)

    useEffect(() => {
        async function fetchAndSetSingleDivision() {
          const { data } = await axiosInstance.get(DIVISION_API + targetID)
          setDivisionInfo(data)
        }
        fetchAndSetSingleDivision()

      }, [targetID, axiosInstance])

    return (
        <div className="crud_view_content">
            {
                divisionInfo &&
                <>
                    <h1>Division</h1>
                    <p>{divisionInfo.name}</p>
                </>
            }
            
        </div>
    )
}

export default ViewDivision
import { useState } from "react";
import useAxiosInstance from "../../../../../../../Utilities/Hooks/useAxiosInstance";
import {
  EMPLOYEE_API,
} from "../../../../../../../Utilities/APIs/APIs";
import Form from "../../../../../../Partials/Layouts/Forms/Form";
import SelectInput from "../../../../../../Partials/Layouts/Forms/FormInputs/SelectInput/SelectInput";
import SelectOption from "../../../../../../Partials/Layouts/Forms/FormInputs/SelectInput/SelectOption/SelectOption";
import FormSubmitButton from "../../../../../../Partials/Layouts/Forms/FormSubmitButton/FormSubmitButton";

function ChangeLevel({ targetID, triggerFetch, setShowLevelSection }) {
  const axiosInstance = useAxiosInstance();

  const [level, setLevel] = useState("");

  //   useEffect(() => {
  //     async function fetchAndSetSingleFAQ() {
  //       const { data } = await axiosInstance.get( FAQ_API + "getSingleFAQ/" + targetID);
  //       setTitle(data.title);
  //       setDescription(data.description);
  //       setisActive(data.isActive);
  //     }
  //     fetchAndSetSingleFAQ();
  //   }, [targetID, axiosInstance]);

  async function handleSubmit(e) {
    e.preventDefault();
    const itemData = { level };

    const response = await axiosInstance.patch(
      EMPLOYEE_API + "changeEmployeeLevel/" + targetID,
      itemData
    );

    if (response) {
      triggerFetch();
      setShowLevelSection(false);
    }
  }

  return (
    <div className="crate_form">
      <Form onSubmit={handleSubmit}>
        <div>
          <SelectInput value={level} setState={setLevel}>
            <SelectOption optionValue="" optionText="Select level" />
            {/* <SelectOption optionValue="pending" optionText="Pending" /> */}
            <SelectOption optionValue="admin" optionText="admin" />
            <SelectOption optionValue="employee" optionText="employee" />
            <SelectOption
              optionValue="operationsHead"
              optionText="operationsHead"
            />
            <SelectOption optionValue="marketing" optionText="marketing" />
            <SelectOption
              optionValue="customerCare"
              optionText="customerCare"
            />
            <SelectOption optionValue="production" optionText="production" />

            {/* <SelectOption optionValue="dispatchedCourier" optionText="Dispatched (Courier)" />
            <SelectOption optionValue="delivered" optionText="Delivered" />
            <SelectOption optionValue="deliveredUnpaid" optionText="Delivered (Unpaid)" />
            <SelectOption optionValue="refunded" optionText="Refunded" />
            <SelectOption optionValue="exchanged" optionText="Exchanged" />
            <SelectOption optionValue="canceled" optionText="Canceled" /> */}
          </SelectInput>
        </div>
        <FormSubmitButton text="Submit" />
      </Form>
    </div>
  );
}

export default ChangeLevel;

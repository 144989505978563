import React, { useEffect, useState } from 'react'
import useAxiosInstance from '../../../../../../Utilities/Hooks/useAxiosInstance'
import { PRODUCT_COLLECTIONS_API } from '../../../../../../Utilities/APIs/APIs'

function ViewProductCollections({targetID}) {
    const axiosInstance = useAxiosInstance()
    
    const [productCollectionsInfo, setProductCollectionsInfo] = useState(null)

    useEffect(() => {
        async function fetchAndSetSingleProductCollections() {
          const { data } = await axiosInstance.get(PRODUCT_COLLECTIONS_API + 'getSingleProductCollection/' + targetID)
          setProductCollectionsInfo(data)
        }
        fetchAndSetSingleProductCollections()

      }, [targetID, axiosInstance])

    return (
        <div className="crud_view_content">
            {
                productCollectionsInfo &&
                <>
                    <h1>Product Collection</h1>
                    <p>{productCollectionsInfo.name}</p>
                </>
            }
            
        </div>
    )
}

export default ViewProductCollections
import { useEffect, useState } from 'react'
import useAxiosInstance from '../../../../../Utilities/Hooks/useAxiosInstance'
import { PRODUCT_CARE_API } from '../../../../../Utilities/APIs/APIs'

function ViewProductCare({ targetID }) {

    const axiosInstance = useAxiosInstance()

    const [productcareInfo, setProductCareInfo] = useState(null)

    useEffect(() => {
        async function fetchAndSetSingleProductCare() {
            const { data } = await axiosInstance.get(PRODUCT_CARE_API + 'getSingleProductCare/' + targetID)
            setProductCareInfo(data)
        }
        fetchAndSetSingleProductCare()

    }, [targetID, axiosInstance])

    return (
        <div className="crud_view_content">
            {
                productcareInfo &&
                <>
                    <h1>Product</h1>
                    <p>{productcareInfo.product?.name}</p>

                    <h1>Care</h1>
                    <p>{productcareInfo.care?.name}</p>
                </>
            }

        </div>
    )
}

export default ViewProductCare
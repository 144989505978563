import { useEffect, useState } from "react";
import useAxiosInstance from "../../../../../Utilities/Hooks/useAxiosInstance";
import Form from "../../../../Partials/Layouts/Forms/Form";
import ShortTextInput from "../../../../Partials/Layouts/Forms/FormInputs/ShortTextInput/ShortTextInput";
import FormSubmitButton from "../../../../Partials/Layouts/Forms/FormSubmitButton/FormSubmitButton";
import {
  BRANDS_API,
  CATAGORY_API,
  PRODUCTS_API,
  DISCOUNTS_API,
  PARTNERS_API,
  SIZEGUIRDES,
} from "../../../../../Utilities/APIs/APIs";
import SwitchInput from "../../../../Partials/Layouts/Forms/FormInputs/SwitchInput/SwitchInput";
import ImageInput from "../../../../Partials/Layouts/Forms/FormInputs/ImageInput/ImageInput";
import SelectInput from "../../../../Partials/Layouts/Forms/FormInputs/SelectInput/SelectInput";
import SelectOption from "../../../../Partials/Layouts/Forms/FormInputs/SelectInput/SelectOption/SelectOption";

import slugify from "react-slugify";
import NumberInput from "../../../../Partials/Layouts/Forms/FormInputs/NumberInput/NumberInput";
import CustomEditor from '../../../../Partials/Elements/CustomEditor/CustomEditor';

function UpdateProduct({ targetID, triggerFetch, setShowUpdateForm }) {
  const axiosInstance = useAxiosInstance();

  const [category, setCategory] = useState("");
  const [brand, setBrand] = useState("");
  const [name, setName] = useState("");
  const [skuId, setSkuId] = useState("");
  const [price, setPrice] = useState("");
  const [gender, setGender] = useState("");
  const [description, setDescription] = useState("");
  const [isVisible, setIsVisible] = useState(false);
  const [cardFrontImage, setCardFrontImage] = useState("");
  const [cardHoverImage, setCardHoverImage] = useState("");
  const [metaTitle, setMetaTitle] = useState("");
  const [metaDescription, setMetaDescription] = useState("");
  const [trialStatus, setTrialStatus] = useState(false);
  const [isDiscount, setIsDiscount] = useState(false);
  const [discount, setDiscount] = useState("");
  const [partner, setPartner] = useState("");

  const [partners, setPartners] = useState(null);
  const [discounts, setDiscounts] = useState(null);
  const [brands, setBrands] = useState(null);
  const [catagories, setCatagories] = useState(null);
  const [precedence, setPrecedence] = useState(0);
  const [singleDiscount, setSingleDiscount] = useState(null);
  const [sizeGuide, setSizeGuide] = useState('')

  const currentPrice =
    isDiscount && singleDiscount
      ? parseInt(price - (price * singleDiscount.percentage) / 100)
      : parseInt(price);

      const [sizeGuides, setSizeGuides] = useState(null);



  useEffect(() => {
    async function fetchAndSetSingleProduct() {
      const { data } = await axiosInstance.get(
        PRODUCTS_API + "getSingleProduct/" + targetID
      );
      setCategory(data?.category?._id);
      setBrand(data?.brand?._id);
      setName(data?.name);
      setSkuId(data?.squId);
      setPrice(data?.price);
      setGender(data?.gender);
      setDescription(data.description);
      setIsVisible(data?.isVisible);
      setCardFrontImage(data?.cardFrontImage);
      setCardHoverImage(data?.cardHoverImage);
      setMetaTitle(data?.metaTitle);
      setMetaDescription(data?.metaDescription);
      setTrialStatus(data?.trialStatus);
      setIsDiscount(data?.isDiscount);
      setDiscount(data?.discount?._id);
      setPartner(data?.partner?._id);
      setSizeGuide(data?.sizeGuide)
      setPrecedence(data?.precedence)
    }
    fetchAndSetSingleProduct();
  }, [targetID, axiosInstance]);

  useEffect(() => {
    if (gender) {
      async function fetchAndSetCatagories() {
        const { data } = await axiosInstance.get(
          CATAGORY_API + "getCategoryByGender/" + gender
        );
        setCatagories(data);
      }
      fetchAndSetCatagories();
    }

    async function fetchAndSetBrands() {
      const { data } = await axiosInstance.get(BRANDS_API);
      setBrands(data);
    }
    fetchAndSetBrands();

    async function fetchAndSetDiscounts() {
      const { data } = await axiosInstance.get(DISCOUNTS_API);
      setDiscounts(data);
    }
    fetchAndSetDiscounts();

    async function fetchAndSetPartners() {
      const { data } = await axiosInstance.get(PARTNERS_API);
      setPartners(data);
    }
    fetchAndSetPartners();
  }, [axiosInstance, gender]);

  useEffect(() => {
    if (discount) {
      async function fetchAndSetSingleDiscount() {
        const { data } = await axiosInstance.get(
          DISCOUNTS_API + "getSingleDiscount/" + discount
        );
        setSingleDiscount(data);
      }
      fetchAndSetSingleDiscount();
    }
  }, [discount, axiosInstance]);

  useEffect(() => {
    async function fetchAndSetGuideSize() {
      const { data } = await axiosInstance.get(SIZEGUIRDES + 'getSizeGuides');
      setSizeGuides(data);

    }
    fetchAndSetGuideSize();
  }, [axiosInstance]);

  async function handleSubmit(e) {
    e.preventDefault();
    const itemData = new FormData();

    itemData.append("category", category);
    if (brand && brand !== "") itemData.append("brand", brand);
    itemData.append("name", name);
    if (skuId && skuId !== "") itemData.append("squId", skuId);
    itemData.append("price", price);
    itemData.append("slug", slugify(name));
    itemData.append("gender", gender);
    itemData.append("description", description);
    itemData.append("isVisible", isVisible);
    itemData.append("cardFrontImage", cardFrontImage);
    itemData.append("cardHoverImage", cardHoverImage);
    itemData.append("metaTitle", metaTitle);
    itemData.append("metaDescription", metaDescription);
    itemData.append("trialStatus", trialStatus);
    itemData.append("isDiscount", isDiscount);
    itemData.append("currentPrice", currentPrice);
    itemData.append("precedence", precedence);
    if (sizeGuide && sizeGuide !== "") itemData.append("sizeGuide", sizeGuide);
    if (discount && discount !== "") itemData.append("discount", discount);
    if (partner && partner !== "") itemData.append("partner", partner);

    const response = await axiosInstance.patch(
      PRODUCTS_API + targetID,
      itemData
    );
    if (response) {
      triggerFetch();
      setShowUpdateForm(false);
    }
  }

  return (
    <div className="crate_form">
      <Form onSubmit={handleSubmit}>
        <div>
          <ShortTextInput
            label={`SkuID`}
            value={skuId}
            placeholder={`SkuID`}
            setState={setSkuId}
          />
          <SelectInput label={"Gender"} value={gender} setState={setGender}>
            <SelectOption optionValue="" optionText="Select Gender" />
            <SelectOption optionValue="men" optionText="Men" />
            <SelectOption optionValue="women" optionText="Women" />
            <SelectOption optionValue="unisex" optionText="Unisex" />
          </SelectInput>
          <SelectInput
            label={"Category"}
            value={category}
            setState={setCategory}
          >
            <SelectOption optionValue="" optionText="Select Category" />
            {catagories?.map((category) => (
              <SelectOption
                optionValue={category._id}
                key={category._id}
                optionText={category?.categoryName}
              />
            ))}
          </SelectInput>
          <SelectInput label={"Brand"} value={brand} setState={setBrand}>
            {/* <SelectOption optionValue='' optionText="Select Brand" /> */}
            {brands?.map((brand) => (
              <SelectOption
                optionValue={brand._id}
                key={brand._id}
                optionText={brand?.name}
              />
            ))}
          </SelectInput>
          <ShortTextInput
            label={`Name`}
            value={name}
            placeholder={`Name`}
            setState={setName}
          />
          <ShortTextInput
            label={`Slug`}
            value={slugify(name)}
            placeholder={`Enter Slug`}
            disabled
          />
          <ShortTextInput
            label={`Price`}
            value={price}
            placeholder={`Price`}
            setState={setPrice}
          />
          <SelectInput label={"Partner"} value={partner} setState={setPartner}>
            {/* <SelectOption optionValue="" optionText="Select Partner" /> */}
            {partners?.map((partner) => (
              <SelectOption
                optionValue={partner._id}
                key={partner._id}
                optionText={partner?.name}
              />
            ))}
          </SelectInput>

          {/* <ShortTextInput
            label={`Description`}
            value={description}
            placeholder={`Description`}
            setState={setDescription}
          /> */}

          <CustomEditor 
          setState={setDescription} data={description}
          />

          <SwitchInput
            label={`IsDiscount`}
            toggleSwitch={() => setIsDiscount((prevState) => !prevState)}
            checked={isDiscount}
          />
          {isDiscount && (
            <SelectInput
              label={"Discount"}
              value={discount}
              setState={setDiscount}
            >
              <SelectOption
                optionValue=""
                optionText="Select Discount Percent"
              />
              {discounts?.map((discount) => (
                <SelectOption
                  optionValue={discount._id}
                  key={discount._id}
                  optionText={discount?.percentage}
                />
              ))}
            </SelectInput>
          )}
          <SelectInput
            label={"Size Guide"}
            value={sizeGuide}
            setState={setSizeGuide}
          >
            <SelectOption optionValue="" optionText="Select Size Guide" />
            {sizeGuides?.length > 0 && sizeGuides?.map((sg) => (
              <SelectOption
                optionValue={sg._id}
                key={sg._id}
                optionText={sg?.name}
              />
            ))}
          </SelectInput>
          <SwitchInput
            label={`Trial Status`}
            toggleSwitch={() => setTrialStatus((prevState) => !prevState)}
            checked={trialStatus}
          />
          <SwitchInput
            label={`Visibility`}
            toggleSwitch={() => setIsVisible((prevState) => !prevState)}
            checked={isVisible}
          />
          <ImageInput
            fieldId="1"
            state={cardFrontImage}
            setState={setCardFrontImage}
            allowUpdateImage
          >
            Upload Card Front Image
          </ImageInput>
          <ImageInput
            fieldId="2"
            state={cardHoverImage}
            setState={setCardHoverImage}
            allowUpdateImage
          >
            Upload Card Hover Image
          </ImageInput>
          <ShortTextInput
            label={`Meta Title`}
            value={metaTitle}
            placeholder={`Meta Title`}
            setState={setMetaTitle}
          />
          <ShortTextInput
            label={`Meta Description`}
            value={metaDescription}
            placeholder={`Meta Description`}
            setState={setMetaDescription}
          />
          <NumberInput
            label={"Precedence"}
            placeholder={"Precedence"}
            setState={setPrecedence}
            value={precedence}
          />
        </div>
        <FormSubmitButton text="Update Product" />
      </Form>
    </div>
  );
}

export default UpdateProduct;

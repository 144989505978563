import React, { useState } from 'react'
import FormSubmitButton from '../../../Partials/Layouts/Forms/FormSubmitButton/FormSubmitButton'
import Form from '../../../Partials/Layouts/Forms/Form'
import EmailInput from '../../../Partials/Layouts/Forms/FormInputs/EmailInput/EmailInput'
import { Link } from 'react-router-dom'
// import { EMPLOYEE_AUTH_API } from '../../../../Utilities/APIs/BizDev/UsersAPI/EmployeesAPI/EmployeesAPI'

import './ForgotPassword.css'
import useAxiosAuthInstance from '../../../../Utilities/Hooks/AxiosInstanceHooks/useAxiosAuthInstance'
import { FORGOT_PASSWORD_API } from '../../../../Utilities/APIs/AuthAPIs/AuthAPIs'

function ForgotPassword() {

    const [emailSent, setEmailSent] = useState(false)
    const [email, setEmail] = useState('')

    const axiosAuthInstance = useAxiosAuthInstance()

    async function handleSubmit(e) {
        e.preventDefault()
        if (email.length > 0) {
            const forgotPass = await axiosAuthInstance.post(FORGOT_PASSWORD_API, { email })
            if (forgotPass) {
                console.log(forgotPass)
                setEmailSent(true)
            }
        }
    }
    return (
        <>
            {
                emailSent ?
                    <div className="form_wrapper">
                        <h3>Check your email</h3>
                        <p className="auth_status">If we found an account with <span>{email ? email : ''}</span>, an email has been sent. Please check your email in a moment.</p>
                        <div className="dha_box" style={{ marginTop: '0' }}>
                            <p className="body_text">Didn’t receive a link?</p>
                            <button type='submit' className='btn_text sign_up'>resend email</button>
                        </div>
                        <div className="have_account">
                            <Link to='/auth/login'>
                                <button>Go Back Login</button>
                            </Link>
                        </div>
                    </div>
                    :
                    <div className="form_wrapper forgot_pass">
                        <h3>Forgot Password</h3>
                        <p>Enter your email address below and we’ll send you a link to
                            reset your password</p>
                        <Form
                            onSubmit={handleSubmit}

                        >

                            <EmailInput
                                label='Email'
                                value={email}
                                placeholder='Please Enter your Email'
                                setState={setEmail}
                            />

                            <FormSubmitButton text='Continue' />

                        </Form>
                        <div className="have_account">
                            <Link to='/auth/login'>
                                <button>Go Back</button>
                            </Link>
                        </div>
                    </div>
            }
        </>
    )
}

export default ForgotPassword
import React, { useEffect, useState } from "react";
import Form from "../../../../../Partials/Layouts/Forms/Form";
import FormSubmitButton from "../../../../../Partials/Layouts/Forms/FormSubmitButton/FormSubmitButton";
import useAxiosInstance from "../../../../../../Utilities/Hooks/useAxiosInstance";
import { PRODUCTS_API, PRODUCT_COLLECTIONS_API } from "../../../../../../Utilities/APIs/APIs";
import SelectInput from "../../../../../Partials/Layouts/Forms/FormInputs/SelectInput/SelectInput";
import SelectOption from "../../../../../Partials/Layouts/Forms/FormInputs/SelectInput/SelectOption/SelectOption";
import ShortTextInput from "../../../../../Partials/Layouts/Forms/FormInputs/ShortTextInput/ShortTextInput";

function UpdateFeaturdCollection({targetID, triggerFetch, setShowUpdateForm}) {
  const axiosInstance = useAxiosInstance()

const [productCollection, setProductCollection] = useState("");
const [precedence, setPrecedence] = useState("");
const [type, setType] = useState("")

const [productCollections, setProductCollections] = useState(null);



useEffect(() => {
    async function fetchAndSetSingleProductCollections() {
      const { data } = await axiosInstance.get(PRODUCT_COLLECTIONS_API + 'getSingleFeaturedCollection/' + targetID)
      setProductCollection(data.productCollection._id)
      setPrecedence(data.precedence)
      setType(data.type)
    }
    fetchAndSetSingleProductCollections()

  }, [targetID, axiosInstance])

  
useEffect(() => {
  async function fetchAndSetProductCollections() {
    const { data } = await axiosInstance.get(
      PRODUCT_COLLECTIONS_API + "getAllProductCollections"
    );
    setProductCollections(data);
  }
  fetchAndSetProductCollections();
}, [axiosInstance]);


async function handleSubmit(e) {
  e.preventDefault();
  const itemData = { productCollection,precedence,type };

  const response = await axiosInstance.patch( PRODUCT_COLLECTIONS_API + "updateFeaturedCollection/" + targetID ,itemData);
  if (response) {
    triggerFetch();
    setShowUpdateForm(false);
  }
}

  return (
    <div className="crate_form">
    <Form onSubmit={handleSubmit}>
      <div>
        <SelectInput
          label={"Name"}
          value={productCollection}
          setState={setProductCollection}
        >
          <SelectOption optionValue="" optionText="Select Featured Collection" />
          {productCollections?.map((product) => (
            <SelectOption
              optionValue={product._id}
              key={product._id}
              optionText={product?.name}
            />
          ))}
        </SelectInput>

        <SelectInput
          label={"Precedence"}
          value={precedence}
          setState={setPrecedence}
        >
          <SelectOption optionValue="" optionText="Select Precedence" />
          <SelectOption optionValue="1" optionText="1" />
          <SelectOption optionValue="2" optionText="2" />
          <SelectOption optionValue="3" optionText="3" />
        </SelectInput>

        <SelectInput
          label={"Type"}
          value={type}
          setState={setType}
        >
          <SelectOption optionValue="" optionText="Select Type" />
          <SelectOption optionValue="colored" optionText="Colored" />
          <SelectOption optionValue="background" optionText="Background" />
          <SelectOption optionValue="animation" optionText="Animation" />
          <SelectOption optionValue="normal" optionText="Normal" />
        </SelectInput>
      </div>
      <FormSubmitButton text="Add Featured Collection" />
    </Form>
  </div>
  )
}

export default UpdateFeaturdCollection;

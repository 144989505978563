import { ORDERS_API } from "../../../../../Utilities/APIs/APIs";
import useAxiosInstance from "../../../../../Utilities/Hooks/useAxiosInstance";

function CancelOrder({ setShowCancelOrder, targetID, triggerFetch }) {
    const axiosInstance = useAxiosInstance();
  async function handleCancel() {
    const canceledOrder = await axiosInstance.patch(
      ORDERS_API + "canceledOrder/" + targetID,
      ""
    );

    if (canceledOrder) {
      triggerFetch();
      setShowCancelOrder(false)
    }
  }

  return (
    <div className="delete_item">
      <div className="delete_content">
        <h1>Are you sure?</h1>
        <p>Please confirm you want to cancel this order?</p>
        <div className="delete_btn">
          <button onClick={handleCancel} className="delete">
            Delete
          </button>
          <button
            className="cancel"
            onClick={() => {
              setShowCancelOrder(false);
            }}
          >
            Cancel
          </button>
        </div>
      </div>
    </div>
  );
}

export default CancelOrder;

import Register from "./Register/Register";
import Login from "./Login/Login";
import ForgotPassword from "./ForgotPassword/ForgotPassword";
import EmailVerification from "./EmailVerification/EmailVerification";
import RecoverPassword from "./RecoverPassword/RecoverPassword";

const authRoutes = [
    {
        path: "register/:token",
        element: <Register />
    },
    {
        path: "login",
        element: <Login />
    },
    {
        path: "forgotPassword",
        element: <ForgotPassword />
    },
    {
        path: "emailVerification",
        element: <EmailVerification />
    },
    {
        path: "resetEmployeePassword/:token",
        element: <RecoverPassword />
    },
]

export default authRoutes
import { Link } from 'react-router-dom';
import blacklogo from '../../../../../Assets/Images/blacklogo.png';
import './RestockEmail.css';
import restockimage from '../../../../../Assets/Images/restockimage.jpg';

function RestockEmail() {

return (
   
<div className="mailstyle">
 <div className="side_style">
  <div style={{ overflow: "hidden" , paddingBottom: "5px" }}>
 
   <div>
    <p style={{ fontSize: "10px", textAlign: "center", color: "#000" }}>Free delivery from ৳3000+ and Return available within 7 days |  
    <Link to ="/" style= {{ textDecoration: "none", fontWeight: "bold", color: "#f1652f" }}> View in browser </Link> </p>
   </div>

   <div className="header_logo" style={{ textAlign: "center" }}> 
     <Link to ="/">
      <img style= {{ width: "170px"}} src={blacklogo} alt="logo"/>
     </Link>
   </div>

   <hr style={{ width: "100%", border: "1px solid #eee", marginBottom: "32px" }}/>
 
   <div style={{ textAlign: "center" }}>
    <h4 style={{ textAlign: "center", color: "#000" }}>The item is available now!</h4>
    <p style={{ color: "#000" }}>The item you were interested in is now available again at &nbsp; 
    <Link to ="/" style={{ textDecoration: "none", fontWeight: "bold", color: "#f1652f" }}>stridesco.com</Link>
    <br/>Hurry and get it before it goes out of stock again!</p>
   </div>

   <br/><br/>

  <table>
    <tr>

    </tr>
    <tr>
      <td rowspan="4" className="td_image_style">
        <img className="img_style" src={restockimage} alt="productimage" />
      </td>
      <td>
        <p className="product_title" style={{color: "#000"}}>product_title</p>
      </td>
      <td rowspan="4">
        <p className="p_price"><span className= "old_price" style={{ color: "#000"}}>৳old_price</span>
        <br/> <span style={{ color: "#f1652f" }}>৳ new price </span></p>
      </td>
    </tr>
    <tr>
      <td>
        <p style={{ color: "#000" }}><span style={{ fontWeight: "bold" }}>Size:</span> size </p>
      </td>
    </tr>
    <tr>
      <td colspan="2"><p className="product_quan" style={{ fontWeight: "bold", color: "#000" }}>There are only size quantity pieces left </p></td>
    </tr>
    <tr>
      <td colspan="2" className="shop_btn"><button className="btn_shop" style={{ width: "100%", background: "#000", outline: "0", border: "1px solid black", paddingTop: "5px", paddingBottom: "5px" }}>
       <Link to ="productlink" style={{ textDecoration: "none", color: "#fff" }}> Shop Now </Link></button> </td>
    </tr>

  </table>

</div>
</div>
</div>

  )
}

export default RestockEmail
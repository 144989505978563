// export const BASE_URL =process.env.REACT_APP_BACKEND_URL
// export const API_URL = BASE_URL + 'api/'

//frontend api groups
export const FRONTEND_CONTENTS_API = "frontendContents/";
export const SLIDERS_API = FRONTEND_CONTENTS_API + "sliders/";
export const FAQ_API = FRONTEND_CONTENTS_API + "faqs/";
export const SOCIALLINKS_API = FRONTEND_CONTENTS_API + "socialLinks/";

//product api groups
export const PRODUCT_GROUP_API = "productGroups/";
export const PRODUCTS_API = PRODUCT_GROUP_API + "products/";
export const PRODUCT_IMAGES_API = PRODUCT_GROUP_API + "productImages/";
export const COLORS_API = PRODUCT_GROUP_API + "colors/";
export const BRANDS_API = PRODUCT_GROUP_API + "brands/";
export const CATAGORY_API = PRODUCT_GROUP_API + "categories/";
export const SIZE_API = PRODUCT_GROUP_API + "sizes/";
export const MATERIAL_API = PRODUCT_GROUP_API + "materials/";
export const PRODUCTCOLOR_API = PRODUCT_GROUP_API + "productColors/";
export const PRICEHISTORY_API = PRODUCT_GROUP_API + "priceHistories/";
export const PAIREDPRODUCT_API = PRODUCT_GROUP_API + "pairedProducts/";
export const PRODUCTSIZE_API = PRODUCT_GROUP_API + "productSizes/";
export const PRODUCTMATERIAL_API = PRODUCT_GROUP_API + "productMaterials/";
export const DISCOUNTS_API = PRODUCT_GROUP_API + "discounts/";
export const CARE_API = PRODUCT_GROUP_API + "cares/";
export const PRODUCT_CARE_API = PRODUCT_GROUP_API + "productCares/";
export const PRODUCT_COLLECTIONS_API = PRODUCT_GROUP_API + "productCollections/";
export const DEMAND_PRODUCTS_API = PRODUCT_GROUP_API + "demandProducts/";

//address api groups
export const ADDRESS_GROUP_API = "addressGroups/";
export const DIVISION_API = ADDRESS_GROUP_API + "divisions/";
export const DISTRICT_API = ADDRESS_GROUP_API + "districts/";
export const AREA_API = ADDRESS_GROUP_API + "areas/";
export const ADDRESS_API = ADDRESS_GROUP_API + "addresses/";

//users api groups
export const EMPLOYEE_API = "employees/";
export const DEPARTMENTS_API = "departments/";
export const ROLES_API = "roles/";
export const DESIGNATIONS_API = "designations/";
export const EMPLOYEE_ROLES_API = "employeeRoles/";
export const EMPLOYEE_DESIGNATIONS_API = "employeeDesignations/";

//partner api groups
export const PARTNER_GROUP_API = "partnerGroups/";
export const PARTNERS_API = PARTNER_GROUP_API + "partners/";

//promotion
export const PROMOTIONS_API = "promotions/";
//changedInventories
export const CHANGED_INVENTORIES_API = "inventory/changeInventory/";

//order
export const ORDERS_API = "orders/";
//customers
export const CUSTOMERS = "customers/";
export const SIZEGUIRDES = PRODUCT_GROUP_API + "sizeGuides/";
export const PRODUCT_COST = PRODUCT_GROUP_API + "manageCosts/";

//loyaltyClub
export const LOYALTY_CLUB_API = "loyaltyClub/";
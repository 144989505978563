import { useEffect, useState } from 'react'
import useAxiosInstance from '../../../../../Utilities/Hooks/useAxiosInstance'
import { MATERIAL_API } from '../../../../../Utilities/APIs/APIs'
import Image from '../../../../Partials/Elements/Image/Image'

function ViewMaterial({targetID}) {

    const axiosInstance = useAxiosInstance()
    
    const [materialInfo, setmaterialInfo] = useState(null)

    useEffect(() => {
        async function fetchAndSetSingleMaterial() {
          const { data } = await axiosInstance.get(MATERIAL_API + 'getSingleMaterial/' + targetID)
          setmaterialInfo(data)
        }
        fetchAndSetSingleMaterial()

      }, [targetID, axiosInstance])

    return (
        <div className="crud_view_content">
            {
                materialInfo &&
                <>
                    <h1>Material</h1>
                    <p>{materialInfo.name}</p>
                    <h1>Material Image</h1>
                    <Image
                        imgLink={materialInfo.image}
                        imgAlt={'Material Image'}
                    />
                    <h1>Status</h1>
                    <p>{materialInfo.isActive === true ? "True" : "False"}</p>
                </>
            }
            
        </div>
    )
}

export default ViewMaterial
import { useState, useEffect, useContext } from 'react';
import { Link, useNavigate, useParams } from 'react-router-dom';
import Form from '../../../Partials/Layouts/Forms/Form'
import ShortTextInput from '../../../Partials/Layouts/Forms/FormInputs/ShortTextInput/ShortTextInput'
import PasswordInput from '../../../Partials/Layouts/Forms/FormInputs/PasswordInput/PasswordInput'
import FormSubmitButton from '../../../Partials/Layouts/Forms/FormSubmitButton/FormSubmitButton'
// import EmailInput from '../../../Partials/Layouts/Forms/FormInputs/EmailInput/EmailInput'
import AppContext from '../../../../AppContext/AppContext'
import ImageInput from '../../../Partials/Layouts/Forms/FormInputs/ImageInput/ImageInput'
// import useAxiosInstance from '../../../../Utilities/Hooks/useAxiosInstance';

import '../../AuthScreens/Auth.css'
import './Register.css'
// import { EMPLOYEE_INVITES_API } from '../../../../Utilities/APIs/AuthAPIs/AuthAPIs';
import useAxiosAuthInstance from '../../../../Utilities/Hooks/AxiosInstanceHooks/useAxiosAuthInstance';

function Register() {

    const { employee, register } = useContext(AppContext)

    const [name, setName] = useState('')
    const [email, setEmail] = useState('')
    const [password, setPassword] = useState('')
    const [password2, setPassword2] = useState('')
    const [dp, setDp] = useState(null)
    const navigate = useNavigate()
  const { token } = useParams()
  // console.log('token: ', token)
  const axiosAuthInstance = useAxiosAuthInstance()

  useEffect(() => {

    async function fetchAndSetEmail() {
      const { data } = await axiosAuthInstance.get('getEmailFromToken/' + token)
      // console.log('Data: ', data)
      setEmail(data.email)
    }
    fetchAndSetEmail()
  }, [token, axiosAuthInstance])

  useEffect(() => {
    if (employee) {
      navigate('/');
    }

  }, [employee, navigate])

    function handleSubmit(e) {

        e.preventDefault();

        if (password !== password2) {
            console.log('Passwords do not match!')

        } else {

            const employeeData = new FormData();

            employeeData.append('name', name)
            employeeData.append('email', email)
            employeeData.append('password', password)
            employeeData.append('dp', dp)
            employeeData.append('level', 'employee')

            register(employeeData)

            setName('')
            setEmail('')
            setPassword('')
            setPassword2('')
            setDp('')
            

        }

    }

    return (
        <div className="form_wrapper">
            <h3>Register</h3>
            <Form
                onSubmit={handleSubmit}
                hasImage
            >
                <ShortTextInput
                    label='Name'
                    value={name}
                    placeholder='Please Enter your Name'
                    setState={setName}
                />
                {/* <EmailInput
                    label='Email'
                    value={email}
                    placeholder='Please Enter your Email'
                    setState={setEmail}
                /> */}
                <PasswordInput
                    label='Password'
                    value={password}
                    placeholder='Please Enter your Password'
                    setState={setPassword}
                />
                <PasswordInput
                    label='Confirm Password'
                    value={password2}
                    placeholder='Please Confirm your Password'
                    setState={setPassword2}
                />
                <ImageInput
                    allowCreateImage
                    state={dp}
                    setState={setDp}
                    fieldId={'employeeDp'}
                    children={'Display Picture'}
                />

                <FormSubmitButton text='Create Account' />

            </Form>
            <div className="have_account">
                <p>Already have an account?</p>
                <Link to='/auth/login'>
                    <button>Log In</button>
                </Link>
            </div>
        </div>
    )
}

export default Register
import { useEffect, useRef, useState } from 'react'
import CreateButton from '../../../../Partials/Layouts/CRUDs/CRUDHeader/CreateButton/CreateButton';
import CRUDTable from '../../../../Partials/Layouts/CRUDs/CRUDTable/CRUDTable';
import CRUDTableHeader from '../../../../Partials/Layouts/CRUDs/CRUDTable/CRUDTableHeader/CRUDTableHeader';
import CRUDth from '../../../../Partials/Layouts/CRUDs/CRUDTable/CRUDTableHeader/CRUDth/CRUDth';
import ViewButton from '../../../../Partials/Layouts/CRUDs/CRUDButtons/ViewButton/ViewButton';
import EditButton from '../../../../Partials/Layouts/CRUDs/CRUDButtons/EditButton/EditButton';
import DeleteButton from '../../../../Partials/Layouts/CRUDs/CRUDButtons/DeleteButton/DeleteButton';
import ShortTextCell from '../../../../Partials/Layouts/CRUDs/CRUDTable/CRUDTableCells/ShortTextCell/ShortTextCell';
import CRUDTableRow from '../../../../Partials/Layouts/CRUDs/CRUDTable/CRUDTableRow/CRUDTableRow';
import Modal from '../../../../Partials/Elements/Modal/Modal';
import useAxiosInstance from '../../../../../Utilities/Hooks/useAxiosInstance';
import DeleteItem from '../../../../Partials/Layouts/DeleteItem/DeleteItem';
import { PRODUCT_CARE_API } from '../../../../../Utilities/APIs/APIs';
import ViewProductCare from './ViewProductCare';
import CreateProductCare from './CreateProductCare';
import UpdateProductCare from './UpdateProductCare';
import { useParams } from 'react-router-dom';


function ProductCares() {

  const [productcares, setProductCares] = useState(null)
  const screenHeaerRef = useRef()
  const [screenHeaderHeight, setScreenHeaderHeight] = useState(0);

  useEffect(() => {
    let screenHeaderHeight = screenHeaerRef.current.clientHeight;
    setScreenHeaderHeight(screenHeaderHeight)
  }, [screenHeaerRef]);

  const [showCreateForm, setShowCreateForm] = useState(false);
  const [showViewSection, setShowViewSection] = useState(false);
  const [showUpdateForm, setShowUpdateForm] = useState(false);
  const [showDeleteSection, setShowDeleteSection] = useState(false)
  const [toggleFetch, setToggleFetch] = useState(false)

  const [targetID, setTargetID] = useState(null)
  const axiosInstance = useAxiosInstance()
const {productID } = useParams ()
  useEffect(() => {

    async function fetchAndsetProductCares() {
      const { data } = await axiosInstance.get(PRODUCT_CARE_API + 'getProductCaresByProduct/' + productID) 
      setProductCares(data)
    }
    fetchAndsetProductCares()

  }, [toggleFetch, axiosInstance ,productID])

  function triggerFetch() {
    setToggleFetch(prevState => !prevState)
  }
  return (
    <>
      <div className='screen_header' ref={screenHeaerRef}>
        <h1 className='screen_heading'>Product Cares ({productcares?.length}  in total)</h1>
        <CreateButton
          screenTopicSingular={'Product Cares'}
          setShowCreateForm={setShowCreateForm}
        />
      </div>
      <CRUDTable screenHeaderHeight={screenHeaderHeight}>
        <CRUDTableHeader>
          <CRUDth th='Products' />
          <CRUDth th='Cares' />
          <CRUDth th='Action' />

        </CRUDTableHeader>
        <tbody>
          {
            productcares?.map(productcare =>
              <CRUDTableRow key={productcare._id}>
                <ShortTextCell text={productcare?.product?.name} />
                <ShortTextCell text={productcare?.care?.name} />
                <td>
                  <div className="action_buttons_wrapper">
                    <div className="action_buttons">
                      <ViewButton
                        setShowViewSection={setShowViewSection}
                        targetID={productcare._id}
                        setTargetID={setTargetID}
                      />

                      <EditButton
                        setShowUpdateForm={setShowUpdateForm}
                        targetID={productcare._id}
                        setTargetID={setTargetID}
                      />

                      <DeleteButton
                        setShowDeleteSection={setShowDeleteSection}
                        targetID={productcare._id}
                        setTargetID={setTargetID}
                      />
                    </div>
                  </div>
                </td>
              </CRUDTableRow>

            )
          }
        </tbody>
      </CRUDTable>
      {
        showCreateForm &&
        <Modal
          extraClass={'small'}
          modalHeading={`Add Product Care`}
          setShowModalContent={setShowCreateForm}
        >
          <CreateProductCare
          productID={productID}
            triggerFetch={triggerFetch}
            setShowCreateForm={setShowCreateForm}
          />
        </Modal>
      }
      {
        showUpdateForm &&
        <Modal
          extraClass={'small'}
          modalHeading={`Update Product Care`}
          setShowModalContent={setShowUpdateForm}
        >
          <UpdateProductCare
          productID={productID}
          
            triggerFetch={triggerFetch}
            targetID={targetID}
            setShowUpdateForm={setShowUpdateForm}
          />
        </Modal>
      }
      {
        showViewSection &&
        <Modal
          extraClass={'small'}
          modalHeading={`View Product Care`}
          setShowModalContent={setShowViewSection}
        >
          <ViewProductCare
            targetID={targetID}
          />
        </Modal>
      }
      {
        showDeleteSection &&
        <Modal
          extraClass={'small'}
          modalHeading={`Delete Product Care`}
          setShowModalContent={setShowDeleteSection}
        >
          <DeleteItem
            api={PRODUCT_CARE_API}
            triggerFetch={triggerFetch}
            targetID={targetID}
            setShowDeleteItem={setShowDeleteSection}
          />
        </Modal>
      }
    </>
  )
}

export default ProductCares
import { useEffect, useState } from 'react'
import useAxiosInstance from '../../../../../Utilities/Hooks/useAxiosInstance'
import { PRICEHISTORY_API } from '../../../../../Utilities/APIs/APIs'

function ViewPriceHistory({ targetID }) {

    const axiosInstance = useAxiosInstance()

    const [priceHistoryInfo, setpriceHistoryInfo] = useState(null)

    useEffect(() => {
        async function fetchAndSetSinglePriceHistory() {
            const { data } = await axiosInstance.get(PRICEHISTORY_API + 'getSinglePriceHistory/' + targetID)
            setpriceHistoryInfo(data)
        }
        fetchAndSetSinglePriceHistory()

    }, [targetID, axiosInstance])

    return (
        <div className="crud_view_content">
            {
                priceHistoryInfo &&
                <>
                    <h1>Product</h1>
                    <p>{priceHistoryInfo.product?.name}</p>

                    <h1>Price</h1>
                    <p>{priceHistoryInfo.price}</p>

                    <h1>Date</h1>
                    <p>{new Date(priceHistoryInfo.date).toDateString()}</p>
                </>
            }

        </div>
    )
}

export default ViewPriceHistory
import { useEffect, useState } from "react"
import { PRODUCTS_API, MATERIAL_API, PRODUCTMATERIAL_API } from "../../../../../Utilities/APIs/APIs"
import useAxiosInstance from "../../../../../Utilities/Hooks/useAxiosInstance"
import Form from "../../../../Partials/Layouts/Forms/Form"
import FormSubmitButton from "../../../../Partials/Layouts/Forms/FormSubmitButton/FormSubmitButton"
import SelectInput from "../../../../Partials/Layouts/Forms/FormInputs/SelectInput/SelectInput"
import SelectOption from "../../../../Partials/Layouts/Forms/FormInputs/SelectInput/SelectOption/SelectOption"

function CreateProductMaterial({ productID, triggerFetch, setShowCreateForm }) {

    const axiosInstance = useAxiosInstance()

    const [material, setMaterial] = useState('')

    const [materials, setMaterials] = useState(null)

    useEffect(() => {
        async function fetchAndSetMaterials() {
            const { data } = await axiosInstance.get(MATERIAL_API)
            setMaterials(data)
        }
        fetchAndSetMaterials()

    }, [axiosInstance])

    async function handleSubmit(e) {

        e.preventDefault()
        const itemData = new FormData()

        itemData.append('product', productID)
        itemData.append('material', material)

        const response = await axiosInstance.post(PRODUCTMATERIAL_API, itemData)
        if (response) {
            triggerFetch()
            setShowCreateForm(false)
        }
    }

    return (
        <div className='crate_form'>
            <Form onSubmit={handleSubmit}>
                <div>
                    <SelectInput
                        label={'Material'}
                        value={material}
                        setState={setMaterial}
                    >
                        <SelectOption optionValue='' optionText="Select Material" />
                        {materials?.map((material => (
                            <SelectOption optionValue={material._id} key={material._id}
                                optionText={material?.name} />
                        )))}
                    </SelectInput>
                  
                </div>
                <FormSubmitButton text="Add Product Material" />
            </Form>
        </div>
    )
}

export default CreateProductMaterial
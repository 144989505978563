import { useEffect, useRef, useState } from "react";
import DeleteButton from "../../../../../Partials/Layouts/CRUDs/CRUDButtons/DeleteButton/DeleteButton";
import EditButton from "../../../../../Partials/Layouts/CRUDs/CRUDButtons/EditButton/EditButton";
import ViewButton from "../../../../../Partials/Layouts/CRUDs/CRUDButtons/ViewButton/ViewButton";
import CreateButton from "../../../../../Partials/Layouts/CRUDs/CRUDHeader/CreateButton/CreateButton";
import CRUDTable from "../../../../../Partials/Layouts/CRUDs/CRUDTable/CRUDTable";
import ShortTextCell from "../../../../../Partials/Layouts/CRUDs/CRUDTable/CRUDTableCells/ShortTextCell/ShortTextCell";
import CRUDTableHeader from "../../../../../Partials/Layouts/CRUDs/CRUDTable/CRUDTableHeader/CRUDTableHeader";
import CRUDth from "../../../../../Partials/Layouts/CRUDs/CRUDTable/CRUDTableHeader/CRUDth/CRUDth";
import CRUDTableRow from "../../../../../Partials/Layouts/CRUDs/CRUDTable/CRUDTableRow/CRUDTableRow";
import useAxiosInstance from "../../../../../../Utilities/Hooks/useAxiosInstance";
import { PRODUCT_COLLECTIONS_API } from "../../../../../../Utilities/APIs/APIs";
import Modal from "../../../../../Partials/Elements/Modal/Modal";
import CreateFeaturdCollection from "./CreateFeaturdCollection";
import UpdateFeaturdCollection from "./UpdateFeaturdCollection";
import ViewFeaturdCollection from "./ViewFeaturdCollection";


function FeaturdCollection() {
  const [featurdCollection, setFeaturdCollection] = useState(null);
  const screenHeaerRef = useRef();
  const [screenHeaderHeight, setScreenHeaderHeight] = useState(0);

  useEffect(() => {
    let screenHeaderHeight = screenHeaerRef.current.clientHeight;
    setScreenHeaderHeight(screenHeaderHeight);
  }, [screenHeaerRef]);

  const [showCreateForm, setShowCreateForm] = useState(false);
  const [showViewSection, setShowViewSection] = useState(false);
  const [showUpdateForm, setShowUpdateForm] = useState(false);
  const [showDeleteSection, setShowDeleteSection] = useState(false);
  const [toggleFetch, setToggleFetch] = useState(false);

  const [targetID, setTargetID] = useState(null);
  const axiosInstance = useAxiosInstance();

  useEffect(() => {
    async function fetchAndSetProductCollections() {
      const { data } = await axiosInstance.get(
        PRODUCT_COLLECTIONS_API + "getAllFeaturedCollections"
      );
      setFeaturdCollection(data);
    }
    fetchAndSetProductCollections();
  }, [toggleFetch, axiosInstance]);

  function triggerFetch() {
    setToggleFetch((prevState) => !prevState);
  }
  return (
    <>
      <div className="screen_header" ref={screenHeaerRef}>
        <h1 className="screen_heading">
        Featurd Collections ({featurdCollection?.length} in total)
        </h1>
        <CreateButton
          screenTopicSingular={"Featurd Collection"}
          setShowCreateForm={setShowCreateForm}
        />
      </div>
      <CRUDTable screenHeaderHeight={screenHeaderHeight}>
        <CRUDTableHeader>
          <CRUDth th="Name" />
          <CRUDth th="precedence" />
          <CRUDth th="Type" />
          <CRUDth th="Actions" />
        </CRUDTableHeader>

        <tbody>
          {featurdCollection?.map((fc ) => (
            <CRUDTableRow key={fc._id}>
              <ShortTextCell text={fc?.productCollection?.name} />
              <ShortTextCell text={fc?.precedence} />
              <ShortTextCell text={fc?.type} />
              <td>
                <div className="action_buttons_wrapper">
                  <div className="action_buttons">
                    <ViewButton
                      setShowViewSection={setShowViewSection}
                      targetID={fc._id}
                      setTargetID={setTargetID}
                      tooltipName={"View"} 
                      tooltipContent={"View"}
                      
                    />

                    <EditButton
                      setShowUpdateForm={setShowUpdateForm}
                      targetID={fc._id}
                      setTargetID={setTargetID}
                      tooltipName={"Edit"} 
                      tooltipContent={"Edit"}
                    />

                    <DeleteButton
                      setShowDeleteSection={setShowDeleteSection}
                      targetID={fc._id}
                      setTargetID={setTargetID}
                      tooltipName={"Delete"} 
                      tooltipContent={"Delete"}
                    />
                  </div>
                </div>
              </td>
            </CRUDTableRow>
          ))}
        </tbody>
      </CRUDTable>
      {showCreateForm && (
        <Modal
          extraClass={"small"}
          modalHeading={`Add Featured Collection`}
          setShowModalContent={setShowCreateForm}
        >
          <CreateFeaturdCollection
            triggerFetch={triggerFetch}
            setShowCreateForm={setShowCreateForm}
          />
        </Modal>
      )}
      {showUpdateForm && (
        <Modal
          extraClass={"small"}
          modalHeading={`Update Featured Collection`}
          setShowModalContent={setShowUpdateForm}
        >
          <UpdateFeaturdCollection
            triggerFetch={triggerFetch}
            targetID={targetID}
            setShowUpdateForm={setShowUpdateForm}
          />
        </Modal>
      )}
      {showViewSection && (
        <Modal
          extraClass={"small"}
          modalHeading={`View product Collection Name`}
          setShowModalContent={setShowViewSection}
        >
          <ViewFeaturdCollection targetID={targetID} />
        </Modal>
      )}
      {/* {showDeleteSection && (
        <Modal
          extraClass={"small"}
          modalHeading={`Delete Brand`}
          setShowModalContent={setShowDeleteSection}
        >
          <DeleteItem
            // api={CATAGORY_API}
            triggerFetch={triggerFetch}
            targetID={targetID}
            setShowDeleteItem={setShowDeleteSection}
          />
        </Modal>
      )} */}
    </>
  );
}

export default FeaturdCollection
import { useEffect, useState } from "react"
import useAxiosInstance from "../../../../../Utilities/Hooks/useAxiosInstance"
import { PROMOTIONS_API } from "../../../../../Utilities/APIs/APIs"


function ViewPromotion({targetID}) {
    const axiosInstance = useAxiosInstance()
    
    const [promotionInfo, setPromotionInfo] = useState(null)
    useEffect(() => {
        async function fetchAndSetSingleProductCollections() {
          const { data } = await axiosInstance.get(PROMOTIONS_API + 'getSinglePromotion/' + targetID)
          setPromotionInfo(data)
        }
        fetchAndSetSingleProductCollections()

      }, [targetID, axiosInstance])

    return (
        <div className="crud_view_content">
            {
                promotionInfo &&
                <>
                    <h1>Product Collection</h1>
                    <p>{promotionInfo?.promoCode}</p>
                    <h1>maxlimit</h1>
                    <p>{promotionInfo?.maxlimit}</p>
                    <h1>discountType</h1>
                    <p>{promotionInfo?.discountType}</p>
                    <h1>promo Type</h1>
                    <p>{promotionInfo?.promoType}</p>
                    <h1>validStartDate</h1>
                    <p>{new Date(promotionInfo?.validStartDate).toLocaleDateString()}</p>
                    <h1>validEndDate</h1>
                    <p>{new Date(promotionInfo?.validEndDate).toLocaleDateString()}</p>
                </>
            }
            
        </div>
    )
}
export default ViewPromotion
import { RiAddCircleFill } from 'react-icons/ri'
import './CreateButton.css'

function CreateButton({ screenTopicSingular , setShowCreateForm }) {
  return (
    <button
      type='button'
      className='btn_create'
      onClick={() => {
        setShowCreateForm(true)
      }}
    >
      <RiAddCircleFill />
      <span>{`Create New ${screenTopicSingular}`}</span>
    </button>
  )
}

export default CreateButton
import { useEffect, useState } from "react"
import useAxiosInstance from '../../../../../Utilities/Hooks/useAxiosInstance';
import Form from "../../../../Partials/Layouts/Forms/Form"
import ShortTextInput from "../../../../Partials/Layouts/Forms/FormInputs/ShortTextInput/ShortTextInput"
import FormSubmitButton from "../../../../Partials/Layouts/Forms/FormSubmitButton/FormSubmitButton"
import { DIVISION_API } from "../../../../../Utilities/APIs/APIs"

function UpdateDivision({targetID, triggerFetch, setShowUpdateForm}) {

    const axiosInstance = useAxiosInstance()

    const [name, setTitle] = useState('')  
    
    useEffect(() => {
        async function fetchAndSetSingleDivision() {
          const { data } = await axiosInstance.get(DIVISION_API + targetID)
          setTitle(data.name)
        }
        fetchAndSetSingleDivision()

      }, [targetID, axiosInstance])

    async function handleSubmit(e) {

        e.preventDefault()
        const itemData = new FormData()

        itemData.append('name', name)

        const response = await axiosInstance.patch(DIVISION_API + targetID, itemData)
        if (response) {
            triggerFetch()
            setShowUpdateForm(false)
        }
    }

    return (
        <div className='crate_form'>
            <Form onSubmit={handleSubmit}>
                <div>
                    <ShortTextInput
                        label={`Division`}
                        value={name}
                        placeholder={`Enter Division`}
                        setState={setTitle}
                    />
                </div>
                <FormSubmitButton text="Submit" />
            </Form>
        </div>
    )
}

export default UpdateDivision
import { useEffect, useState } from 'react'
import useAxiosInstance from '../../../../../Utilities/Hooks/useAxiosInstance'
import { CATAGORY_API } from '../../../../../Utilities/APIs/APIs'
import Image from '../../../../Partials/Elements/Image/Image'

function ViewCatagory({targetID}) {

    const axiosInstance = useAxiosInstance()
    
    const [catagoryInfo, setcatagoryInfo] = useState(null)

    useEffect(() => {
        async function fetchAndSetSingleCatagory() {
          const { data } = await axiosInstance.get(CATAGORY_API + 'getSingleCategory/' + targetID)
          setcatagoryInfo(data)
        }
        fetchAndSetSingleCatagory()

      }, [targetID, axiosInstance])

    return (
        <div className="crud_view_content">
            {
                catagoryInfo &&
                <>
                    <h1>Category</h1>
                    <p>{catagoryInfo.categoryName}</p>
                    <h1>Gender</h1>
                    <p>{catagoryInfo.gender}</p>
                    <h1>Category Subtitle</h1>
                    <p>{catagoryInfo.categorySubtitle}</p>
                    <h1>Category Description</h1>
                    <p>{catagoryInfo.categoryDescription}</p>
                    <h1>Category Code</h1>
                    <p>{catagoryInfo.categoryCode}</p>
                    <h1>Meta Title</h1>
                    <p>{catagoryInfo.metaTitle}</p>
                    <h1>Meta Description</h1>
                    <p>{catagoryInfo.metaDescription}</p>
                    <h1>Precedence</h1>
                    <p>{catagoryInfo.precedence}</p>
                    <h1>Category Slug</h1>
                    <p>{catagoryInfo.categorySlug}</p>
                    <h1>Category Image</h1>
                    <Image
                        imgLink={catagoryInfo.categoryImage}
                        imgAlt={'Category Image'}
                    />
                    <h1>Status</h1>
                    <p>{catagoryInfo.isActive === true ? 'True' : 'False'}</p>
                </>
            }
            
        </div>
    )
}

export default ViewCatagory
import Dashboard from "./Dashboard/Dashboard";
import Blogs from "./Home/Blogs/Blogs";
import Sliders from "./Home/Sliders/Sliders";
import FAQS from "./Frontend/FAQ/FAQ";
import Colors from "./ProductSetup/Color/Colors";
import Sociallinks from "./Frontend/SocialLinks/SocialLinks";
import Divisions from "./Zone/Division/Division";
import Districts from "./Zone/District/District";
import Areas from "./Zone/Area/Area";
import InviteEmployees from "./Users/Employees/InviteEmployees/InviteEmployees";
import Catagories from "./ProductSetup/Categories/Categories";
import Brands from "./ProductSetup/Brands/Brands";
import Sizes from "./ProductSetup/Sizes/Sizes";
import Materials from "./ProductSetup/Materials/Materials";
import Products from "./ProductSetup/Products/Products";
import ProductColors from "./ProductSetup/ProductColors/ProductColors";
import PriceHistories from "./ProductSetup/PriceHistories/PriceHistories";
import PairedProducts from "./ProductSetup/PairedProducts/PairedProducts";
import ProductSizes from "./ProductSetup/ProductSizes/ProductSizes";
import ProductMaterials from "./ProductSetup/ProductMaterials/ProductMaterials";
import Departments from "./Users/Departments/Departments";
import Roles from "./Users/Roles/Roles";
import Designations from "./Users/Designation/Designations";
import EmployeeRoles from "./Users/Employees/EmployeeRoles/EmployeeRoles";
import EmployeeDesignations from "./Users/Employees/EmployeeDesignations/EmployeeDesignations";
import FAQScreens from "./Frontend/FAQScreens/FAQScreens";
import ProductImages from "./ProductSetup/ProductImages/ProductImages";
import OrderScreens from "./OrderScreens/OrderScreens";
import OrdersFromStatus from "./OrderScreens/OrdersFromStatus/OrdersFromStatus";
import Discounts from "./ProductSetup/Discounts/Discounts";
import Partners from "./ProductSetup/Partners/Partners";
import Cares from "./ProductSetup/Care/Cares";
import ProductCares from "./ProductSetup/ProductCares/ProductCares";
import Collection from "./ProductSetup/CollectionModule/Collection/Collection";
import FeaturdCollection from "./ProductSetup/CollectionModule/FeaturdCollection/FeaturdCollection";
import ProductCollections from "./ProductSetup/CollectionModule/ProductCollections/ProductCollections";
import ProductCollectionProducts from "./ProductSetup/CollectionModule/ProductCollectionProducts/ProductCollectionProducts";
import Promotions from "./ProductSetup/Promotions/Promotions";
import ChangedInventories from "./ProductSetup/ProductSizes/ChangedInventories/ChangedInventories";
import ProductScreen from "./ProductSetup/Products/ProductScreen/ProductScreen";
import ProductGrouping from "./ProductSetup/Products/ProductGrouping/ProductGrouping";
import ProductFeature from "./ProductSetup/Products/ProductFeature/ProductFeature";
import EmployeesScreen from "./Users/Users/EmployeesScreen/EmployeesScreen";
import ProductsScreen from "./ProductSetup/Products/ProductsScreen/ProductsScreen";
import OrderItemsFromOrders from "./OrderScreens/OrderItemsFromOrders";
import DemandProducts from "./ProductSetup/DemandProducts/DemandProducts";
import Customers from "./Users/Users/Customers/Customers";
import AddressesFromCustomer from "./Users/Users/Customers/AddressesFromCustomer/AddressesFromCustomer";
import CustomerOrders from "./Users/Users/Customers/CustomerOrders/CustomerOrders";
import CatagoriesScreen from "./ProductSetup/Categories/CatagoriesScreen/CatagoriesScreen";
import CollectionScreen from "./ProductSetup/CollectionModule/CollectionScreen/CollectionScreen";
import OrderInvoice from "../../Partials/Elements/OrderInvoice/OrderInvoice";
import Employees from "./Users/Users/EmployeesScreen/Employees/Employees";
import Admins from "./Users/Users/EmployeesScreen/Admins/Admins";
import OrderCompleteMail from "../../Partials/Elements/Mails/OrderCompleteMail";
import SizeGuides from "./ProductSetup/Products/SizeGuides/SizeGuides";
import RestockEmail from "../../Partials/Elements/Mails/RestockEmail/RestockEmail";
import ProductCost from "./ProductSetup/ProductCost/ProductCost";
import AllOrders from "./OrderScreens/AllOrders";
import SalesReport from "./Reports/SalesReport";
import CollaboratorSales from "./Reports/CollaboratorSales";
import TopSoldProducts from "./Reports/TopSoldProducts";
import NewAndOldCustomerReports from "./Reports/NewAndOldCustomer";
import LoyaltyClubs from "./Frontend/LoyaltyClub/LoyaltyClubs";
import ProductReports from "./ProductSetup/Products/ProductReport";
import ReportProcess from "./Reports/ReportProcess";
import ArchSalesReport from "./Reports/ArchSalesReport";
import AllArchOrders from "./OrderScreens/AllArchOrders";
// import ProductScreen from "../MainScreens/ProductSetup/Products/ProductScreen/ProductScreen"

const MainRoutes = [
  //default routes
  {
    path: "dashboard",
    element: <Dashboard />,
  },

  //frontend routes
  {
    path: "sliders",
    element: <Sliders />,
  },
  {
    path: "faq/:type",
    element: <FAQS />,
  },
  {
    path: "faqs",
    element: <FAQScreens />,
  },
  {
    path: "sociallinks",
    element: <Sociallinks />,
  },

  //product routes
  {
    path: "blogs",
    element: <Blogs />,
  },
  {
    path: "colors",
    element: <Colors />,
  },
  {
    path: "divisions",
    element: <Divisions />,
  },
  {
    path: "districts/:parentID",
    element: <Districts />,
  },
  {
    path: "areas/:parentID",
    element: <Areas />,
  },
  {
    path: "catagories/:gender",
    element: <Catagories />,
  },
  {
    path: "catagoriesScreen",
    element: <CatagoriesScreen />,
  },
  {
    path: "products",
    element: <Products />,
  },
  {
    path: "productreport",
    element: <ProductReports />,
  },
 
  {
    path: "productsScreen/:id",
    element: <ProductsScreen />,
  },
  {
    path: "productImages/:productID",
    element: <ProductImages />,
  },
  {
    path: "productCost/:productID",
    element: <ProductCost />,
  },
  {
    path: "brands",
    element: <Brands />,
  },
  {
    path: "partners",
    element: <Partners />,
  },
  {
    path: "sizes",
    element: <Sizes />,
  },
  {
    path: "cares",
    element: <Cares />,
  },
  {
    path: "materials",
    element: <Materials />,
  },
  {
    path: "productColors/:productID",
    element: <ProductColors />,
  },

  {
    path: "productCares/:productID",
    element: <ProductCares />,
  },
  {
    path: "pairedProducts/:productID",
    element: <PairedProducts />,
  },

  {
    path: "priceHistories/:productID",
    element: <PriceHistories />,
  },

  {
    path: "productSizes/:productID",
    element: <ProductSizes />,
  },
  {
    path: "productMaterials/:productID",
    element: <ProductMaterials />,
  },

  {
    path: "discounts",
    element: <Discounts />,
  },
  {
    path: "collection",
    element: <Collection />,
  },
  {
    path: "collectionScreen",
    element: <CollectionScreen />,
  },

  {
    path: "productCollections",
    element: <ProductCollections />,
  },
  {
    path: "productCollectionProducts/:id",
    element: <ProductCollectionProducts />,
  },
  {
    path: "featurdCollection",
    element: <FeaturdCollection />,
  },
  {
    path: "promoCode",
    element: <Promotions />,
  },
  {
    path: "changedInventories/:id",
    element: <ChangedInventories />,
  },
  {
    path: "productScreen/:id",
    element: <ProductScreen />,
  },
  {
    path: "productGrouping",
    element: <ProductGrouping />,
  },
  {
    path: "productFeature",
    element: <ProductFeature />,
  },
  {
    path: "demandProducts",
    element: <DemandProducts />,
  },
  {
    path: "sizeGuides",
    element: <SizeGuides />,
  },

  //order Routes
  {
    path: "orders",
    element: <OrderScreens />,
  },
  {
    path: "allOrders",
    element: <AllOrders />,
  },
  {
    path: "allSalesOrders",
    element: <AllArchOrders />,
  },
  {
    path: "ordersFromStatus/:status",
    element: <OrdersFromStatus />,
  },
  {
    path: "order_items_from_order/:orderID",
    element: <OrderItemsFromOrders />,
  },

  //Employee Routes
  {
    path: "loyaltyClubs",
    element: <LoyaltyClubs />,
  },
  {
    path: "customers",
    element: <Customers />,
  },
  {
    path: "addressesFromCustomer/:customerID",
    element: <AddressesFromCustomer />,
  },
  {
    path: "customerOrders/:customerID",
    element: <CustomerOrders />,
  },
  {
    path: "employees",
    element: <EmployeesScreen />,
  },
  {
    path: "inviteEmployees",
    element: <InviteEmployees />,
  },
  {
    path: "employee",
    element: <Employees />,
  },
  {
    path: "admins",
    element: <Admins />,
  },

  {
    path: "departments",
    element: <Departments />,
  },
  {
    path: "roles",
    element: <Roles />,
  },
  {
    path: "designations",
    element: <Designations />,
  },
  {
    path: "employeeRoles",
    element: <EmployeeRoles />,
  },
  {
    path: "employeeDesignations",
    element: <EmployeeDesignations />,
  },
  {
    path: "orderinvoice",
    element: <OrderInvoice />,
  },
  {
    path: "ordercompletemail",
    element: <OrderCompleteMail />,
  },
  {
    path: "restockemail",
    element: <RestockEmail />,
  },

  // reports routes
  {
    path: "salesReport",
    element: <SalesReport />,
  },
  {
    path: "reportProcess",
    element: <ReportProcess />,
  },
  {
    path: "orderSalesReport",
    element: <ArchSalesReport />,
  },
  {
    path: "collaboratorSales",
    element: <CollaboratorSales />,
  },
  {
    path: "TopSoldProducts",
    element: <TopSoldProducts />,
  },
  {
    path: "NewAndOldCustomerReports",
    element: <NewAndOldCustomerReports />,
  },
];

export default MainRoutes;

import React from 'react'
import NavCardList from '../../../../../Partials/Layouts/NavCardList/NavCardList';
import NavCard from '../../../../../Partials/Layouts/NavCardList/NavCard/NavCard';
import { RiGiftFill } from 'react-icons/ri';

function CatagoriesScreen() {
    return (
      <NavCardList numOfCards="four">
        <NavCard
          cardName={`Men Categories`}
          navCardLink={"/main/catagories/men" }
        >
          <RiGiftFill />
        </NavCard>
        <NavCard
          cardName={`Women Categories`}
          navCardLink={"/main/catagories/women" }
        >
          <RiGiftFill />
        </NavCard>
        <NavCard
          cardName={`All Categories`}
          navCardLink={"/main/catagories/all" }
        >
          <RiGiftFill />
        </NavCard>
      </NavCardList>
    );
  }
  

export default CatagoriesScreen
import { useState } from "react";
import useAxiosInstance from "../../../../../Utilities/Hooks/useAxiosInstance";
import Form from "../../../../Partials/Layouts/Forms/Form";
import ShortTextInput from "../../../../Partials/Layouts/Forms/FormInputs/ShortTextInput/ShortTextInput";
import FormSubmitButton from "../../../../Partials/Layouts/Forms/FormSubmitButton/FormSubmitButton";
import { CATAGORY_API } from "../../../../../Utilities/APIs/APIs";
import ImageInput from "../../../../Partials/Layouts/Forms/FormInputs/ImageInput/ImageInput";
import SwitchInput from "../../../../Partials/Layouts/Forms/FormInputs/SwitchInput/SwitchInput";
import slugify from "react-slugify";
import SelectInput from "../../../../Partials/Layouts/Forms/FormInputs/SelectInput/SelectInput";
import SelectOption from "../../../../Partials/Layouts/Forms/FormInputs/SelectInput/SelectOption/SelectOption";

function CreateCatagory({ triggerFetch, setShowCreateForm }) {
  const axiosInstance = useAxiosInstance();

  const [categoryName, setCategoryName] = useState("");
  const [categorySubtitle, setCategorySubtitle] = useState("");
  const [categoryDescription, setCategoryDescription] = useState("");
  const [categoryCode, setCategoryCode] = useState("");
  const [metaTitle, setMetaTitle] = useState("");
  const [metaDescription, setMetaDescription] = useState("");
  const [precedence, setPrecedence] = useState("");
  const [gender, setGender] = useState("");
  // const [categorySlug, setCategorySlug] = useState('')
  const [categoryImage, setCategoryImage] = useState("");
  const [isActive, setIsActive] = useState("");

  async function handleSubmit(e) {
    e.preventDefault();
    const itemData = new FormData();

    itemData.append("categoryName", categoryName);
    itemData.append("categorySubtitle", categorySubtitle);
    itemData.append("categoryDescription", categoryDescription);
    itemData.append("categoryCode", categoryCode);
    itemData.append("metaTitle", metaTitle);
    itemData.append("metaDescription", metaDescription);
    itemData.append("precedence", precedence);
    itemData.append("gender", gender);
    itemData.append("categorySlug", slugify(categoryName));
    itemData.append("categoryImage", categoryImage);
    itemData.append("isActive", isActive);

    const response = await axiosInstance.post(CATAGORY_API, itemData);
    if (response) {
      triggerFetch();
      setShowCreateForm(false);
    }
  }

  return (
    <div className="crate_form">
      <Form onSubmit={handleSubmit}>
        <div>
          <ShortTextInput
            label={`Category Name`}
            value={categoryName}
            placeholder={`Category Name`}
            setState={setCategoryName}
          />
          <SelectInput label={"Gender"} value={gender} setState={setGender}>
            <SelectOption optionValue="" optionText="Select Gender" />
            <SelectOption optionValue="men" optionText="Men" />
            <SelectOption optionValue="women" optionText="Women" />
            <SelectOption optionValue="unisex" optionText="Unisex" />
          </SelectInput>
          <ShortTextInput
            label={`Category Subtitle`}
            value={categorySubtitle}
            placeholder={`Category Subtitle`}
            setState={setCategorySubtitle}
          />
          <ShortTextInput
            label={`Category Description`}
            value={categoryDescription}
            placeholder={`Category Description`}
            setState={setCategoryDescription}
          />
          <ShortTextInput
            label={`Category Code`}
            value={categoryCode}
            placeholder={`Category Code`}
            setState={setCategoryCode}
          />
          <ShortTextInput
            label={`Category Meta Title`}
            value={metaTitle}
            placeholder={`Category Meta Title`}
            setState={setMetaTitle}
          />
          <ShortTextInput
            label={`Meta Description`}
            value={metaDescription}
            placeholder={`Meta Description`}
            setState={setMetaDescription}
          />
          <ShortTextInput
            label={`Precedence`}
            value={precedence}
            placeholder={`Precedence`}
            setState={setPrecedence}
          />
          <ShortTextInput
            label={`Category Slug`}
            value={slugify(categoryName)}
            placeholder={`Enter Category Slug`}
            disabled
          />
          <ImageInput
            fieldId="1"
            state={categoryImage}
            setState={setCategoryImage}
            allowCreateImage
          >
            Upload Category Image
          </ImageInput>
          <SwitchInput
            label={`Status`}
            toggleSwitch={() => setIsActive((prevState) => !prevState)}
            checked={isActive}
          />
        </div>
        <FormSubmitButton text="Submit" />
      </Form>
    </div>
  );
}

export default CreateCatagory;

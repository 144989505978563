import React, { useEffect, useState } from "react";
import Form from "../../../../../Partials/Layouts/Forms/Form";
import FormSubmitButton from "../../../../../Partials/Layouts/Forms/FormSubmitButton/FormSubmitButton";
import useAxiosInstance from "../../../../../../Utilities/Hooks/useAxiosInstance";
import {
  PRODUCTS_API,
  PRODUCT_COLLECTIONS_API,
} from "../../../../../../Utilities/APIs/APIs";
import SelectInput from "../../../../../Partials/Layouts/Forms/FormInputs/SelectInput/SelectInput";
import SelectOption from "../../../../../Partials/Layouts/Forms/FormInputs/SelectInput/SelectOption/SelectOption";
import SearchSelectField from "../../../../../Partials/Layouts/Forms/FormInputs/searchSelectField/SearchSelectField";

function UpdateProductCollectionProduct({
  id,
  targetID,
  triggerFetch,
  setShowUpdateForm,
  setShowCreateForm,
}) {
  const axiosInstance = useAxiosInstance();
  console.log("targetID", targetID);

  const [productCollection, setProductCollection] = useState("");
  const [product, setProduct] = useState("");

  const [productCollections, setProductCollections] = useState(null);
  const [products, setProducts] = useState(null);
  const [selectValue, setSelectValue] = useState(null);
  console.log("selectValue5555556", selectValue);
  useEffect(() => {
    async function fetchAndSetSingleProductCollections() {
      const { data } = await axiosInstance.get(
        PRODUCT_COLLECTIONS_API +
          "getSingleProductCollectionProduct/" +
          targetID
      );
      setProductCollection(data);
    }
    fetchAndSetSingleProductCollections();
  }, [targetID, axiosInstance]);

  useEffect(() => {
    async function fetchAndSetPairedProducts() {
      const { data } = await axiosInstance.get(PRODUCTS_API);

      console.log("555555555", data);
      setProducts(data.products);
    }
    fetchAndSetPairedProducts();
  }, [axiosInstance]);

  useEffect(() => {
    async function fetchAndSetProductCollections() {
      const { data } = await axiosInstance.get(
        PRODUCT_COLLECTIONS_API + "getAllProductCollectionProducts"
      );
      setProductCollections(data);
    }
    fetchAndSetProductCollections();
  }, [axiosInstance]);

  useEffect(() => {
    async function fetchAndSetPairedProducts() {
      const { data } = await axiosInstance.get(PRODUCTS_API);

      console.log("data12258545", data);

      const options = data?.products?.map((product) => {
        return {
          value: product?._id,
          label: product?.name,
        };
      });
      console.log("options", options);
      setProducts(options);
    }
    fetchAndSetPairedProducts();
  }, [axiosInstance]);

  async function handleSubmit(e) {
    e.preventDefault();
    const itemData = new FormData();

    itemData.append("productCollection", id);
    itemData.append("product", selectValue?.value);
    const response = await axiosInstance.patch(
      PRODUCT_COLLECTIONS_API + "updateFeaturedCollection/" + targetID,
      itemData
    );
    if (response) {
      triggerFetch();
      setShowUpdateForm(false);
    }
  }

  return (
    <div className="crate_form">
      <Form onSubmit={handleSubmit}>
        <SearchSelectField
          options={products}
          value={selectValue}
          setState={setSelectValue}
        />
        {/* <div>
          <SelectInput label={"Product"} value={product} setState={setProduct}>
            <SelectOption optionValue="" optionText="Select Product" />
            {products?.map((product) => (
              <SelectOption
                optionValue={product._id}
                key={product._id}
                optionText={product?.name}
              />
            ))}
          </SelectInput>
        </div> */}
        <FormSubmitButton text="Add Product Collection Product" />
      </Form>
    </div>
  );
}

export default UpdateProductCollectionProduct;

import JSZip from 'jszip';
import { RiFileZipLine } from 'react-icons/ri';
import { PRODUCT_IMAGES_API } from '../../../../../Utilities/APIs/APIs';
import useAxiosInstance from '../../../../../Utilities/Hooks/useAxiosInstance';
import CRUDButton from '../../../../Partials/Layouts/CRUDs/CRUDButtons/CRUDButton';

const ProductZipImages = ({ product }) => {
	const axiosInstance = useAxiosInstance();

	const downloadZip = (file) => {
		const a = document.createElement('a');
		a.download = `${product.slug}.zip`;
		const url = URL.createObjectURL(file);
		a.href = url;
		a.style.display = 'none';
		document.body.appendChild(a);
		a.click();
		a.remove();
		URL.revokeObjectURL(url);
	};

	const downloadImagesAsZip = async (allImgs) => {
		try {
			const imgUrls = allImgs.map((elm) => {
				const split_url_type = elm.split('.');
				const img_type = split_url_type[split_url_type.length - 1];
				const without_type = elm.split(`.${img_type}`)[0];
				const split_url_name = without_type.split(`/`);
				const img_name = split_url_name[split_url_name.length - 1];
				return {
					src: elm,
					type: img_type,
					name: img_name,
				};
			});
			const promises = imgUrls.map(async (elm) => {
				const res = await fetch(elm.src);
				const blob = res.blob();
				return blob;
			});
			const res = await Promise.all(promises);
			const zip = new JSZip();
			res.forEach((blob, index) => {
				zip.file(`${imgUrls[index].name}.${imgUrls[index].type}`, blob);
			});
			const zipFile = await zip.generateAsync({ type: 'blob' });
			downloadZip(zipFile);
		} catch (error) {
			console.log('error', error.message);
		}
	};

	const getProductImages = async (id) => {
		try {
			const { data } = await axiosInstance.get(
				`${PRODUCT_IMAGES_API}getImagesOfOneProduct/${id}`
			);
			const images = data.map(
				(image) => process.env.REACT_APP_SPACES_URL + image.image
			);
			if (images?.length > 0) {
				await downloadImagesAsZip(images);
			} else {
				alert('No images found');
			}
		} catch (error) {}
	};
	return (
		<CRUDButton handleClick={() => getProductImages(product?._id)}>
			<RiFileZipLine />
		</CRUDButton>
	);
};

export default ProductZipImages;

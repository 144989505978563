import { useState } from "react";
import useAxiosInstance from "../../../../Utilities/Hooks/useAxiosInstance";
import { useParams } from "react-router-dom";
import { useEffect } from "react";
import {
  ORDERS_API,
  PRODUCTSIZE_API,
  PRODUCTS_API
} from "../../../../Utilities/APIs/APIs";
import CRUDTable from "../../../Partials/Layouts/CRUDs/CRUDTable/CRUDTable";
import CRUDTableHeader from "../../../Partials/Layouts/CRUDs/CRUDTable/CRUDTableHeader/CRUDTableHeader";
import CRUDth from "../../../Partials/Layouts/CRUDs/CRUDTable/CRUDTableHeader/CRUDth/CRUDth";
import CRUDTableRow from "../../../Partials/Layouts/CRUDs/CRUDTable/CRUDTableRow/CRUDTableRow";
import ShortTextCell from "../../../Partials/Layouts/CRUDs/CRUDTable/CRUDTableCells/ShortTextCell/ShortTextCell";

import { useRef } from "react";
import CRUDButton from "../../../Partials/Layouts/CRUDs/CRUDButtons/CRUDButton";
import CancelOrderItem from "./CancelOrderItem";
import { RiCloseCircleLine } from "react-icons/ri";
import Modal from "../../../Partials/Elements/Modal/Modal";

function OrderItemsFromOrders() {
  const axiosInstance = useAxiosInstance();
  const [orderItems, setOrderItems] = useState(null);
  const [order, setOrder] = useState(null);
  const [showCancelOrderItem, setShowCancelOrderItem] = useState(false);



  // const [showModal, setShowModal] = useState(false)
  const [targetID, setTargetID] = useState(null)
  const [toggleFetch, setToggleFetch] = useState(false)

  // const [showViewSection, setShowViewSection] = useState(false)
  const screenHeaerRef = useRef();
  const [screenHeaderHeight, setScreenHeaderHeight] = useState(0);

  useEffect(() => {
    let screenHeaderHeight = screenHeaerRef.current.clientHeight;
    setScreenHeaderHeight(screenHeaderHeight);
  }, [screenHeaerRef]);

  const { orderID } = useParams();

  useEffect(() => {
    async function fetchAndSetOrderItems() {
      const { data } = await axiosInstance.get(
        ORDERS_API + "orderItemsFromOrder/" + orderID
      );
      setOrderItems(data.orderItems);
      setOrder(data.order);
    }
    fetchAndSetOrderItems();

  }, [axiosInstance, orderID, toggleFetch]);

  // function handleClose() {
  //     setShowModal(false);
  //     setShowViewSection(false);
  // }
  function triggerFetch() {
    setToggleFetch(prevState => !prevState)
  }

  return (
    <>
      <div className="screen_header" ref={screenHeaerRef}>
        <h1 className="screen_heading">
          Products ({orderItems?.length} in total)
        </h1>
      </div>

      <CRUDTable>
        <CRUDTableHeader>
          <CRUDth th="Item" />
          <CRUDth th="Size" />
          <CRUDth th="Quantity" />
          <CRUDth th="Unit Price" />
          <CRUDth th="Total" />
          <CRUDth th="Actions" />
        </CRUDTableHeader>
        <tbody style={{ overflowY: "initial" }}>
          {orderItems?.map((orderItem) => (
            <CRUDTableRow key={orderItem._id}>
              <ShortTextCell text={orderItem?.item?.name} />
              <ShortTextCell text={orderItem?.size?.name} />
              <ShortTextCell text={orderItem?.quantity} />
              <ShortTextCell text={orderItem?.unitPrice} />
              <ShortTextCell text={orderItem?.totalPrice} />

              {/* {order?.orderStatus === "pending" && ( */}
                <td>
                  <div className="action_buttons_wrapper">
                    <div className="action_buttons">
                      <CRUDButton
                        handleClick={() => {
                          setShowCancelOrderItem(true);
                          setTargetID(orderItem._id);
                        }}
                      >
                        <RiCloseCircleLine />
                      </CRUDButton>
                    </div>
                  </div>
                </td>
              {/* )} */}
            </CRUDTableRow>
          ))}
        </tbody>
      </CRUDTable>

      {showCancelOrderItem && (
        <Modal
          extraClass={"small"}
          modalHeading={`Cancel Order Item`}
          setShowModalContent={setShowCancelOrderItem}
        >
          <CancelOrderItem
            targetID={targetID}
            orderID={orderID}
            setShowCancelOrderItem={setShowCancelOrderItem}
            triggerFetch={triggerFetch}
          />
        </Modal>
      )}
    </>
  );
}

export default OrderItemsFromOrders;

import { useEffect, useState } from "react"
import useAxiosInstance from "../../../../../../Utilities/Hooks/useAxiosInstance"
import { CUSTOMERS } from "../../../../../../Utilities/APIs/APIs"

function ViewCustomer({ targetID }) {

    const axiosInstance = useAxiosInstance()

    const [customerInfo, setCustomerInfo] = useState(null)

    useEffect(() => {
        async function fetchAndSetSingleCustomer() {
            const { data } = await axiosInstance.get(CUSTOMERS + 'getSingleCustomer/' + targetID)
            setCustomerInfo(data)
        }
        fetchAndSetSingleCustomer()

    }, [targetID, axiosInstance])

    return (
        <div className="crud_view_content">
            {
                customerInfo &&
                <>
                    <h1>Name</h1>
                    <p>{customerInfo.name}</p>
                    
                    <h1>Email</h1>
                    <p>{customerInfo.email}</p>

                    <h1>Phone</h1>
                    <p>{customerInfo.mobile}</p>

                </>
            }

        </div>
    )
}

export default ViewCustomer
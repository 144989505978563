import React, { useEffect, useState } from 'react'
import useAxiosInstance from '../../../../../../Utilities/Hooks/useAxiosInstance'
import { SIZEGUIRDES } from '../../../../../../Utilities/APIs/APIs'

function ViewSizeGuides({targetID}) {
    

    const axiosInstance = useAxiosInstance()
    const [sizeGuidesInfo, setSizeGuidesInfo] = useState(null)

    useEffect(() => {
        async function fetchAndSizeGuides() {
          const { data } = await axiosInstance.get(SIZEGUIRDES + 'getSingleSizeGuide/' + targetID)
          setSizeGuidesInfo(data)
        }
        fetchAndSizeGuides()

      }, [targetID, axiosInstance])

    return (
        <div className="crud_view_content">
            {
                sizeGuidesInfo &&
                <>
                    <h1>Title</h1>
                    <p>{sizeGuidesInfo.name}</p>

                    <h1>Hex Value</h1>
                    <div dangerouslySetInnerHTML={{ __html: sizeGuidesInfo?.description }} />

                    <h1>Status</h1>
                    <p>{sizeGuidesInfo.isActive === true ? 'True' : 'False'}</p>
                </>
            }
            
        </div>
    )
}

export default ViewSizeGuides
import { useEffect, useState } from "react"
import useAxiosInstance from "../../../../../Utilities/Hooks/useAxiosInstance"
import Form from "../../../../Partials/Layouts/Forms/Form"
import FormSubmitButton from "../../../../Partials/Layouts/Forms/FormSubmitButton/FormSubmitButton"
import { PRODUCTS_API, CARE_API, PRODUCT_CARE_API } from "../../../../../Utilities/APIs/APIs"
import SelectInput from "../../../../Partials/Layouts/Forms/FormInputs/SelectInput/SelectInput"
import SelectOption from "../../../../Partials/Layouts/Forms/FormInputs/SelectInput/SelectOption/SelectOption"

function UpdateProductCare({ targetID, triggerFetch, setShowUpdateForm }) {

    const axiosInstance = useAxiosInstance()

    const [product, setProduct] = useState('')
    const [care, setCare] = useState('')

    const [cares, setCares] = useState(null)

    useEffect(() => {
        async function fetchAndSetSingleProductCare() {
            const { data } = await axiosInstance.get(PRODUCT_CARE_API + 'getSingleProductCare/' + targetID)
            setProduct(data.product._id)
            setCare(data.care._id)
        }
        fetchAndSetSingleProductCare()

    }, [targetID, axiosInstance])

    useEffect(() => {
        async function fetchAndSetCares() {
            const { data } = await axiosInstance.get(CARE_API)
            setCares(data)
        }
        fetchAndSetCares()

    }, [axiosInstance])

    async function handleSubmit(e) {

        e.preventDefault()
        const itemData = new FormData()

        itemData.append('product', product)
        itemData.append('care', care)

        const response = await axiosInstance.patch(PRODUCT_CARE_API + targetID, itemData)
        if (response) {
            triggerFetch()
            setShowUpdateForm(false)
        }
    }

    return (
        <div className='crate_form'>
            <Form onSubmit={handleSubmit}>
                <div>
                    <SelectInput
                        label={'Care'}
                        value={care}
                        setState={setCare}
                    >
                        <SelectOption optionValue='' optionText="Select Care" />
                        {cares?.map((care => (
                            <SelectOption optionValue={care._id} key={care._id}
                                optionText={care?.name} />
                        )))}
                    </SelectInput>
                </div>
                <FormSubmitButton text="Update Product Care" />
            </Form>
        </div>
    )
}

export default UpdateProductCare
import { useEffect, useRef, useState } from "react";
// zip file
import JSZip from "jszip";
import { saveAs } from "file-saver";

import CreateButton from "../../../../Partials/Layouts/CRUDs/CRUDHeader/CreateButton/CreateButton";
import CRUDTable from "../../../../Partials/Layouts/CRUDs/CRUDTable/CRUDTable";
import CRUDTableHeader from "../../../../Partials/Layouts/CRUDs/CRUDTable/CRUDTableHeader/CRUDTableHeader";
import CRUDth from "../../../../Partials/Layouts/CRUDs/CRUDTable/CRUDTableHeader/CRUDth/CRUDth";
import ViewButton from "../../../../Partials/Layouts/CRUDs/CRUDButtons/ViewButton/ViewButton";
import EditButton from "../../../../Partials/Layouts/CRUDs/CRUDButtons/EditButton/EditButton";
import DeleteButton from "../../../../Partials/Layouts/CRUDs/CRUDButtons/DeleteButton/DeleteButton";
import ShortTextCell from "../../../../Partials/Layouts/CRUDs/CRUDTable/CRUDTableCells/ShortTextCell/ShortTextCell";
import CRUDTableRow from "../../../../Partials/Layouts/CRUDs/CRUDTable/CRUDTableRow/CRUDTableRow";
import Modal from "../../../../Partials/Elements/Modal/Modal";
import useAxiosInstance from "../../../../../Utilities/Hooks/useAxiosInstance";
import DeleteItem from "../../../../Partials/Layouts/DeleteItem/DeleteItem";
import {
  PRODUCTS_API,
  PRODUCT_IMAGES_API,
} from "../../../../../Utilities/APIs/APIs";
import ViewProduct from "./ViewProduct";
import CreateProduct from "./CreateProduct";
import UpdateProduct from "./UpdateProduct";
import CRUDButton from "../../../../Partials/Layouts/CRUDs/CRUDButtons/CRUDButton";
import { useNavigate } from "react-router-dom";
import { RiFileZipLine, RiImage2Line, RiSearchLine } from "react-icons/ri";
import Pagination from "../../../../Partials/Elements/pagination/Pagination";
import axios from "axios";
import Image from "../../../../Partials/Elements/Image/Image";
import ProductImages from "../ProductImages/ProductImages";
import ProductZipImages from "./ProductZipImages";

function Products() {
  const [products, setProducts] = useState([]);
  const screenHeaerRef = useRef();
  const [screenHeaderHeight, setScreenHeaderHeight] = useState(0);

  useEffect(() => {
    let screenHeaderHeight = screenHeaerRef.current.clientHeight;
    setScreenHeaderHeight(screenHeaderHeight);
  }, [screenHeaerRef]);

  const [showCreateForm, setShowCreateForm] = useState(false);
  const [showViewSection, setShowViewSection] = useState(false);
  const [showUpdateForm, setShowUpdateForm] = useState(false);
  const [showDeleteSection, setShowDeleteSection] = useState(false);
  const [toggleFetch, setToggleFetch] = useState(false);
  const [search, setSearch] = useState("");
  const [searchInput, setSearchInput] = useState("");
  const [limit, setLimit] = useState(40);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalItems, setTotalItems] = useState(0);

  const [productImages, setProductImages] = useState(null);
  console.log("🚀 ~ Products ~ productImages:", productImages);

  // Pagination states

  const [targetID, setTargetID] = useState(null);
  const axiosInstance = useAxiosInstance();
  const navigate = useNavigate();

  useEffect(() => {
    async function fetchAndSetProducts() {
      // const { data } = await axiosInstance.get(PRODUCTS_API + '?search=' +search);
      const res = await axiosInstance.get(
        `${PRODUCTS_API}?search=${search}&page=${currentPage}&limit=${limit}`
      );

      console.log("res", res);
      setProducts(res?.data?.products);
      setTotalItems(res?.data?.totalItems);
    }
    fetchAndSetProducts();
  }, [toggleFetch, axiosInstance, search, currentPage]);



  const handleSearchInput = (value) => {
    const timeoutId = setTimeout(() => {
      setSearch(value);
    }, 500);

    return () => {
      clearTimeout(timeoutId);
    };
  };

  function triggerFetch() {
    setToggleFetch((prevState) => !prevState);
  }

  useEffect(() => {
    async function zipAndDownloadImages() {
      if (!productImages || productImages.length === 0) {
        console.log("No images to zip and download.");
        return;
      }

      const zip = new JSZip();

      try {
        const addImagePromises = productImages.map(async (image, index) => {
          const imageUrl = `${process.env.REACT_APP_SPACES_URL}${image?.image}`;
          console.log(`Fetching image from URL: ${imageUrl}`);

          const response = await fetch(imageUrl, {
            headers: {
              'Content-Type': 'application/octet-stream',
            },
          });

          if (!response.ok) {
            throw new Error(`Failed to fetch image at ${imageUrl}`);
          }

          const imageData = await response.blob();
          const fileExtension = imageUrl.split('.').pop(); // Get the file extension from the URL
          zip.file(`image${index + 1}.${fileExtension}`, imageData);
        });

        await Promise.all(addImagePromises);
        const content = await zip.generateAsync({ type: 'blob' });
        saveAs(content, 'images.zip');
      } catch (error) {
        console.error('Error fetching or zipping images:', error);
      }
    }

    zipAndDownloadImages();
  }, [productImages]);

  
  

  // zip file


  // useEffect(() => {
  //   async function zipAndDownloadImages() {
  //     if (!productImages || productImages.length === 0) {
  //       console.log("No images to zip and download.");
  //       return;
  //     }

  //     const zip = new JSZip();
  //     const proxyUrl = 'https://cors-anywhere.herokuapp.com/';

  //     try {
  //       const addImagePromises = productImages.map(async (image, index) => {
  //         const imageUrl = `${encodeURIComponent(`${process.env.REACT_APP_SPACES_URL}${image?.image}`)}`;
  //         console.log(`Fetching image from URL: ${imageUrl}`);
          
  //         const response = await fetch(imageUrl, {
  //           headers: {
  //             'Content-Type': 'application/octet-stream'
  //           }
  //         });
          
  //         if (!response.ok) {
  //           throw new Error(`Failed to fetch image at ${imageUrl}`);
  //         }

  //         const imageData = await response.blob();
  //         zip.file(`image${index + 1}.${response.type}`, imageData);
  //       });

  //       await Promise.all(addImagePromises);
  //       const content = await zip.generateAsync({ type: "blob" });
  //       saveAs(content, "images.zip");
  //     } catch (error) {
  //       console.error("Error fetching or zipping images:", error);
  //     }
  //   }
  //   zipAndDownloadImages();
  // }, [productImages]);




  return (
    <>
      <div className="screen_header" ref={screenHeaerRef}>
        <h1 className="screen_heading">Products ({totalItems} in total)</h1>
        <div>
          <input
            value={searchInput}
            onChange={(e) => {
              handleSearchInput(e.target.value);
              setSearchInput(e.target.value);
            }}
            className="input_field"
            placeholder="Search"
            style={{ width: "400px" }}
          />
          <RiSearchLine
            style={{
              width: "30px",
              height: "30px",
              marginLeft: "-50px",
              color: "var(--white-2)",
            }}
          />
        </div>

        <CreateButton
          screenTopicSingular={"Product"}
          setShowCreateForm={setShowCreateForm}
        />
      </div>
      <CRUDTable screenHeaderHeight={screenHeaderHeight}>
        <CRUDTableHeader>
          <CRUDth th="Sku ID" />
          <CRUDth th="Name" />
          <CRUDth th="Image" />
          <CRUDth th="Action" />
        </CRUDTableHeader>
        <tbody>
          {products?.length > 0 &&
            products?.map((product) => (
              <CRUDTableRow key={product._id}>
                <ShortTextCell text={product?.squId} />
                <ShortTextCell text={product.name} />

                <td>
                  <div
                    style={{
                      maxWidth: "100px",
                      maxHeight: "100px",
                      borderRadius: "50%",
                      overflow: "hidden",
                    }}
                  >
                    <Image
                      imgLink={product?.cardFrontImage}
                      imgAlt={"Card Front Image"}
                      style={{
                        width: "100%",
                        height: "100%",
                        objectFit: "contain",
                      }}
                    />
                    {/* <img
                      src={product?.cardFrontImage}
                      style={{width: "100%", height: "100", objectFit: "cover"}}
                      alt="Image"
                    /> */}
                  </div>
                </td>
                <td>
                  <div className="action_buttons_wrapper">
                    <div className="action_buttons">
                      <ViewButton
                        setShowViewSection={setShowViewSection}
                        targetID={product?._id}
                        setTargetID={setTargetID}
                        tooltipContent={"View Product Details"}
                        tooltipName={"view"}
                      />

                      <EditButton
                        setShowUpdateForm={setShowUpdateForm}
                        targetID={product?._id}
                        setTargetID={setTargetID}
                        tooltipContent={"Update Product Details"}
                        tooltipName={"edit"}
                      />

                      <DeleteButton
                        setShowDeleteSection={setShowDeleteSection}
                        targetID={product._id}
                        setTargetID={setTargetID}
                        tooltipContent={"Delete Product"}
                        tooltipName={"delete"}
                      />
                      <CRUDButton
                        handleClick={() =>
                          navigate("/main/productsScreen/" + product._id)
                        }
                        tooltipContent={"Product Sreen"}
                        tooltipName={"productSreen"}
                      >
                        <RiImage2Line />
                      </CRUDButton>
                      <ProductZipImages product={product}/>
                    </div>
                  </div>
                </td>
              </CRUDTableRow>
            ))}
        </tbody>
        <Pagination
          items={products}
          totalItems={totalItems}
          limit={limit}
          currentPage={currentPage}
          setCurrentPage={setCurrentPage}
        />
      </CRUDTable>
      {showCreateForm && (
        <Modal
          extraClass={"small"}
          modalHeading={`Add Product`}
          setShowModalContent={setShowCreateForm}
        >
          <CreateProduct
            triggerFetch={triggerFetch}
            setShowCreateForm={setShowCreateForm}
          />
        </Modal>
      )}
      {showUpdateForm && (
        <Modal
          extraClass={"small"}
          modalHeading={`Update Product`}
          setShowModalContent={setShowUpdateForm}
        >
          <UpdateProduct
            triggerFetch={triggerFetch}
            targetID={targetID}
            setShowUpdateForm={setShowUpdateForm}
          />
        </Modal>
      )}
      {showViewSection && (
        <Modal
          extraClass={"small"}
          modalHeading={`View Product`}
          setShowModalContent={setShowViewSection}
        >
          <ViewProduct targetID={targetID} />
        </Modal>
      )}
      {showDeleteSection && (
        <Modal
          extraClass={"small"}
          modalHeading={`Delete Product`}
          setShowModalContent={setShowDeleteSection}
        >
          <DeleteItem
            api={PRODUCTS_API}
            triggerFetch={triggerFetch}
            targetID={targetID}
            setShowDeleteItem={setShowDeleteSection}
          />
        </Modal>
      )}
    </>
  );
}

export default Products;

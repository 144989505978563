import React, { useState } from "react";
import useAxiosInstance from "../../../../../Utilities/Hooks/useAxiosInstance";
import { PROMOTIONS_API } from "../../../../../Utilities/APIs/APIs";
import Form from "../../../../Partials/Layouts/Forms/Form";
import ShortTextInput from "../../../../Partials/Layouts/Forms/FormInputs/ShortTextInput/ShortTextInput";
import FormSubmitButton from "../../../../Partials/Layouts/Forms/FormSubmitButton/FormSubmitButton";
import DateInput from "../../../../Partials/Layouts/Forms/FormInputs/DateInput/DateInput";
import SelectInput from "../../../../Partials/Layouts/Forms/FormInputs/SelectInput/SelectInput";
import SelectOption from "../../../../Partials/Layouts/Forms/FormInputs/SelectInput/SelectOption/SelectOption";

function CreatePromotion({ triggerFetch, setShowCreateForm }) {
  const axiosInstance = useAxiosInstance();

  const [promoCode, setPromoCode] = useState("");
  const [maxlimit, setMaxlimit] = useState(0);
  const [discountType, setDiscountType] = useState("");
  const [discountAmount, setDiscountAmount] = useState(0);
  const [promoType, setPromoType] = useState("");
  const [validStartDate, setValidStartDate] = useState("");
  const [validEndDate, setValidEndDate] = useState("");

  async function handleSubmit(e) {
    e.preventDefault();
    const itemData = {promoCode,maxlimit, discountType, discountAmount,  promoType,validStartDate,validEndDate };

    const response = await axiosInstance.post(PROMOTIONS_API, itemData);
    if (response) {
      triggerFetch();
      setShowCreateForm(false);
    }
  }

  return (
    <div className="crate_form">
      <Form onSubmit={handleSubmit}>
        <div>
          <ShortTextInput
            label={`Promo Code`}
            value={promoCode}
            placeholder={`Enter Promo Code`}
            setState={setPromoCode}
          />
          <ShortTextInput
            label={`Maxlimit`}
            value={maxlimit}
            placeholder={`Enter Maxlimit`}
            setState={setMaxlimit}
          />
          
           <SelectInput
            label={`Discount Type`}
            value={discountType}
            setState={setDiscountType}
          >
            <SelectOption optionValue="" optionText="Select Discount Type" />
            <SelectOption optionValue="percentage" optionText="Percentage" />
            <SelectOption optionValue="amount" optionText="Amount" />
          </SelectInput>
          <ShortTextInput
            label={`Discount Amount`}
            value={discountAmount}
            placeholder={`Enter DiscountAmount`}
            setState={setDiscountAmount}
          />
           <SelectInput
            label={"Promo Type"}
            value={promoType}
            setState={setPromoType}
          >
            <SelectOption optionValue="" optionText="Select Promo type" />
            <SelectOption optionValue="unlimited" optionText="unlimited" />
            <SelectOption optionValue="oneTime" optionText="oneTime" />
          </SelectInput>
          <DateInput
            label={`ValidStart Date`}
            value={validStartDate}
            placeholder={`Enter ValidStart Date`}
            setState={setValidStartDate}
          />
          <DateInput
            label={`Valid EndDate`}
            value={validEndDate}
            placeholder={`Enter Valid EndDate`}
            setState={setValidEndDate}
          />
        </div>
        <FormSubmitButton text="Submit" />
      </Form>
    </div>
  );
}
export default CreatePromotion;

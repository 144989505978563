import { useEffect, useRef, useState } from 'react'
import CreateButton from '../../../../Partials/Layouts/CRUDs/CRUDHeader/CreateButton/CreateButton';
import CRUDTable from '../../../../Partials/Layouts/CRUDs/CRUDTable/CRUDTable';
import CRUDTableHeader from '../../../../Partials/Layouts/CRUDs/CRUDTable/CRUDTableHeader/CRUDTableHeader';
import CRUDth from '../../../../Partials/Layouts/CRUDs/CRUDTable/CRUDTableHeader/CRUDth/CRUDth';
import ViewButton from '../../../../Partials/Layouts/CRUDs/CRUDButtons/ViewButton/ViewButton';
import EditButton from '../../../../Partials/Layouts/CRUDs/CRUDButtons/EditButton/EditButton';
import DeleteButton from '../../../../Partials/Layouts/CRUDs/CRUDButtons/DeleteButton/DeleteButton';
import ShortTextCell from '../../../../Partials/Layouts/CRUDs/CRUDTable/CRUDTableCells/ShortTextCell/ShortTextCell';
import CRUDTableRow from '../../../../Partials/Layouts/CRUDs/CRUDTable/CRUDTableRow/CRUDTableRow';
import Modal from '../../../../Partials/Elements/Modal/Modal';
import CreateArea from './CreateArea';
import ViewArea from './ViewArea';
import useAxiosInstance from '../../../../../Utilities/Hooks/useAxiosInstance';
import DeleteItem from '../../../../Partials/Layouts/DeleteItem/DeleteItem';
import UpdateArea from './UpdateArea';
import { AREA_API } from '../../../../../Utilities/APIs/APIs';

import { useParams } from 'react-router-dom';

function Areas() {

  const axiosInstance = useAxiosInstance()

  const [areas, setAreas] = useState(null)
  const screenHeaerRef = useRef()
  const [screenHeaderHeight, setScreenHeaderHeight] = useState(0);

  useEffect(() => {
    let screenHeaderHeight = screenHeaerRef.current.clientHeight;
    setScreenHeaderHeight(screenHeaderHeight)
  }, [screenHeaerRef]);

  const [showCreateForm, setShowCreateForm] = useState(false);
  const [showViewSection, setShowViewSection] = useState(false);
  const [showUpdateForm, setShowUpdateForm] = useState(false);
  const [showDeleteSection, setShowDeleteSection] = useState(false)
  const [toggleFetch, setToggleFetch] = useState(false)

  const [targetID, setTargetID] = useState(null)
  const {parentID} =useParams()

  useEffect(() => {

  async function fetchAndSetAreas() {
      const { data } = await axiosInstance.get(AREA_API + "getAreasFromDistrict/" + parentID)
      setAreas(data)
    }
    fetchAndSetAreas()

  }, [toggleFetch, parentID, axiosInstance])

  function triggerFetch() {
    setToggleFetch(prevState => !prevState)
  }
  return (
    <>
      <div className='screen_header' ref={screenHeaerRef}>
        <h1 className='screen_heading'>Area ({areas?.length}  in total)</h1>
        <CreateButton
          screenTopicSingular={'Area'}
          setShowCreateForm={setShowCreateForm}
        />
      </div>
      <CRUDTable screenHeaderHeight={screenHeaderHeight}>
        <CRUDTableHeader>
          <CRUDth th='Area' />
          <CRUDth th='Actions' />
        </CRUDTableHeader>
        <tbody>
          {
            areas?.map(area =>
              <CRUDTableRow key={area._id}>
                <ShortTextCell text={area.name} />
                <td>
                  <div className="action_buttons_wrapper">
                    <div className="action_buttons">
                      <ViewButton
                        setShowViewSection={setShowViewSection}
                        targetID={area._id}
                        setTargetID={setTargetID}
                        tooltipName={"View"} 
                        tooltipContent={"View"}
                      />

                      <EditButton
                        setShowUpdateForm={setShowUpdateForm}
                        targetID={area._id}
                        setTargetID={setTargetID}
                        tooltipName={"Edit"} 
                        tooltipContent={"Edit"}
                      />

                      <DeleteButton
                        setShowDeleteSection={setShowDeleteSection}
                        targetID={area._id}
                        setTargetID={setTargetID}
                        tooltipName={"Delelte"} 
                        tooltipContent={"Delelte"}
                      />
                    </div>
                  </div>
                </td>
              </CRUDTableRow>

            )
          }
        </tbody>
      </CRUDTable>
      {
        showCreateForm &&
        <Modal
          extraClass={'small'}
          modalHeading={`Create Area`}
          setShowModalContent={setShowCreateForm}
        >
          <CreateArea
            triggerFetch={triggerFetch}
            setShowCreateForm={setShowCreateForm}
            district={parentID}
          />
        </Modal>
      }
      {
        showUpdateForm &&
        <Modal
          extraClass={'small'}
          modalHeading={`Update Area`}
          setShowModalContent={setShowUpdateForm}
        >
          <UpdateArea
            triggerFetch={triggerFetch}
            targetID={targetID}
            setShowUpdateForm={setShowUpdateForm}
          />
        </Modal>
      }
      {
        showViewSection &&
        <Modal
          extraClass={'small'}
          modalHeading={`View Area`}
          setShowModalContent={setShowViewSection}
        >
          <ViewArea
            targetID={targetID}
          />
        </Modal>
      }
      {
        showDeleteSection &&
        <Modal
          extraClass={'small'}
          modalHeading={`Delete Area`}
          setShowModalContent={setShowDeleteSection}
        >
          <DeleteItem
            api={AREA_API}
            triggerFetch={triggerFetch}
            targetID={targetID}
            setShowDeleteItem={setShowDeleteSection}
          />
        </Modal>
      }
    </>
  )
}

export default Areas
import React, { useEffect, useRef, useState } from 'react'
import useAxiosInstance from '../../../../../../../Utilities/Hooks/useAxiosInstance';
import { EMPLOYEE_API } from '../../../../../../../Utilities/APIs/APIs';
import CRUDTable from '../../../../../../Partials/Layouts/CRUDs/CRUDTable/CRUDTable';
import CRUDTableHeader from '../../../../../../Partials/Layouts/CRUDs/CRUDTable/CRUDTableHeader/CRUDTableHeader';
import CRUDth from '../../../../../../Partials/Layouts/CRUDs/CRUDTable/CRUDTableHeader/CRUDth/CRUDth';
import CRUDTableRow from '../../../../../../Partials/Layouts/CRUDs/CRUDTable/CRUDTableRow/CRUDTableRow';
import ShortTextCell from './../../../../../../Partials/Layouts/CRUDs/CRUDTable/CRUDTableCells/ShortTextCell/ShortTextCell';
import ViewButton from '../../../../../../Partials/Layouts/CRUDs/CRUDButtons/ViewButton/ViewButton';
import Modal from '../../../../../../Partials/Elements/Modal/Modal';
import CRUDButton from '../../../../../../Partials/Layouts/CRUDs/CRUDButtons/CRUDButton';
import { RiUser2Line } from 'react-icons/ri';
import ChangeLevel from './ChangeLevel';

function Employees() {

    const [employees, setEmployees] = useState(null)
    const screenHeaerRef = useRef()
    const [screenHeaderHeight, setScreenHeaderHeight] = useState(0);
  
    useEffect(() => {
      let screenHeaderHeight = screenHeaerRef.current.clientHeight;
      setScreenHeaderHeight(screenHeaderHeight)
    }, [screenHeaerRef]);
  
    const [showViewSection, setShowViewSection] = useState(false);
    const [showLevelSection, setShowLevelSection] = useState(false);
    const [toggleFetch, setToggleFetch] = useState(false)
  
    const [targetID, setTargetID] = useState(null)
    const axiosInstance = useAxiosInstance()
  
    useEffect(() => {
  
      async function fetchAndSetEmployees() {
        const { data } = await axiosInstance.get(EMPLOYEE_API + 'getAllEmployees')
        setEmployees(data)
      }
      fetchAndSetEmployees()
  
    }, [toggleFetch, axiosInstance])
  
    function triggerFetch() {
      setToggleFetch(prevState => !prevState)
    }
    return (
      <>
        <div className="screen_header" ref={screenHeaerRef}>
          <h1 className="screen_heading">
            Employees ({employees?.length} in total)
          </h1>
        </div>
        <CRUDTable screenHeaderHeight={screenHeaderHeight}>
          <CRUDTableHeader>
            <CRUDth th="Name" />
            <CRUDth th="Email" />
            <CRUDth th="Level" />
            <CRUDth th="Action" />
          </CRUDTableHeader>
          <tbody>
            {employees?.map((employee) => (
              <CRUDTableRow key={employee._id}>
                <ShortTextCell text={employee?.name} />
                <ShortTextCell text={employee?.email} />
                <ShortTextCell text={employee?.level} />
                <td>
                  <div className="action_buttons_wrapper">
                    <div className="action_buttons">
                      <ViewButton
                        setShowViewSection={setShowViewSection}
                        targetID={employee._id}
                        setTargetID={setTargetID}
                      />
                      <CRUDButton
                        handleClick={() => {
                          setShowLevelSection(true);
                          setTargetID(employee._id);
                        }}
                      >
                        <RiUser2Line />
                      </CRUDButton>
                    </div>
                  </div>
                </td>
              </CRUDTableRow>
            ))}
          </tbody>
        </CRUDTable>
        {showLevelSection && (
          <Modal
            extraClass={"small"}
            modalHeading={`Change level`}
            setShowModalContent={setShowLevelSection}
          >
            <ChangeLevel
              setShowLevelSection={setShowLevelSection}
              triggerFetch={triggerFetch}
              targetID={targetID}
            />
          </Modal>
        )}
      </>
    );
  }

export default Employees
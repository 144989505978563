import { useEffect, useRef, useState } from "react";
// zip file
import JSZip from "jszip";
import { saveAs } from "file-saver";
import { useNavigate } from "react-router-dom";
import { RiFileZipLine, RiImage2Line, RiSearchLine } from "react-icons/ri";
import axios from "axios";
import useAxiosInstance from "../../../../Utilities/Hooks/useAxiosInstance";
import {
  ORDERS_API,
  PARTNERS_API,
  PRODUCTS_API,
  PRODUCT_IMAGES_API,
} from "../../../../Utilities/APIs/APIs";
import CreateButton from "../../../Partials/Layouts/CRUDs/CRUDHeader/CreateButton/CreateButton";
import CRUDTable from "../../../Partials/Layouts/CRUDs/CRUDTable/CRUDTable";
import CRUDTableHeader from "../../../Partials/Layouts/CRUDs/CRUDTable/CRUDTableHeader/CRUDTableHeader";
import CRUDth from "../../../Partials/Layouts/CRUDs/CRUDTable/CRUDTableHeader/CRUDth/CRUDth";
import CRUDTableRow from "../../../Partials/Layouts/CRUDs/CRUDTable/CRUDTableRow/CRUDTableRow";
import ShortTextCell from "../../../Partials/Layouts/CRUDs/CRUDTable/CRUDTableCells/ShortTextCell/ShortTextCell";
import Image from "../../../Partials/Elements/Image/Image";
import ViewButton from "../../../Partials/Layouts/CRUDs/CRUDButtons/ViewButton/ViewButton";
import EditButton from "../../../Partials/Layouts/CRUDs/CRUDButtons/EditButton/EditButton";
import DeleteButton from "../../../Partials/Layouts/CRUDs/CRUDButtons/DeleteButton/DeleteButton";
import CRUDButton from "../../../Partials/Layouts/CRUDs/CRUDButtons/CRUDButton";
import Pagination from "../../../Partials/Elements/pagination/Pagination";
import Modal from "../../../Partials/Elements/Modal/Modal";
import CreateProduct from "../ProductSetup/Products/CreateProduct";
import UpdateProduct from "../ProductSetup/Products/UpdateProduct";
import ViewProduct from "../ProductSetup/Products/ViewProduct";
import DeleteItem from "../../../Partials/Layouts/DeleteItem/DeleteItem";
import DateInput from "../../../Partials/Layouts/Forms/FormInputs/DateInput/DateInput";
import SelectInput from "../../../Partials/Layouts/Forms/FormInputs/SelectInput/SelectInput";
import SelectOption from "../../../Partials/Layouts/Forms/FormInputs/SelectInput/SelectOption/SelectOption";

function CollaboratorSales() {
  const [products, setProducts] = useState([]);
  const screenHeaerRef = useRef();
  const [screenHeaderHeight, setScreenHeaderHeight] = useState(0);

  useEffect(() => {
    let screenHeaderHeight = screenHeaerRef.current.clientHeight;
    setScreenHeaderHeight(screenHeaderHeight);
  }, [screenHeaerRef]);

  const [showCreateForm, setShowCreateForm] = useState(false);
  const [showViewSection, setShowViewSection] = useState(false);
  const [showUpdateForm, setShowUpdateForm] = useState(false);
  const [showDeleteSection, setShowDeleteSection] = useState(false);
  const [toggleFetch, setToggleFetch] = useState(false);
  const [search, setSearch] = useState("");
  const [searchInput, setSearchInput] = useState("");
  const [limit, setLimit] = useState(40);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalItems, setTotalItems] = useState(0);

  const [partners, setPartners] = useState([]);
  const [partner, setPartner] = useState('')

  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const [totalOrderPrice, setTotalOrderPrice] = useState(0);



  // Pagination states

  const [targetID, setTargetID] = useState(null);
  const axiosInstance = useAxiosInstance();
  const navigate = useNavigate();

  useEffect(() => {
    async function fetchAndSetProducts() {
        const res = await axiosInstance.get(
            `${ORDERS_API}getPartnerSoldProducts?startDate=${startDate}&endDate=${endDate}&partner=${partner}&page=${currentPage}&limit=${limit}`
        );

      console.log("res", res);
      setProducts(res?.data?.products);
      setTotalItems(res?.data?.metadata.total);

      // Generating total order sales amount by  array.reduce metod. acc = total, curr = each order, 0 initial value. 
      const totalOrderSales = res?.data?.products.reduce(
        (acc, curr) => acc + curr.totalSales,
        0
      );
      setTotalOrderPrice(totalOrderSales);
    }
    fetchAndSetProducts();
}, [toggleFetch, axiosInstance, search, currentPage, partner, startDate, endDate, limit]);



  ;

  function triggerFetch() {
    setToggleFetch((prevState) => !prevState);

  }

  useEffect(() => {
    async function fetchAndSetPartners() {
      const { data } = await axiosInstance.get(PARTNERS_API);
      setPartners(data);
    }
    fetchAndSetPartners();
  }, [toggleFetch, axiosInstance]);

  return (
    <>
      <div className="screen_header" ref={screenHeaerRef}>
        <h1 className="screen_heading">products ({totalItems} in total)</h1>
        <DateInput
          label={"Start Date"}
          value={startDate}
          setState={setStartDate}
        />

        <DateInput label={"End Date"} value={endDate} setState={setEndDate} />

        <h1 className="screen_heading">
          Total Order Price {totalOrderPrice} TK
        </h1>
        <SelectInput
          value={partner}
          setState={setPartner}
        >
          <SelectOption optionValue="" optionText="Select Partner" />
          {partners?.length > 0 &&
            partners?.map((partner) => (
              <SelectOption
                optionValue={partner._id}
                key={partner._id}
                optionText={partner?.name}
              />
            ))}
        </SelectInput>
      </div>
      <CRUDTable screenHeaderHeight={screenHeaderHeight}>
        <CRUDTableHeader>
          <CRUDth th="Invoice No" />
          <CRUDth th="Date" />
          <CRUDth th="Product" />
          <CRUDth th="Quantity" />
          <CRUDth th="Price" />
          <CRUDth th="Total" />
        </CRUDTableHeader>
        <tbody>
          {products?.length > 0 &&
            products?.map((product) => (
              <CRUDTableRow key={product._id}>
                <ShortTextCell 
                  text={product?.invoices?.join(", ")} 
                  // OR, to display them as separate lines, you could use a <div> with multiple <span> elements
                  // text={product?.invoices.map((invoice, index) => <span key={index}>{invoice}</span>)}
                />
                <ShortTextCell
                  text={new Date(product?.createdAt).toDateString()}
                />
                <ShortTextCell text={product.name} />
                <ShortTextCell text={product.totalQuantity} />
                <ShortTextCell text={product.currentPrice} />
                <ShortTextCell text={product.totalSales} />
              </CRUDTableRow>
            ))}
        </tbody>
        <Pagination
          items={products}
          totalItems={totalItems}
          limit={limit}
          currentPage={currentPage}
          setCurrentPage={setCurrentPage}
        />
      </CRUDTable>
      {showCreateForm && (
        <Modal
          extraClass={"small"}
          modalHeading={`Add Product`}
          setShowModalContent={setShowCreateForm}
        >
          <CreateProduct
            triggerFetch={triggerFetch}
            setShowCreateForm={setShowCreateForm}
          />
        </Modal>
      )}
      {showUpdateForm && (
        <Modal
          extraClass={"small"}
          modalHeading={`Update Product`}
          setShowModalContent={setShowUpdateForm}
        >
          <UpdateProduct
            triggerFetch={triggerFetch}
            targetID={targetID}
            setShowUpdateForm={setShowUpdateForm}
          />
        </Modal>
      )}
      {showViewSection && (
        <Modal
          extraClass={"small"}
          modalHeading={`View Product`}
          setShowModalContent={setShowViewSection}
        >
          <ViewProduct targetID={targetID} />
        </Modal>
      )}
      {showDeleteSection && (
        <Modal
          extraClass={"small"}
          modalHeading={`Delete Product`}
          setShowModalContent={setShowDeleteSection}
        >
          <DeleteItem
            api={PRODUCTS_API}
            triggerFetch={triggerFetch}
            targetID={targetID}
            setShowDeleteItem={setShowDeleteSection}
          />
        </Modal>
      )}
    </>
  );
}

export default CollaboratorSales;

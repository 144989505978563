import { useEffect, useRef, useState } from "react";
import CRUDTable from "../../../../Partials/Layouts/CRUDs/CRUDTable/CRUDTable";
import CRUDTableHeader from "../../../../Partials/Layouts/CRUDs/CRUDTable/CRUDTableHeader/CRUDTableHeader";
import CRUDth from "../../../../Partials/Layouts/CRUDs/CRUDTable/CRUDTableHeader/CRUDth/CRUDth";
import ShortTextCell from "../../../../Partials/Layouts/CRUDs/CRUDTable/CRUDTableCells/ShortTextCell/ShortTextCell";
import CRUDTableRow from "../../../../Partials/Layouts/CRUDs/CRUDTable/CRUDTableRow/CRUDTableRow";
import useAxiosInstance from "../../../../../Utilities/Hooks/useAxiosInstance";
import { PRODUCTS_API, PRODUCTSIZE_API } from "../../../../../Utilities/APIs/APIs";
import CRUDButton from "../../../../Partials/Layouts/CRUDs/CRUDButtons/CRUDButton";
import { useNavigate } from "react-router-dom";
import { RiImage2Line, RiSearchLine } from "react-icons/ri";
import Pagination from "../../../../Partials/Elements/pagination/Pagination";

function ProductReports() {
  const [products, setProducts] = useState([]);
  const [productSizes, setProductSizes] = useState({});
  const screenHeaerRef = useRef();
  const [screenHeaderHeight, setScreenHeaderHeight] = useState(0);

  useEffect(() => {
    let screenHeaderHeight = screenHeaerRef.current.clientHeight;
    setScreenHeaderHeight(screenHeaderHeight);
  }, [screenHeaerRef]);

  const [toggleFetch, setToggleFetch] = useState(false);
  const [search, setSearch] = useState("");
  const [searchInput, setSearchInput] = useState("");
  const [limit] = useState(40);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalItems, setTotalItems] = useState(0);
  
  const axiosInstance = useAxiosInstance();
  const navigate = useNavigate();

  useEffect(() => {
    async function fetchAndSetProducts() {
      const res = await axiosInstance.get(
        `${PRODUCTS_API}?search=${search}&page=${currentPage}&limit=${limit}`
      );
      setProducts(res?.data?.products);
      setTotalItems(res?.data?.totalItems);

      // Fetch product sizes for each product
      res?.data?.products?.forEach(async (product) => {
        const sizeRes = await axiosInstance.get(
          PRODUCTSIZE_API + 'getProductSizesByProduct/' + product._id
        );
        setProductSizes((prevState) => ({
          ...prevState,
          [product._id]: sizeRes?.data || [],
        }));
      });
    }
    fetchAndSetProducts();
  }, [toggleFetch, axiosInstance, search, currentPage]);

  const handleSearchInput = (value) => {
    const timeoutId = setTimeout(() => {
      setSearch(value);
    }, 500);
    return () => {
      clearTimeout(timeoutId);
    };
  };

  return (
    <>
      <div className="screen_header" ref={screenHeaerRef}>
        <h1 className="screen_heading">Products ({totalItems} in total)</h1>
        <div>
          <input
            value={searchInput}
            onChange={(e) => {
              handleSearchInput(e.target.value);
              setSearchInput(e.target.value);
            }}
            className="input_field"
            placeholder="Search"
            style={{ width: "400px" }}
          />
          <RiSearchLine
            style={{
              width: "30px",
              height: "30px",
              marginLeft: "-50px",
              color: "var(--white-2)",
            }}
          />
        </div>
      </div>
      <CRUDTable screenHeaderHeight={screenHeaderHeight}>
        <CRUDTableHeader>
          <CRUDth th="SKU" />
          <CRUDth th="Name" />
          <CRUDth th="Old Price" />
          <CRUDth th="New Price" />
          <CRUDth th="Gender" />
          <CRUDth th="Discount" />
          <CRUDth th="Sizes" />
          <CRUDth th="Total Quantity" />
        </CRUDTableHeader>
        <tbody>
          {products?.length > 0 &&
            products?.map((product) => (
            product.isVisible && (
              <CRUDTableRow key={product._id}>
                <ShortTextCell text={product.squId} />
                <ShortTextCell text={product.name} />
                <ShortTextCell text={product.price} />
                <ShortTextCell text={product.currentPrice} />
                <ShortTextCell text={product.gender} />
                {product.discount ? (
                  <ShortTextCell text={`${product.discount.percentage}%`} />
                ) : (
                  <ShortTextCell text="0%" />
                )}
                {/* Displaying product sizes and quantities */}
                <td>
                  {productSizes[product._id]?.map((size) => (
                    <div key={size._id}>
                      <span>{size.size.name} : {size.quantity}</span>
                    </div>
                  ))}
                </td>
                <ShortTextCell text={product.quantity} />
            
              </CRUDTableRow>
            )
            ))}
        </tbody>
        <Pagination
          items={products}
          totalItems={totalItems}
          limit={limit}
          currentPage={currentPage}
          setCurrentPage={setCurrentPage}
        />
      </CRUDTable>
    </>
  );
}

export default ProductReports;

import { useEffect, useRef, useState } from "react";
import useAxiosInstance from "../../../../../../Utilities/Hooks/useAxiosInstance";
import CRUDTable from "../../../../../Partials/Layouts/CRUDs/CRUDTable/CRUDTable";
import CRUDTableHeader from "../../../../../Partials/Layouts/CRUDs/CRUDTable/CRUDTableHeader/CRUDTableHeader";
import CRUDth from "../../../../../Partials/Layouts/CRUDs/CRUDTable/CRUDTableHeader/CRUDth/CRUDth";
import CRUDTableRow from "../../../../../Partials/Layouts/CRUDs/CRUDTable/CRUDTableRow/CRUDTableRow";
import ShortTextCell from "../../../../../Partials/Layouts/CRUDs/CRUDTable/CRUDTableCells/ShortTextCell/ShortTextCell";
import ViewButton from "../../../../../Partials/Layouts/CRUDs/CRUDButtons/ViewButton/ViewButton";
import { CUSTOMERS } from "../../../../../../Utilities/APIs/APIs";
import Modal from "../../../../../Partials/Elements/Modal/Modal";
import ViewCustomer from "./ViewCustomer";
import CRUDButton from "../../../../../Partials/Layouts/CRUDs/CRUDButtons/CRUDButton";
import { RiImage2Line, RiMapPin2Line } from "react-icons/ri";
import { useNavigate } from "react-router-dom";
import Pagination from "../../../../../Partials/Elements/pagination/Pagination";

function Customers() {
  const [customers, setCustomers] = useState(null);
  const screenHeaerRef = useRef();
  const [screenHeaderHeight, setScreenHeaderHeight] = useState(0);
  const [totalItems, setTotalItems] = useState(0)
  const [currentPage, setCurrentPage] = useState(1)
  const [limit, setLimit] = useState(500)
  const navigate = useNavigate()

  useEffect(() => {
    let screenHeaderHeight = screenHeaerRef.current.clientHeight;
    setScreenHeaderHeight(screenHeaderHeight);
  }, [screenHeaerRef]);

  const [showCreateForm, setShowCreateForm] = useState(false);
  const [showViewSection, setShowViewSection] = useState(false);
  const [showUpdateForm, setShowUpdateForm] = useState(false);
  const [showDeleteSection, setShowDeleteSection] = useState(false);
  const [toggleFetch, setToggleFetch] = useState(false);

  const [targetID, setTargetID] = useState(null);
  const axiosInstance = useAxiosInstance();

  useEffect(() => {
    async function fetchAndSetCustomers() {
      const { data } = await axiosInstance.get(CUSTOMERS + `getAllCustomers?page=${currentPage}&limit=${limit}`);
      console.log("data:" , data);
      setCustomers(data.customers);
      setTotalItems(data.totalItems);
      
    }
    fetchAndSetCustomers();
  }, [toggleFetch, axiosInstance, currentPage, limit]);

  function triggerFetch() {
    setToggleFetch((prevState) => !prevState);
  }
  return (
    <>
      <div className="screen_header" ref={screenHeaerRef}>
        <h1 className="screen_heading">
          Customers ({totalItems} in total)
        </h1>
        {/* <CreateButton
          screenTopicSingular={'Employee Designation'}
          setShowCreateForm={setShowCreateForm}
        /> */}
      </div>
      <CRUDTable screenHeaderHeight={screenHeaderHeight}>
        <CRUDTableHeader>
          <CRUDth th="Name" />
          <CRUDth th="Email" />
          <CRUDth th="Mobile" />
          <CRUDth th="Points" />
          <CRUDth th="Club" />
          <CRUDth th="Action" />
        </CRUDTableHeader>
        <tbody>
          {customers?.map((customer) => (
            <CRUDTableRow key={customer._id}>
              <ShortTextCell text={customer?.name} />
              <ShortTextCell text={customer?.email} />
              <ShortTextCell text={customer?.mobile} />
              <ShortTextCell text={customer?.totalPoints} />
              <ShortTextCell text={customer?.club} />
              <td>
                <div className="action_buttons_wrapper">
                  <div className="action_buttons">
                    <ViewButton
                      setShowViewSection={setShowViewSection}
                      targetID={customer._id}
                      setTargetID={setTargetID}
                    />
                    {/* 
                      <EditButton
                        setShowUpdateForm={setShowUpdateForm}
                        targetID={customer._id}
                        setTargetID={setTargetID}
                      /> */}

                    {/* <DeleteButton
                        setShowDeleteSection={setShowDeleteSection}
                        targetID={customer._id}
                        setTargetID={setTargetID}
                      /> */}

                    <CRUDButton
                      handleClick={() =>
                        navigate("/main/addressesFromCustomer/" + customer._id)
                      }
                    >
                      <RiMapPin2Line />
                    </CRUDButton>

                    <CRUDButton
                      handleClick={() =>
                        navigate("/main/customerOrders/" + customer._id)
                      }
                    >
                      <RiImage2Line />
                    </CRUDButton>
                  </div>
                </div>
              </td>
            </CRUDTableRow>
          ))}

        </tbody>
        <Pagination
          totalItems={totalItems}
          limit={limit}
          currentPage={currentPage}
          setCurrentPage={setCurrentPage}
        />
      </CRUDTable>

      {showViewSection && (
        <Modal
          extraClass={"small"}
          modalHeading={`View Customer`}
          setShowModalContent={setShowViewSection}
        >
          <ViewCustomer targetID={targetID} />
        </Modal>
      )}
      {/* {
        showDeleteSection &&
        <Modal
          extraClass={'small'}
          modalHeading={`Delete Employee Designation`}
          setShowModalContent={setShowDeleteSection}
        >
          <DeleteItem
            api={EMPLOYEE_DESIGNATIONS_API}
            triggerFetch={triggerFetch}
            targetID={targetID}
            setShowDeleteItem={setShowDeleteSection}
          />
        </Modal>
      } */}
    </>
  );
}

export default Customers;

import Modal from "../../../../../Partials/Elements/Modal/Modal";
import {
  PAIREDPRODUCT_API,
  PRODUCT_COLLECTIONS_API,
} from "../../../../../../Utilities/APIs/APIs";
import CreateProductCollectionProducts from "./CreateProductCollectionProducts";
import { useEffect, useRef, useState } from "react";
import CreateButton from "../../../../../Partials/Layouts/CRUDs/CRUDHeader/CreateButton/CreateButton";
import CRUDTable from "../../../../../Partials/Layouts/CRUDs/CRUDTable/CRUDTable";
import CRUDTableHeader from "../../../../../Partials/Layouts/CRUDs/CRUDTable/CRUDTableHeader/CRUDTableHeader";
import CRUDth from "../../../../../Partials/Layouts/CRUDs/CRUDTable/CRUDTableHeader/CRUDth/CRUDth";
import CRUDTableRow from "../../../../../Partials/Layouts/CRUDs/CRUDTable/CRUDTableRow/CRUDTableRow";
import ShortTextCell from "../../../../../Partials/Layouts/CRUDs/CRUDTable/CRUDTableCells/ShortTextCell/ShortTextCell";
import ViewButton from "../../../../../Partials/Layouts/CRUDs/CRUDButtons/ViewButton/ViewButton";
import EditButton from "../../../../../Partials/Layouts/CRUDs/CRUDButtons/EditButton/EditButton";
import DeleteButton from "../../../../../Partials/Layouts/CRUDs/CRUDButtons/DeleteButton/DeleteButton";
import useAxiosInstance from "../../../../../../Utilities/Hooks/useAxiosInstance";
import UpdateProductCollectionProduct from "./UpdateProductCollectionProduct";
import ViewProductCollectionProducts from "./ViewProductCollectionProducts";
import { useParams } from "react-router-dom";
import DeleteItem from "../../../../../Partials/Layouts/DeleteItem/DeleteItem";
import Pagination from "../../../../../Partials/Elements/pagination/Pagination";

function ProductCollectionProducts() {
  const [productCollectionProducts, setProductCollectionProducts] =
    useState(null);
  const screenHeaerRef = useRef();
  const [screenHeaderHeight, setScreenHeaderHeight] = useState(0);

  useEffect(() => {
    let screenHeaderHeight = screenHeaerRef.current.clientHeight;
    setScreenHeaderHeight(screenHeaderHeight);
  }, [screenHeaerRef]);

  const [showCreateForm, setShowCreateForm] = useState(false);
  const [showViewSection, setShowViewSection] = useState(false);
  const [showUpdateForm, setShowUpdateForm] = useState(false);
  const [showDeleteSection, setShowDeleteSection] = useState(false);
  const [toggleFetch, setToggleFetch] = useState(false);
  const [limit, setLimit] = useState(40);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalItems, setTotalItems] = useState(0);

  const [targetID, setTargetID] = useState(null);
  const axiosInstance = useAxiosInstance();
  const { id } = useParams();

  useEffect(() => {
    async function fetchAndSetPairedProducts() {
      const { data } = await axiosInstance.get(
        `${PRODUCT_COLLECTIONS_API}getAllProductCollectionProductsById/${id}?page=${currentPage}&limit=${limit}`
      );
      console.log("data", data);

      setProductCollectionProducts(data?.productCollectionProducts);
      setTotalItems(data?.totalItems);
    }
    fetchAndSetPairedProducts();
  }, [toggleFetch, axiosInstance, id, currentPage, limit]);

  function triggerFetch() {
    setToggleFetch((prevState) => !prevState);
  }
  return (
    <>
      <div className="screen_header" ref={screenHeaerRef}>
        <h1 className="screen_heading">
          Product Collection Products ({totalItems} in total)
        </h1>
        <CreateButton
          screenTopicSingular={"Product Collection Products"}
          setShowCreateForm={setShowCreateForm}
        />
      </div>
      <CRUDTable screenHeaderHeight={screenHeaderHeight}>
        <CRUDTableHeader>
          <CRUDth th="product Collection" />
          <CRUDth th="product" />
          <CRUDth th="Action" />
        </CRUDTableHeader>
        <tbody>
          {productCollectionProducts?.map((productCollectionProduct) => (
            <CRUDTableRow key={productCollectionProduct._id}>
              <ShortTextCell
                text={productCollectionProduct?.productCollection?.name}
              />
              <ShortTextCell text={productCollectionProduct?.product?.name} />
              <td>
                <div className="action_buttons_wrapper">
                  <div className="action_buttons">
                    <ViewButton
                      setShowViewSection={setShowViewSection}
                      targetID={productCollectionProduct._id}
                      setTargetID={setTargetID}
                      tooltipName={"View"}
                      tooltipContent={"view"}
                    />

                    <EditButton
                      setShowUpdateForm={setShowUpdateForm}
                      targetID={productCollectionProduct?._id}
                      setTargetID={setTargetID}
                      tooltipName={"Edit"}
                      tooltipContent={"edit"}
                    />

                    <DeleteButton
                      setShowDeleteSection={setShowDeleteSection}
                      targetID={productCollectionProduct._id}
                      setTargetID={setTargetID}
                      tooltipName={"Delete"}
                      tooltipContent={"delete"}
                    />
                  </div>
                </div>
              </td>
            </CRUDTableRow>
          ))}
        </tbody>
        <Pagination
          totalItems={totalItems}
          limit={limit}
          currentPage={currentPage}
          setCurrentPage={setCurrentPage}
        />
      </CRUDTable>
      {showCreateForm && (
        <Modal
          extraClass={"small"}
          modalHeading={`Add Product Collection Products`}
          setShowModalContent={setShowCreateForm}
          bodyStyle={{ overflowY: "visible" }}
          contentStyle={{ overflow: "visible" }}
        >
          <CreateProductCollectionProducts
            id={id}
            triggerFetch={triggerFetch}
            setShowCreateForm={setShowCreateForm}
          />
        </Modal>
      )}
      {showUpdateForm && (
        <Modal
          extraClass={"small"}
          modalHeading={`Update Product Collection Products`}
          setShowModalContent={setShowUpdateForm}
          bodyStyle={{ overflowY: "visible" }}
          contentStyle={{ overflow: "visible" }}
        >
          <UpdateProductCollectionProduct
            id={id}
            triggerFetch={triggerFetch}
            targetID={targetID}
            setShowUpdateForm={setShowUpdateForm}
          />
        </Modal>
      )}
      {showViewSection && (
        <Modal
          extraClass={"small"}
          modalHeading={`View Product Collection Products`}
          setShowModalContent={setShowViewSection}
        >
          <ViewProductCollectionProducts targetID={targetID} />
        </Modal>
      )}
      {showDeleteSection && (
        <Modal
          extraClass={"small"}
          modalHeading={`Delete Product Collection Products`}
          setShowModalContent={setShowDeleteSection}
        >
          <DeleteItem
            api={PAIREDPRODUCT_API}
            triggerFetch={triggerFetch}
            targetID={targetID}
            setShowDeleteItem={setShowDeleteSection}
          />
        </Modal>
      )}
    </>
  );
}

export default ProductCollectionProducts;

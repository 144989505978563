import { useContext, useEffect } from "react";
import { Outlet, useNavigate } from "react-router-dom";
import AppContext from "../../../AppContext/AppContext";

function AuthScreens() {

    const navigate = useNavigate()

    const { employee } = useContext(AppContext)

    useEffect(() => {
        if (employee) {
            navigate('/main/dashboard')
        }
    }, [employee, navigate])

    return (
        <Outlet />
    )
}

export default AuthScreens
import { useEffect, useState } from "react"
import useAxiosInstance from '../../../../../Utilities/Hooks/useAxiosInstance';
import slugify from "react-slugify"
import Form from "../../../../Partials/Layouts/Forms/Form"
import ShortTextInput from "../../../../Partials/Layouts/Forms/FormInputs/ShortTextInput/ShortTextInput"
import SwitchInput from "../../../../Partials/Layouts/Forms/FormInputs/SwitchInput/SwitchInput"
import FormSubmitButton from "../../../../Partials/Layouts/Forms/FormSubmitButton/FormSubmitButton"
import { PARTNERS_API } from "../../../../../Utilities/APIs/APIs"

function UpdatePartner({targetID, triggerFetch, setShowUpdateForm}) {

    const axiosInstance = useAxiosInstance()

    const [name, setName] = useState('')  
    const [isActive, setIsActive] = useState('')   
    
    useEffect(() => {
        async function fetchAndSetSinglePartner() {
          const { data } = await axiosInstance.get(PARTNERS_API + 'getSinglePartner/' + targetID)
          setName(data.name)
          setIsActive(data.isActive)
        }
        fetchAndSetSinglePartner()

      }, [targetID, axiosInstance])

    async function handleSubmit(e) {

        e.preventDefault()
        const itemData = new FormData()

        itemData.append('name', name)
        itemData.append('slug', slugify(name))
        itemData.append('isActive', isActive)

        const response = await axiosInstance.patch(PARTNERS_API + targetID, itemData)
        if (response) {
            triggerFetch()
            setShowUpdateForm(false)
        }
    }

    return (
        <div className='crate_form'>
            <Form onSubmit={handleSubmit}>
                <div>
                    <ShortTextInput
                        label={`Title`}
                        value={name}
                        placeholder={`Enter Title`}
                        setState={setName}
                    />
                     <ShortTextInput
                        label={`Slug`}
                        value={slugify(name)}
                        placeholder={`Enter Slug`}
                        disabled
                    />
                     <SwitchInput
                        label={`Status`}
                        toggleSwitch={() => setIsActive(prevState => !prevState)}
                        checked={isActive}
                    />
                </div>
                <FormSubmitButton text="Submit" />
            </Form>
        </div>
    )
}

export default UpdatePartner
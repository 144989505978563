import React from 'react'
import { ORDERS_API } from '../../../../../Utilities/APIs/APIs';
import useAxiosInstance from '../../../../../Utilities/Hooks/useAxiosInstance';

function ExchangedFromDelivered({
     setshowExchangeOrder,triggerFetch,
    targetID,

  }) 
  {
    const axiosInstance = useAxiosInstance();

      
  async function handleStatusChange(id) {
   const response= await axiosInstance.patch(ORDERS_API +"exchangedFromDelivered/" + id, "");
    if (response) {
        triggerFetch()
        setshowExchangeOrder(false)
    }

    }
  
    return (
      <div className="delete_item">
        <div className="delete_content">
          <h1>Are you sure?</h1>
          <p>Please confirm you want to exchanged this order?</p>
          <div className="delete_btn">
            <button
              className="delete"
              onClick={() => handleStatusChange(targetID)}
            >
              Accept
            </button>
            <button
              className="cancel"
              onClick={() => {
                setshowExchangeOrder(false);
              }}
            >
              Cancel
            </button>
          </div>
        </div>
      </div>
    );
  }

export default ExchangedFromDelivered
import { useEffect, useState } from "react"
import useAxiosInstance from "../../../../../Utilities/Hooks/useAxiosInstance"
import Form from "../../../../Partials/Layouts/Forms/Form"
import FormSubmitButton from "../../../../Partials/Layouts/Forms/FormSubmitButton/FormSubmitButton"
import { PRICEHISTORY_API } from "../../../../../Utilities/APIs/APIs"
import ShortTextInput from "../../../../Partials/Layouts/Forms/FormInputs/ShortTextInput/ShortTextInput"
import DateInput from "../../../../Partials/Layouts/Forms/FormInputs/DateInput/DateInput"

function UpdatePriceHistory({productID, targetID, triggerFetch, setShowUpdateForm }) {

    const axiosInstance = useAxiosInstance()

    const [price, setPrice] = useState('')
    const [date, setDate] = useState('')


    useEffect(() => {
        async function fetchAndSetSinglePriceHistories() {
            const { data } = await axiosInstance.get(PRICEHISTORY_API + 'getSinglePriceHistory/' + targetID)
            const dt = new Date(data.date)
            setPrice(data.price)
            setDate(`${dt.toISOString().slice(0, 10)}`)
        }
        fetchAndSetSinglePriceHistories()

    }, [targetID, axiosInstance])

    async function handleSubmit(e) {

        e.preventDefault()
        const itemData = new FormData()

        itemData.append('product', productID)
        itemData.append('price', price)
        itemData.append('date', date)

        const response = await axiosInstance.patch(PRICEHISTORY_API + targetID, itemData)
        if (response) {
            triggerFetch()
            setShowUpdateForm(false)
        }
    }

    return (
        <div className='crate_form'>
            <Form onSubmit={handleSubmit}>
                <div>

                    <ShortTextInput
                        label={'Price'}
                        value={price}
                        setState={setPrice}
                    />

                    <DateInput
                        label={'Date'}
                        value={date}
                        setState={setDate}
                    />
                </div>
                <FormSubmitButton text="Update Price" />
            </Form>
        </div>
    )
}

export default UpdatePriceHistory
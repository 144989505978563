import React, { useEffect, useRef, useState } from "react";
import useAxiosInstance from "../../../../../Utilities/Hooks/useAxiosInstance";
import CRUDTable from "../../../../Partials/Layouts/CRUDs/CRUDTable/CRUDTable";
import CRUDTableHeader from "../../../../Partials/Layouts/CRUDs/CRUDTable/CRUDTableHeader/CRUDTableHeader";
import CRUDth from "../../../../Partials/Layouts/CRUDs/CRUDTable/CRUDTableHeader/CRUDth/CRUDth";
import CRUDTableRow from "../../../../Partials/Layouts/CRUDs/CRUDTable/CRUDTableRow/CRUDTableRow";
import ShortTextCell from "../../../../Partials/Layouts/CRUDs/CRUDTable/CRUDTableCells/ShortTextCell/ShortTextCell";
import ViewButton from "../../../../Partials/Layouts/CRUDs/CRUDButtons/ViewButton/ViewButton";
import Modal from "../../../../Partials/Elements/Modal/Modal";
import ViewDemandProducts from "./ViewDemandProducts";
import { DEMAND_PRODUCTS_API } from "../../../../../Utilities/APIs/APIs";
import ImageCell from "../../../../Partials/Layouts/CRUDs/CRUDTable/CRUDTableCells/ImageCell/ImageCell";

function DemandProducts() {
  const [demandProducts, setDemandProducts] = useState(null);
  console.log("🚀 ~ file: DemandProducts.js:16 ~ DemandProducts ~ demandProducts:", demandProducts)
  const screenHeaerRef = useRef();
  const [screenHeaderHeight, setScreenHeaderHeight] = useState(0);

  useEffect(() => {
    let screenHeaderHeight = screenHeaerRef.current.clientHeight;
    setScreenHeaderHeight(screenHeaderHeight);
  }, [screenHeaerRef]);

  const [showViewSection, setShowViewSection] = useState(false);
  const [toggleFetch, setToggleFetch] = useState(false);

  const [targetID, setTargetID] = useState(null);
  const axiosInstance = useAxiosInstance();

  useEffect(() => {
    async function fetchAndSetMaterials() {
      const { data } = await axiosInstance.get(DEMAND_PRODUCTS_API);
      setDemandProducts(data);
    }
    fetchAndSetMaterials();
  }, [toggleFetch, axiosInstance]);

  function triggerFetch() {
    setToggleFetch((prevState) => !prevState);
  }
  return (
    <>
      <div className="screen_header" ref={screenHeaerRef}>
        <h1 className="screen_heading">
          Demand Products ({demandProducts?.length} in total)
        </h1>
      </div>
      <CRUDTable screenHeaderHeight={screenHeaderHeight}>
        <CRUDTableHeader>
          <CRUDth th="Email" />
          <CRUDth th="Product Name" />
          <CRUDth th="Size" />
          <CRUDth th="Image" />
          <CRUDth th="Actions" />
        </CRUDTableHeader>
        <tbody>
          {demandProducts?.map((demandProduct) => (
            <CRUDTableRow key={demandProduct._id}>
              <ShortTextCell text={demandProduct.email} />
              <ShortTextCell text={demandProduct?.productSize?.product?.name} />
              <ShortTextCell text={demandProduct?.productSize?.size?.name} />
              <ImageCell imgSrc={demandProduct?.productSize?.product?.cardFrontImage} />


              <td>
                <div className="action_buttons_wrapper">
                  <div className="action_buttons">
                    <ViewButton
                      setShowViewSection={setShowViewSection}
                      targetID={demandProduct._id}
                      setTargetID={setTargetID}
                      tooltipName={"View"} 
                        tooltipContent={"View"}
                    />
                  </div>
                </div>
              </td>
            </CRUDTableRow>
          ))}
        </tbody>
      </CRUDTable>

      {showViewSection && (
        <Modal
          extraClass={"small"}
          modalHeading={`View Demand Product`}
          setShowModalContent={setShowViewSection}
        >
          <ViewDemandProducts targetID={targetID} />
        </Modal>
      )}
    </>
  );
}

export default DemandProducts;

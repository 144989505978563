// import axios from "axios";
// import { useContext, useMemo } from "react";
// import AppContext from "../../AppContext/AppContext";

// function useAxiosInstance() {
//   const { employee } = useContext(AppContext);

//   const axiosInstance = useMemo(
//     () =>
//       axios.create({
//         baseURL: process.env.REACT_APP_BACKEND_URL + "api/employee/",
//         headers: {
//           Authorization: "Bearer " + employee?.token,
//         },
//       }),
//     [employee]
//   );

//   return axiosInstance;
// }

// export default useAxiosInstance;

import axios from "axios";
import { useContext, useMemo } from "react";
import AppContext from "../../AppContext/AppContext";

function useAxiosInstance() {
  const { employee, logout ,setErrorMessage} = useContext(AppContext);

  const axiosInstance = useMemo(() => {
    const instance = axios.create({
      baseURL: process.env.REACT_APP_BACKEND_URL + "api/employee/",
      headers: {
        Authorization: "Bearer " + employee?.token,
      },
    });
    instance.interceptors.response.use(
      (response) => response,
      (error) => {
        setErrorMessage(error?.response.data.message)
        if (error?.response && error?.response?.status === 401) {
          logout();
        }
        // return Promise.reject(error);

        return { data: [] };
      }
    );

    return instance;
  }, [employee, logout, setErrorMessage]);

  return axiosInstance;
}

export default useAxiosInstance;

import React from 'react'
import Image from '../../../../../Elements/Image/Image'


function ImageCell({ imgSrc, imgAlt }) {
    return (
        <td>
            <Image
                imgLink={imgSrc}
                imgAlt={imgAlt}
            />
        </td>
    )
}

export default ImageCell
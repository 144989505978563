import { useEffect, useState } from 'react'
import useAxiosInstance from '../../../../../../Utilities/Hooks/useAxiosInstance'
import { CHANGED_INVENTORIES_API } from '../../../../../../Utilities/APIs/APIs'

function ViewChangedInventories({ targetID }) {

    const axiosInstance = useAxiosInstance()


    const [changedInventoriesInfo, setChangedInventoriesInfo] = useState(null)

    useEffect(() => {
        async function fetchAndSetSingleProductSize() {
            const { data } = await axiosInstance.get(CHANGED_INVENTORIES_API + 'getSingleChangeInventory/' + targetID)
            setChangedInventoriesInfo(data)
        }
        fetchAndSetSingleProductSize()

    }, [targetID, axiosInstance])

    return (
        <div className="crud_view_content">
            {
                changedInventoriesInfo &&
                <>
                    <h1>Quantity</h1>
                    <p>{changedInventoriesInfo.quantity}</p>

                    <h1>Date</h1>
                    <p>{new Date(changedInventoriesInfo?.date).toLocaleDateString()}</p>

                    <h1>Change Type</h1>
                    <p>{changedInventoriesInfo?.changeType }</p>

                    <h1> Start Quantity</h1>
                    <p>{changedInventoriesInfo?.startQuantity}</p>

                    <h1>End Quantity</h1>
                    <p>{changedInventoriesInfo?.endQuantity }</p>
                </>
            }

        </div>
    )
}

export default ViewChangedInventories
import { useEffect, useRef, useState } from "react";
// zip file
import JSZip from "jszip";
import { saveAs } from "file-saver";
import { useNavigate } from "react-router-dom";
import {
  RiFileZipLine,
  RiImage2Line,
  RiListSettingsLine,
  RiPrinterLine,
  RiSearchLine,
} from "react-icons/ri";
import axios from "axios";
import useAxiosInstance from "../../../../Utilities/Hooks/useAxiosInstance";
import {
  ORDERS_API,
  PRODUCTS_API,
  PRODUCT_IMAGES_API,
} from "../../../../Utilities/APIs/APIs";
import CreateButton from "../../../Partials/Layouts/CRUDs/CRUDHeader/CreateButton/CreateButton";
import CRUDTable from "../../../Partials/Layouts/CRUDs/CRUDTable/CRUDTable";
import CRUDTableHeader from "../../../Partials/Layouts/CRUDs/CRUDTable/CRUDTableHeader/CRUDTableHeader";
import CRUDth from "../../../Partials/Layouts/CRUDs/CRUDTable/CRUDTableHeader/CRUDth/CRUDth";
import CRUDTableRow from "../../../Partials/Layouts/CRUDs/CRUDTable/CRUDTableRow/CRUDTableRow";
import ShortTextCell from "../../../Partials/Layouts/CRUDs/CRUDTable/CRUDTableCells/ShortTextCell/ShortTextCell";
import Image from "../../../Partials/Elements/Image/Image";
import ViewButton from "../../../Partials/Layouts/CRUDs/CRUDButtons/ViewButton/ViewButton";
import EditButton from "../../../Partials/Layouts/CRUDs/CRUDButtons/EditButton/EditButton";
import DeleteButton from "../../../Partials/Layouts/CRUDs/CRUDButtons/DeleteButton/DeleteButton";
import CRUDButton from "../../../Partials/Layouts/CRUDs/CRUDButtons/CRUDButton";
import Pagination from "../../../Partials/Elements/pagination/Pagination";
import Modal from "../../../Partials/Elements/Modal/Modal";
import CreateProduct from "../ProductSetup/Products/CreateProduct";
import UpdateProduct from "../ProductSetup/Products/UpdateProduct";
import ViewProduct from "../ProductSetup/Products/ViewProduct";
import DeleteItem from "../../../Partials/Layouts/DeleteItem/DeleteItem";
import DateInput from "../../../Partials/Layouts/Forms/FormInputs/DateInput/DateInput";
import { takaFormatter } from "../../../../Utilities/Formatter";
import ViewOrder from "../OrderScreens/ViewOrder";
import OrderInvoice from "../../../Partials/Elements/OrderInvoice/OrderInvoice";

function SalesReport() {
  const [products, setProducts] = useState([]);
  const screenHeaerRef = useRef();
  const [screenHeaderHeight, setScreenHeaderHeight] = useState(0);

  useEffect(() => {
    let screenHeaderHeight = screenHeaerRef.current.clientHeight;
    setScreenHeaderHeight(screenHeaderHeight);
  }, [screenHeaerRef]);

  const [showCreateForm, setShowCreateForm] = useState(false);
  const [showViewSection, setShowViewSection] = useState(false);
  const [showUpdateForm, setShowUpdateForm] = useState(false);
  const [showDeleteSection, setShowDeleteSection] = useState(false);
  const [toggleFetch, setToggleFetch] = useState(false);
  const [search, setSearch] = useState("");
  const [searchInput, setSearchInput] = useState("");
  const [limit, setLimit] = useState(100);
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const [totalOrderPrice, setTotalOrderPrice] = useState(0);
  const [oneday, setOneday] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const [totalItems, setTotalItems] = useState(0);
  const [showInvoice, setShowInvoice] = useState(false);


  const navigate = useNavigate();


  // Pagination states

  const [targetID, setTargetID] = useState(null);
  const axiosInstance = useAxiosInstance();

  useEffect(() => {
    setStartDate(`${new Date().getFullYear()}-${new Date().getMonth() + 1}-${new Date().getDay() < 10 ? '0' + new Date().getDay() : new Date().getDay()}`)
    setEndDate(`${new Date().getFullYear()}-${new Date().getMonth() + 1}-${new Date().getDay() < 10 ? '0' + new Date().getDay() : new Date().getDay()}`)
  }, [])

  useEffect(() => {
    async function fetchAndSetProducts() {
      const res = await axiosInstance.get(
        `${ORDERS_API}getOrderSaleReports?startDate=${startDate}&endDate=${endDate}&oneday=${oneday}&page=${currentPage}&limit=${limit}`
      );

      setProducts(res?.data?.orders);
      setTotalItems(res?.data?.totalItems);
      const formatNumber = new Intl.NumberFormat("en-IN").format(
        parseInt(res?.data?.totalOrderAmount)
      );

      setTotalOrderPrice(formatNumber);
    }
    fetchAndSetProducts();
  }, [
    toggleFetch,
    axiosInstance,
    currentPage,
    startDate,
    endDate,
    currentPage,
    limit,
    oneday
  ]);




  return (
    <>
      <div className="screen_header" ref={screenHeaerRef}>
        <h1 className="screen_heading">Orders ({totalItems} in total)</h1>
        <DateInput
          label={"Start Date"}
          value={startDate}
          setState={setStartDate}
        />

        <DateInput label={"End Date"} value={endDate} setState={setEndDate} />
        <DateInput label={"One Day"} value={oneday} setState={setOneday} />

        <h1 className="screen_heading">
          Total Order Price {totalOrderPrice} TK
        </h1>
      </div>
      <CRUDTable screenHeaderHeight={screenHeaderHeight}>
        <CRUDTableHeader>
          <CRUDth th="Invoice No" />
          <CRUDth th="Date" />
          <CRUDth th="Name" />
          <CRUDth th="Phone" />
          <CRUDth th="Order Total" />
          <CRUDth th="Delivery Media" />
          <CRUDth th="Actions" />
        </CRUDTableHeader>
        <tbody>
          {products?.length > 0 &&
            products?.map((order) => (
              <CRUDTableRow key={order._id}>
                <ShortTextCell text={order?.invoiceNo} />
                <ShortTextCell
                  text={new Date(order?.createdAt).toDateString()}
                />
                <ShortTextCell text={order?.shippingName} />
                <ShortTextCell text={order?.shippingPhone} />
                <ShortTextCell text={takaFormatter(order?.orderTotal)} />
                <ShortTextCell text={order?.shippingMediaType} />

                <td>
                  <div className="action_buttons_wrapper">
                    <div className="action_buttons">
                      <ViewButton
                        setShowViewSection={setShowViewSection}
                        targetID={order?._id}
                        setTargetID={setTargetID}
                        tooltipContent={"View Product Details"}
                        tooltipName={"view"}
                      />
                      <CRUDButton
                        handleClick={() =>
                          navigate("/main/order_items_from_order/" + order._id)
                        }
                        tooltip={"Order Items From Order"}
                      >
                        <RiListSettingsLine />
                      </CRUDButton>

                      {/* <EditButton
                        setShowUpdateForm={setShowUpdateForm}
                        targetID={product?._id}
                        setTargetID={setTargetID}
                        tooltipContent={"Update Product Details"}
                        tooltipName={"edit"}
                      />

                      <DeleteButton
                        setShowDeleteSection={setShowDeleteSection}
                        targetID={product._id}
                        setTargetID={setTargetID}
                        tooltipContent={"Delete Product"}
                        tooltipName={"delete"}
                      /> */}
                      {/* <CRUDButton
                        handleClick={() =>
                          navigate("/main/productsScreen/" + product._id)
                        }
                        tooltipContent={"Product Sreen"}
                        tooltipName={"productSreen"}
                      >
                        <RiImage2Line />
                      </CRUDButton> */}
                      {/* <CRUDButton
                      handleClick={() =>{
                        setTargetID(product._id)
                        zipAndDownloadImages()
                      }}
                    >
                      <RiFileZipLine />
                    </CRUDButton> */}
                    </div>
                  </div>
                </td>
              </CRUDTableRow>
            ))}
        </tbody>
        <Pagination
          items={products}
          totalItems={totalItems}
          limit={limit}
          currentPage={currentPage}
          setCurrentPage={setCurrentPage}
        />
      </CRUDTable>
      {/* {showCreateForm && (
        <Modal
          extraClass={"small"}
          modalHeading={`Add Product`}
          setShowModalContent={setShowCreateForm}
        >
          <CreateProduct
            triggerFetch={triggerFetch}
            setShowCreateForm={setShowCreateForm}
          />
        </Modal>
      )} */}
      {/* {showUpdateForm && (
        <Modal
          extraClass={"small"}
          modalHeading={`Update Product`}
          setShowModalContent={setShowUpdateForm}
        >
          <UpdateProduct
            triggerFetch={triggerFetch}
            targetID={targetID}
            setShowUpdateForm={setShowUpdateForm}
          />
        </Modal>
      )} */}
      {showViewSection && (
        <Modal
          extraClass={"small"}
          modalHeading={`View Order Details`}
          setShowModalContent={setShowViewSection}
        >
          <ViewOrder targetID={targetID} />
        </Modal>
      )}
      {/* {showDeleteSection && (
        <Modal
          extraClass={"small"}
          modalHeading={`Delete Product`}
          setShowModalContent={setShowDeleteSection}
        >
          <DeleteItem
            api={PRODUCTS_API}
            triggerFetch={triggerFetch}
            targetID={targetID}
            setShowDeleteItem={setShowDeleteSection}
          />
        </Modal>
      )} */}

      {showInvoice && (
        <Modal
          // extraClass={"small"}
          modalHeading={`View Invoice`}
          setShowModalContent={setShowInvoice}
        >
          <OrderInvoice targetID={targetID} />
        </Modal>
      )}
    </>
  );
}

export default SalesReport;

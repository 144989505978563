function Image({ imgLink, imgAlt, className, assetOrWeb, style }) {
  console.log("assetOrWeb:",assetOrWeb)
    return (
      imgLink &&
      <img
        src={assetOrWeb ? imgLink : process.env.REACT_APP_SPACES_URL + imgLink}
        alt={imgAlt}
        className={className}
        style={{...style}}
      />
    )
  }
  
  export default Image
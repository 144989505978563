import React from "react";
import NavCardList from "../../../../../Partials/Layouts/NavCardList/NavCardList";
import { RiGiftFill } from "react-icons/ri";
import NavCard from "../../../../../Partials/Layouts/NavCardList/NavCard/NavCard";
import { useParams } from "react-router-dom";

function ProductsScreen() {
  const { id } = useParams();
  return (
    <NavCardList numOfCards="eight">
      <NavCard
        cardName={`Product Colors`}
        navCardLink={"/main/productColors/" + id}
      >
        <RiGiftFill />
      </NavCard>
      <NavCard
        cardName={`Product Cares`}
        navCardLink={"/main/productCares/" + id}
      >
        <RiGiftFill />
      </NavCard>
      <NavCard
        cardName={`Paired Products`}
        navCardLink={"/main/pairedProducts/" + id}
      >
        <RiGiftFill />
      </NavCard>
      <NavCard
        cardName={`Price Histories`}
        navCardLink={"/main/priceHistories/" + id}
      >
        <RiGiftFill />
      </NavCard>
      <NavCard
        cardName={`Product Sizes`}
        navCardLink={"/main/productSizes/" + id}
      >
        <RiGiftFill />
      </NavCard>
      <NavCard
        cardName={`Product Materials`}
        navCardLink={"/main/productMaterials/" + id}
      >
        <RiGiftFill />
      </NavCard>

      <NavCard
        cardName={`Product image`}
        navCardLink={"/main/productImages/" + id}
      >
        <RiGiftFill />
      </NavCard>
      <NavCard
        cardName={`Product Cost`}
        navCardLink={"/main/productCost/" + id}
      >
        <RiGiftFill />
      </NavCard>
    </NavCardList>
  );
}

export default ProductsScreen;

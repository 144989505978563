import React, { useState } from 'react'
import PasswordInput from '../../../Partials/Layouts/Forms/FormInputs/PasswordInput/PasswordInput'
import FormSubmitButton from '../../../Partials/Layouts/Forms/FormSubmitButton/FormSubmitButton'
import Form from '../../../Partials/Layouts/Forms/Form'
import { useNavigate, useParams } from 'react-router-dom'
import './RecoverPassword.css'
import useAxiosAuthInstance from '../../../../Utilities/Hooks/AxiosInstanceHooks/useAxiosAuthInstance'
import { RESET_PASSWORD_API } from '../../../../Utilities/APIs/AuthAPIs/AuthAPIs'

function RecoverPassword() {

  const [newPass, setNewPass] = useState('')
  const [newPass2, setNewPass2] = useState('')

  const {token} = useParams()
  const navigate = useNavigate()

  const axiosAuthInstance = useAxiosAuthInstance()

  async function handleSubmit(e) {
      
      e.preventDefault()

      if (newPass !== newPass2) {
          console.log('Passwords Do Not Match')
      }

      const formBody = {
          token,
          newPassword: newPass
      }

      const changePassword = await axiosAuthInstance.patch(RESET_PASSWORD_API, formBody)

      if (changePassword) {
          console.log(changePassword)
          setNewPass('')
          setNewPass2('')
          navigate('/auth/login')
      }

  }
  
  return (
    <div className="recover_pass">
      <div className="form_wrapper">
        <h3>Recover Password</h3>
        <Form
        onSubmit={handleSubmit}
        >
          <PasswordInput
            label='New Password'
            value={newPass}
            placeholder='Enter New Password'
            setState={setNewPass}
          />
          <PasswordInput
            label='Confirm Password'
            value={newPass2}
            placeholder='Re-enter Password'
            setState={setNewPass2}
          />

          <FormSubmitButton text='Recover Password' />

        </Form>
      </div>
    </div>
  )
}

export default RecoverPassword
import { useEffect, useState } from "react"
import useAxiosInstance from '../../../../../Utilities/Hooks/useAxiosInstance';
import Form from "../../../../Partials/Layouts/Forms/Form"
import ShortTextInput from "../../../../Partials/Layouts/Forms/FormInputs/ShortTextInput/ShortTextInput"
import FormSubmitButton from "../../../../Partials/Layouts/Forms/FormSubmitButton/FormSubmitButton"
import { LOYALTY_CLUB_API } from "../../../../../Utilities/APIs/APIs"
import SwitchInput from "../../../../Partials/Layouts/Forms/FormInputs/SwitchInput/SwitchInput"

function UpdateLoyaltyClub({targetID, triggerFetch, setShowUpdateForm}) {

    const axiosInstance = useAxiosInstance()

    const [clubName, setClubName] = useState('')  
    const [points, setPoints] = useState('')
    const [bgColor, setbgColor] = useState('')
    
    useEffect(() => {
        async function fetchAndSetSingleLoyaltyClub() {
          const { data } = await axiosInstance.get(LOYALTY_CLUB_API + 'getSingleLoyaltyClub/' + targetID)
          setClubName(data.clubName)
          setPoints(data.points)
          setbgColor(data.bgColor)
        }
        fetchAndSetSingleLoyaltyClub()

      }, [targetID, axiosInstance])

    async function handleSubmit(e) {

        e.preventDefault()
        const itemData = new FormData()

        itemData.append('clubName', clubName)
        itemData.append('points', points)
        itemData.append('bgColor', bgColor)

        const response = await axiosInstance.patch(LOYALTY_CLUB_API + targetID, itemData)
        if (response) {
            triggerFetch()
            setShowUpdateForm(false)
        }
    }

    return (
        <div className='crate_form'>
            <Form onSubmit={handleSubmit}>
                <div>
                   <ShortTextInput
                        label={`Club Name`}
                        value={clubName}
                        placeholder={`Enter Club Name`}
                        setState={setClubName}
                    />

                    <ShortTextInput
                        label={`Points`}
                        value={points}
                        placeholder={`Enter Points`}
                        setState={setPoints}
                    />

                   <ShortTextInput
                        label={`BG Colors`}
                        value={bgColor}
                        placeholder={`Enter BG Color`}
                        setState={setbgColor}
                    />
                </div>
                <FormSubmitButton text="Submit" />
            </Form>
        </div>
    )
}

export default UpdateLoyaltyClub
import { useState } from "react"
import useAxiosInstance from "../../../../../../Utilities/Hooks/useAxiosInstance"
import { EMPLOYEE_INVITES_API } from "../../../../../../Utilities/APIs/AuthAPIs/AuthAPIs"
import Form from "../../../../../Partials/Layouts/Forms/Form"
import EmailInput from "../../../../../Partials/Layouts/Forms/FormInputs/EmailInput/EmailInput"
import FormSubmitButton from "../../../../../Partials/Layouts/Forms/FormSubmitButton/FormSubmitButton"




function CreateInviteEmployee({ triggerFetch, setShowCreateForm }) {

    const [email, setEmail] = useState('')

    const axiosInstance = useAxiosInstance()


    async function handleSubmit(e) {
        e.preventDefault()

        const itemData = new FormData()
        itemData.append('email', email)

        const response = await axiosInstance.post(EMPLOYEE_INVITES_API + 'employeeinvite', itemData)
        if (response) {
            triggerFetch()
            setShowCreateForm(false)

        }
    }
    return (
        <div className='crate_form'>
            <Form onSubmit={handleSubmit}>
                <div>
                  
                    <EmailInput
                        label={`Email`}
                        value={email}
                        placeholder={`Enter Email`}
                        setState={setEmail}
                    />
                   

                </div>
                <FormSubmitButton text="Send Invite to Email" />
            </Form>
        </div>
    )
}

export default CreateInviteEmployee
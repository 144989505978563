import { useState } from "react";
import useAxiosInstance from "../../../../../../Utilities/Hooks/useAxiosInstance";
import NavCardList from "../../../../../Partials/Layouts/NavCardList/NavCardList";
import NavCard from "../../../../../Partials/Layouts/NavCardList/NavCard/NavCard";
import {
  RiGiftFill, RiPaintBrushFill,
} from "react-icons/ri";

export const ordersFromStatusRoute = "/main/ordersFromStatus/";

function ProductFeature() {
  const axiosInstance = useAxiosInstance();

  const [orders, setOrders] = useState(null);

 

  return (
    <NavCardList numOfCards="five">
      
      <NavCard cardName={`Colour`} navCardLink={'/main/Colors'}> <RiPaintBrushFill  /></NavCard>
      <NavCard
        cardName={`Brands`}
        navCardLink={'/main/Brands'}
      >
        <RiGiftFill />
      </NavCard>
      <NavCard
        cardName={`Cares`}
        navCardLink={'/main/Cares'}
      >
        <RiGiftFill />
      </NavCard>
      <NavCard
        cardName={`Sizes`}
        navCardLink={'/main/Sizes'}
      >
        <RiGiftFill />
      </NavCard>
      <NavCard
        cardName={`Materials`}
        navCardLink={'/main/Materials'}
      >
        <RiGiftFill />
      </NavCard>
      <NavCard
        cardName={`Size Guides`}
        navCardLink={'/main/SizeGuides'}
      >
        <RiGiftFill />
      </NavCard>
    </NavCardList>
  );
}

export default ProductFeature;

import { useEffect, useState } from "react";
import {
  PRODUCTS_API,
  SIZE_API,
  PRODUCTSIZE_API,
} from "../../../../../Utilities/APIs/APIs";
import useAxiosInstance from "../../../../../Utilities/Hooks/useAxiosInstance";
import Form from "../../../../Partials/Layouts/Forms/Form";
import FormSubmitButton from "../../../../Partials/Layouts/Forms/FormSubmitButton/FormSubmitButton";
import SelectInput from "../../../../Partials/Layouts/Forms/FormInputs/SelectInput/SelectInput";
import SelectOption from "../../../../Partials/Layouts/Forms/FormInputs/SelectInput/SelectOption/SelectOption";

function CreateProductSize({ productID, triggerFetch, setShowCreateForm }) {
  const axiosInstance = useAxiosInstance();

  const [size, setSize] = useState("");

  const [sizes, setSizes] = useState(null);

  useEffect(() => {
    async function fetchAndSetSizes() {
      const { data } = await axiosInstance.get(SIZE_API);
      setSizes(data);
    }
    fetchAndSetSizes();
  }, [axiosInstance]);

  async function handleSubmit(e) {
    e.preventDefault();
    const itemData = new FormData();

    itemData.append("product", productID);
    itemData.append("size", size);

    const response = await axiosInstance.post(PRODUCTSIZE_API, itemData);
    if (response) {
      triggerFetch();
      setShowCreateForm(false);
    }
  }

  return (
    <div className="crate_form">
      <Form onSubmit={handleSubmit}>
        <div>
          <SelectInput label={"Size"} value={size} setState={setSize}>
            <SelectOption optionValue="" optionText="Select Size" />
            {sizes?.map((size) => (
              <SelectOption
                optionValue={size._id}
                key={size._id}
                optionText={size?.name}
              />
            ))}
          </SelectInput>
        </div>
        <FormSubmitButton text="Add Product Size" />
      </Form>
    </div>
  );
}

export default CreateProductSize;

import { useEffect, useState } from "react"
import { PRODUCTS_API, CARE_API, PRODUCT_CARE_API } from "../../../../../Utilities/APIs/APIs"
import useAxiosInstance from "../../../../../Utilities/Hooks/useAxiosInstance"
import Form from "../../../../Partials/Layouts/Forms/Form"
import FormSubmitButton from "../../../../Partials/Layouts/Forms/FormSubmitButton/FormSubmitButton"
import SelectInput from "../../../../Partials/Layouts/Forms/FormInputs/SelectInput/SelectInput"
import SelectOption from "../../../../Partials/Layouts/Forms/FormInputs/SelectInput/SelectOption/SelectOption"

function CreateProductCare({productID, triggerFetch, setShowCreateForm }) {

    const axiosInstance = useAxiosInstance()

    const [care, setCare] = useState('')

    const [cares, setCares] = useState(null)

    useEffect(() => {

        async function fetchAndSetCares() {
            const { data } = await axiosInstance.get(CARE_API)
            setCares(data)
        }
        fetchAndSetCares()

    }, [axiosInstance])

    async function handleSubmit(e) {

        e.preventDefault()
        const itemData = new FormData()

        itemData.append('product', productID)
        itemData.append('care', care)

        const response = await axiosInstance.post(PRODUCT_CARE_API, itemData)
        if (response) {
            triggerFetch()
            setShowCreateForm(false)
        }
    }

    return (
        <div className='crate_form'>
            <Form onSubmit={handleSubmit}>
                <div>
                    <SelectInput
                        label={'Care'}
                        value={care}
                        setState={setCare}
                    >
                        <SelectOption optionValue='' optionText="Select Care" />
                        {cares?.map((care => (
                            <SelectOption optionValue={care._id} key={care._id}
                                optionText={care?.name} />
                        )))}
                    </SelectInput>
                  
                </div>
                <FormSubmitButton text="Add Product Care" />
            </Form>
        </div>
    )
}

export default CreateProductCare
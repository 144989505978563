import { useEffect, useRef, useState } from 'react'
import CreateButton from '../../../../Partials/Layouts/CRUDs/CRUDHeader/CreateButton/CreateButton';
import CRUDTable from '../../../../Partials/Layouts/CRUDs/CRUDTable/CRUDTable';
import CRUDTableHeader from '../../../../Partials/Layouts/CRUDs/CRUDTable/CRUDTableHeader/CRUDTableHeader';
import CRUDth from '../../../../Partials/Layouts/CRUDs/CRUDTable/CRUDTableHeader/CRUDth/CRUDth';
import ViewButton from '../../../../Partials/Layouts/CRUDs/CRUDButtons/ViewButton/ViewButton';
import EditButton from '../../../../Partials/Layouts/CRUDs/CRUDButtons/EditButton/EditButton';
import DeleteButton from '../../../../Partials/Layouts/CRUDs/CRUDButtons/DeleteButton/DeleteButton';
import ShortTextCell from '../../../../Partials/Layouts/CRUDs/CRUDTable/CRUDTableCells/ShortTextCell/ShortTextCell';
import CRUDTableRow from '../../../../Partials/Layouts/CRUDs/CRUDTable/CRUDTableRow/CRUDTableRow';
import Modal from '../../../../Partials/Elements/Modal/Modal';
import CreateColor from './CreateColor';
import ViewColor from './ViewColor';
import useAxiosInstance from '../../../../../Utilities/Hooks/useAxiosInstance';
import DeleteItem from '../../../../Partials/Layouts/DeleteItem/DeleteItem';
import UpdateColor from './UpdateColor';
import { COLORS_API } from '../../../../../Utilities/APIs/APIs';


function Colors() {

  const axiosInstance = useAxiosInstance()
  const [colors, setColors] = useState(null)
  const screenHeaerRef = useRef()
  const [screenHeaderHeight, setScreenHeaderHeight] = useState(0);

  useEffect(() => {
    let screenHeaderHeight = screenHeaerRef.current.clientHeight;
    setScreenHeaderHeight(screenHeaderHeight)
  }, [screenHeaerRef]);

  const [showCreateForm, setShowCreateForm] = useState(false);
  const [showViewSection, setShowViewSection] = useState(false);
  const [showUpdateForm, setShowUpdateForm] = useState(false);
  const [showDeleteSection, setShowDeleteSection] = useState(false)
  const [toggleFetch, setToggleFetch] = useState(false)

  const [targetID, setTargetID] = useState(null)

  useEffect(() => {

  async function fetchAndSetColors() {
      const { data } = await axiosInstance.get(COLORS_API)
      setColors(data)
    }
    fetchAndSetColors()

  }, [toggleFetch, axiosInstance])

  function triggerFetch() {
    setToggleFetch(prevState => !prevState)
  }
  return (
    <>
      <div className='screen_header' ref={screenHeaerRef}>
        <h1 className='screen_heading'>Colors ({colors?.length}  in total)</h1>
        <CreateButton
          screenTopicSingular={'Color'}
          setShowCreateForm={setShowCreateForm}
        />
      </div>
      <CRUDTable screenHeaderHeight={screenHeaderHeight}>
        <CRUDTableHeader>
          <CRUDth th='Title' />
          <CRUDth th='Hex Code' />
          <CRUDth th='Actions' />
        </CRUDTableHeader>
        <tbody>
          {
            colors?.map(color =>
              <CRUDTableRow key={color._id}>
                <ShortTextCell text={color.name} />
                <ShortTextCell text={color.hexValue} />
                <td>
                  <div className="action_buttons_wrapper">
                    <div className="action_buttons">
                      <ViewButton
                        setShowViewSection={setShowViewSection}
                        targetID={color._id}
                        setTargetID={setTargetID}
                        tooltipName={"View"} 
                        tooltipContent={"View"}
                      />

                      <EditButton
                        setShowUpdateForm={setShowUpdateForm}
                        targetID={color._id}
                        setTargetID={setTargetID}
                        tooltipName={"Edit"} 
                      tooltipContent={"Edit"}
                      />

                      <DeleteButton
                        setShowDeleteSection={setShowDeleteSection}
                        targetID={color._id}
                        setTargetID={setTargetID}
                        tooltipName={"Delete"} 
                      tooltipContent={"Delete"}
                      />
                    </div>
                  </div>
                </td>
              </CRUDTableRow>

            )
          }
        </tbody>
      </CRUDTable>
      {
        showCreateForm &&
        <Modal
          extraClass={'small'}
          modalHeading={`Add Color`}
          setShowModalContent={setShowCreateForm}
        >
          <CreateColor
            triggerFetch={triggerFetch}
            setShowCreateForm={setShowCreateForm}
          />
        </Modal>
      }
      {
        showUpdateForm &&
        <Modal
          extraClass={'small'}
          modalHeading={`Update Color`}
          setShowModalContent={setShowUpdateForm}
        >
          <UpdateColor
            triggerFetch={triggerFetch}
            targetID={targetID}
            setShowUpdateForm={setShowUpdateForm}
          />
        </Modal>
      }
      {
        showViewSection &&
        <Modal
          extraClass={'small'}
          modalHeading={`View Color`}
          setShowModalContent={setShowViewSection}
        >
          <ViewColor
            targetID={targetID}
          />
        </Modal>
      }
      {
        showDeleteSection &&
        <Modal
          extraClass={'small'}
          modalHeading={`Delete Color`}
          setShowModalContent={setShowDeleteSection}
        >
          <DeleteItem
            api={COLORS_API}
            triggerFetch={triggerFetch}
            targetID={targetID}
            setShowDeleteItem={setShowDeleteSection}
          />
        </Modal>
      }
    </>
  )
}

export default Colors
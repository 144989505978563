import React from "react";
import { useEffect } from "react";
import { useRef } from "react";
import { useState } from "react";
import { EMPLOYEE_API } from "../../../../../../../Utilities/APIs/APIs";
import useAxiosInstance from "../../../../../../../Utilities/Hooks/useAxiosInstance";
import CRUDTable from "../../../../../../Partials/Layouts/CRUDs/CRUDTable/CRUDTable";
import CRUDTableHeader from "../../../../../../Partials/Layouts/CRUDs/CRUDTable/CRUDTableHeader/CRUDTableHeader";
import CRUDth from "../../../../../../Partials/Layouts/CRUDs/CRUDTable/CRUDTableHeader/CRUDth/CRUDth";
import CRUDTableRow from "../../../../../../Partials/Layouts/CRUDs/CRUDTable/CRUDTableRow/CRUDTableRow";
import ShortTextCell from "../../../../../../Partials/Layouts/CRUDs/CRUDTable/CRUDTableCells/ShortTextCell/ShortTextCell";
import CRUDButton from "../../../../../../Partials/Layouts/CRUDs/CRUDButtons/CRUDButton";
import { RiAdminLine } from "react-icons/ri";
import Modal from "../../../../../../Partials/Elements/Modal/Modal";
import MakeEmployee from "../Employees/MakeEmployee/MakeEmployee";

function Admins() {
  const [admins, setAdmins] = useState(null);
  const screenHeaerRef = useRef();
  const [screenHeaderHeight, setScreenHeaderHeight] = useState(0);
  const [showMakeEmployeeSection, setShowMakeEmployeeSection] = useState(false)
  useEffect(() => {
    let screenHeaderHeight = screenHeaerRef.current.clientHeight;
    setScreenHeaderHeight(screenHeaderHeight);
  }, [screenHeaerRef]);

  const [toggleFetch, setToggleFetch] = useState(false);

  const [targetID, setTargetID] = useState(null);
  const axiosInstance = useAxiosInstance();

  useEffect(() => {
    async function fetchAndSetAdmin() {
      const { data } = await axiosInstance.get(EMPLOYEE_API + "getAllAdmins");
      setAdmins(data);
    }
    fetchAndSetAdmin();
  }, [toggleFetch, axiosInstance]);

  function triggerFetch() {
    setToggleFetch((prevState) => !prevState);
  }

  return (
    <>
      <div className="screen_header" ref={screenHeaerRef}>
        <h1 className="screen_heading">
          Employees ({admins?.length} in total)
        </h1>
      </div>
      <CRUDTable screenHeaderHeight={screenHeaderHeight}>
        <CRUDTableHeader>
          <CRUDth th="Name" />
          <CRUDth th="Email" />
          <CRUDth th="Level" />
          <CRUDth th="Action" />
        </CRUDTableHeader>
        <tbody>
          {admins?.map((admin) => (
            <CRUDTableRow key={admin._id}>
              <ShortTextCell text={admin?.name} />
              <ShortTextCell text={admin?.email} />
              <ShortTextCell text={admin?.level} />
              <td>
                <div className="action_buttons_wrapper">
                  <div className="action_buttons">
                  <CRUDButton
                      handleClick={() => {
                        setShowMakeEmployeeSection(true);
                        setTargetID(admin._id);
                      }}
                    >
                      <RiAdminLine />
                    </CRUDButton>
                  </div>
                </div>
              </td>
            </CRUDTableRow>
          ))}
        </tbody>
      </CRUDTable>
      {
          showMakeEmployeeSection &&
          <Modal
            extraClass={'small'}
            modalHeading={`Make Admin`}
            setShowModalContent={setShowMakeEmployeeSection}
          >
            <MakeEmployee
            setShowMakeEmployeeSection={setShowMakeEmployeeSection}
            triggerFetch={triggerFetch}
              targetID={targetID}
            />
          </Modal>
        }
    </>
  );
}

export default Admins;

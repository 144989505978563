import { useState } from "react";

import { ORDERS_API } from "../../../../../Utilities/APIs/APIs";
import SelectInput from "../../../../Partials/Layouts/Forms/FormInputs/SelectInput/SelectInput";
import SelectOption from "../../../../Partials/Layouts/Forms/FormInputs/SelectInput/SelectOption/SelectOption";
import useAxiosInstance from "../../../../../Utilities/Hooks/useAxiosInstance";
import Form from "../../../../Partials/Layouts/Forms/Form";
import FormSubmitButton from "../../../../Partials/Layouts/Forms/FormSubmitButton/FormSubmitButton";

function AddShippingMedia({ targetID, triggerFetch, setShowShippingMedia }) {
  const axiosInstance = useAxiosInstance();

  const [shippingMediaType, setShippingMediaType] = useState("");

  //   useEffect(() => {
  //     async function fetchAndSetSingleFAQ() {
  //       const { data } = await axiosInstance.get( FAQ_API + "getSingleFAQ/" + targetID);
  //       setTitle(data.title);
  //       setDescription(data.description);
  //       setisActive(data.isActive);
  //     }
  //     fetchAndSetSingleFAQ();
  //   }, [targetID, axiosInstance]);

  async function handleSubmit(e) {
    e.preventDefault();
    const itemData = { shippingMediaType };

    const response = await axiosInstance.patch(
      ORDERS_API + "addShippingMedia/" + targetID,
      itemData
    );
    if (response) {
      triggerFetch();
      setShowShippingMedia(false);
    }
  }

  return (
    <div className="crate_form">
      <Form onSubmit={handleSubmit}>
        <div>
          <SelectInput
            label={"Order Status"}
            value={shippingMediaType}
            setState={setShippingMediaType}
          >
            <SelectOption optionValue="" optionText="Select Shipping Media" />
            <SelectOption optionValue="pathao" optionText="Pathao" />
            <SelectOption optionValue="rapido" optionText="Rapido" />
            <SelectOption optionValue="bahon" optionText="Bahon" />
            <SelectOption
              optionValue="internalDelivery"
              optionText="Internal Delivery"
            />
          </SelectInput>
        </div>
        <FormSubmitButton text="Submit" />
      </Form>
    </div>
  );
}

export default AddShippingMedia;

import { useEffect, useState } from "react"
import { PRODUCTS_API, PAIREDPRODUCT_API } from "../../../../../Utilities/APIs/APIs"
import useAxiosInstance from "../../../../../Utilities/Hooks/useAxiosInstance"
import Form from "../../../../Partials/Layouts/Forms/Form"
import FormSubmitButton from "../../../../Partials/Layouts/Forms/FormSubmitButton/FormSubmitButton"
import SelectInput from "../../../../Partials/Layouts/Forms/FormInputs/SelectInput/SelectInput"
import SelectOption from "../../../../Partials/Layouts/Forms/FormInputs/SelectInput/SelectOption/SelectOption"

function CreatePairedProduct({productID, triggerFetch, setShowCreateForm }) {

    const axiosInstance = useAxiosInstance()
    const [product2, setProduct2] = useState('')

    const [products, setProducts] = useState(null)

    useEffect(() => {

        async function fetchAndSetPairedProducts() {
            const { data } = await axiosInstance.get(PRODUCTS_API)
            const filterData = data?.filter(d => d._id !== productID)
            setProducts(filterData)
        }
        fetchAndSetPairedProducts()

    }, [axiosInstance, productID])

    async function handleSubmit(e) {

        e.preventDefault()
        const itemData = new FormData()

        itemData.append('product1', productID)
        itemData.append('product2', product2)

        const response = await axiosInstance.post(PAIREDPRODUCT_API, itemData)
        if (response) {
            triggerFetch()
            setShowCreateForm(false)
        }
    }

    return (
        <div className='crate_form'>
            <Form onSubmit={handleSubmit}>
                <div>
                    <SelectInput
                        label={'Paired Product'}
                        value={product2}
                        setState={setProduct2}
                    >
                        <SelectOption optionValue='' optionText="Select Paired Product" />
                        {products?.map((product => (
                            <SelectOption optionValue={product._id 
                            } key={product._id}
                                optionText={product?.name} />
                        )))}
                    </SelectInput>
                  
                </div>
                <FormSubmitButton text="Add Paired Product" />
            </Form>
        </div>
    )
}

export default CreatePairedProduct
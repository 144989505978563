import React, { useEffect, useRef, useState } from 'react'
import DeleteItem from '../../../../../Partials/Layouts/DeleteItem/DeleteItem';
import { CHANGED_INVENTORIES_API } from '../../../../../../Utilities/APIs/APIs';
import Modal from '../../../../../Partials/Elements/Modal/Modal';
import CreateButton from '../../../../../Partials/Layouts/CRUDs/CRUDHeader/CreateButton/CreateButton';
import useAxiosInstance from '../../../../../../Utilities/Hooks/useAxiosInstance';
import CRUDTable from '../../../../../Partials/Layouts/CRUDs/CRUDTable/CRUDTable';
import CRUDth from '../../../../../Partials/Layouts/CRUDs/CRUDTable/CRUDTableHeader/CRUDth/CRUDth';
import CRUDTableHeader from '../../../../../Partials/Layouts/CRUDs/CRUDTable/CRUDTableHeader/CRUDTableHeader';
import CRUDTableRow from '../../../../../Partials/Layouts/CRUDs/CRUDTable/CRUDTableRow/CRUDTableRow';
import ShortTextCell from '../../../../../Partials/Layouts/CRUDs/CRUDTable/CRUDTableCells/ShortTextCell/ShortTextCell';
import ViewButton from '../../../../../Partials/Layouts/CRUDs/CRUDButtons/ViewButton/ViewButton';
import CreateChangedInventories from './CreateChangedInventories';
import ViewChangedInventories from './ViewChangedInventories';
import UpdateChangedInventories from './UpdateChangedInventories';
import { useParams } from 'react-router-dom';

function ChangedInventories() {
    const [changedInventories, setChangedInventories] = useState(null);
    const screenHeaerRef = useRef();
    const [screenHeaderHeight, setScreenHeaderHeight] = useState(0);
  
    useEffect(() => {
      let screenHeaderHeight = screenHeaerRef.current.clientHeight;
      setScreenHeaderHeight(screenHeaderHeight);
    }, [screenHeaerRef]);
  
    const [showCreateForm, setShowCreateForm] = useState(false);
    const [showViewSection, setShowViewSection] = useState(false);
    const [showUpdateForm, setShowUpdateForm] = useState(false);
    const [showDeleteSection, setShowDeleteSection] = useState(false);
    const [toggleFetch, setToggleFetch] = useState(false);
  
    const [targetID, setTargetID] = useState(null);
    const axiosInstance = useAxiosInstance();
  const {id} =useParams()

  
    useEffect(() => {
      async function fetchAndsetChangedInventories() {
        const { data } = await axiosInstance.get(CHANGED_INVENTORIES_API + 'getChangedInventories/' + id);
        setChangedInventories(data);
      }
      fetchAndsetChangedInventories();
    }, [id,toggleFetch, axiosInstance]);
  
    function triggerFetch() {
      setToggleFetch((prevState) => !prevState);
    }
    return (
      <>
        <div className="screen_header" ref={screenHeaerRef}>
          <h1 className="screen_heading">
            Changed Inventories ({changedInventories?.length} in total)
          </h1>
          <CreateButton
            screenTopicSingular={"Changed Inventories"}
            setShowCreateForm={setShowCreateForm}
          />
        </div>
        <CRUDTable screenHeaderHeight={screenHeaderHeight}>
          <CRUDTableHeader>
            <CRUDth th="Quantity" />
            <CRUDth th="Date" />
            <CRUDth th="Change Type" />
            <CRUDth th="Action" />
          </CRUDTableHeader>
          <tbody>
       
      
            {changedInventories?.map((changedInventory) => (
              <CRUDTableRow key={changedInventory._id}>
                <ShortTextCell text={changedInventory?.quantity} />
                <ShortTextCell text={new Date(changedInventory?.date).toLocaleDateString()}/>
                <ShortTextCell text={changedInventory?.changeType} />
                <td>
                  <div className="action_buttons_wrapper">
                    <div className="action_buttons">
                      <ViewButton
                        setShowViewSection={setShowViewSection}
                        targetID={changedInventory._id}
                        setTargetID={setTargetID}
                      />
  
                      {/* <EditButton
                        setShowUpdateForm={setShowUpdateForm}
                        targetID={changedInventory._id}
                        setTargetID={setTargetID}
                      /> */}
  
                      {/* <DeleteButton
                        setShowDeleteSection={setShowDeleteSection}
                        targetID={changedInventory._id}
                        setTargetID={setTargetID}
                      /> */}
                    </div>
                  </div>
                </td>
              </CRUDTableRow>
            ))}
          </tbody>
        </CRUDTable>
        {showCreateForm && (
          <Modal
            extraClass={"small"}
            modalHeading={`Add Inventories`}
            setShowModalContent={setShowCreateForm}
          >
            <CreateChangedInventories
            id={id}
              triggerFetch={triggerFetch}
              setShowCreateForm={setShowCreateForm}
            />
          </Modal>
        )}
        {showUpdateForm && (
          <Modal
            extraClass={"small"}
            modalHeading={`Update Inventories`}
            setShowModalContent={setShowUpdateForm}
          >
            <UpdateChangedInventories
              triggerFetch={triggerFetch}
              targetID={targetID}
              setShowUpdateForm={setShowUpdateForm}
            />
          </Modal>
        )}
        {showViewSection && (
          <Modal
            extraClass={"small"}
            modalHeading={`View Inventories`}
            setShowModalContent={setShowViewSection}
          >
            <ViewChangedInventories targetID={targetID} />
          </Modal>
        )}
        {showDeleteSection && (
          <Modal
            extraClass={"small"}
            modalHeading={`Delete PInventories`}
            setShowModalContent={setShowDeleteSection}
          >
            <DeleteItem
              api={CHANGED_INVENTORIES_API}
              triggerFetch={triggerFetch}
              targetID={targetID}
              setShowDeleteItem={setShowDeleteSection}
            />
          </Modal>
        )}
      </>
    );
  }
export default ChangedInventories
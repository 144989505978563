import { Outlet, useLocation, useNavigate } from "react-router-dom";
import ScreenHolder from "./Partials/ScreenHolder/ScreenHolder";
import Sidebar from "./Partials/Sections/Sidebar/Sidebar";
import axios from "axios";
import AppContext from "../AppContext/AppContext";
import { useContext, useEffect } from "react";
import "./App.css";

function App() {
  const { employee, logout } = useContext(AppContext);
  const navigate = useNavigate();

  const { pathname } = useLocation();

  useEffect(() => {
    if (pathname === "/") {
      navigate("/main/dashboard");
    }
  }, [employee, navigate, pathname]);

  axios.interceptors.response.use(
    (res) => {
      return res;
    },
    (err) => {
      if (err?.response?.status === 401) {
        logout();
      }
      return Promise.reject(err);
    }
  );

  return (
    <main className={`main ${employee ? "employee" : "auth"}`}>
      {employee && <Sidebar />}
      <ScreenHolder>
        <Outlet />
      </ScreenHolder>
    </main>
  );
}

export default App;

import React, { useEffect, useRef, useState } from 'react'
import CreateButton from '../../../../Partials/Layouts/CRUDs/CRUDHeader/CreateButton/CreateButton'
import CRUDTable from '../../../../Partials/Layouts/CRUDs/CRUDTable/CRUDTable'
import CRUDTableHeader from '../../../../Partials/Layouts/CRUDs/CRUDTable/CRUDTableHeader/CRUDTableHeader'
import CRUDth from '../../../../Partials/Layouts/CRUDs/CRUDTable/CRUDTableHeader/CRUDth/CRUDth'
import CRUDTableRow from '../../../../Partials/Layouts/CRUDs/CRUDTable/CRUDTableRow/CRUDTableRow'
import ShortTextCell from '../../../../Partials/Layouts/CRUDs/CRUDTable/CRUDTableCells/ShortTextCell/ShortTextCell'
import ViewButton from '../../../../Partials/Layouts/CRUDs/CRUDButtons/ViewButton/ViewButton'
import EditButton from '../../../../Partials/Layouts/CRUDs/CRUDButtons/EditButton/EditButton'
import DeleteButton from '../../../../Partials/Layouts/CRUDs/CRUDButtons/DeleteButton/DeleteButton'
import useAxiosInstance from '../../../../../Utilities/Hooks/useAxiosInstance'
import {PROMOTIONS_API } from '../../../../../Utilities/APIs/APIs'
import DeleteItem from '../../../../Partials/Layouts/DeleteItem/DeleteItem'
import Modal from '../../../../Partials/Elements/Modal/Modal'
import CreatePromotion from './CreatePromotion'
import ViewPromotion from './ViewPromotion'
import UpdatePromotion from './UpdatePromotion'
import Pagination from '../../../../Partials/Elements/pagination/Pagination'

function Promotions() {
    const [promotions, setPromotions] = useState([]);
    const screenHeaerRef = useRef();
    const [screenHeaderHeight, setScreenHeaderHeight] = useState(0);
    const [limit, setLimit] = useState(40);
  const [totalitems, setTotalItems] = useState(0);
  const [totalPages, setTotalPages] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  
    useEffect(() => {
      let screenHeaderHeight = screenHeaerRef.current.clientHeight;
      setScreenHeaderHeight(screenHeaderHeight);
    }, [screenHeaerRef]);
  
    const [showCreateForm, setShowCreateForm] = useState(false);
    const [showViewSection, setShowViewSection] = useState(false);
    const [showUpdateForm, setShowUpdateForm] = useState(false);
    const [showDeleteSection, setShowDeleteSection] = useState(false);
    const [toggleFetch, setToggleFetch] = useState(false);
  
    const [targetID, setTargetID] = useState(null);
    const axiosInstance = useAxiosInstance();
  
    useEffect(() => {
      async function fetchAndSetProductCollections() {
        const { data } = await axiosInstance.get(`${PROMOTIONS_API}?page=${currentPage}&limit=${limit}` );
        console.log("data:", data);
        setTotalItems(data?.totalPromotions)
        setTotalPages(data?.totalPage)
        setPromotions(data?.promotions);
      }
      fetchAndSetProductCollections();
    }, [toggleFetch, axiosInstance, limit, currentPage]);
  
    function triggerFetch() {
      setToggleFetch((prevState) => !prevState);
    }
  return (
    <>
      <div className="screen_header" ref={screenHeaerRef}>
        <h1 className="screen_heading">
        promotions ({totalitems} in total)
        </h1>
        <CreateButton
          screenTopicSingular={"Promotions"}
          setShowCreateForm={setShowCreateForm}
        />
      </div>
      <CRUDTable screenHeaderHeight={screenHeaderHeight}>
        <CRUDTableHeader>
          <CRUDth th="promoCode" />
          <CRUDth th="maxlimit" />
          <CRUDth th="discountType" />
          <CRUDth th="Actions" />
        </CRUDTableHeader>

        <tbody>
          {promotions?.map((promotion ) => (
            <CRUDTableRow key={promotion._id}>
              <ShortTextCell text={promotion?.promoCode} />
              <ShortTextCell text={promotion?.maxlimit} /> 
              <ShortTextCell text={promotion?.discountType} /> 
              <td>
                <div className="action_buttons_wrapper">
                  <div className="action_buttons">
                    <ViewButton
                      setShowViewSection={setShowViewSection}
                      targetID={promotion._id}
                      setTargetID={setTargetID}
                      tooltipName={"View"} 
                        tooltipContent={"View"}
                    />

                    <EditButton
                      setShowUpdateForm={setShowUpdateForm}
                      targetID={promotion._id}
                      setTargetID={setTargetID}
                      tooltipName={"Edit"} 
                        tooltipContent={"Edit"}
                    />

                    <DeleteButton
                      setShowDeleteSection={setShowDeleteSection}
                      targetID={promotion._id}
                      setTargetID={setTargetID}
                      tooltipName={"Delete"} 
                        tooltipContent={"Delete"}
                    />
                  </div>
                </div>
              </td>
            </CRUDTableRow>
          ))}
        </tbody>
      <Pagination items={promotions} totalPages={totalPages} limits={limit} currentPage={currentPage} setCurrentPage={setCurrentPage} />
      </CRUDTable>

      {showCreateForm && (
        <Modal
          extraClass={"small"}
          modalHeading={`Add Promotion`}
          setShowModalContent={setShowCreateForm}
        >
          <CreatePromotion
            triggerFetch={triggerFetch}
            setShowCreateForm={setShowCreateForm}
          />
        </Modal>
      )}
      {showUpdateForm && (
        <Modal
          extraClass={"small"}
          modalHeading={`Update Featured Collection`}
          setShowModalContent={setShowUpdateForm}
        >
          <UpdatePromotion
            triggerFetch={triggerFetch}
            targetID={targetID}
            setShowUpdateForm={setShowUpdateForm}
          />
        </Modal>
      )}
      {showViewSection && (
        <Modal
          extraClass={"small"}
          modalHeading={`View product Collection Name`}
          setShowModalContent={setShowViewSection}
        >
          <ViewPromotion targetID={targetID} />
        </Modal>
      )}
      {showDeleteSection && (
        <Modal
          extraClass={"small"}
          modalHeading={`Delete promotion`}
          setShowModalContent={setShowDeleteSection}
        >
          <DeleteItem
            api={PROMOTIONS_API}
            triggerFetch={triggerFetch}
            targetID={targetID}
            setShowDeleteItem={setShowDeleteSection}
          />
        </Modal>
      )}
    </>
  )
}

export default Promotions
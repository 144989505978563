import { useState } from "react"

import {   PRODUCT_IMAGES_API } from "../../../../../Utilities/APIs/APIs"
import useAxiosInstance from "../../../../../Utilities/Hooks/useAxiosInstance"
import Form from "../../../../Partials/Layouts/Forms/Form"
import ShortTextInput from "../../../../Partials/Layouts/Forms/FormInputs/ShortTextInput/ShortTextInput"
import FormSubmitButton from "../../../../Partials/Layouts/Forms/FormSubmitButton/FormSubmitButton"
import SwitchInput from "../../../../Partials/Layouts/Forms/FormInputs/SwitchInput/SwitchInput"
import ImageInput from "../../../../Partials/Layouts/Forms/FormInputs/ImageInput/ImageInput"


function CreateProductImage({ productID, triggerFetch, setShowCreateForm }) {

    const axiosInstance = useAxiosInstance()

    const [image, setImage] = useState('')
    const [precedence, setPrecedence] = useState('')
    const [isActive, setIsActive] = useState(false)


    async function handleSubmit(e) {

        e.preventDefault()
        const itemData = new FormData()

        itemData.append('product', productID)
        itemData.append('image', image)
        itemData.append('precedence', precedence)
        itemData.append('isActive', isActive)


        const response = await axiosInstance.post(PRODUCT_IMAGES_API, itemData)
        if (response) {
            triggerFetch()
            setShowCreateForm(false)
        }
    }

    return (
        <div className='crate_form'>
            <Form onSubmit={handleSubmit}>
                <div>
                    <ImageInput
                        fieldId="1"
                        state={image}
                        setState={setImage}
                        allowCreateImage
                    >
                        Upload Card Image
                    </ImageInput>
                    <ShortTextInput
                        label={`Precedence`}
                        value={precedence}
                        placeholder={`Precedence`}
                        setState={setPrecedence}
                    />
                    <SwitchInput
                        label={`Is Active`}
                        toggleSwitch={() => setIsActive(prevState => !prevState)}
                        checked={isActive}
                    />


                </div>
                <FormSubmitButton text="Create Product Image" />
            </Form>
        </div>
    )
}

export default CreateProductImage
import "./Modal.css";
import ModalHeader from "./ModalHeader/ModalHeader";

function Modal({
  modalHeading,
  children,
  extraClass,
  setShowModalContent,
  contentStyle,
  bodyStyle,
}) {
  return (
    <div className={`modal ${extraClass}`}>
      <div
        className="modal_backdrop"
        onClick={() => setShowModalContent(false)}
      ></div>
      <div
        className={`modal_dialog modal_dialog_centered modal_dialog_scrollable`}
      >
        <div className="modal_content" style={{ ...contentStyle }}>
          <ModalHeader
            modalHeading={modalHeading}
            setShowModalContent={setShowModalContent}
          />
          <div className="modal_body" style={{ ...bodyStyle }}>
            {children}
          </div>
        </div>
      </div>
    </div>
  );
}

export default Modal;

import React, { useEffect, useRef, useState } from "react";
import useAxiosInstance from "../../../../../Utilities/Hooks/useAxiosInstance";
import { useParams } from "react-router-dom";
import {
  PRODUCT_COST,
  PRODUCT_IMAGES_API,
} from "../../../../../Utilities/APIs/APIs";
import CreateButton from "../../../../Partials/Layouts/CRUDs/CRUDHeader/CreateButton/CreateButton";
import CRUDTable from "../../../../Partials/Layouts/CRUDs/CRUDTable/CRUDTable";
import CRUDTableHeader from "../../../../Partials/Layouts/CRUDs/CRUDTable/CRUDTableHeader/CRUDTableHeader";
import CRUDth from "../../../../Partials/Layouts/CRUDs/CRUDTable/CRUDTableHeader/CRUDth/CRUDth";
import CRUDTableRow from "../../../../Partials/Layouts/CRUDs/CRUDTable/CRUDTableRow/CRUDTableRow";
import ImageCell from "../../../../Partials/Layouts/CRUDs/CRUDTable/CRUDTableCells/ImageCell/ImageCell";
import ViewButton from "../../../../Partials/Layouts/CRUDs/CRUDButtons/ViewButton/ViewButton";
import EditButton from "../../../../Partials/Layouts/CRUDs/CRUDButtons/EditButton/EditButton";
import DeleteButton from "../../../../Partials/Layouts/CRUDs/CRUDButtons/DeleteButton/DeleteButton";
import Modal from "../../../../Partials/Elements/Modal/Modal";
import CreateProductCost from "./CreateProductCost";
import ShortTextCell from "../../../../Partials/Layouts/CRUDs/CRUDTable/CRUDTableCells/ShortTextCell/ShortTextCell";
import UpdateProductCost from "./UpdateProductCost";
import ViewProductCost from "./ViewProductCost";
import DeleteItem from "../../../../Partials/Layouts/DeleteItem/DeleteItem";

function ProductCost() {
  const [productCost, setProductCost] = useState(null);
  const screenHeaerRef = useRef();
  const [screenHeaderHeight, setScreenHeaderHeight] = useState(0);

  useEffect(() => {
    let screenHeaderHeight = screenHeaerRef.current.clientHeight;
    setScreenHeaderHeight(screenHeaderHeight);
  }, [screenHeaerRef]);

  const [showCreateForm, setShowCreateForm] = useState(false);
  const [showViewSection, setShowViewSection] = useState(false);
  const [showUpdateForm, setShowUpdateForm] = useState(false);
  const [showDeleteSection, setShowDeleteSection] = useState(false);
  const [toggleFetch, setToggleFetch] = useState(false);

  const [targetID, setTargetID] = useState(null);
  const axiosInstance = useAxiosInstance();

  const { productID } = useParams();

  useEffect(() => {
    async function fetchAndSetProducts() {
      const { data } = await axiosInstance.get(
        PRODUCT_COST + "getManageCostByProduct/" + productID
      );
      setProductCost(data);
    }
    fetchAndSetProducts();
  }, [toggleFetch, axiosInstance, productID]);

  function triggerFetch() {
    setToggleFetch((prevState) => !prevState);
  }
  return (
    <>
      <div className="screen_header" ref={screenHeaerRef}>
        <h1 className="screen_heading">
          Product Cost({productCost?.length} in total)
        </h1>
        <CreateButton
          screenTopicSingular={"Product Cost"}
          setShowCreateForm={setShowCreateForm}
        />
      </div>
      <CRUDTable screenHeaderHeight={screenHeaderHeight}>
        <CRUDTableHeader>
          <CRUDth th="Product" />
          <CRUDth th="Unit Cost" />
          <CRUDth th="Total Cost" />
          <CRUDth th="Action" />
        </CRUDTableHeader>
        <tbody>
          {productCost?.map((product) => (
            <CRUDTableRow key={product._id}>
              <ShortTextCell text={product?.product?.name} />
              <ShortTextCell text={product?.unitCost} />
              <ShortTextCell text={product?.totalCost} />
              <td>
                <div className="action_buttons_wrapper">
                  <div className="action_buttons">
                    <ViewButton
                      setShowViewSection={setShowViewSection}
                      targetID={product._id}
                      setTargetID={setTargetID}
                    />

                    <EditButton
                      setShowUpdateForm={setShowUpdateForm}
                      targetID={product._id}
                      setTargetID={setTargetID}
                    />

                    <DeleteButton
                      setShowDeleteSection={setShowDeleteSection}
                      targetID={product._id}
                      setTargetID={setTargetID}
                    />
                  </div>
                </div>
              </td>
            </CRUDTableRow>
          ))}
        </tbody>
      </CRUDTable>
      {showCreateForm && (
        <Modal
          extraClass={"small"}
          modalHeading={`Add Product`}
          setShowModalContent={setShowCreateForm}
        >
          <CreateProductCost
            productID={productID}
            triggerFetch={triggerFetch}
            setShowCreateForm={setShowCreateForm}
          />
        </Modal>
      )}

      {showUpdateForm && (
        <Modal
          extraClass={"small"}
          modalHeading={`Update Product Cost`}
          setShowModalContent={setShowUpdateForm}
        >
          <UpdateProductCost
            productID={productID}
            triggerFetch={triggerFetch}
            targetID={targetID}
            setShowUpdateForm={setShowUpdateForm}
          />
        </Modal>
      )}

      {showViewSection && (
        <Modal
          extraClass={"small"}
          modalHeading={`View Product Cost`}
          setShowModalContent={setShowViewSection}
        >
          <ViewProductCost targetID={targetID} />
        </Modal>
      )}

      {showDeleteSection && (
        <Modal
          extraClass={"small"}
          modalHeading={`Delete Product Cost`}
          setShowModalContent={setShowDeleteSection}
        >
          <DeleteItem
            api={PRODUCT_COST}
            triggerFetch={triggerFetch}
            targetID={targetID}
            setShowDeleteItem={setShowDeleteSection}
          />
        </Modal>
      )}
    </>
  );
}

export default ProductCost;

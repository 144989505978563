import { useState } from "react"
import useAxiosInstance from "../../../../../Utilities/Hooks/useAxiosInstance"
import Form from "../../../../Partials/Layouts/Forms/Form"
import ShortTextInput from "../../../../Partials/Layouts/Forms/FormInputs/ShortTextInput/ShortTextInput"
import FormSubmitButton from "../../../../Partials/Layouts/Forms/FormSubmitButton/FormSubmitButton"
import { ROLES_API } from "../../../../../Utilities/APIs/APIs"

import slugify from "react-slugify"

function CreateRole({triggerFetch, setShowCreateForm}) {

    const axiosInstance = useAxiosInstance()

    const [name, setName] = useState('')  
    const [description, setDescription] = useState('')

    async function handleSubmit(e) {

        e.preventDefault()
        const itemData = new FormData()

        itemData.append('name', name)
        itemData.append('slug', slugify(name))
        itemData.append('description', description)

        const response = await axiosInstance.post(ROLES_API, itemData)
        if (response) {
            triggerFetch()
            setShowCreateForm(false)
        }
    }

    return (
        <div className='crate_form'>
            <Form onSubmit={handleSubmit}>
                <div>

                    <ShortTextInput
                        label={`Role Name`}
                        value={name}
                        placeholder={`Role Name`}
                        setState={setName}
                    />
                    <ShortTextInput
                        label={`Description`}
                        value={description}
                        placeholder={`Description`}
                        setState={setDescription}
                    />
                    <ShortTextInput
                        label={`Slug`}
                        value={slugify(name)}
                        placeholder={`Slug`}
                        disabled
                    />

                </div>
                <FormSubmitButton text="Submit" />
            </Form>
        </div>
    )
}

export default CreateRole
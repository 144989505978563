import { useEffect, useState } from "react"
import useAxiosInstance from '../../../../../Utilities/Hooks/useAxiosInstance';

import Form from "../../../../Partials/Layouts/Forms/Form"
import ShortTextInput from "../../../../Partials/Layouts/Forms/FormInputs/ShortTextInput/ShortTextInput"
import SwitchInput from "../../../../Partials/Layouts/Forms/FormInputs/SwitchInput/SwitchInput"
import FormSubmitButton from "../../../../Partials/Layouts/Forms/FormSubmitButton/FormSubmitButton"
import { COLORS_API } from "../../../../../Utilities/APIs/APIs"

function UpdateColor({targetID, triggerFetch, setShowUpdateForm}) {

    const axiosInstance = useAxiosInstance()

    const [name, setName] = useState('')  
    const [hexValue, sethexValue] = useState('')
    const [isActive, setIsActive] = useState('')   
    
    useEffect(() => {
        async function fetchAndSetSingleColor() {
          const { data } = await axiosInstance.get(COLORS_API + 'getSingleColor/' + targetID)
          setName(data.name)
          sethexValue(data.hexValue)
          setIsActive(data.isActive)
        }
        fetchAndSetSingleColor()

      }, [targetID, axiosInstance])

    async function handleSubmit(e) {

        e.preventDefault()
        const itemData = new FormData()

        itemData.append('name', name)
        itemData.append('hexValue', hexValue)
        itemData.append('isActive', isActive)

        const response = await axiosInstance.patch(COLORS_API + targetID, itemData)
        if (response) {
            triggerFetch()
            setShowUpdateForm(false)
        }
    }

    return (
        <div className='crate_form'>
            <Form onSubmit={handleSubmit}>
                <div>
                    <ShortTextInput
                        label={`Title`}
                        value={name}
                        placeholder={`Enter Title`}
                        setState={setName}
                    />
                    <ShortTextInput
                        label={`Hex Value`}
                        value={hexValue}
                        placeholder={`Enter Hex Value`}
                        setState={sethexValue}
                    />
                     <SwitchInput
                        label={`Status`}
                        toggleSwitch={() => setIsActive(prevState => !prevState)}
                        checked={isActive}
                    />
                </div>
                <FormSubmitButton text="Submit" />
            </Form>
        </div>
    )
}

export default UpdateColor
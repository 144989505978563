import { useState } from "react"
import Form from "../../../../Partials/Layouts/Forms/Form"
import ShortTextInput from "../../../../Partials/Layouts/Forms/FormInputs/ShortTextInput/ShortTextInput"
import FormSubmitButton from "../../../../Partials/Layouts/Forms/FormSubmitButton/FormSubmitButton"
import { SLIDERS_API } from "../../../../../Utilities/APIs/APIs"
import ImageInput from "../../../../Partials/Layouts/Forms/FormInputs/ImageInput/ImageInput"
import useAxiosInstance from "../../../../../Utilities/Hooks/useAxiosInstance"

function CreateSlider({triggerFetch, setShowCreateForm}) {

    const axiosInstance = useAxiosInstance()

    const [title, setTitle] = useState('')  
    const [subTitle, setSubTitle] = useState('')
    const [image, setImage] = useState('')
    const [linkProduct, setLinkProduct] = useState('')   

    async function handleSubmit(e) {

        e.preventDefault()
        const itemData = new FormData()

        itemData.append('title', title)
        itemData.append('subTitle', subTitle)
        itemData.append('image', image)
        itemData.append('linkProduct', linkProduct)

        const response = await axiosInstance.post(SLIDERS_API, itemData)
        if (response) {
            triggerFetch()
            setShowCreateForm(false)

        }
    }

    return (
        <div className='crate_form'>
            <Form onSubmit={handleSubmit}>
                <div>
                    <ShortTextInput
                        label={`Title`}
                        value={title}
                        placeholder={`Enter Title`}
                        setState={setTitle}
                    />
                    <ShortTextInput
                        label={`Sub Title`}
                        value={subTitle}
                        placeholder={`Enter Sub Title`}
                        setState={setSubTitle}
                    />
                     <ImageInput
                        fieldId="1"
                        state={image}
                        setState={setImage}
                        allowCreateImage
                    >
                        Upload Card Image
                    </ImageInput>
                    <ShortTextInput
                        label={`Product Link`}
                        value={linkProduct}
                        placeholder={`Enter Product Link`}
                        setState={setLinkProduct}
                    />
                </div>
                <FormSubmitButton text="Submit" />
            </Form>
        </div>
    )
}

export default CreateSlider
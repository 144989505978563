import axios from "axios";

function useAxiosAuthInstance() {
  console.log(process.env.REACT_APP_BACKEND_URL);
  const axiosAuthInstance = axios.create({
    baseURL: process.env.REACT_APP_BACKEND_URL + "api/public/employeeAuth/",
  });

  return axiosAuthInstance;
}

export default useAxiosAuthInstance;

// import axios from "axios";
// import { useContext, useMemo } from "react";
// import AppContext from "../../../AppContext/AppContext";

// function useAxiosAuthInstance() {
//   const { customer, logout } = useContext(AppContext);
//   //   console.log(process.env.REACT_APP_BACKEND_URL);
//   //   const axiosAuthInstance = axios.create({
//   //     baseURL: process.env.REACT_APP_BACKEND_URL + "api/public/employeeAuth/",
//   //   });

//   //   return axiosAuthInstance;

//   const axiosInstance = useMemo(() => {
//     const axiosAuthInstance = axios.create({
//       baseURL: process.env.REACT_APP_BACKEND_URL + "api/public/employeeAuth/",
//       headers: {
//         Authorization: customer ? `Bearer ${customer.token}` : "",
//       },
//     });

//     axiosAuthInstance.interceptors.response.use(
//       (response) => response,
//       (error) => {
//         if (error.response && error.response.status === 401) {
//           logout();
//         }
//         return Promise.reject(error);
//       }
//     );

//     return axiosAuthInstance;
//   }, [customer, logout]);

//   return axiosInstance;
// }

// export default useAxiosAuthInstance;

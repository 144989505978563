import { RiFileEditLine } from "react-icons/ri"
import CRUDButton from "../CRUDButton"


function EditButton({ setShowUpdateForm, setTargetID, targetID,  tooltipContent, tooltipName }) {
    return (
        <CRUDButton
            handleClick={() => {
                setShowUpdateForm(true)
                setTargetID(targetID)
            }}
            tooltipName={tooltipName} 
            tooltipContent={tooltipContent}
        >
             <RiFileEditLine />
        </CRUDButton>
    )
}

export default EditButton
import { useEffect, useRef, useState } from 'react'
import CreateButton from '../../../../Partials/Layouts/CRUDs/CRUDHeader/CreateButton/CreateButton';
import CRUDTable from '../../../../Partials/Layouts/CRUDs/CRUDTable/CRUDTable';
import CRUDTableHeader from '../../../../Partials/Layouts/CRUDs/CRUDTable/CRUDTableHeader/CRUDTableHeader';
import CRUDth from '../../../../Partials/Layouts/CRUDs/CRUDTable/CRUDTableHeader/CRUDth/CRUDth';
import DeleteButton from '../../../../Partials/Layouts/CRUDs/CRUDButtons/DeleteButton/DeleteButton';
import ShortTextCell from '../../../../Partials/Layouts/CRUDs/CRUDTable/CRUDTableCells/ShortTextCell/ShortTextCell';
import CRUDTableRow from '../../../../Partials/Layouts/CRUDs/CRUDTable/CRUDTableRow/CRUDTableRow';
import Modal from '../../../../Partials/Elements/Modal/Modal';
import CreateDiscount from './CreateDiscount';
import useAxiosInstance from '../../../../../Utilities/Hooks/useAxiosInstance';
import DeleteItem from '../../../../Partials/Layouts/DeleteItem/DeleteItem';

import { DISCOUNTS_API } from '../../../../../Utilities/APIs/APIs';


function Discounts() {

  const axiosInstance = useAxiosInstance()
  const [discounts, setDiscounts] = useState(null)
  const screenHeaerRef = useRef()
  const [screenHeaderHeight, setScreenHeaderHeight] = useState(0);

  useEffect(() => {
    let screenHeaderHeight = screenHeaerRef.current.clientHeight;
    setScreenHeaderHeight(screenHeaderHeight)
  }, [screenHeaerRef]);

  const [showCreateForm, setShowCreateForm] = useState(false);
  const [showDeleteSection, setShowDeleteSection] = useState(false)
  const [toggleFetch, setToggleFetch] = useState(false)

  const [targetID, setTargetID] = useState(null)

  useEffect(() => {

  async function fetchAndSetDiscounts() {
      const { data } = await axiosInstance.get(DISCOUNTS_API)
      setDiscounts(data)
    }
    fetchAndSetDiscounts()

  }, [toggleFetch, axiosInstance])

  function triggerFetch() {
    setToggleFetch(prevState => !prevState)
  }
  return (
    <>
      <div className='screen_header' ref={screenHeaerRef}>
        <h1 className='screen_heading'>Discounts ({discounts?.length}  in total)</h1>
        <CreateButton
          screenTopicSingular={'Discount'}
          setShowCreateForm={setShowCreateForm}
        />
      </div>
      <CRUDTable screenHeaderHeight={screenHeaderHeight}>
        <CRUDTableHeader>
          <CRUDth th='Titles' />
        </CRUDTableHeader>
        <tbody>
          {
            discounts?.map(discount =>
              <CRUDTableRow key={discount._id}>
                <ShortTextCell text={discount.percentage} />
                <td>
                  <div className="action_buttons_wrapper">
                    <div className="action_buttons">

                      <DeleteButton
                        setShowDeleteSection={setShowDeleteSection}
                        targetID={discount._id}
                        setTargetID={setTargetID}
                        tooltipName={"Delete"} 
                        tooltipContent={"Delete"}
                      />
                    </div>
                  </div>
                </td>
              </CRUDTableRow>

            )
          }
        </tbody>
      </CRUDTable>
      {
        showCreateForm &&
        <Modal
          extraClass={'small'}
          modalHeading={`Add Discount`}
          setShowModalContent={setShowCreateForm}
        >
          <CreateDiscount
            triggerFetch={triggerFetch}
            setShowCreateForm={setShowCreateForm}
          />
        </Modal>
      }
     
      {
        showDeleteSection &&
        <Modal
          extraClass={'small'}
          modalHeading={`Delete Discount`}
          setShowModalContent={setShowDeleteSection}
        >
          <DeleteItem
            api={DISCOUNTS_API}
            triggerFetch={triggerFetch}
            targetID={targetID}
            setShowDeleteItem={setShowDeleteSection}
          />
        </Modal>
      }
    </>
  )
}

export default Discounts
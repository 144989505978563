import {  useContext, useEffect } from "react";
import { Outlet, useLocation, useNavigate } from "react-router-dom";
import AppContext from "../../../AppContext/AppContext";

function MainScreens() {

  const navigate = useNavigate()

  const {employee} = useContext(AppContext)
  const {pathname} = useLocation()

  useEffect(() => {
      if (employee && pathname === '/') {
        navigate('/main/dashboard')
      } 
      if (!employee) {
        navigate('/auth/login')
      }
    }, [employee, navigate, pathname])

    return (
        <Outlet />
    )
}

export default MainScreens
import React, { useEffect, useState } from "react";
import useAxiosInstance from "../../../../../../Utilities/Hooks/useAxiosInstance";
import { CHANGED_INVENTORIES_API } from "../../../../../../Utilities/APIs/APIs";
import { Form } from "react-router-dom";
import ShortTextInput from "../../../../../Partials/Layouts/Forms/FormInputs/ShortTextInput/ShortTextInput";
import DateInput from "../../../../../Partials/Layouts/Forms/FormInputs/DateInput/DateInput";
import SelectInput from "../../../../../Partials/Layouts/Forms/FormInputs/SelectInput/SelectInput";
import SelectOption from "../../../../../Partials/Layouts/Forms/FormInputs/SelectInput/SelectOption/SelectOption";
import FormSubmitButton from "../../../../../Partials/Layouts/Forms/FormSubmitButton/FormSubmitButton";

function UpdateChangedInventories({
  targetID,
  triggerFetch,
  setShowUpdateForm,
}) {
  const axiosInstance = useAxiosInstance();

  const [quantity, setQuantity] = useState("");
  const [date, setDate] = useState("");
  const [changeType, setChangeType] = useState("");
  // const [startQuantity, settartQuantity] = useState("");
  // const [endQuantity, setEndQuantity] = useState("");


  useEffect(() => {
    async function fetchAndSetSingleProductSize() {
      const { data } = await axiosInstance.get(
        CHANGED_INVENTORIES_API + "getSingleChangeInventory/" + targetID
      );
      setQuantity(data?.quantity);
      const dt = new Date(data.date);
      setDate(`${dt.toISOString().slice(0, 10)}`);
      setChangeType(data?.changeType);
      // settartQuantity(data?.startQuantity);
      // setEndQuantity(data?.endQuantity);
    }
    fetchAndSetSingleProductSize();
  }, [targetID, axiosInstance]);

  async function handleSubmit(e) {
    e.preventDefault();
    const itemData = {
      quantity,
      date,
      changeType,
      // startQuantity,
      // endQuantity,
    };

    const response = await axiosInstance.patch(
      CHANGED_INVENTORIES_API + targetID,
      itemData
    );
    if (response) {
      triggerFetch();
      setShowUpdateForm(false);
    }
  }

  return (
    <div className="crate_form">
      <Form onSubmit={handleSubmit}>
        <div>
          <ShortTextInput
            label={`quantity`}
            value={quantity}
            placeholder={`Enter Quantity`}
            setState={setQuantity}
          />

          <DateInput label={"Date"} value={date} setState={setDate} />

          <SelectInput
            label={"change Type"}
            value={changeType}
            setState={setChangeType}
          >
            <SelectOption optionValue="" optionText="Select Change Type" />
            <SelectOption optionValue="sold" optionText="sold" />
            <SelectOption optionValue="return" optionText="return" />
            <SelectOption optionValue="newStock" optionText="newStock" />
          </SelectInput>

          {/* <ShortTextInput
            label={`end Quantity`}
            value={endQuantity}
            placeholder={`Enter End Quantity`}
            setState={setEndQuantity}
          />

          <ShortTextInput
            label={`end Quantity`}
            value={endQuantity}
            placeholder={`Enter End Quantity`}
            setState={setEndQuantity}
          /> */}
        </div>
        <FormSubmitButton text="Add Changed Inventories" />
      </Form>
    </div>
  );
}

export default UpdateChangedInventories;

import { useState } from "react"
import useAxiosInstance from '../../../../../Utilities/Hooks/useAxiosInstance';
import { DISCOUNTS_API } from "../../../../../Utilities/APIs/APIs"
import ShortTextInput from "../../../../Partials/Layouts/Forms/FormInputs/ShortTextInput/ShortTextInput"
import FormSubmitButton from "../../../../Partials/Layouts/Forms/FormSubmitButton/FormSubmitButton"
import Form from "../../../../Partials/Layouts/Forms/Form"

function CreateDiscount({triggerFetch, setShowCreateForm}) {

    const axiosInstance = useAxiosInstance()

    const [percentage, setPercentage] = useState('')    

    async function handleSubmit(e) {

        e.preventDefault()
        const itemData = new FormData()

        itemData.append('percentage', percentage)

        const response = await axiosInstance.post(DISCOUNTS_API, itemData)
        if (response) {
            triggerFetch()
            setShowCreateForm(false)
        }
    }

    return (
        <div className='crate_form'>
            <Form onSubmit={handleSubmit}>
                <div>
                    <ShortTextInput
                        label={`Percentage`}
                        value={percentage}
                        placeholder={`Enter Percentage`}
                        setState={setPercentage}
                    />

                </div>
                <FormSubmitButton text="Submit" />
            </Form>
        </div>
    )
}

export default CreateDiscount
import { useEffect, useState } from "react";
import useAxiosInstance from "../../../../Utilities/Hooks/useAxiosInstance";
import { ORDERS_API } from "../../../../Utilities/APIs/APIs";

function ViewOrder({ targetID}) {

  const axiosInstance = useAxiosInstance()
  const [order, setOrder] = useState(null)

  useEffect(() => {

    async function fetchAndSetOrder() {
    
        const {data} = await axiosInstance.get(ORDERS_API + 'getSingleOrder/' + targetID)

        setOrder(data[0]);
    }

    fetchAndSetOrder()

    
  }, [targetID, axiosInstance])

  return (
    <div className="crud_view_content">

       
        <h1>InvoiceNo</h1>
        <p>{order?.invoiceNo}</p>


        <h1>Status</h1>
        <p>{order?.orderStatus}</p>

        <h1>Customer Name</h1>
        <p>{order?.orderCustomerName}</p>

        <h1>Customer Type</h1>
        <p>{order?.customerType}</p>

        <h1>Customer Email</h1>
        <p>{order?.orderCustomerEmail}</p>

        <h1>Customer Phone</h1>
        <p>{order?.orderCustomerPhone}</p>

        <h1>Customer Address</h1>
        <p>{order?.orderCustomerAddress}</p>

        <h1>Customer District </h1>
        <p>{order?.customerDistrict}</p>

        <h1>Customer Area</h1>
        <p>{order?.customerArea}</p>

        <h1>Customer Point</h1>
        <p>{order?.CustomerPoint}</p>

        <h1>Shipping Name</h1>
        <p>{order?.shippingName}</p>

        <h1>Shipping Address</h1>
        <p>{order?.shippingAddress}</p>

        <h1>Additional Instructions</h1>
        <p>{order?.additionalInstructions}</p>

        <h1>shippingPhone</h1>
        <p>{order?.shippingPhone}</p>

        <h1>Shipping Media Type</h1>
        <p>{order?.shippingMediaType}</p>

        <h1>Subtotal</h1>
        <p>{order?.orderSubtotal}</p>

        <h1>Discount</h1>
        <p>{order?.promoDiscountAmount}</p>

        <h1>Promo Code</h1>
        <p>{order?.promoCode}</p>
    </div>
  )
}
       
      // orderSubtotal,
      // shippingRate,
      // promoDiscountAmount,
      // orderTotal,
      // orderStatusId,
      // orderAddedDate,
      // paymentMethod,
      // sslTransactionNumber,
      // cardBrand,
      // shippingMethod,
      // deliveryDateStart,
      // deliveryDateEnd,
      // shippingMediaType,
      // gifted,
export default ViewOrder
import { useEffect, useRef, useState } from 'react'
import CreateButton from '../../../../Partials/Layouts/CRUDs/CRUDHeader/CreateButton/CreateButton';
import CRUDTable from '../../../../Partials/Layouts/CRUDs/CRUDTable/CRUDTable';
import CRUDTableHeader from '../../../../Partials/Layouts/CRUDs/CRUDTable/CRUDTableHeader/CRUDTableHeader';
import CRUDth from '../../../../Partials/Layouts/CRUDs/CRUDTable/CRUDTableHeader/CRUDth/CRUDth';
import ViewButton from '../../../../Partials/Layouts/CRUDs/CRUDButtons/ViewButton/ViewButton';
import EditButton from '../../../../Partials/Layouts/CRUDs/CRUDButtons/EditButton/EditButton';
import DeleteButton from '../../../../Partials/Layouts/CRUDs/CRUDButtons/DeleteButton/DeleteButton';
import ShortTextCell from '../../../../Partials/Layouts/CRUDs/CRUDTable/CRUDTableCells/ShortTextCell/ShortTextCell';
import CRUDTableRow from '../../../../Partials/Layouts/CRUDs/CRUDTable/CRUDTableRow/CRUDTableRow';
import Modal from '../../../../Partials/Elements/Modal/Modal';
import useAxiosInstance from '../../../../../Utilities/Hooks/useAxiosInstance';
import DeleteItem from '../../../../Partials/Layouts/DeleteItem/DeleteItem';
import { PRODUCTMATERIAL_API } from '../../../../../Utilities/APIs/APIs';
import ViewProductMaterial from './ViewProductMaterial';
import CreateProductMaterial from './CreateProductMaterial';
import UpdateProductMaterial from './UpdateProductMaterial';
import { useParams } from 'react-router-dom';


function ProductMaterials() {

  const [productmaterials, setProductMaterials] = useState(null)
  const screenHeaerRef = useRef()
  const [screenHeaderHeight, setScreenHeaderHeight] = useState(0);

  useEffect(() => {
    let screenHeaderHeight = screenHeaerRef.current.clientHeight;
    setScreenHeaderHeight(screenHeaderHeight)
  }, [screenHeaerRef]);

  const [showCreateForm, setShowCreateForm] = useState(false);
  const [showViewSection, setShowViewSection] = useState(false);
  const [showUpdateForm, setShowUpdateForm] = useState(false);
  const [showDeleteSection, setShowDeleteSection] = useState(false)
  const [toggleFetch, setToggleFetch] = useState(false)

  const [targetID, setTargetID] = useState(null)
  const axiosInstance = useAxiosInstance()
  const {productID} = useParams();

  useEffect(() => {

    async function fetchAndsetProductMaterials() {
      const { data } = await axiosInstance.get(PRODUCTMATERIAL_API + 'getProductMaterialsByProduct/' + productID)
      setProductMaterials(data)
    }
    fetchAndsetProductMaterials()

  }, [toggleFetch, axiosInstance, productID])

  function triggerFetch() {
    setToggleFetch(prevState => !prevState)
  }
  return (
    <>
      <div className='screen_header' ref={screenHeaerRef}>
        <h1 className='screen_heading'>Product Materials ({productmaterials?.length}  in total)</h1>
        <CreateButton
          screenTopicSingular={'Product Materials'}
          setShowCreateForm={setShowCreateForm}
        />
      </div>
      <CRUDTable screenHeaderHeight={screenHeaderHeight}>
        <CRUDTableHeader>
          <CRUDth th='Products' />
          <CRUDth th='Materials' />
          <CRUDth th='Action' />

        </CRUDTableHeader>
        <tbody>
          {
            productmaterials?.map(productmaterial =>
              <CRUDTableRow key={productmaterial._id}>
                <ShortTextCell text={productmaterial?.product?.name} />
                <ShortTextCell text={productmaterial?.material?.name} />
                <td>
                  <div className="action_buttons_wrapper">
                    <div className="action_buttons">
                      <ViewButton
                        setShowViewSection={setShowViewSection}
                        targetID={productmaterial._id}
                        setTargetID={setTargetID}
                      />

                      <EditButton
                        setShowUpdateForm={setShowUpdateForm}
                        targetID={productmaterial._id}
                        setTargetID={setTargetID}
                      />

                      <DeleteButton
                        setShowDeleteSection={setShowDeleteSection}
                        targetID={productmaterial._id}
                        setTargetID={setTargetID}
                      />
                    </div>
                  </div>
                </td>
              </CRUDTableRow>

            )
          }
        </tbody>
      </CRUDTable>
      {
        showCreateForm &&
        <Modal
          extraClass={'small'}
          modalHeading={`Add Product Material`}
          setShowModalContent={setShowCreateForm}
        >
          <CreateProductMaterial
          productID={productID}
            triggerFetch={triggerFetch}
            setShowCreateForm={setShowCreateForm}
          />
        </Modal>
      }
      {
        showUpdateForm &&
        <Modal
          extraClass={'small'}
          modalHeading={`Update Product Material`}
          setShowModalContent={setShowUpdateForm}
        >
          <UpdateProductMaterial
          productID={productID}
            triggerFetch={triggerFetch}
            targetID={targetID}
            setShowUpdateForm={setShowUpdateForm}
          />
        </Modal>
      }
      {
        showViewSection &&
        <Modal
          extraClass={'small'}
          modalHeading={`View Product Material`}
          setShowModalContent={setShowViewSection}
        >
          <ViewProductMaterial
            targetID={targetID}
          />
        </Modal>
      }
      {
        showDeleteSection &&
        <Modal
          extraClass={'small'}
          modalHeading={`Delete Product Material`}
          setShowModalContent={setShowDeleteSection}
        >
          <DeleteItem
            api={PRODUCTMATERIAL_API}
            triggerFetch={triggerFetch}
            targetID={targetID}
            setShowDeleteItem={setShowDeleteSection}
          />
        </Modal>
      }
    </>
  )
}

export default ProductMaterials
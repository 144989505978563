import { useEffect, useRef, useState } from "react";
import CreateButton from "../../../../Partials/Layouts/CRUDs/CRUDHeader/CreateButton/CreateButton";
import CRUDTable from "../../../../Partials/Layouts/CRUDs/CRUDTable/CRUDTable";
import CRUDTableHeader from "../../../../Partials/Layouts/CRUDs/CRUDTable/CRUDTableHeader/CRUDTableHeader";
import CRUDth from "../../../../Partials/Layouts/CRUDs/CRUDTable/CRUDTableHeader/CRUDth/CRUDth";
import ViewButton from "../../../../Partials/Layouts/CRUDs/CRUDButtons/ViewButton/ViewButton";
import EditButton from "../../../../Partials/Layouts/CRUDs/CRUDButtons/EditButton/EditButton";
import DeleteButton from "../../../../Partials/Layouts/CRUDs/CRUDButtons/DeleteButton/DeleteButton";
import ShortTextCell from "../../../../Partials/Layouts/CRUDs/CRUDTable/CRUDTableCells/ShortTextCell/ShortTextCell";
import CRUDTableRow from "../../../../Partials/Layouts/CRUDs/CRUDTable/CRUDTableRow/CRUDTableRow";
import Modal from "../../../../Partials/Elements/Modal/Modal";
import useAxiosInstance from "../../../../../Utilities/Hooks/useAxiosInstance";
import DeleteItem from "../../../../Partials/Layouts/DeleteItem/DeleteItem";
import { PRICEHISTORY_API } from "../../../../../Utilities/APIs/APIs";
import ViewPriceHistory from "./ViewPriceHistory";
import CreatePriceHistory from "./CreatePriceHistory";
import UpdatePriceHistory from "./UpdatePriceHistory";
import { useParams } from "react-router-dom";

function PriceHistories() {
  const [pricehistories, setPriceHistories] = useState(null);
  const screenHeaerRef = useRef();
  const [screenHeaderHeight, setScreenHeaderHeight] = useState(0);

  useEffect(() => {
    let screenHeaderHeight = screenHeaerRef.current.clientHeight;
    setScreenHeaderHeight(screenHeaderHeight);
  }, [screenHeaerRef]);

  const [showCreateForm, setShowCreateForm] = useState(false);
  const [showViewSection, setShowViewSection] = useState(false);
  const [showUpdateForm, setShowUpdateForm] = useState(false);
  const [showDeleteSection, setShowDeleteSection] = useState(false);
  const [toggleFetch, setToggleFetch] = useState(false);

  const [targetID, setTargetID] = useState(null);
  const axiosInstance = useAxiosInstance();
  const { productID } = useParams();

  useEffect(() => {
    async function fetchAndSetPriceHistories() {
      const { data } = await axiosInstance.get(
        PRICEHISTORY_API + "getPriceHistorysByProduct/" + productID
      );
      setPriceHistories(data);
    }
    fetchAndSetPriceHistories();
  }, [toggleFetch, axiosInstance, productID]);

  function triggerFetch() {
    setToggleFetch((prevState) => !prevState);
  }
  return (
    <>
      <div className="screen_header" ref={screenHeaerRef}>
        <h1 className="screen_heading">
          Price Histories ({pricehistories?.length} in total)
        </h1>
        <CreateButton
          screenTopicSingular={"Price Histories"}
          setShowCreateForm={setShowCreateForm}
        />
      </div>
      <CRUDTable screenHeaderHeight={screenHeaderHeight}>
        <CRUDTableHeader>
          <CRUDth th="Products" />
          <CRUDth th="Price" />
          <CRUDth th="Action" />
        </CRUDTableHeader>
        <tbody>
          {pricehistories?.map((pricehistory) => (
            <CRUDTableRow key={pricehistory._id}>
              <ShortTextCell text={pricehistory?.product?.name} />
              <ShortTextCell text={pricehistory?.price} />
              <td>
                <div className="action_buttons_wrapper">
                  <div className="action_buttons">
                    <ViewButton
                      setShowViewSection={setShowViewSection}
                      targetID={pricehistory._id}
                      setTargetID={setTargetID}
                    />

                    <EditButton
                      setShowUpdateForm={setShowUpdateForm}
                      targetID={pricehistory._id}
                      setTargetID={setTargetID}
                    />

                    <DeleteButton
                      setShowDeleteSection={setShowDeleteSection}
                      targetID={pricehistory._id}
                      setTargetID={setTargetID}
                    />
                  </div>
                </div>
              </td>
            </CRUDTableRow>
          ))}
        </tbody>
      </CRUDTable>
      {showCreateForm && (
        <Modal
          extraClass={"small"}
          modalHeading={`Add Price History`}
          setShowModalContent={setShowCreateForm}
        >
          <CreatePriceHistory
          productID={productID}
            triggerFetch={triggerFetch}
            setShowCreateForm={setShowCreateForm}
          />
        </Modal>
      )}
      {showUpdateForm && (
        <Modal
          extraClass={"small"}
          modalHeading={`Update Price History`}
          setShowModalContent={setShowUpdateForm}
        >
          <UpdatePriceHistory
          productID={productID}
            triggerFetch={triggerFetch}
            targetID={targetID}
            setShowUpdateForm={setShowUpdateForm}
          />
        </Modal>
      )}
      {showViewSection && (
        <Modal
          extraClass={"small"}
          modalHeading={`View Price History`}
          setShowModalContent={setShowViewSection}
        >
          <ViewPriceHistory targetID={targetID} />
        </Modal>
      )}
      {showDeleteSection && (
        <Modal
          extraClass={"small"}
          modalHeading={`Delete Price History`}
          setShowModalContent={setShowDeleteSection}
        >
          <DeleteItem
            api={PRICEHISTORY_API}
            triggerFetch={triggerFetch}
            targetID={targetID}
            setShowDeleteItem={setShowDeleteSection}
          />
        </Modal>
      )}
    </>
  );
}

export default PriceHistories;

import { useEffect, useState } from "react"
import useAxiosInstance from "../../../../../Utilities/Hooks/useAxiosInstance"
import Form from "../../../../Partials/Layouts/Forms/Form"
import FormSubmitButton from "../../../../Partials/Layouts/Forms/FormSubmitButton/FormSubmitButton"
import { PRODUCTS_API, PAIREDPRODUCT_API } from "../../../../../Utilities/APIs/APIs"
import SelectInput from "../../../../Partials/Layouts/Forms/FormInputs/SelectInput/SelectInput"
import SelectOption from "../../../../Partials/Layouts/Forms/FormInputs/SelectInput/SelectOption/SelectOption"

function UpdatePairedProduct({ productID, targetID, triggerFetch, setShowUpdateForm }) {

    const axiosInstance = useAxiosInstance()

    const [product2, setProduct2] = useState('')

    const [products, setProducts] = useState(null)

    useEffect(() => {
        async function fetchAndSetSinglePairedProducts() {
            const { data } = await axiosInstance.get(PAIREDPRODUCT_API + 'getSinglePairedProduct/' + targetID)
            setProduct2(data.product2?._id)
        }
        fetchAndSetSinglePairedProducts()

    }, [targetID, axiosInstance])

    useEffect(() => {

        async function fetchAndSetProducts() {
            const { data } = await axiosInstance.get(PRODUCTS_API)
            const filterData = data?.filter(d => d._id !== productID)
            setProducts(filterData)
        }
        fetchAndSetProducts()

    }, [axiosInstance])

    async function handleSubmit(e) {

        e.preventDefault()
        const itemData = new FormData()

        itemData.append('product1', productID)
        itemData.append('product2', product2)

        const response = await axiosInstance.patch(PAIREDPRODUCT_API + targetID, itemData)
        if (response) {
            triggerFetch()
            setShowUpdateForm(false)
        }
    }

    return (
        <div className='crate_form'>
            <Form onSubmit={handleSubmit}>
                <div>

                    <SelectInput
                        label={'Paired Product'}
                        value={product2}
                        setState={setProduct2}
                    >
                        <SelectOption optionValue='' optionText="Select Paired Product" />
                        {products?.map((product => (
                            <SelectOption optionValue={product._id} key={product._id}
                                optionText={product?.name} />
                        )))}
                    </SelectInput>
                </div>
                <FormSubmitButton text="Update Paired Product" />
            </Form>
        </div>
    )
}

export default UpdatePairedProduct
import { useState } from "react"
import Form from "../../../../Partials/Layouts/Forms/Form"
import ShortTextInput from "../../../../Partials/Layouts/Forms/FormInputs/ShortTextInput/ShortTextInput"
import FormSubmitButton from "../../../../Partials/Layouts/Forms/FormSubmitButton/FormSubmitButton"
import { DISTRICT_API } from "../../../../../Utilities/APIs/APIs"
import useAxiosInstance from '../../../../../Utilities/Hooks/useAxiosInstance';

function CreateDistrict({division, triggerFetch, setShowCreateForm }) {

    const axiosInstance = useAxiosInstance()
    const [name, setName] = useState('')
    // const [division, setDivision] = useState('')

    async function handleSubmit(e) {

        e.preventDefault()
        const itemData = new FormData()

        itemData.append('division', division)
        itemData.append('name', name)

        const response = await axiosInstance.post(DISTRICT_API, itemData)
        if (response) {
            triggerFetch()
            setShowCreateForm(false)
        }
    }

    return (
        <div className='crate_form'>
            <Form onSubmit={handleSubmit}>
                <div>
                    {/* <ShortTextInput
                        label={`Division`}
                        value={division}
                        placeholder={`Enter Division`}
                        setState={setDivision}
                    /> */}
                    <ShortTextInput
                        label={`District`}
                        value={name}
                        placeholder={`Enter District`}
                        setState={setName}
                    />
                </div>
                <FormSubmitButton text="Submit" />
            </Form>
        </div>
    )
}

export default CreateDistrict
import { useState } from "react"
import useAxiosInstance from '../../../../../Utilities/Hooks/useAxiosInstance';
import Form from "../../../../Partials/Layouts/Forms/Form"
import ShortTextInput from "../../../../Partials/Layouts/Forms/FormInputs/ShortTextInput/ShortTextInput"
import FormSubmitButton from "../../../../Partials/Layouts/Forms/FormSubmitButton/FormSubmitButton"
import { LOYALTY_CLUB_API } from "../../../../../Utilities/APIs/APIs"
import SwitchInput from "../../../../Partials/Layouts/Forms/FormInputs/SwitchInput/SwitchInput"

function CreateLoyaltyClub({triggerFetch, setShowCreateForm}) {

    const axiosInstance = useAxiosInstance()

    const [clubName, setClubName] = useState('')  
    const [points, setPoints] = useState('')
    const [bgColor, setbgColor] = useState('')

    
    async function handleSubmit(e) {

        e.preventDefault()
        const itemData = new FormData()

        itemData.append('clubName', clubName)
        itemData.append('points', points)
        itemData.append('bgColor', bgColor)

        const response = await axiosInstance.post(LOYALTY_CLUB_API, itemData)
        if (response) {
            triggerFetch()
            setShowCreateForm(false)
        }
    }

    return (
        <div className='crate_form'>
            <Form onSubmit={handleSubmit}>
                <div>
                    <ShortTextInput
                        label={`Club Name`}
                        value={clubName}
                        placeholder={`Enter Club Name`}
                        setState={setClubName}
                    />
                
                    <ShortTextInput
                        label={`points`}
                        value={points}
                        placeholder={`Enter Points`}
                        setState={setPoints}
                    />

                   <ShortTextInput
                        label={`BG Colors`}
                        value={bgColor}
                        placeholder={`Enter BG Color`}
                        setState={setbgColor}
                    />

                </div>
                <FormSubmitButton text="Submit" />
            </Form>
        </div>
    )
}

export default CreateLoyaltyClub
import { useState } from "react"
import useAxiosInstance from "../../../../../Utilities/Hooks/useAxiosInstance"
import Form from "../../../../Partials/Layouts/Forms/Form"
import ShortTextInput from "../../../../Partials/Layouts/Forms/FormInputs/ShortTextInput/ShortTextInput"
import FormSubmitButton from "../../../../Partials/Layouts/Forms/FormSubmitButton/FormSubmitButton"
import ImageInput from "../../../../Partials/Layouts/Forms/FormInputs/ImageInput/ImageInput"
import { CARE_API } from "../../../../../Utilities/APIs/APIs"
import SwitchInput from "../../../../Partials/Layouts/Forms/FormInputs/SwitchInput/SwitchInput"

function CreateCare({triggerFetch, setShowCreateForm}) {

    const axiosInstance = useAxiosInstance()

    const [name, setName] = useState('')  
    const [image, setImage] = useState('')
    const [isActive, setIsActive] = useState('') 

    async function handleSubmit(e) {

        e.preventDefault()
        const itemData = new FormData()

        itemData.append('name', name)
        itemData.append('image', image)
        itemData.append('isActive', isActive)

        const response = await axiosInstance.post(CARE_API, itemData)
        if (response) {
            triggerFetch()
            setShowCreateForm(false)
        }
    }

    return (
        <div className='crate_form'>
            <Form onSubmit={handleSubmit}>
                <div>

                    <ShortTextInput
                        label={`Care Name`}
                        value={name}
                        placeholder={`Care Name`}
                        setState={setName}
                    />
                    <ImageInput
                        fieldId="1"
                        state={image}
                        setState={setImage}
                        allowCreateImage
                    >
                        Upload Care Image
                    </ImageInput>
                    <SwitchInput
                        label={`Status`}
                        toggleSwitch={() => setIsActive(prevState => !prevState)}
                        checked={isActive}
                    />

                </div>
                <FormSubmitButton text="Submit" />
            </Form>
        </div>
    )
}

export default CreateCare
import { useEffect, useRef, useState } from "react";
import CreateButton from "../../../../../Partials/Layouts/CRUDs/CRUDHeader/CreateButton/CreateButton";
import CRUDTable from "../../../../../Partials/Layouts/CRUDs/CRUDTable/CRUDTable";
import CRUDTableHeader from "../../../../../Partials/Layouts/CRUDs/CRUDTable/CRUDTableHeader/CRUDTableHeader";
import CRUDth from "../../../../../Partials/Layouts/CRUDs/CRUDTable/CRUDTableHeader/CRUDth/CRUDth";
import CRUDTableRow from "../../../../../Partials/Layouts/CRUDs/CRUDTable/CRUDTableRow/CRUDTableRow";
import ShortTextCell from "../../../../../Partials/Layouts/CRUDs/CRUDTable/CRUDTableCells/ShortTextCell/ShortTextCell";
import ViewButton from "../../../../../Partials/Layouts/CRUDs/CRUDButtons/ViewButton/ViewButton";
import EditButton from "../../../../../Partials/Layouts/CRUDs/CRUDButtons/EditButton/EditButton";
import DeleteButton from "../../../../../Partials/Layouts/CRUDs/CRUDButtons/DeleteButton/DeleteButton";
import Modal from "../../../../../Partials/Elements/Modal/Modal";
import DeleteItem from "../../../../../Partials/Layouts/DeleteItem/DeleteItem";
import useAxiosInstance from "../../../../../../Utilities/Hooks/useAxiosInstance";
import CreateProductCollections from "./CreateProductCollections";
import UpdateProductCollections from "./UpdateProductCollections";
import ViewProductCollections from "./ViewProductCollections";
import {
  CATAGORY_API,
  PRODUCT_COLLECTIONS_API,
} from "../../../../../../Utilities/APIs/APIs";
import CRUDButton from "../../../../../Partials/Layouts/CRUDs/CRUDButtons/CRUDButton";
import { RiImage2Line } from "react-icons/ri";
import { useNavigate } from "react-router-dom";

function ProductCollections() {
  const [productCollections, setProductCollections] = useState(null);
  const screenHeaerRef = useRef();
  const [screenHeaderHeight, setScreenHeaderHeight] = useState(0);

  useEffect(() => {
    let screenHeaderHeight = screenHeaerRef.current.clientHeight;
    setScreenHeaderHeight(screenHeaderHeight);
  }, [screenHeaerRef]);

  const [showCreateForm, setShowCreateForm] = useState(false);
  const [showViewSection, setShowViewSection] = useState(false);
  const [showUpdateForm, setShowUpdateForm] = useState(false);
  const [showDeleteSection, setShowDeleteSection] = useState(false);
  const [toggleFetch, setToggleFetch] = useState(false);

  const [targetID, setTargetID] = useState(null);
  const axiosInstance = useAxiosInstance();
  const navigate = useNavigate();

  useEffect(() => {
    async function fetchAndSetProductCollections() {
      const { data } = await axiosInstance.get(
        PRODUCT_COLLECTIONS_API + "getAllProductCollections"
      );

      console.log("data collection:", data);
      setProductCollections(data);
    }
    fetchAndSetProductCollections();
  }, [toggleFetch, axiosInstance]);

  function triggerFetch() {
    setToggleFetch((prevState) => !prevState);
  }
  return (
    <>
      <div className="screen_header" ref={screenHeaerRef}>
        <h1 className="screen_heading">
          Product Collections ({productCollections?.length} in total)
        </h1>
        <CreateButton
          screenTopicSingular={"Product Collections"}
          setShowCreateForm={setShowCreateForm}
        />
      </div>
      <CRUDTable screenHeaderHeight={screenHeaderHeight}>
        <CRUDTableHeader>
          <CRUDth th="Name" />
          <CRUDth th="Slug" />
          <CRUDth th="status" />
          <CRUDth th="Actions" />
        </CRUDTableHeader>

        <tbody>
          {productCollections?.map((productCollection) => (
            <CRUDTableRow key={productCollection._id}>
              <ShortTextCell text={productCollection?.name} />
              <ShortTextCell text={productCollection?.slug} />
              <ShortTextCell
                text={productCollection?.isActive ? "Active" : "Inactive"}
              />
              <td>
                <div className="action_buttons_wrapper">
                  <div className="action_buttons">
                    <ViewButton
                      setShowViewSection={setShowViewSection}
                      targetID={productCollection._id}
                      setTargetID={setTargetID}
                      tooltipName={"View"}
                      tooltipContent={"view"}
                    />

                    <EditButton
                      setShowUpdateForm={setShowUpdateForm}
                      targetID={productCollection._id}
                      setTargetID={setTargetID}
                      tooltipName={"Edit"}
                      tooltipContent={"edit"}
                    />

                    {/* <DeleteButton
                      setShowDeleteSection={setShowDeleteSection}
                      targetID={productCollection._id}
                      setTargetID={setTargetID}
                      tooltipName={"Delete"}
                      tooltipContent={"delete"}
                    /> */}
                    <CRUDButton
                      handleClick={() =>
                        navigate(
                          "/main/ProductCollectionProducts/" +
                            productCollection?._id
                        )
                      }
                      tooltipName={"Products"}
                      tooltipContent={"Products"}
                    >
                      <RiImage2Line />
                    </CRUDButton>
                  </div>
                </div>
              </td>
            </CRUDTableRow>
          ))}
        </tbody>
      </CRUDTable>
      {showCreateForm && (
        <Modal
          extraClass={"small"}
          modalHeading={`Add product Collection Name`}
          setShowModalContent={setShowCreateForm}
        >
          <CreateProductCollections
            triggerFetch={triggerFetch}
            setShowCreateForm={setShowCreateForm}
          />
        </Modal>
      )}
      {showUpdateForm && (
        <Modal
          extraClass={"small"}
          modalHeading={`Update product Collection Name`}
          setShowModalContent={setShowUpdateForm}
          contentStyle={{ overflow: "visible" }}
          bodyStyle={{ overflowY: "visible" }}
        >
          <UpdateProductCollections
            triggerFetch={triggerFetch}
            targetID={targetID}
            setShowUpdateForm={setShowUpdateForm}
          />
        </Modal>
      )}
      {showViewSection && (
        <Modal
          extraClass={"small"}
          modalHeading={`View product Collection Name`}
          setShowModalContent={setShowViewSection}
        >
          <ViewProductCollections targetID={targetID} />
        </Modal>
      )}
      {/* {showDeleteSection && (
        <Modal
          extraClass={"small"}
          modalHeading={`Delete Brand`}
          setShowModalContent={setShowDeleteSection}
        >
          <DeleteItem
            api={CATAGORY_API}
            triggerFetch={triggerFetch}
            targetID={targetID}
            setShowDeleteItem={setShowDeleteSection}
          />
        </Modal>
      )} */}
    </>
  );
}
export default ProductCollections;

import { useEffect, useState } from "react"
import useAxiosInstance from '../../../../../Utilities/Hooks/useAxiosInstance';
import Form from "../../../../Partials/Layouts/Forms/Form"
import ShortTextInput from "../../../../Partials/Layouts/Forms/FormInputs/ShortTextInput/ShortTextInput"
import FormSubmitButton from "../../../../Partials/Layouts/Forms/FormSubmitButton/FormSubmitButton"
import { SOCIALLINKS_API } from "../../../../../Utilities/APIs/APIs"
import SwitchInput from "../../../../Partials/Layouts/Forms/FormInputs/SwitchInput/SwitchInput"

function UpdateSocialLink({targetID, triggerFetch, setShowUpdateForm}) {

    const axiosInstance = useAxiosInstance()

    const [platform, setPlatform] = useState('')  
    const [visibility, setVisibility] = useState('')
    const [link, setLink] = useState('')
    
    useEffect(() => {
        async function fetchAndSetSingleSocialLink() {
          const { data } = await axiosInstance.get(SOCIALLINKS_API + targetID)
          setPlatform(data.platform)
          setVisibility(data.visibility)
          setLink(data.link)

        }
        fetchAndSetSingleSocialLink()

      }, [targetID, axiosInstance])

    async function handleSubmit(e) {

        e.preventDefault()
        const itemData = new FormData()

        itemData.append('platform', platform)
        itemData.append('visibility', visibility)
        itemData.append('link', link)

        const response = await axiosInstance.patch(SOCIALLINKS_API + targetID, itemData)
        if (response) {
            triggerFetch()
            setShowUpdateForm(false)
        }
    }

    return (
        <div className='crate_form'>
            <Form onSubmit={handleSubmit}>
                <div>
                <ShortTextInput
                        label={`Title`}
                        value={platform}
                        placeholder={`Enter Title`}
                        setState={setPlatform}
                    />
                    <SwitchInput
                        label={`Visibility`}
                        toggleSwitch={() => setVisibility(prevState => !prevState)}
                        checked={visibility}
                    />
                    <ShortTextInput
                        label={`Link`}
                        value={link}
                        placeholder={`Enter Link`}
                        setState={setLink}
                    />
                </div>
                <FormSubmitButton text="Submit" />
            </Form>
        </div>
    )
}

export default UpdateSocialLink
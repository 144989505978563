import DeleteButton from "../../../../../Partials/Layouts/CRUDs/CRUDButtons/DeleteButton/DeleteButton";
import EditButton from "../../../../../Partials/Layouts/CRUDs/CRUDButtons/EditButton/EditButton";
import ViewButton from "../../../../../Partials/Layouts/CRUDs/CRUDButtons/ViewButton/ViewButton";
import CRUDTable from "../../../../../Partials/Layouts/CRUDs/CRUDTable/CRUDTable";
import ShortTextCell from "../../../../../Partials/Layouts/CRUDs/CRUDTable/CRUDTableCells/ShortTextCell/ShortTextCell";
import CRUDTableHeader from "../../../../../Partials/Layouts/CRUDs/CRUDTable/CRUDTableHeader/CRUDTableHeader";
import CRUDth from "../../../../../Partials/Layouts/CRUDs/CRUDTable/CRUDTableHeader/CRUDth/CRUDth";
import CRUDTableRow from "../../../../../Partials/Layouts/CRUDs/CRUDTable/CRUDTableRow/CRUDTableRow";

function Collection() {
  return (
    <>
      <div className="screen_header">
        <h1 className="screen_heading">Collection</h1>
      </div>

      <CRUDTable>
        <CRUDTableHeader>
          <CRUDth th="Name" />
          <CRUDth th="Actions" />
        </CRUDTableHeader>

        <CRUDTableRow>
          <td>
            <div className="action_buttons_wrapper">
              <ShortTextCell text="k" />
              <div className="action_buttons">
                <ViewButton />

                <EditButton />
                <DeleteButton />
              </div>
            </div>
          </td>
        </CRUDTableRow>
      </CRUDTable>
    </>
  );
}

export default Collection;

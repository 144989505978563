import { useEffect, useState } from 'react'
import useAxiosInstance from '../../../../../Utilities/Hooks/useAxiosInstance';
import { SOCIALLINKS_API } from '../../../../../Utilities/APIs/APIs'

function ViewSocailLinks({targetID}) {

    const axiosInstance = useAxiosInstance()
    const [sociallinksInfo, setsociallinksInfo] = useState(null)

    useEffect(() => {
        async function fetchAndSetSingleSocailLink() {
          const { data } = await axiosInstance.get(SOCIALLINKS_API + targetID)
          setsociallinksInfo(data)
        }
        fetchAndSetSingleSocailLink()

      }, [targetID, axiosInstance])

    return (
        <div className="crud_view_content">
            {
                sociallinksInfo &&
                <>
                    <h1>Title</h1>
                    <p>{sociallinksInfo.platform}</p>
                    <h1>Visibility</h1>
                    <p>{sociallinksInfo.visibility === true ? 'True' : 'False'}</p>
                    <h1>Link</h1>
                    <p>{sociallinksInfo.link}</p>
                </>
            }
            
        </div>
    )
}

export default ViewSocailLinks
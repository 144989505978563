import { useEffect, useState } from 'react'
import useAxiosInstance from '../../../../../Utilities/Hooks/useAxiosInstance';
import { LOYALTY_CLUB_API } from '../../../../../Utilities/APIs/APIs'

function LoyaltyClub({targetID}) {

    const axiosInstance = useAxiosInstance()
    const [loyaltyClubsInfo, setloyaltyClubsInfo] = useState(null)

    useEffect(() => {
        async function fetchAndSetSingleSocailLink() {
          const { data } = await axiosInstance.get(LOYALTY_CLUB_API + 'getSingleLoyaltyClub/' + targetID)
          setloyaltyClubsInfo(data)
        }
        fetchAndSetSingleSocailLink()

      }, [targetID, axiosInstance])

    return (
        <div className="crud_view_content">
            {
                loyaltyClubsInfo &&
                <>
                    <h1>Club Name</h1>
                    <p>{loyaltyClubsInfo.clubName}</p>

                    <h1>Points</h1>
                    <p>{loyaltyClubsInfo.points}</p>
                </>
            }
            
        </div>
    )
}

export default LoyaltyClub
import React, { useState } from "react";
import useAxiosInstance from "../../../../../../Utilities/Hooks/useAxiosInstance";
import { Form } from "react-router-dom";
import ShortTextInput from "../../../../../Partials/Layouts/Forms/FormInputs/ShortTextInput/ShortTextInput";
import FormSubmitButton from "../../../../../Partials/Layouts/Forms/FormSubmitButton/FormSubmitButton";
import { PRODUCT_COLLECTIONS_API } from "../../../../../../Utilities/APIs/APIs";
import slugify from "react-slugify";
import SwitchInput from "../../../../../Partials/Layouts/Forms/FormInputs/SwitchInput/SwitchInput";

function CreateProductCollections({ triggerFetch, setShowCreateForm }) {
  const axiosInstance = useAxiosInstance();

  const [name, setName] = useState("");
  const [isActive, setIsActive] = useState(false);

  async function handleSubmit(e) {
    e.preventDefault();
    const itemData = new FormData();

    itemData.append("name", name);
    itemData.append("slug", slugify(name));
    itemData.append("isActive", isActive);

    const response = await axiosInstance.post(
      PRODUCT_COLLECTIONS_API + "createProductCollections",
      itemData
    );
    if (response) {
      triggerFetch();
      setShowCreateForm(false);
    }
  }

  return (
    <div className="crate_form">
      <Form onSubmit={handleSubmit}>
        <div>
          <ShortTextInput
            label={`Product Collection name`}
            value={name}
            placeholder={`Category Name`}
            setState={setName}
          />
          <ShortTextInput
            label={`Slug`}
            value={slugify(name)}
            placeholder={`Enter Slug`}
            disabled
          />
           <SwitchInput
            label={`IsActive`}
            toggleSwitch={() => setIsActive((prevState) => !prevState)}
            checked={isActive}
          />
        </div>
        <FormSubmitButton text="Submit" />
      </Form>
    </div>
  );
}
export default CreateProductCollections;

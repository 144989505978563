import { useEffect, useState } from 'react'
import useAxiosInstance from '../../../../../Utilities/Hooks/useAxiosInstance'
import { PRODUCTCOLOR_API } from '../../../../../Utilities/APIs/APIs'

function ViewProductColor({ targetID }) {

    const axiosInstance = useAxiosInstance()

    const [productColorInfo, setProductColorInfo] = useState(null)

    useEffect(() => {
        async function fetchAndSetSingleProductColor() {
            const { data } = await axiosInstance.get(PRODUCTCOLOR_API + 'getSingleProductColor/' + targetID)
            setProductColorInfo(data)
        }
        fetchAndSetSingleProductColor()

    }, [targetID, axiosInstance])

    return (
        <div className="crud_view_content">
            {
                productColorInfo &&
                <>
                    <h1>Product</h1>
                    <p>{productColorInfo.product?.name}</p>

                    <h1>Color</h1>
                    <p>{productColorInfo.color?.name}</p>
                </>
            }

        </div>
    )
}

export default ViewProductColor
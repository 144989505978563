import { useEffect, useState } from 'react'
import useAxiosInstance from '../../../../../Utilities/Hooks/useAxiosInstance'
import { DESIGNATIONS_API } from '../../../../../Utilities/APIs/APIs'

function ViewDesignation({targetID}) {

    const axiosInstance = useAxiosInstance()
    
    const [designationInfo, setDesignationInfo] = useState(null)

    useEffect(() => {
        async function fetchAndSetSingleDesignation() {
          const { data } = await axiosInstance.get(DESIGNATIONS_API + 'getSingleDesignation/' + targetID)
          setDesignationInfo(data)
        }
        fetchAndSetSingleDesignation()

      }, [targetID, axiosInstance])

    return (
        <div className="crud_view_content">
            {
                designationInfo &&
                <>
                    <h1>Department</h1>
                    <p>{designationInfo.department?.name}</p>
                    <h1>Designation</h1>
                    <p>{designationInfo.name}</p>
                    <h1>Description</h1>
                    <p>{designationInfo.description}</p>
                    <h1>Slug</h1>
                    <p>{designationInfo.slug}</p>
    
                </>
            }
            
        </div>
    )
}

export default ViewDesignation
import {
  RiHome2Line,
  RiImage2Line,
  RiBook2Line,
  RiHome3Line,
  RiBarChartLine,
  RiLogoutBoxRLine,
  RiUser2Line,
  RiGroupLine,
  RiMapPin2Line,
  RiShoppingCart2Line,
  RiShoppingCartLine,
  RiShakeHandsLine,
  RiSlideshow2Line,
  RiExternalLinkLine,
} from "react-icons/ri";
import { TbReportAnalytics } from "react-icons/tb";
import "./SidebarItems.css";
import { useContext, useState } from "react";
import DropDownItems from "./DropDownItems/DropDownItems";
import AppContext from "../../../../../AppContext/AppContext";
import AllOrders from "../../../../Screens/MainScreens/OrderScreens/AllOrders";

function SidebarItems() {
  const { logout, employee } = useContext(AppContext);

  //employee.level

  const [selectedDropdownItem, setSelectedDropdownItem] = useState("Dashboard");

  const parentItems = [
    {
      name: "Dashboard",
      type: "normal",
      children: <RiHome2Line />,
      // sidebarItems: [
      //   {
      //     title: 'Dashboard',
      //   },
      // ]
    },

    {
      name: "Products",
      type: "dropdown",
      children: <RiBarChartLine />,
      sidebarItems: [
        {
          title: "Products",
          children: <RiBook2Line />,
        },
        // {
        //   title: 'ChangedInventories',
        //   children: <RiBook2Line />
        // },
        {
          title: "Product Report",
          children: <RiBook2Line />,
        },
        {
          title: "Product Grouping",
          children: <RiBook2Line />,
        },
        {
          title: "Product Feature",
          children: <RiBook2Line />,
        },
      ],
    },
    {
      name: "Orders",
      type: "dropdown",
      children: <RiShoppingCart2Line />,
      sidebarItems: [
        {
          title: "Orders",
          children: <RiShoppingCartLine />,
        },
        {
          title: "All Orders",
          children: <RiShoppingCart2Line />,
        },
        {
          title: "All Sales Orders",
          children: <RiShoppingCart2Line />,
        }
      ],
    },
    {
      name: "Users",
      type: "dropdown",
      children: <RiUser2Line />,
      sidebarItems: [
        {
          title: "Customers",
          children: <RiGroupLine />,
        },
        {
          title: "Employees",
          children: <RiGroupLine />,
        },
        {
          title: "Loyalty Clubs",
          children: <RiExternalLinkLine />,
        },
      ],
    },
    {
      name: "Acquisition tools",
      type: "dropdown",
      children: <RiShakeHandsLine />,
      sidebarItems: [
        {
          title: "Promo Code",
          children: <RiBook2Line />,
        },
        {
          title: "Discounts",
          children: <RiImage2Line />,
        },
        {
          title: "Partners",
          children: <RiBook2Line />,
        },
        {
          title: "Demand Products",
          children: <RiBook2Line />,
        },
      ],
    },
    {
      name: "Frontend Content",
      type: "dropdown",
      children: <RiHome3Line />,
      sidebarItems: [
        {
          title: "Sliders",
          children: <RiSlideshow2Line />,
        },
        {
          title: "Faqs",
          children: <RiImage2Line />,
        },
        // {
        //   title: 'Blogs',
        //   children: <RiImage2Line />
        // },
        {
          title: "Social Links",
          children: <RiExternalLinkLine />,
        },

      ],
    },
    {
      name: "Zone",
      type: "dropdown",
      children: <RiMapPin2Line />,
      sidebarItems: [
        {
          title: "Divisions",
          children: <RiBook2Line />,
        },
      ],
    },
    {
      name: "Reports",
      type: "dropdown",
      children: <TbReportAnalytics />,
      sidebarItems: [
        {
          title: "Sales Report",
          children: <TbReportAnalytics />,
        },
        {
          title: "Report Process",
          children: <TbReportAnalytics />,
        },
        {
          title: "Order Sales Report",
          children: <TbReportAnalytics />,
        },
        {
          title: "Collaborator Sales",
          children: <TbReportAnalytics />,
        },
        {
          title: "New And Old Customer Reports",
          children: <TbReportAnalytics />,
        },
        {
          title: "Top Sold Products",
          children: <TbReportAnalytics />,
        },
      ],
    },
  ];
  const sidebarItems = {
    superAdmin: parentItems,
    admin: [
      { name: "Dashboard", type: "normal", children: <RiHome2Line /> },
      {
        name: "Products",
        type: "dropdown",
        children: <RiBarChartLine />,
        sidebarItems: [{ title: "Products", children: <RiBarChartLine /> }],
      },
      {
        name: "Orders",
        type: "dropdown",
        children: <RiShoppingCart2Line />,
        sidebarItems: [
          { title: "Orders", children: <RiShoppingCart2Line /> },
          { title: "Sales Report", children: <TbReportAnalytics /> },
        ],
      },
      {
        name: "Acquisition tools",
        type: "dropdown",
        children: <RiBarChartLine />,
        sidebarItems: [
          { title: "DemandProducts", children: <RiBarChartLine /> },
        ],
      },
    ],

    operationsHead: [
      { name: "Dashboard", type: "normal", children: <RiHome2Line /> },
      {
        name: "Products",
        type: "dropdown",
        children: <RiBarChartLine />,
        sidebarItems: [
          { title: "Products", children: <RiBarChartLine /> },
          { title: "Products Feature", children: <RiBarChartLine /> },
        ],
      },
      {
        name: "Orders",
        type: "dropdown",
        children: <RiShoppingCart2Line />,
        sidebarItems: [{ title: "Orders", children: <RiShoppingCart2Line /> }],
      },
      {
        name: "Acquisition tools",
        type: "dropdown",
        children: <RiShakeHandsLine />,
        sidebarItems: [
          { title: "DemandProducts", children: <RiShakeHandsLine /> },
          { title: "PromoCode", children: <RiShakeHandsLine /> },
        ],
      },
    ],

    customerCare: [
      { name: "Dashboard", type: "normal", children: <RiHome2Line /> },
      {
        name: "Products",
        type: "dropdown",
        children: <RiBarChartLine />,
        sidebarItems: [
          {
            title: "Products",
            children: <RiBook2Line />,
          },
          // {
          //   title: 'ChangedInventories',
          //   children: <RiBook2Line />
          // },
          {
            title: "Product Grouping",
            children: <RiBook2Line />,
          },
          {
            title: "Product Feature",
            children: <RiBook2Line />,
          },
        ],
      },
      {
        name: "Orders",
        type: "dropdown",
        children: <RiShoppingCart2Line />,
        sidebarItems: [{ title: "Orders", children: <RiShoppingCart2Line /> }],
      },
      {
        name: "Acquisition tools",
        type: "dropdown",
        children: <RiShakeHandsLine />,
        sidebarItems: [{ title: "PromoCode", children: <RiShakeHandsLine /> }],
      },
    ],
    marketing: [
      { name: "Dashboard", type: "normal", children: <RiHome2Line /> },
      {
        name: "Products",
        type: "dropdown",
        children: <RiBarChartLine />,
        sidebarItems: [{ title: "Products", children: <RiBarChartLine /> }],
      },
    ],
    production: [
      { name: "Dashboard", type: "normal", children: <RiHome2Line /> },
      {
        name: "Products",
        type: "dropdown",
        children: <RiBarChartLine />,
        sidebarItems: [
          { title: "Products", children: <RiBarChartLine /> },
          { title: "Products Feature", children: <RiBarChartLine /> },
        ],
      },
      {
        name: "Acquisition tools",
        type: "dropdown",
        children: <RiShakeHandsLine />,
        sidebarItems: [
          { title: "DemandProducts", children: <RiShakeHandsLine /> },
        ],
      },
    ],
  };

  // Get sidebar items based on employee's access level
  const employeeSidebarItems = sidebarItems[employee.level] || [];

  return (
    <div className="nav_wrapper">
      <nav className="sidebar_nav">
        <div>
          {employeeSidebarItems.map((pItem) => (
            <DropDownItems
              key={pItem.name}
              icon={pItem.children}
              type={pItem.type}
              dropDownTitle={pItem.name}
              selectedDropdownItem={selectedDropdownItem}
              setSelectedDropdownItem={setSelectedDropdownItem}
              sidebarItems={pItem.sidebarItems}
            />
          ))}
          {/* <SidebarItem title="Dashboard" children={<RiHome2Line />} />
          <div className="dropDownItems">
            <DropDownItems
              showDropDownItems={showHomeItems}
              setShowDropDownItems={setShowHomeItems}
              icon={<RiHome3Line />}
              dropDownTitle="Frontend Content"
            />
            {showHomeItems && (
              <div className="d_items">
                <SidebarItem title="Sliders" children={<RiImage2Line />} />
                <SidebarItem title="Faqs" children={<RiImage2Line />} />
                <SidebarItem title="Blogs" children={<RiBook2Line />} />
                <SidebarItem title="Social Links" children={<RiImage2Line />} />
              </div>
            )}
          </div>
          <div className="dropDownItems">
            <DropDownItems
              showDropDownItems={showProductItems}
              setShowDropDownItems={setShowProductItems}
              icon={<RiBarChartLine />}
              dropDownTitle="Products"
            />
            {showProductItems && (
              <div className="d_items">
                <SidebarItem title="Colors" children={<RiBook2Line />} />
                <SidebarItem title="Products" children={<RiBook2Line />} />
                <SidebarItem title="Product Colors" children={<RiBook2Line />} />
                <SidebarItem title="Brands" children={<RiBook2Line />} />
                <SidebarItem title="Catagories" children={<RiBook2Line />} />
                <SidebarItem title="Sizes" children={<RiBook2Line />} />
                <SidebarItem title="Materials" children={<RiBook2Line />} />
                <SidebarItem title="Paired Products" children={<RiBook2Line />} />
                <SidebarItem title="Price Histories" children={<RiBook2Line />} />
                <SidebarItem title="Product Sizes" children={<RiBook2Line />} />
                <SidebarItem title="Product Materials" children={<RiBook2Line />} />
              </div>
            )}
          </div>
          <div className="dropDownItems">
            <DropDownItems
              showDropDownItems={showZoneItems}
              setShowDropDownItems={setshowZoneItems}
              icon={<RiBarChartLine />}
              dropDownTitle="Zone"
            />
            {showZoneItems && (
              <div className="d_items">
                <SidebarItem title="Divisions" children={<RiBook2Line />} />
              </div>
            )}

          </div>
          <div className="dropDownItems">
            <DropDownItems
              showDropDownItems={showUsers}
              setShowDropDownItems={setShowUsers}
              icon={<RiUser2Line />}
              dropDownTitle="Users"
            />
            {showUsers && (
              <div className="d_items">
                <SidebarItem title="Invite Employees" children={<RiGroupLine />} />
                <SidebarItem title="Departments" children={<RiGroupLine />} />
                <SidebarItem title="Roles" children={<RiGroupLine />} />
                <SidebarItem title="Designations" children={<RiGroupLine />} />
                <SidebarItem title="Employee Roles" children={<RiGroupLine />} />
                <SidebarItem title="Employee Designations" children={<RiGroupLine />} />
              </div>
            )}

          </div> */}
        </div>
        <div>
          <div className="item log_out">
            <button onClick={logout}>
              <RiLogoutBoxRLine />
              <span>Log Out</span>
            </button>
          </div>
        </div>
      </nav>
    </div>
  );
}

export default SidebarItems;


import { Tooltip as ReactTooltip } from "react-tooltip";
function CRUDButton({ children, handleClick, deleteButton, tooltip, tooltipName, tooltipContent }) {
    return (
        <>
        <button
            className={deleteButton ? 'trash' : ''}
            onClick={handleClick}
            title={tooltip}
            data-tooltip-id={tooltipName} 
        >
            {children}
        </button>
        <ReactTooltip
        id={tooltipName}
        place="top"
        content={tooltipContent}
      />
        </>
    )
}

export default CRUDButton
import { createBrowserRouter } from 'react-router-dom';
import App from './App';
import MainScreens from './Screens/MainScreens/MainScreens';
import MainRoutes from './Screens/MainScreens/MainRoutes';
import AuthScreens from './Screens/AuthScreens/AuthScreens';
import authRoutes from './Screens/AuthScreens/AuthRoutes';

const routes = createBrowserRouter([
  {
    path: '/',
    element: <App />,
     children: [
      {
        path: '/main',
        element: <MainScreens />,
        children: MainRoutes
      },
      {
        path: '/auth',
        element: <AuthScreens />,
        children: authRoutes
      }
    ]
  }

])

export default routes
import { useEffect, useState } from 'react'
import useAxiosInstance from '../../../../../Utilities/Hooks/useAxiosInstance'
import { PRODUCTSIZE_API } from '../../../../../Utilities/APIs/APIs'

function ViewProductSize({ targetID }) {

    const axiosInstance = useAxiosInstance()

    const [productSizeInfo, setProductSizeInfo] = useState(null)

    useEffect(() => {
        async function fetchAndSetSingleProductSize() {
            const { data } = await axiosInstance.get(PRODUCTSIZE_API + 'getSingleProductSize/' + targetID)
            setProductSizeInfo(data)
        }
        fetchAndSetSingleProductSize()

    }, [targetID, axiosInstance])

    return (
        <div className="crud_view_content">
            {
                productSizeInfo &&
                <>
                    <h1>Product</h1>
                    <p>{productSizeInfo.product?.name}</p>

                    <h1>Size</h1>
                    <p>{productSizeInfo.size?.name}</p>
                    <h1>quantity</h1>
                    <p>{productSizeInfo?.quantity}</p>
                </>
            }

        </div>
    )
}

export default ViewProductSize
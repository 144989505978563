import { NavLink } from "react-router-dom"
import './SidebarItem.css'

function SidebarItem({  children, title }) {

    return (
        <div className="item">
            <NavLink to={
                '/main/' +
                (
                    title.includes(' ')
                    ?
                    title.replace(/\s/g, '')
                    :
                    title
                )
            } >
                {children}
                    <span>{title}</span>
            </NavLink>
        </div>
    )
}

export default SidebarItem
import { useEffect, useState } from 'react'
import useAxiosInstance from '../../../../../Utilities/Hooks/useAxiosInstance';
import { DISTRICT_API } from '../../../../../Utilities/APIs/APIs'

function ViewDistrict({targetID}) {

    const axiosInstance = useAxiosInstance()
    
    const [districtInfo, setDistrictInfo] = useState(null)

    useEffect(() => {
        async function fetchAndSetSingleDistrict() {
          const { data } = await axiosInstance.get(DISTRICT_API + 'getDistrictsFromDivision/' + targetID)
          setDistrictInfo(data)
        }
        fetchAndSetSingleDistrict()

      }, [targetID, axiosInstance])

    return (
        <div className="crud_view_content">
            {
                districtInfo &&
                <>
                    <h1>Division</h1>
                    <p>{districtInfo.division}</p>
                    <h1>District</h1>
                    <p>{districtInfo.name}</p>
                </>
            }
            
        </div>
    )
}

export default ViewDistrict
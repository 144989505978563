// import axios from 'axios'
// import { ORDERS_API } from '../../../../Utilities/APIs'

function OrderDelivered({
  setShowProcessingOrder,
  targetID,
  handleStatusChange,
}) {
  return (
    <div className="delete_item">
      <div className="delete_content">
        <h1>Are you sure?</h1>
        <p>Please confirm you want to deliver this order?</p>
        <div className="delete_btn">
          <button
            className="delete"
            onClick={() => handleStatusChange(targetID)}
          >
            Accept
          </button>
          <button
            className="cancel"
            onClick={() => {
              setShowProcessingOrder(false);
            }}
          >
            Cancel
          </button>
        </div>
      </div>
    </div>
  );
}

export default OrderDelivered;

import React, { useEffect, useState } from 'react'
import useAxiosInstance from '../../../../../Utilities/Hooks/useAxiosInstance'
import { PRODUCT_COST } from '../../../../../Utilities/APIs/APIs'

function ViewProductCost({ targetID }) {
    const axiosInstance = useAxiosInstance()

    const [productCost, setProductCost] = useState(null)
    console.log("🚀 ~ ViewProductCost ~ productCost:", productCost)

    useEffect(() => {
        async function fetchAndSetSingleProductImage() {
            const { data } = await axiosInstance.get(PRODUCT_COST + "getSingleManageCost/" + targetID)
            setProductCost(data)
        }
        fetchAndSetSingleProductImage()

    }, [targetID, axiosInstance])


  return (
    <div className="crud_view_content">
    {
        productCost &&
        <>
            <h1>Product</h1>
            <p>{productCost?.product?.name}</p>
            <h1>Unit Cost</h1>
            <p>{productCost?.product?.unitCost}</p>
            <h1>Total Cost</h1>
            <p>{productCost?.product?.totalCost}</p>
        </>
    }

</div>
  )
}

export default ViewProductCost
import './CRUDTable.css'

function CRUDTable({ screenHeaderHeight, children }) {
    return (
        <div className='data_table_wrapper' style={{ height: `calc(100% - ${screenHeaderHeight}px)` }}>
            <div className="data_table_inner">
                <table className="data_table" >
                    {children}
                </table>
            </div>
        </div>
    )
}

export default CRUDTable
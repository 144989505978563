import { useEffect, useRef, useState } from 'react'
import CreateButton from '../../../../Partials/Layouts/CRUDs/CRUDHeader/CreateButton/CreateButton';
import CRUDTable from '../../../../Partials/Layouts/CRUDs/CRUDTable/CRUDTable';
import CRUDTableHeader from '../../../../Partials/Layouts/CRUDs/CRUDTable/CRUDTableHeader/CRUDTableHeader';
import CRUDth from '../../../../Partials/Layouts/CRUDs/CRUDTable/CRUDTableHeader/CRUDth/CRUDth';
import ViewButton from '../../../../Partials/Layouts/CRUDs/CRUDButtons/ViewButton/ViewButton';
import EditButton from '../../../../Partials/Layouts/CRUDs/CRUDButtons/EditButton/EditButton';
import DeleteButton from '../../../../Partials/Layouts/CRUDs/CRUDButtons/DeleteButton/DeleteButton';
import ShortTextCell from '../../../../Partials/Layouts/CRUDs/CRUDTable/CRUDTableCells/ShortTextCell/ShortTextCell';
import CRUDTableRow from '../../../../Partials/Layouts/CRUDs/CRUDTable/CRUDTableRow/CRUDTableRow';
import Modal from '../../../../Partials/Elements/Modal/Modal';
import CreateBrand from './CreateBrand';
import ViewBrand from './ViewBrand';
import useAxiosInstance from '../../../../../Utilities/Hooks/useAxiosInstance';
import DeleteItem from '../../../../Partials/Layouts/DeleteItem/DeleteItem';
import UpdateBrand from './UpdateBrand';
import { BRANDS_API } from '../../../../../Utilities/APIs/APIs';


function Brands() {

  const [brands, setBrands] = useState(null)
  const screenHeaerRef = useRef()
  const [screenHeaderHeight, setScreenHeaderHeight] = useState(0);

  useEffect(() => {
    let screenHeaderHeight = screenHeaerRef.current.clientHeight;
    setScreenHeaderHeight(screenHeaderHeight)
  }, [screenHeaerRef]);

  const [showCreateForm, setShowCreateForm] = useState(false);
  const [showViewSection, setShowViewSection] = useState(false);
  const [showUpdateForm, setShowUpdateForm] = useState(false);
  const [showDeleteSection, setShowDeleteSection] = useState(false)
  const [toggleFetch, setToggleFetch] = useState(false)

  const [targetID, setTargetID] = useState(null)
  const axiosInstance = useAxiosInstance()

  useEffect(() => {

    async function fetchAndSetBrands() {
      const { data } = await axiosInstance.get(BRANDS_API)
      setBrands(data)
    }
    fetchAndSetBrands()

  }, [toggleFetch, axiosInstance])

  function triggerFetch() {
    setToggleFetch(prevState => !prevState)
  }
  return (
    <>
      <div className='screen_header' ref={screenHeaerRef}>
        <h1 className='screen_heading'>Brands ({brands?.length}  in total)</h1>
        <CreateButton
          screenTopicSingular={'Brand'}
          setShowCreateForm={setShowCreateForm}
        />
      </div>
      <CRUDTable screenHeaderHeight={screenHeaderHeight}>
        <CRUDTableHeader>
          <CRUDth th='Brand' />
          <CRUDth th='Slug' />
          <CRUDth th='Description' />
          <CRUDth th='brandCategory' />
          <CRUDth th='Actions' />
        </CRUDTableHeader>
        <tbody>
          {
            brands?.map(brand =>
              <CRUDTableRow key={brand._id}>
                <ShortTextCell text={brand.name} />
                <ShortTextCell text={brand.slug} />
                <ShortTextCell text={brand.description} />
                <ShortTextCell text={brand.brandCategory} />
                <td>
                  <div className="action_buttons_wrapper">
                    <div className="action_buttons">
                      <ViewButton
                        setShowViewSection={setShowViewSection}
                        targetID={brand._id}
                        setTargetID={setTargetID}
                        tooltipName={"View"} 
                      tooltipContent={"View"}
                      />

                      <EditButton
                        setShowUpdateForm={setShowUpdateForm}
                        targetID={brand._id}
                        setTargetID={setTargetID}
                        tooltipName={"Edit"} 
                      tooltipContent={"Edit"}
                      />

                      <DeleteButton
                        setShowDeleteSection={setShowDeleteSection}
                        targetID={brand._id}
                        setTargetID={setTargetID}
                        tooltipName={"Delete"} 
                      tooltipContent={"Delete"}
                        
                      />
                    </div>
                  </div>
                </td>
              </CRUDTableRow>

            )
          }
        </tbody>
      </CRUDTable>
      {
        showCreateForm &&
        <Modal
          extraClass={'small'}
          modalHeading={`Add Brand`}
          setShowModalContent={setShowCreateForm}
        >
          <CreateBrand
            triggerFetch={triggerFetch}
            setShowCreateForm={setShowCreateForm}
          />
        </Modal>
      }
      {
        showUpdateForm &&
        <Modal
          extraClass={'small'}
          modalHeading={`Update Brand`}
          setShowModalContent={setShowUpdateForm}
        >
          <UpdateBrand
            triggerFetch={triggerFetch}
            targetID={targetID}
            setShowUpdateForm={setShowUpdateForm}
          />
        </Modal>
      }
      {
        showViewSection &&
        <Modal
          extraClass={'small'}
          modalHeading={`View Brand`}
          setShowModalContent={setShowViewSection}
        >
          <ViewBrand
            targetID={targetID}
          />
        </Modal>
      }
      {
        showDeleteSection &&
        <Modal
          extraClass={'small'}
          modalHeading={`Delete Brand`}
          setShowModalContent={setShowDeleteSection}
        >
          <DeleteItem
            api={BRANDS_API}
            triggerFetch={triggerFetch}
            targetID={targetID}
            setShowDeleteItem={setShowDeleteSection}
          />
        </Modal>
      }
    </>
  )
}

export default Brands
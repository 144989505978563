import { useEffect, useState } from 'react'
import useAxiosInstance from '../../../../../Utilities/Hooks/useAxiosInstance'
import { PRODUCT_IMAGES_API } from '../../../../../Utilities/APIs/APIs'
import Image from '../../../../Partials/Elements/Image/Image'

function ViewProductImage({ targetID }) {

    const axiosInstance = useAxiosInstance()

    const [productImage, setProductImage] = useState(null)

    useEffect(() => {
        async function fetchAndSetSingleProductImage() {
            const { data } = await axiosInstance.get(PRODUCT_IMAGES_API + 'getSingleProductImage/' + targetID)
            setProductImage(data)
        }
        fetchAndSetSingleProductImage()

    }, [targetID, axiosInstance])

    return (
        <div className="crud_view_content">
            {
                productImage &&
                <>

                    <h1>Image</h1>
                    <Image
                        imgLink={productImage.image}
                        imgAlt={' Image'}
                    />

                    <h1>Is Active</h1>
                    <p>{productImage.isActive === true ? 'True' : 'False'}</p>

                    <h1>Precedence</h1>
                    <p>{productImage.precedence}</p>


                </>
            }

        </div>
    )
}

export default ViewProductImage
import { useEffect, useState } from 'react'
import useAxiosInstance from '../../../../../Utilities/Hooks/useAxiosInstance';
import { AREA_API } from '../../../../../Utilities/APIs/APIs'

function ViewArea({targetID}) {

    const axiosInstance = useAxiosInstance()
    
    const [areaInfo, setAreaInfo] = useState(null)

    useEffect(() => {
        async function fetchAndSetSingleArea() {
          const { data } = await axiosInstance.get(AREA_API + targetID)
          setAreaInfo(data)
        }
        fetchAndSetSingleArea()

      }, [targetID, axiosInstance])

    return (
        <div className="crud_view_content">
            {
                areaInfo &&
                <>
                    <h1>District</h1>
                    <p>{areaInfo.district}</p>
                    <h1>Area</h1>
                    <p>{areaInfo.area}</p>
                </>
            }
            
        </div>
    )
}

export default ViewArea
import { Link } from "react-router-dom"
import Image from "../Image/Image"
import logo from '../../../../Assets/Images/logo.svg'

function Logo({ handleClick }) {
    return (
        <Link href="/" onClick={handleClick}>
            <Image
                imgLink={logo}
                imgAlt={'brand'}
                className={'logo_wide'}
                assetOrWeb
            />
        </Link>
    )
}

export default Logo
import { useState } from "react"
import useAxiosInstance from "../../../../../../../Utilities/Hooks/useAxiosInstance"
import { useEffect } from "react"
import { ADDRESS_API } from "../../../../../../../Utilities/APIs/APIs"

function ViewAddress({ targetID }) {

    const axiosInstance = useAxiosInstance()

    const [address, setAddress] = useState(null)

    useEffect(() => {
        async function fetchAndSetSingleAddress() {
            const { data } = await axiosInstance.get(ADDRESS_API + 'getSingleAddress/' + targetID)
            setAddress(data)
        }
        fetchAndSetSingleAddress()

    }, [targetID, axiosInstance])

    return (
        <div className="crud_view_content">
            {
                address &&
                <>
                    <h1>Type</h1>
                    <p>{address.type}</p>

                    <h1>Name</h1>
                    <p>{address.name}</p>
                    
                    <h1>Area</h1>
                    <p>{address.area}</p>

                    <h1>FullAddress</h1>
                    <p>{address.fullAddress}</p>

                    <h1>ZipCode</h1>
                    <p>{address.zipCode}</p>

                </>
            }

        </div>
    )
}

export default ViewAddress
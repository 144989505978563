import Logo from "../../../Elements/Logos/Logo"


function SidebarTop() {

    return (
        <div className="logo_wrapper">
            <Logo />
        </div>

    )
}

export default SidebarTop
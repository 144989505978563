import { useState } from "react"
import useAxiosInstance from "../../../../../Utilities/Hooks/useAxiosInstance"
import Form from "../../../../Partials/Layouts/Forms/Form"
import ShortTextInput from "../../../../Partials/Layouts/Forms/FormInputs/ShortTextInput/ShortTextInput"
import FormSubmitButton from "../../../../Partials/Layouts/Forms/FormSubmitButton/FormSubmitButton"
import { FAQ_API } from "../../../../../Utilities/APIs/APIs"
import SwitchInput from "../../../../Partials/Layouts/Forms/FormInputs/SwitchInput/SwitchInput"
import CustomEditor from "../../../../Partials/Elements/CustomEditor/CustomEditor"
import NumberInput from "../../../../Partials/Layouts/Forms/FormInputs/NumberInput/NumberInput"

function CreateFAQ({ type, triggerFetch, setShowCreateForm }) {

    const axiosInstance = useAxiosInstance()

    const [title, setTitle] = useState('')
    const [description, setDescription] = useState('')
    const [isActive, setisActive] = useState(false)
    const [precedence, setPrecedence] = useState(0)

    async function handleSubmit(e) {

        e.preventDefault()
        const itemData = { title, type, description, isActive, precedence }

        const response = await axiosInstance.post(FAQ_API, itemData)
        if (response) {
            triggerFetch()
            setShowCreateForm(false)
        }
    }

    return (
        <div className='crate_form'>
            <Form onSubmit={handleSubmit}>
                <div>
                    <ShortTextInput
                        label={`Title`}
                        value={title}
                        placeholder={`Enter Title`}
                        setState={setTitle}
                    />

                    <CustomEditor setState={setDescription} />
                    <SwitchInput
                        label={`Is Active`}
                        toggleSwitch={() => setisActive(prevState => !prevState)}
                        checked={isActive}
                    />
                    <NumberInput
                        label={`Precedence`}
                        value={precedence}
                        placeholder={`Enter Precedence`}
                        setState={setPrecedence}
                    />

                </div>
                <FormSubmitButton text="Submit" />
            </Form>
        </div>
    )
}

export default CreateFAQ
import React, { useEffect, useState } from "react";
import useAxiosInstance from "../../../../../../Utilities/Hooks/useAxiosInstance";
import { PRODUCT_COLLECTIONS_API } from "../../../../../../Utilities/APIs/APIs";

function ViewProductCollectionProducts({ targetID }) {
  const axiosInstance = useAxiosInstance();

  const [ProductCollectionProductsInfo, setProductCollectionsInfo] = useState(null);
  useEffect(() => {
    async function fetchAndSetSingleProductCollections() {
      const { data } = await axiosInstance.get(
        PRODUCT_COLLECTIONS_API + 'getSingleProductCollectionProduct/' + targetID
      );
      setProductCollectionsInfo(data);
    }
    fetchAndSetSingleProductCollections();
  }, [targetID, axiosInstance]);

  return (
    <div className="crud_view_content">
      {ProductCollectionProductsInfo && (
        <>
          <h1>Product Collection</h1>
          <p>{ProductCollectionProductsInfo?.productCollection?.name}</p>
          <h1>Product</h1>
          <p>{ProductCollectionProductsInfo?.product?.name}</p>
        </>
      )}
    </div>
  );
}

export default ViewProductCollectionProducts;

import { useState } from "react"
import useAxiosInstance from '../../../../../Utilities/Hooks/useAxiosInstance';
import Form from "../../../../Partials/Layouts/Forms/Form"
import ShortTextInput from "../../../../Partials/Layouts/Forms/FormInputs/ShortTextInput/ShortTextInput"
import FormSubmitButton from "../../../../Partials/Layouts/Forms/FormSubmitButton/FormSubmitButton"
import { AREA_API } from "../../../../../Utilities/APIs/APIs"

function CreateArea({district, triggerFetch, setShowCreateForm}) {

    const axiosInstance = useAxiosInstance()

    const [name, setName] = useState('')  
    // const [district, setDistrict] = useState('')

    async function handleSubmit(e) {

        e.preventDefault()
        const itemData = new FormData()

        itemData.append('name', name)
        itemData.append('district', district)

        const response = await axiosInstance.post(AREA_API, itemData)
        if (response) {
            triggerFetch()
            setShowCreateForm(false)
        }
    }

    return (
        <div className='crate_form'>
            <Form onSubmit={handleSubmit}>
                <div>
                    <ShortTextInput
                        label={`Area`}
                        value={name}
                        placeholder={`Enter Area`}
                        setState={setName}
                    />
 
                </div>
                <FormSubmitButton text="Submit" />
            </Form>
        </div>
    )
}

export default CreateArea
import { useEffect, useState } from "react"
import { EMPLOYEE_API, ROLES_API, EMPLOYEE_ROLES_API } from "../../../../../../Utilities/APIs/APIs"
import useAxiosInstance from "../../../../../../Utilities/Hooks/useAxiosInstance"
import Form from "../../../../../Partials/Layouts/Forms/Form"
import FormSubmitButton from "../../../../../Partials/Layouts/Forms/FormSubmitButton/FormSubmitButton"
import SelectInput from "../../../../../Partials/Layouts/Forms/FormInputs/SelectInput/SelectInput"
import SelectOption from "../../../../../Partials/Layouts/Forms/FormInputs/SelectInput/SelectOption/SelectOption"

function CreateEmployeeRoles({ triggerFetch, setShowCreateForm }) {

    const axiosInstance = useAxiosInstance()

    const [employee, setEmployee] = useState('')
    const [role, setRole] = useState('')

    const [employees, setEmployees] = useState(null)
    const [roles, setRoles] = useState(null)

    useEffect(() => {

        async function fetchAndSetEmployees() {
            const { data } = await axiosInstance.get(EMPLOYEE_API)
            setEmployees(data)
        }
        fetchAndSetEmployees()

        async function fetchAndSetRoles() {
            const { data } = await axiosInstance.get(ROLES_API)
            setRoles(data)
        }
        fetchAndSetRoles()

    }, [axiosInstance])

    async function handleSubmit(e) {

        e.preventDefault()
        const itemData = new FormData()

        itemData.append('employee', employee)
        itemData.append('role', role)

        const response = await axiosInstance.post(EMPLOYEE_ROLES_API, itemData)
        if (response) {
            triggerFetch()
            setShowCreateForm(false)
        }
    }

    return (
        <div className='crate_form'>
            <Form onSubmit={handleSubmit}>
                <div>
                    <SelectInput
                        label={'Employee'}
                        value={employee}
                        setState={setEmployee}
                    >
                        <SelectOption optionValue='' optionText="Select Employee" />
                        {employees?.map((employee => (
                            <SelectOption optionValue={employee._id} key={employee._id}
                                optionText={employee?.name} />
                        )))}
                    </SelectInput>

                    <SelectInput
                        label={'Role'}
                        value={role}
                        setState={setRole}
                    >
                        <SelectOption optionValue='' optionText="Select Role" />
                        {roles?.map((role => (
                            <SelectOption optionValue={role._id} key={role._id}
                                optionText={role?.name} />
                        )))}
                    </SelectInput>
                  
                </div>
                <FormSubmitButton text="Add Employee Role" />
            </Form>
        </div>
    )
}

export default CreateEmployeeRoles
import { useEffect, useState } from 'react'
import useAxiosInstance from '../../../../../Utilities/Hooks/useAxiosInstance'
import { PAIREDPRODUCT_API } from '../../../../../Utilities/APIs/APIs'

function ViewPairedProduct({ targetID }) {

    const axiosInstance = useAxiosInstance()

    const [pairedProductInfo, setPairedProductInfo] = useState(null)

    useEffect(() => {
        async function fetchAndSetSinglePairedProduct() {
            const { data } = await axiosInstance.get(PAIREDPRODUCT_API + 'getSinglePairedProduct/' + targetID)
            setPairedProductInfo(data)
        }
        fetchAndSetSinglePairedProduct()

    }, [targetID, axiosInstance])

    return (
        <div className="crud_view_content">
            {
                pairedProductInfo &&
                <>
                    <h1>Main Product</h1>
                    <p>{pairedProductInfo.product1?.name}</p>

                    <h1>Paired Product</h1>
                    <p>{pairedProductInfo.product2?.name}</p>
    
                </>
            }

        </div>
    )
}

export default ViewPairedProduct
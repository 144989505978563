import { useEffect, useState } from "react"
import useAxiosInstance from "../../../../../../Utilities/Hooks/useAxiosInstance"
import NavCardList from "../../../../../Partials/Layouts/NavCardList/NavCardList"
import NavCard from "../../../../../Partials/Layouts/NavCardList/NavCard/NavCard"
import { RiCheckboxCircleFill, RiCloseCircleFill, RiGiftFill, RiRepeatFill, RiTruckFill } from "react-icons/ri"


export const ordersFromStatusRoute ='/main/ordersFromStatus/' 

function ProductScreen() {

    const axiosInstance = useAxiosInstance()

    const [orders, setOrders] = useState(null)

    // useEffect(() => {

    //     async function fetchAndSetItems() {
    //         const {data} = await axiosInstance.get('getAllOrders')
    //         setOrders(data)
    //     }
    //     fetchAndSetItems()
    // }, [axiosInstance])

    return (

            <NavCardList numOfCards='five'>
                <NavCard
        cardName={`Materials`}
        navCardLink={'/main/Materials'}
      >
        <RiGiftFill />
      </NavCard>
            </NavCardList>
    )
}

export default ProductScreen
import { RiDeleteBinLine } from "react-icons/ri"
import CRUDButton from "../CRUDButton"



function DeleteButton({ setShowDeleteSection, targetID, setTargetID, tooltipContent, tooltipName }) {
    return (
        <CRUDButton
            handleClick={() => {
                setShowDeleteSection(true)
                setTargetID(targetID)
            }}
            deleteButton
            tooltipName={tooltipName}
            tooltipContent={tooltipContent}
        >
            <RiDeleteBinLine />
        </CRUDButton>
    )
}

export default DeleteButton
import { useState } from "react";

import { ORDERS_API } from "../../../../../Utilities/APIs/APIs";
import SelectInput from "../../../../Partials/Layouts/Forms/FormInputs/SelectInput/SelectInput";
import SelectOption from "../../../../Partials/Layouts/Forms/FormInputs/SelectInput/SelectOption/SelectOption";
import useAxiosInstance from "../../../../../Utilities/Hooks/useAxiosInstance";
import Form from "../../../../Partials/Layouts/Forms/Form";
import FormSubmitButton from "../../../../Partials/Layouts/Forms/FormSubmitButton/FormSubmitButton";
import SwitchInput from "../../../../Partials/Layouts/Forms/FormInputs/SwitchInput/SwitchInput";

function ChangeOrderStatus({
  targetID,
  triggerFetch,
  setShowChangeOrderStatus,
}) {
  const axiosInstance = useAxiosInstance();

  const [orderStatus, setOrderStatus] = useState('')
  const [notify, setNotify] = useState(false)
  console.log(notify, 'notify');

  //   useEffect(() => {
  //     async function fetchAndSetSingleFAQ() {
  //       const { data } = await axiosInstance.get( FAQ_API + "getSingleFAQ/" + targetID);
  //       setTitle(data.title);
  //       setDescription(data.description);
  //       setisActive(data.isActive);
  //     }
  //     fetchAndSetSingleFAQ();
  //   }, [targetID, axiosInstance]);

  async function handleSubmit(e) {
    e.preventDefault();
    const itemData = { orderStatus, notify };

    const response = await axiosInstance.patch(ORDERS_API + 'changeOrderStatus/' + targetID, itemData);
    if (response) {
      triggerFetch();
      setShowChangeOrderStatus(false);
    }
  }

  return (
    <div className="crate_form">
      <Form onSubmit={handleSubmit}>
        <div>
          <SelectInput label={"Order Status"} value={orderStatus} setState={setOrderStatus}>
            <SelectOption optionValue="" optionText="Select Order Status" />
            {/* <SelectOption optionValue="pending" optionText="Pending" /> */}
            <SelectOption optionValue="dispatchedInHouse" optionText="Dispatched (InHouse)" />
            <SelectOption optionValue="dispatchedCourier" optionText="Dispatched (Courier)" />
            <SelectOption optionValue="delivered" optionText="Delivered" />
            <SelectOption optionValue="deliveredUnpaid" optionText="Delivered (Unpaid)" />
            <SelectOption optionValue="refunded" optionText="Refunded" />
            <SelectOption optionValue="exchanged" optionText="Exchanged" />
            <SelectOption optionValue="canceled" optionText="Canceled" />
          </SelectInput>

          <SwitchInput
              label={`Notify`}
              toggleSwitch={() => setNotify((prevState) => !prevState)}
              checked={notify}
            />
          {/* {
           ( orderStatus === 'dispatchedInHouse' ||
            orderStatus === 'dispatchedCourier' ||
            orderStatus === 'delivered' ||
            orderStatus === 'deliveredUnpaid') &&

            <SwitchInput
              label={`Notify`}
              toggleSwitch={() => setNotify((prevState) => !prevState)}
              checked={notify}
            />
          } */}

        </div>
        <FormSubmitButton text="Submit" />
      </Form>
    </div>
  );
}

export default ChangeOrderStatus;

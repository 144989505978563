import { useEffect, useState } from 'react'
import useAxiosInstance from '../../../../../Utilities/Hooks/useAxiosInstance';
import { PARTNERS_API } from '../../../../../Utilities/APIs/APIs'

function ViewPartner({targetID}) {
    

    const axiosInstance = useAxiosInstance()
    const [partnerInfo, setpartnerInfo] = useState(null)

    useEffect(() => {
        async function fetchAndSetSinglePartner() {
          const { data } = await axiosInstance.get(PARTNERS_API + 'getSinglePartner/' + targetID)
          setpartnerInfo(data)
        }
        fetchAndSetSinglePartner()

      }, [targetID, axiosInstance])

    return (
        <div className="crud_view_content">
            {
                partnerInfo &&
                <>
                    <h1>Partner</h1>
                    <p>{partnerInfo.name}</p>

                    <h1>Slug</h1>
                    <p>{partnerInfo.slug}</p>

                    <h1>Status</h1>
                    <p>{partnerInfo.isActive === true ? 'True' : 'False'}</p>
                </>
            }
            
        </div>
    )
}

export default ViewPartner
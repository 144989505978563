import React, { useState } from "react";
import FormSubmitButton from "../../../../../Partials/Layouts/Forms/FormSubmitButton/FormSubmitButton";
import useAxiosInstance from "../../../../../../Utilities/Hooks/useAxiosInstance";
import { CHANGED_INVENTORIES_API } from "../../../../../../Utilities/APIs/APIs";
import { Form } from "react-router-dom";
import ShortTextInput from "../../../../../Partials/Layouts/Forms/FormInputs/ShortTextInput/ShortTextInput";
import SelectInput from "../../../../../Partials/Layouts/Forms/FormInputs/SelectInput/SelectInput";
import SelectOption from "../../../../../Partials/Layouts/Forms/FormInputs/SelectInput/SelectOption/SelectOption";
import DateInput from "../../../../../Partials/Layouts/Forms/FormInputs/DateInput/DateInput";

function CreateChangedInventories({ id, triggerFetch, setShowCreateForm }) {
  const axiosInstance = useAxiosInstance();

  const [quantity, setQuantity] = useState("");
  const [date, setDate] = useState("");
  const [changeType, setChangeType] = useState("");
  // const [endQuantity, setEndQuantity] = useState("");
  // const [startQuantity, settartQuantity] = useState("");

  async function handleSubmit(e) {
    e.preventDefault();
    const itemData = {
      productSize: id,
      quantity,
      date,
      changeType,
      // startQuantity,
      // endQuantity,
    };

    const response = await axiosInstance.post(
      CHANGED_INVENTORIES_API,
      itemData
    );
    if (response) {
      triggerFetch();
      setShowCreateForm(false);
    }
  }

  return (
    <div className="crate_form">
      <Form onSubmit={handleSubmit}>
        <div>
          <ShortTextInput
            label={`quantity`}
            value={quantity}
            placeholder={`Enter Quantity`}
            setState={setQuantity}
          />

          <DateInput label={"Date"} value={date} setState={setDate} />
          <SelectInput
            label={"change Type"}
            value={changeType}
            setState={setChangeType}
          >
            <SelectOption optionValue="" optionText="Select Change Type" />
            <SelectOption optionValue="sold" optionText="sold" />
            <SelectOption optionValue="return" optionText="return" />
            <SelectOption optionValue="newStock" optionText="newStock" />
          </SelectInput>

          {/* <ShortTextInput
            label={`start Quantity`}
            value={startQuantity}
            placeholder={`Enter Start Quantity`}
            setState={settartQuantity}
          />

          <ShortTextInput
            label={`end Quantity`}
            value={endQuantity}
            placeholder={`Enter End Quantity`}
            setState={setEndQuantity}
          /> */}
        </div>
        <FormSubmitButton text="Add Changed Inventories" />
      </Form>
    </div>
  );
}
export default CreateChangedInventories;

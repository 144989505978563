import React from 'react'
import { EMPLOYEE_API } from '../../../../../../../../Utilities/APIs/APIs'
import useAxiosInstance from '../../../../../../../../Utilities/Hooks/useAxiosInstance'

function MakeEmployee({targetID, triggerFetch, setShowMakeEmployeeSection}) {
    const axiosInstance = useAxiosInstance()
    async function handleMakeAdmin() {

        const response = await axiosInstance.patch(EMPLOYEE_API + 'createAdminToEmployeeBySuperAdmin/' + targetID, {})
    
        if (response) {
            setShowMakeEmployeeSection(false)
            triggerFetch()
        }
      }
  return (
    <div className="delete_item">
    <div className="delete_content">
      <h1>Are you sure?</h1>
      <p> You want to make this employee a Employee?</p>
      <div className="delete_btn">
        <button
          onClick={handleMakeAdmin}
          className='delete'
        >Make Employee
        </button>
        <button className='cancel'
          onClick={() => {
            setShowMakeEmployeeSection(false)
          }}
        >Cancel</button>
      </div>
    </div>
  </div>
  )
}

export default MakeEmployee